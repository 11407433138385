import * as actionType from './actionsTypes';
import { faqList, addFaq, editFaq } from '../services/faqService';
import { resHandle2 } from '../helper';

const initialState = {
    loader: false,
    toggleFAQModal: false,
    faqs: [],
    faq: '',
    isEditMode: false,
    isViewMode: false
}


/************************************************************************************/
// offer Reducer
/************************************************************************************/


const faqReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionType.FAQ_LOADER:
            return {
            ...state,
            loader: !state.loader
        }


        case actionType.FAQ_LIST:
            return {
            ...state,
            loader: false,
            faqs: action.payload
        }

        case actionType.FAQ_MODAL:
            return {
            ...state,
            faq: '',
            toggleFAQModal: state.isEditMode ? false : !state.toggleFAQModal,
            isEditMode: false,
        }


        case actionType.FAQ_EDIT_VIEW:
            return {
            ...state,
            isEditMode: true,
            toggleFAQModal: true,
            faq: action.payload
        }


        case actionType.FAQ_VIEW:
            return {
            ...state,
            toggleFAQModal: true,
            isViewMode: true,
            faq: action.payload
        }


        case actionType.FAQ_VIEW_CLOSE:
            return {
            ...state,
            toggleFAQModal: false,
            isViewMode: false,
            faq: ''
        }


        case actionType.FAQ_ERROR:
            return {
            ...state,
            loader: false,
            error: action.payload
        }

        

        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/

export const faqModalModalAction = () => dispatch => dispatch({ type: actionType.FAQ_MODAL });

export const editViewAction = payload => dispatch => dispatch({ type: actionType.FAQ_EDIT_VIEW, payload });

export const viewAction = payload => dispatch => dispatch({ type: actionType.FAQ_VIEW, payload });

export const viewCloseAction = () => dispatch => dispatch({ type: actionType.FAQ_VIEW_CLOSE });


export const faqListAction = params => async dispatch => {
    dispatch({ type: actionType.FAQ_LOADER });
    try {
        let faqs = await faqList(params);
        let { status, data } = resHandle2(faqs);
        if(status){
            dispatch({ type: actionType.FAQ_LIST, payload: data });
            return status;
        }else{
            dispatch({ type: actionType.FAQ_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.FAQ_ERROR, payload: error });
    }
}



export const addFaqAction = params => async dispatch => {
    dispatch({ type: actionType.FAQ_LOADER });
    try {
        let faq = await addFaq(params);
        let { status, data } = resHandle2(faq);
        if(status){
            dispatch({ type: actionType.FAQ_MODAL });
            return status;
        }else{
            dispatch({ type: actionType.FAQ_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.FAQ_ERROR, payload: error });
    }
}



export const editFaqAction = params => async dispatch => {
    dispatch({ type: actionType.FAQ_LOADER });
    try {
        let faq = await editFaq(params);
        let { status, data } = resHandle2(faq);
        if(status){
            dispatch({ type: actionType.FAQ_MODAL });
            return status;
        }else{
            dispatch({ type: actionType.FAQ_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.FAQ_ERROR, payload: error });
    }
}

export default faqReducer;