import ApiInstance from './intercepter';
import API from '../config/api';


export const userListService = params => ApiInstance.post(API.USER_LIST, params);

export const directorListService = params => ApiInstance.post(API.DIRECTOR_LIST, params);

export const changeStatusService = params => ApiInstance.put(API.CHANGE_USER_STATUS, params);

export const deleteUserService = params => ApiInstance.delete(API.DELETE_USER + params);

export const unreadCountService = () => ApiInstance.get(API.UNREAD_COUNT);

export const fastSignupService = params => ApiInstance.post(API.USER_FAST_SIGNUP, params);

export const getUserDetailService = params => ApiInstance.get(API.USER_DETAIL + params);

export const uploadMediaService = params => ApiInstance.post(API.UPLOAD_MEDIA , params);

export const editParticipantService = params => ApiInstance.post(API.EDIT_PARTICIPANT , params);

export const addParticipantsService = params => ApiInstance.post(API.ADD_PARTICIPANTS , params);

export const deleteParticipantService = params => ApiInstance.delete(API.DELETE_PARTICIPANT + params);

export const getCustomerCardsService = params => ApiInstance.get(API.GET_CUSTOMER_PROFILE + params);

export const deleteCustomerCardService = params => ApiInstance.post(API.DELETE_CUSTOMER_CARD, params);

export const createCustomerCardService = (query, params) => ApiInstance.post(API.CREATE_CUSTOMER_CARD + query, params);

export const billingDetailService = params => ApiInstance.get(API.BILLING_DETAILS + params);

export const userImpersonate = params => ApiInstance.post(API.ADMIN_USER_IMPERSONATE, params);