import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';


import uiReducer from './ui';
import authReducer from './auth';
import campReducer from './camps';
import categoryReducer from './category';
import offerReducer from './offer'
import termsReducer from './terms';
import notificationReducer from './notification';
import advertisementReducer from './advertisement';
import faqReducer from './faq';
import userReducer from './user';
import supportReducer from './support';

const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    camp: campReducer,
    category: categoryReducer,
    offer: offerReducer,
    terms: termsReducer,
    notification: notificationReducer,
    advertisement: advertisementReducer,
    faq: faqReducer,
    user: userReducer,
    support: supportReducer,
});


const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware
    )
);

export default store;