import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { isValidEmail } from '../../helper';
import { forgotPasswordAction } from '../../store/auth';
import { MainLoader } from '../reuse/SVG';

const ForgotPassword = props => {
    const [email, setEmail] = useState('');
    const [emailSuccess, setEmailSuccess] = useState(0);
    const [emailError, setEmailError] = useState('');


    const handleChange = e => {
        setEmail(e.target.value);
        setEmailError('');
    }


    const handleValidate = () => {
        let validate = true;
        if(email == ''){
            setEmailError('Email is required');
            validate = false
        }else if(!isValidEmail(email)){
            setEmailError('Email is not valid');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                email
            }
            props.forgotPasswordAction(params).then(status => setEmailSuccess(status));
        }
    }



    return(
        <div className="d-flex align-items-center auth_wrapper fp_wrapper">
            {props.loading ? <MainLoader /> : ''}
            {emailSuccess ? <Redirect to="/success" /> : '' }
            <div className="container">
                <div className="row no-gutters h-100 d-flex align-items-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 text-center">
                        <img src={require('../../assets/img/logo.svg')} alt="logo" />
                        <h2 className="mb-5 mt-5">Forgot Password</h2>
                        <form onSubmit={handleSubmit}>
                            

                            <div className="form-group mb-4 text-left">
                                <label>Email</label>
                                <div className="icon_input">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        name="email"
                                        value={email}
                                        onChange={handleChange} />
                                        {emailError ? <OverlayTrigger
                                                            placement="left"
                                                            overlay={<Tooltip>{emailError}</Tooltip>}
                                                        >
                                                            <span className="error_tootltip" />
                                                        </OverlayTrigger> : ''}
                                </div>
                                    
                            </div>

                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                            <Link to="/login" className="btn btn-link btn-block color_black">Cancel</Link>

                            {props.error && <p className="error_msg">{props.error}</p>}

                        </form>
                    </div>
                </div>
            </div>

            <div className="copyright">© 2020 CampsEZ. All Rights Reserved.</div>
        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loading, error } = state.auth;
    return {
        loading,
        error
    };
}
  
const mapDispatchToProps = dispatch => ({
    forgotPasswordAction: params => dispatch(forgotPasswordAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);