import React from 'react';
import { connect } from 'react-redux';

import CategoryModal from './CategoryModal';
import CouponCodeModal from './CouponCodeModal'
import GiftCardModel from './GiftCardModel'
import SpecialDealsModal from './SpecialDealsModal'
import TermsModal from './TermsModal';
import FaqModal from './FaqModal';
import AddProgramModal from './AddProgramModal';
import ProgramMediaModal from './ProgramMediaModal';
import AdditionalExperience from './AdditionalExperience';
import AddScheduleModal from './AddScheduleModal';


const Modals = props => (
    <React.Fragment>
        <CategoryModal />
        <GiftCardModel />
        <SpecialDealsModal />
        <TermsModal />
        <FaqModal />
        <AddProgramModal />
        {props.progMediaModal ? <ProgramMediaModal /> : ''}
        {props.toggleCoupanCodeModal ? <CouponCodeModal /> : ''}
        <AdditionalExperience />
        <AddScheduleModal />
    </React.Fragment>
)


const mapStateToProps = state => {
    let { progMediaModal } = state.camp;
    let { toggleCoupanCodeModal } = state.offer;
    
    return {
      progMediaModal,
      toggleCoupanCodeModal
    };
  }
  
  
  export default connect(mapStateToProps, null)(Modals);


