import ApiInstance from './intercepter';
import API from '../config/api';


export const addFaq = params => ApiInstance.post(API.CREATE_FAQ, params);

export const editFaq = params => ApiInstance.post(API.FAQ_EDIT, params);

export const faqList = params => ApiInstance.get(API.FAQ_LIST + params);

export const faqStatusChange = params => ApiInstance.put(API.FAQ_CHANGE_STATUS, params);

export const faqDelete = params => ApiInstance.delete(API.FAQ_DELETE, {data: params});