import React, { useState, useRef, useCallback, useEffect, Fragment } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Empty, InputNumber, notification } from 'antd';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactCrop from 'react-image-crop';
import { uploadMediaService } from '../../services/userService';
import { colorsList, shortsAndTshirtSizes, getCroppedImg } from '../../helper';
import { connect } from 'react-redux';
import { createProductService, deleteProductService, editProductService, getProductsService } from '../../services/otherService';
import Pagination from 'react-js-pagination';
import { MainLoader } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';


const OnlineStore = (props) => {
    const [mainLoader, setMainLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [activeId, setActiveId] = useState('');
    const [activeProduct, setActiveProduct] = useState('');

    const [hasSize, setHasSize] = useState(true);



    const [productList, setProductList] = useState([]);
    const [productTotalCount, setProductTotalCount] = useState(0);
    const [selectedCampCode, setSelectedCampCode] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
  


    const [title, setTitle] = useState('');
    const [skuNumber, setSkuNumber] = useState('');
    const [price, setPrice] = useState('');
    const [textColor, setTextColor] = useState('');
    const [sizes, setSizes] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [desc, setDesc] = useState('');
    const [registrationDisplay, setRegistrationDisplay] = useState(0);
    const [storeDisplay, setStoreDisplay] = useState(1);

    const [titleErr, setTitleErr] = useState('');
    const [skuNumberErr, setSkuNumberErr] = useState('');
    const [priceErr, setPriceErr] = useState('');
    const [textColorErr, setTextColorErr] = useState('');
    const [campCode, setCampCode] = useState('');



    const [images, setImages] = useState([]);
    const [imagesErr, setImagesErr] = useState([]);
    const [cImage, setCImage] = useState('');
    const [upImg, setUpImg] = useState();
    const [uploadImage, setUploadImage] = useState('');
    const [loader, setLoader] = useState(false);
    const imgRef = useRef(null);
    const mediaRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 / 1 });



    const closeModal = () => {
        setShowModal(false);
        setActiveProduct('');
        setTitle('');
        setSkuNumber('');
        setTextColor('');
        setPrice('');
        setTotalCount(0);
        setRegistrationDisplay(0);
        setStoreDisplay(1);
        setTitleErr('');
        setSkuNumberErr('');
        setPriceErr('');
        setImages([]);
        setImagesErr([]);
        setTextColorErr('');
        setDesc('');
        setSizes([]);
        setActiveId('');
        setConfirmModal(false);
    }

    const handleEditModalOpen = data => {
        setActiveProduct(data)
        setShowModal(true);
        setTitle(data.title);
        setSkuNumber(data.skuNumber);
        setPrice(data.price);
        setTotalCount(data.totalCount);
        setDesc(data.desc);
        setRegistrationDisplay(data.registrationDisplay);
        setStoreDisplay(data.storeDisplay);
        if(data?.image?.length){
            setImages(data.image);
        }

        setCampCode({label: data.campName, value: data.campCode})

        setSizes(data.sizes);

        let _hasSize = data.sizes.filter(i => i.name);
        if(_hasSize.length){

        }else{
            setHasSize(false)
        }
 
        if(data.textColor){
            setTextColor({label: data.textColor, value: data.textColor});
        }
    }




    const getProducts = () => {
        setMainLoader(true);
        let query = `?campCode=${selectedCampCode}&page=${page - 1}&limit=${limit}&search=${search}`;
        getProductsService(query).then(res => {
            setMainLoader(false);
            if(res?.data?.statusCode){
                setProductList(res.data.responseData.result);
                setProductTotalCount(res.data.responseData.count);
            }
        })
    }


    useEffect(() => {
        getProducts()
    }, [page, search, selectedCampCode])




   
    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          setCImage(e.target.files)
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        }
    };
    
    
    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);



    const completedCrop = crop => {
        getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => setUploadImage(res))
    }


    const handleFileUpload = () => {
        setImagesErr('');
        if(upImg){
          setLoader(true);
                const data = new FormData();
                data.append('file', uploadImage);
    
                uploadMediaService(data).then((res) => {
                    if(res.data.responseData.result.fileUrl !== undefined) {
                        setLoader('');
                        setCImage('');
                        setUpImg('');
                        let _images = [...images];
                        _images.push(res.data.responseData.result.fileUrl)
                        setImages(_images);
                    }
                    
                })
   
        }
    }


    const handleRemove = (index) => {
        let _images = [...images];
        _images.splice(index, 1);
        setImages(_images);
    }




    const addInventory  = () => {
        let _sizes = [...sizes];
        _sizes.push({
			value : 0,
			name : '',
			color: ''
		});

        setSizes(_sizes);
    }




    const handleInventoryChange = (e, type, i) => {
        let _sizes = [...sizes];
        if(type == 'name'){
            _sizes[i].name = e.value
        }

        if(type == 'color'){
            _sizes[i].color = e.value
        }

        if(type == 'count'){
            _sizes[i].value = e;

            let _totalCount = 0;

            for (let j = 0; j < _sizes.length; j++) {
                if(_sizes[j].value){
                    _totalCount += _sizes[j].value;
                }
            }

            setTotalCount(_totalCount);
        }
        

        setSizes(_sizes);

    }




    const handleValidate = () => {
        let validate = true;

        if(title == ''){
            setTitleErr('Title is required');
            validate = false
        }

        if(price == ''){
            setPriceErr('Price is required');
            validate = false
        }

        if(skuNumber == ''){
            setSkuNumberErr('SKU number is required');
            validate = false
        }

        if(images.length == 0){
            setImagesErr('Product image is required');
            validate = false
        }
        

        return validate

    }





    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){

            setBtnLoader(true);

            let _sizes = sizes.map(i => ({
                ...i,
                color: i.color || 'No Color',
                name: i.name || 'No Size',
                value: i.value || 0,
            }))

            let params = {
                title,
                skuNumber,
                textColor: textColor.value,
                price,
                registrationDisplay,
                storeDisplay,
                sizes: _sizes,
                totalCount,
                desc,
                image: images,
                campCode: campCode.value,
                isAll: 1
            }

            if(activeProduct._id){
                params.itemId = activeProduct._id
            }

            let _service = activeProduct._id ? editProductService : createProductService;

            _service(params).then(res => {
                setBtnLoader(false);
                if(res?.data?.statusCode){
                    closeModal();
                    getProducts();
                }
               
            })
        }
    }



    const handleDelete = () => {

        deleteProductService(`/${activeId}`).then(res => {
            closeModal();
            if(res.data.statusCode){
                notification.open({
                    description:
                        'Product Deleted Successfully',
                }); 
                getProducts();
            }
        })
    }



    const handleDeleteInventry = index => {
        let _sizes = [...sizes];
        _sizes.splice(index, 1);

        let _totalCount = 0;

            for (let j = 0; j < _sizes.length; j++) {
                if(_sizes[j].value){
                    _totalCount += _sizes[j].value;
                }
            }

            setTotalCount(_totalCount);
            setSizes(_sizes);

    }

    return(
        <div className="page_wrapper">

            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    value={search}
                    onChange={e => (setSearch(e.target.value), setPage(1))}
                    className="form-control"
                    placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center page_card_header">
                    <h4>Products</h4>
                    <div className='ml-auto pp_fiter'>
                        <Select
                            className="form-control"
                            onChange={e => setSelectedCampCode(e.value)}
                            placeholder="Filter by Provider"
                            classNamePrefix="cm_select"
                            options={[{label: 'All', value: ''}, ...props?.campList?.map(i => ({label: i.campName, value: i.campCode}))]} />
                    </div>
                    <button onClick={() => setShowModal(true)} className="btn btn-primary pl-5 pr-5 ml-4">Add Product</button>
                </div>

                

                {
                    mainLoader ? <div className="p_loader"><MainLoader /> </div> : (
                        <div className="page_card_body">
                            {
                                productList.length ? productList.map(item => (
                                    <div key={item._id} className='product_wide_card'>
                                        <div className='product_wide_card_img'>
                                            <img src={item.image[0]} />
                                        </div>

                                        <div className='product_wide_card_title'>
                                            <h4>{item.title}</h4>
                                            <div><small><b>SKU Number:</b> {item.skuNumber}</small></div>
                                            <div> <small><b>Total Count Available:</b> {item.totalCount}</small></div>
                                            <div> <small><b>Provider Name:</b> {item.campName}</small></div>
                                        </div>

                                        <div className='product_wide_card_detail ml-auto'>
                                            <p>${item?.price?.toFixed(2)}</p>
                                            <div className='p_actions'>
                                                <i title="Delete" onClick={() => (setConfirmModal(true), setActiveId(item._id))} className="icon-delete" />
                                                <i title="Edit" onClick={() => handleEditModalOpen(item)} className="icon-pancil" />
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                                )
                            }
                        </div>
                    )
                }


                <div className='d-flex justify-content-center'>
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={productTotalCount}
                        pageRangeDisplayed={4}
                        onChange={e => (setPage(e), window.scroll(0, 0))} />
                </div>

            </div>




            <ConfirmModal
                show={confirmModal}
                hideModal={() => (setConfirmModal(false), setActiveId(''))}
                msg="Are you sure you want to delete?"
                action={handleDelete} />







            <Modal
                dialogClassName="h_auto"
                show={showModal}
                animation={false}
                size="xl"
                centered>
                <Modal.Body>
                    <div className="p-2 product_modal input_err_pos">
                        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                        <h3>{activeProduct._id ? 'Edit' : 'Create'}  Product</h3>
                       
                        <form onSubmit={handleSubmit}>

                            <div className='row'>
                                <div className='col-lg-6 border-right'>
                                    <div className='form-group'>
                                        <label>Title<b>*</b></label>
                                        <input
                                            type='text'
                                            value={title}
                                            onChange={e => (setTitle(e.target.value), setTitleErr(''))}
                                            className={`form-control ${ titleErr ? 'is-invalid' : '' }`} />

                                        {titleErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{titleErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                        

                                    <div className='d-flex'>

                                        <div className='form-group w-100 mr-2'>
                                            <label>Price<b>*</b></label>
                                            <InputNumber
                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                onChange={e => (setPrice(e), setPriceErr(''))}
                                                value={price}
                                                min={0}
                                                precision={2}
                                                placeholder='$'
                                                step={1}
                                                className={`form-control ${ priceErr ? 'is-invalid' : '' }`} />
                                           
                                            {priceErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{priceErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>


        
                                        <div className='form-group w-100 ml-2'>
                                            <label>SKU Number</label>
                                            <input
                                                type='text'
                                                value={skuNumber}
                                                onChange={e => (setSkuNumber(e.target.value), setSkuNumberErr(''))}
                                                className={`form-control ${ skuNumberErr ? 'is-invalid' : '' }`} />

                                            {skuNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{skuNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>
                                       
                                    </div>


                                    <div className='form-group' style={{zIndex: '9999'}}>
                                        <label>Text Color</label>
                                        <Select
                                            className={`form-control ${ textColorErr ? 'is-invalid' : '' }`}
                                            placeholder="Select"
                                            value={textColor}
                                            onChange={e => (setTextColor(e), setTextColorErr(''))}
                                            classNamePrefix="cm_select"
                                            options={colorsList.map(item => ({label: item, value: item}))} />

                                        {textColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{textColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className='form-group' style={{zIndex: '999'}}>
                                        <label>Providers</label>
                                        <Select
                                            className="form-control"
                                            placeholder="Select Program"
                                            onChange={e => setCampCode(e)}
                                            value={campCode}
                                            classNamePrefix="cm_select"
                                            options={props?.campList?.map(i => ({label: i.campName, value: i.campCode}))}/>
                                    </div>


                                    

                                   

                                 


                                    <div className="check_box_wrapper mt-3">
                                        <input
                                            type="checkbox" onChange={() => setRegistrationDisplay(registrationDisplay == 0 ? 1 : 0)} checked={registrationDisplay == 1} />
                                            <span className="check_box checkbox_dark">
                                                <span />
                                                Display Item During Registration
                                            </span>
                                    </div>

                                    <div className="check_box_wrapper mt-3">
                                        <input
                                             type="checkbox" onChange={() => setStoreDisplay(storeDisplay == 0 ? 1 : 0)} checked={storeDisplay == 1} />
                                            <span className="check_box checkbox_dark">
                                                <span />
                                                Display Item In Store
                                            </span>
                                    </div>

                                    
                                </div>
                                <div className='col-lg-6'>



                                <div className="text-center">
                                        <div className="icon_upload_wrapper upload_wrapper_sm">
                                            <input ref={mediaRef} type="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                                            <div><i className="icon-button" /><p>Upload Product Image</p></div>
                                        </div>
                                        <ReactCrop
                                            src={upImg}
                                            onImageLoaded={onLoad}
                                            crop={crop}
                                            onChange={c => setCrop(c)}
                                            onComplete={completedCrop} />

                                        {imagesErr ? <h6 className='err_msg mb-3'>{imagesErr}</h6> : ''}

                                        <div className="text-center mb-4">
                                            {upImg ? <span onClick={loader ? null : handleFileUpload} style={{paddingLeft: '50px', paddingRight: '50px'}} className={`btn btn-primary d-inline rounded-0 btn-block mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Save'}</span> : ''}
                                        </div>
                                    
                                        <ul className="m-0 p-0 product_imgs">
                                            {images.map((item, i) => (
                                                    <li key={i}><img src={item} /> <i className="icon-cross" onClick={() => handleRemove(i)} /></li>
                                                ))}
                                        </ul>

                                       
                                    </div>


                                    <div className="form-group my_editer">
                                        <label>Description</label>
                                        <ReactQuill
                                            className=""
                                            theme="snow"
                                            value={desc}
                                            onChange={e => setDesc(e)} />
                                    </div>

                                 
                                           



                                    

                                    
                                </div>
                            </div>


                            <div className='form-group mt-5 pos_r_z'>
                                    <div className='d-flex align-content-center mb-4'>
                                        <label> {hasSize ? 'Sizes' : 'Total Count Available'}  <b>*</b></label>
                                    </div>

                                    <ul className='m-0 p-0'>
                                        {
                                            sizes.map((inventory, i) => (
                                                <li className='d-flex justify-content-between align-items-center mb-3' key={i}>

                                                    <Select
                                                        value={inventory.name ? {label: inventory.name, value: inventory.name} : ''}
                                                        onChange={e => handleInventoryChange(e, 'name', i)}
                                                        className={`form-control ${ false ? 'is-invalid' : '' }`}
                                                        placeholder="Select Size"
                                                        classNamePrefix="cm_select"
                                                        options={[{label: 'No Size', value: 'No Size'}, ...shortsAndTshirtSizes.map(item => ({label: item, value: item}))]} /> 

                                                    <Select
                                                        className={`form-control ml-3 ${ false ? 'is-invalid' : '' }`}
                                                        onChange={e => handleInventoryChange(e, 'color', i)}
                                                        value={inventory.color ? {label: inventory.color, value: inventory.color} : ''}
                                                        placeholder="Select Color"
                                                        classNamePrefix="cm_select"
                                                        options={[{label: 'No Color', value: 'No Color'}, ...colorsList.map(item => ({label: item, value: item}))]} /> 

                                                
                                                    <InputNumber
                                                        value={inventory.value ? inventory.value : ''}
                                                        onChange={e => handleInventoryChange(e, 'count', i)}
                                                        min={0}
                                                        precision={0}
                                                        placeholder='Total Count'
                                                        step={1}
                                                        className="form-control ml-3"  />

                                                    <i className="icon-delete pointer ml-3" onClick={() => handleDeleteInventry(i)} />
                                                </li>
                                            ))
                                        }
                                    </ul>

                                    <div className='mb-4'>
                                        <span className='btn btn-dark btn-sm' onClick={addInventory}>Add Inventory</span>
                                    </div>

                                    <h6><b>Total Count Available: {totalCount}</b></h6>

                        </div>

                            <div className='d-flex justify-content-center mt-3'>
                                <span onClick={closeModal} className='btn btn-dark px-5'>Cancel</span>
                                <button type='submit'  disabled={btnLoader} className={`btn btn-primary rounded-0 ml-3 px-5 ${btnLoader ? 'btnLoader' : ''}`}>{activeProduct._id ? 'Update' : 'Create'}</button>
                            </div>


                        </form>
                       
                    </div>
                </Modal.Body>
            </Modal>
        
        </div>
    )
}



const mapStateToProps = state => {
    let { preList } = state.ui;
    let { camp } = preList;


    return {
        campList: camp
    };
}


const mapDispatchToProps = dispatch => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineStore);