import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import Pagination from 'react-js-pagination';

import { setBreadcrumbAction } from '../../store/ui';
import { faqModalModalAction, faqListAction, editViewAction, viewAction } from '../../store/faq';
import { faqStatusChange, faqDelete } from '../../services/faqService';
import { MiniLoader, MainLoader, Empty } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';


const Faq = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [faqId, setFaqId] = useState('');

    let params = {
        icon: 'FAQ',
        links: [
            { name: 'FAQ', path: '' }
        ]
    }


    const faqList = () => {;
        let data = `?limit=10&page=${page}&search=${search}`;
        props.faqListAction(data)
    };


    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    useEffect(() => {
        faqList();
    }, [page]);

    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            faqList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])



    const changeStatus = faq => {
        setMiniLoader({[faq._id]: true});

        let params = {
            faqId: faq._id,
            status: faq.status == 0 ? '1' : '0'
        }
        

        faqStatusChange(params).then(status => {
            setMiniLoader('');
            notification.open({
                message: 'FAQ Status',
                description: `FAQ status change successfully to  ${faq.status == 1 ? 'Inactive' : 'Active'}`
            });
            faqList();
        }); 
    }


    const handleConfirmModal = currentId => {
        setFaqId(currentId);
        setConfirmModal(!confirmModal);
    }




    const handleDelete = () => {
        let params = { faqId: faqId };
        faqDelete(params).then(status => {
            setFaqId('');
            faqList();
            setConfirmModal(false);
        }); 
    }


    return (
        <div className="page_wrapper">
            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}
                <i className="icon-search" />
                <input
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    className="form-control"
                    placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>FAQ</h4>
                    <button onClick={props.faqModalModalAction} className="btn btn-primary pl-5 pr-5">Add FAQ</button>
                </div>

                <div className="page_card_body ">

                    <div className="coupan_code_wrapper">

                        <div className="table_responsive_wrap mt-4 text-center">
                            <div className="table-responsive">

                                <table className="table table-bordered table-striped no_t_texts">

                                    <thead>
                                        <tr>
                                            <th><span className="t_text">S.No</span></th>
                                            <th><span className="t_text">Heading</span></th>
                                            <th><span className="t_text">FAQ Question</span></th>
                                            <th><span className="t_text">FAQ Answer</span></th>
                                            <th><span className="t_text">Was this FAQ Answer Helpful?</span></th>
                                            <th><span className="t_text">Action</span></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        props.faqs.map((faq, i) => (
                                            <tr key={faq._id}>
                                                <td><span className="t_text">{((i + 1) + (page * 10))}</span></td>
                                                <td><span className="t_text">{faq.heading}</span></td>
                                                <td className="p-0">
                                                    <table className="table child_table">
                                                        {faq.list ? faq.list.map(item => (
                                                            <tr>
                                                                <td><span className="t_text">{item.question}</span></td>
                                                            </tr>
                                                        )) : '' }
                                                    </table></td>
                                                <td className="p-0">
                                                    <table className="table child_table">
                                                        {faq.list ? faq.list.map(item => (
                                                            <tr>
                                                                <td><span className="t_text">{item.answer}</span></td>
                                                            </tr>
                                                        )) : '' }
                                                    </table></td>


                                                <td className="p-0">
                                                    <table className="table child_table">
                                                    {faq.list ? faq.list.map(item => (
                                                            <tr>
                                                                <td><span className="t_text">
                                                                    {(item.yes == 0) ? 0 : ((item.yes / (item.yes + item.no)) * 100).toFixed(0)}% yes , 
                                                                    {(item.no == 0) ? 0 : ((item.no / (item.yes + item.no)) * 100).toFixed(0)}% no
                                                                </span></td>
                                                            </tr>
                                                        )) : '' }
                                                         
                                                    
                                                    </table></td>

                                                <td className="p-0">
                                                    <table className="table child_table">
                                                        {faq.list ? faq.list.map(item => (
                                                            <tr>
                                                                <td>
                                                                <span className="t_text">
                                                                    <i className="icon-pancil" onClick={() => props.editViewAction(item)} />
                                                                    <i className="icon-eye" onClick={() => props.viewAction(faq)} />
                                                                    <i className="icon-delete" onClick={() => handleConfirmModal(item._id)} />
                                                                    <div className="cm_swich_wrap">
                                                                        {miniLoader[item._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={item.status} onChange={() => changeStatus(item)} /><span /></React.Fragment>}
                                                                    </div>
                                                                </span>
                                                                </td>
                                                            </tr>
                                                        )) : '' }
                                                    </table></td>
                                            </tr>
                                        ))
                                    }


                                    </tbody>
                                </table>
                            </div>

                            {props.faqs.length ? '' : <div className="cm_empty mt-3">
                                                            <Empty />
                                                            <p>No Data</p>
                                                        </div> }
                        </div>
                    </div>


                    <div className="mt-4">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={10}
                            totalItemsCount={props.couponCount}
                            pageRangeDisplayed={3}
                            onChange={page => setPage(page)}/>
                    </div>

                </div>

            </div>
            
            <ConfirmModal
                show={confirmModal}
                hideModal={() => setConfirmModal(false)}
                msg="Are you sure you want to delete?"
                action={handleDelete} />

        </div>
    )
}


const mapStateToProps = state => {
    let { faqs } = state.faq;
    return {
        faqs
    }
}


const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    faqModalModalAction: () => dispatch(faqModalModalAction()),
    faqListAction: params => dispatch(faqListAction(params)),
    editViewAction: params => dispatch(editViewAction(params)),
    viewAction: params => dispatch(viewAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);