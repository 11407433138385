export const LOGIN_REQIEST = 'LOGIN_REQIEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SUCCESS_PAGE = 'SUCCESS_PAGE'
export const LOGOUT = 'LOGOUT'
export const TEMP_EMAIL = 'TEMP_EMAIL'
export const BOOKING_LIMIT_ACTION = 'BOOKING_LIMIT_ACTION'

export const SET_BREADCRUMB = 'SET_BREADCRUMB'
export const CATEGORY_MODAL = 'CATEGORY_MODAL'
export const CATEGORY_EDIT_MODAL = 'CATEGORY_EDIT_MODAL'


export const CATEGORY_LIST = 'CATEGORY_LIST'
export const CATEGORY_LOADER = 'CATEGORY_LOADER'
export const CATEGORY_ERROR = 'CATEGORY_ERROR'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL'

export const OFFER_LOADER = 'OFFER_LOADER'
export const OFFER_ACTIVE_TAB = 'OFFER_ACTIVE_TAB'
export const OFFER_ERROR = 'OFFER_ERROR'
export const COUPAN_CODE_MODAL = 'COUPAN_CODE_MODAL'
export const IS_NEW_GIFT_CARD = 'IS_NEW_GIFT_CARD'
export const GIFT_CARD_MODAL = 'GIFT_CARD_MODAL'
export const SPECIAL_DEAL_MODAL = 'SPECIAL_DEAL_MODAL'
export const CREATE_COUPON_CODE = 'CREATE_COUPON_CODE'
export const CREATE_GIFT_CARD = 'CREATE_GIFT_CARD'
export const CREATE_SPECIAL_DEAL = 'CREATE_SPECIAL_DEAL'
export const COUPAN_LIST = 'COUPAN_LIST'
export const GIFT_CARD_LIST = 'GIFT_CARD_LIST'
export const SPECIAL_DEAL_LIST = 'SPECIAL_DEAL_LIST'
export const IS_COUPON_VIEW = 'IS_COUPON_VIEW'
export const IS_GIFT_VIEW = 'IS_GIFT_VIEW'
export const IS_DEAL_VIEW = 'IS_DEAL_VIEW'

export const TERMS_LOADER = 'TERMS_LOADER'
export const TERMS_TYPE = 'TERMS_TYPE'
export const TERMS_MODAL = 'TERMS_MODAL'
export const TERMS_EDIT_MODAL = 'TERMS_EDIT_MODAL'
export const TERMS_LIST = 'TERMS_LIST'
export const TERMS_ERROR = 'TERMS_ERROR'

export const ADD_NOTIFICATION = "ADD_NOTIFICATION"

export const FAQ_LOADER = 'FAQ_LOADER'
export const FAQ_MODAL = 'FAQ_MODAL'
export const FAQ_EDIT_VIEW = 'FAQ_EDIT_VIEW'
export const FAQ_LIST = 'FAQ_LIST'
export const FAQ_VIEW = 'FAQ_VIEW'
export const FAQ_VIEW_CLOSE = 'FAQ_VIEW_CLOSE'
export const FAQ_ERROR = 'FAQ_ERROR'


export const CAMP_LOADER = 'CAMP_LOADER'
export const CAMP_LIST = 'CAMP_LIST'
export const CAMP_DETAIL = 'CAMP_DETAIL'
export const CAMP_ERROR = 'CAMP_ERROR'
export const CLEAR_LOADER = 'CLEAR_LOADER'
export const PAGE_CHANGE = 'PAGE_CHANGE'
export const CLEAR_CAMP_DETAIL = 'CLEAR_CAMP_DETAIL'
export const CAMP_MODE = 'CAMP_MODE'
export const PROGRAM_MEDIA = 'PROGRAM_MEDIA'
export const ADD_PROGRAM_MODAL = 'ADD_PROGRAM_MODAL'
export const EDIT_PROGRAM_MODAL = 'EDIT_PROGRAM_MODAL'
export const VIEW_PROGRAM = 'VIEW_PROGRAM'
export const EXPERIENCE_MODAL = 'EXPERIENCE_MODAL'
export const ADD_SCHEDULER = 'ADD_SCHEDULER'
export const PROGRAM_PAGE_NUMBER = 'PROGRAM_PAGE_NUMBER'

export const PRE_LIST = 'ADMIN_LIST'
export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES'
export const SET_NON_SPACIAL_PROGRAMS = 'SET_NON_SPACIAL_PROGRAMS'
export const PRE_LIST_LOADER = 'ADMIN_LIST_LOADER'
export const PRE_LIST_ERROR = 'ADMIN_LIST_ERROR'


export const REWARD_MODAL = 'REWARD_MODAL'


export const USER_SEARCH = 'USER_SEARCH'
export const USER_ACTIVE_TAB = 'USER_ACTIVE_TAB'
export const DIRECTOR_PAGE_NO = 'DIRECTOR_PAGE_NO'
export const COUNSELOR_PAGE_NO = 'COUNSELOR_PAGE_NO'
export const USER_PAGE_NO = 'USER_PAGE_NO'
export const NON_USER_PAGE_NO = 'NON_USER_PAGE_NO'
export const GET_UNREAD_COUNT = 'GET_UNREAD_COUNT'


export const SUPPORT_SEARCH = 'SUPPORT_SEARCH'
export const SUPPORT_ACTIVE_TAB = 'SUPPORT_ACTIVE_TAB'

export const ADV_SEARCH = 'ADV_SEARCH'
export const PERSONNAL_BY_CAMP_CODE = 'PERSONNAL_BY_CAMP_CODE'






