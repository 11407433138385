import ApiInstance from './intercepter';
import API from '../config/api';


export const getProductsService = params => ApiInstance.get(API.LIST_PRODUCT + params);

export const createProductService = params => ApiInstance.post(API.CREATE_PRODUCT, params);

export const editProductService = params => ApiInstance.post(API.EDIT_PRODUCT, params);

export const deleteProductService = params => ApiInstance.delete(API.PRODUCT_DELETE + params);
