import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Pagination from 'react-js-pagination';
import moment from 'moment';

import {setBreadcrumbAction} from '../../store/ui';
import { donationListService } from '../../services/donationService';
import { MainLoader } from '../reuse/SVG';


const Donation = props => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [donationList, setDonationList] = useState([]);
    const [totalCount, setTotalCount] = useState('');
    const [loader, setLoader] = useState(true);

    let params = {
        icon: 'donation',
        links: [
            { name: 'Donation', path: '' }
        ]
    }

    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);



    const getDonationList = () => {
        let query = `?page=${page - 1}&limit=15&search=${search}`
        donationListService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setDonationList(res.data.responseData.result);
                setTotalCount(res.data.responseData.count);
            }
        })
    }



    useEffect(() => {
        getDonationList();
    }, [search])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getDonationList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    return (
        <div className="page_wrapper">
            {loader ? <MainLoader /> : ''}
            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className="form-control"
                    placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Donation</h4>
                </div>

                <div className="page_card_body">



                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">

                            <table className="table table-bordered table-striped">

                                <thead>
                                <tr>
                                    <th><span className="t_text">S.No</span></th>
                                    <th><span className="t_text">Name</span></th>
                                    <th><span className="t_text">Email</span></th>
                                    <th><span className="t_text">Donation Amount</span></th>
                                    <th><span className="t_text">Phone Number</span></th>
                                    <th><span className="t_text">Created</span></th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    donationList.map((item, i) => (
                                        <tr key={item._id}>
                                            <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                            <td><span className="t_text">{item.name}</span></td>
                                            <td><span className="t_text">{item.email}</span></td>
                                            <td><span className="t_text">${item.amount}</span></td>
                                            <td><span className="t_text">{item.phoneNo}</span></td>
                                            <td><span className="t_text">{moment(item.created).format('MM/DD/YYYY')}</span></td>
                                        </tr>
                                    ))
                                }


                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="mt-3">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
               </div>


                </div>

            </div>


        </div>
    )
}

const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Donation);

const loop = [1, 2, 3, 4, 5, 7, 8, 9, 10]