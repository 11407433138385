import React, { useEffect, Fragment } from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { connect } from 'react-redux';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import { setBreadcrumbAction } from '../../store/ui';
import { viewProgramAction } from '../../store/camps';
import { dateFormat, getProgramType, getExperience, gradeArr, getHtml } from '../../helper';
import { LogoWhite } from '../reuse/SVG';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const MapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `180px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{lng: props.geoLocation ? props.geoLocation[1] : 0, lat: props.geoLocation ? props.geoLocation[0] : 0 }}>
            <Marker
              position={{lng: props.geoLocation ? props.geoLocation[1] : 0, lat: props.geoLocation ? props.geoLocation[0] : 0 }}
              icon={{
                url: require('../../assets/img/mapIcon.svg')
                }}
            >
            </Marker>

    </GoogleMap>
        
     
  );



const ViewPorgram = props => {
    const programId = props.match.params.programId;
    let params = {
        icon: 'camps',
        links: [
            {name: 'Camps', path: 'camps'},
            {name: 'Cyclone Sports Camps', path: ''}
        ]

    }


    useEffect(() => {
        props.setBreadcrumbAction(params);
        props.viewProgramAction(`?programId=${programId}`);
    }, []);

    let { program } = props;

     let {
        aboutProgram,
        addressLine1,
        minAge,
        campLogo,
        maxAge,
        campId,
        categoryName,
        checkInTime,
        checkOutTime,
        city,
        endDate,
        expLevel,
        gender,
        geoLocation,
        grade,
        personnel,
        programCode,
        programImages,
        programInstruction,
        programIntroduction,
        programName,
        programType,
        ratingCount,
        registrationFee,
        schedule,
        startDate,
        state,
        subCategory,
        travel,
        tshirt,
        campName,
        meals,
        isOnline

     } = program;

    return(
        <div className="page_wrapper">

        <div className="page_card">

            <div className="page_card_header">
                <h4>{campName}</h4>
            </div>


            <div className="page_card_body">
                

                <div className="container">


                <div className="d-flex justify-content-between program_head">
                    <div>
                        <h2><img src={campLogo} />{programName}</h2>
                    </div>
                    <div className="text-center">
                        <div className="ratings">
                            <span>EZPro: </span>
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                        </div>
                        <small>({ratingCount} reviews)</small>
                    </div>
                </div>


                    <div className="row">
                        <div className="col-lg-8 prog_main">

                        <div className="prog_media prog_media_desk">
                            {
                                programImages && programImages.length ? <Slider {...settings}>
                                                {programImages.filter(i => !i.viewType).map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : <div className="no_img"><LogoWhite /></div>
                            }
                            
                        </div>


                        <div className="prog_media prog_media_mob">
                            {
                                programImages && programImages.length ? <Slider {...settings}>
                                                    {programImages.filter(i => i.viewType).map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : <div className="no_img"><LogoWhite /></div>
                            }
                            
                        </div>

                        <div className="d-flex justify-content-between mt-3 mb-4 prog_d">
                            <div>
                                <h3>{dateFormat(startDate)} - {dateFormat(endDate)}</h3>
                            </div>
                           

                            <div className="prog_price">
                                <h3>Price : <b>${registrationFee}</b></h3>
                                <small>Price detail</small>
                            </div>
                        </div>

                        <p>{getHtml(aboutProgram)}</p>

                        </div>
                        <div className="col-lg-4">

                        {isOnline ? 
                            (<h2 className="cm_on_line">Online Program</h2>)
                            :
                            (<div className="camp_detail_map">
                                {geoLocation ? <MapComponent geoLocation={geoLocation} {...props} /> : ''}
                                <div className="camp_detail_address">
                                    {city ? <p className="m-0">{`${addressLine1} ${city} ${state}`}</p> : ''}
                                </div>
                            </div>)}



                            <h3>Daily Schedule</h3>

                            <div className="daily_schedule">
                                <ul>

                                    {
                                        schedule && schedule.length ? schedule.map(item => (
                                            <li key={item._id}>
                                                <b>{item.time}</b>
                                                {item.activity}
                                            </li> 
                                        )) : <li>Not updated</li>
                                    }
                                    
                                </ul>
                            </div>
                        </div>
                    </div>






                    <div className="mt-4 mb-4 program_detail">
                        <h3>Program Details</h3>
                        <div className="row mt-3">
                            <div className="col-lg-4">
                                <p><b>Programs :</b> {programName}</p>
                                <p><b>Age Group :</b> {minAge} - {maxAge}</p>
                                <p><b>Gender : </b> 
                                            {gender == 1 ? 'Male' : ''}
                                            {gender == 2 ? 'Female' : ''}
                                            {gender == 3 ? 'Co-Ed' : ''}</p>
                                <p className="last_dot"><b>Grade : </b> {grade && gradeArr.filter(el => grade.find(e => el.value == e )).sort((a, b) => a.count - b.count).map(item => <Fragment key={item}>{item.value}<span>,</span> </Fragment>)}</p>
                                <p className="last_dot"><b>Meals Provided :</b> {meals && meals.length ? meals.map(item => <Fragment key={item}>{item ? item : 'None'}<span>,</span> </Fragment>) : 'None'}</p>
                            </div>
                            <div className="col-lg-4 cm_bordered">
                                <p><b>Check in Time :</b> {checkInTime}</p>
                                <p><b>Check out Time :</b> {checkOutTime}</p>
                                <p><b>Type :</b> {programType ? getProgramType(programType) : ''}</p>
                                <p className="last_dot"><b>Camp Personnel: </b> {personnel && personnel.map(item => <Fragment key={item._id}>{item.name}<span>,</span> </Fragment>)}</p>
                                
                            </div>
                            <div className="col-lg-4">
                                <p><b>Experience :</b> {expLevel ? getExperience(expLevel) : ''}</p>
                                <p><b>Category :</b> {categoryName}</p>
                                <p className="last_dot"><b>Sub Category: </b> {subCategory && subCategory.map(item => <Fragment key={item._id}>{item.name}<span>,</span> </Fragment>)}</p>
                                <p><b>Travel :</b> {travel == 1 ? '' : 'Not'} Included</p>
                                <p><b>T-Shirt Included :</b> {tshirt == 1 ? 'Yes' : 'No'}</p>
                                <p><b>Program Code: </b> {programCode}</p>
                            </div>
                        </div>
                        
                            <p>{getHtml(programIntroduction)}</p>


                       
                    </div>






                    </div>


            </div>

        </div>

        <Link to={`/camps/edit-${campId}`} className="btn btn-outline-primary mt-4 mb-3"><i className="icon-left-arrow" /> GO Back</Link>


    </div>
    )
}



const mapStateToProps = state => {
    let { program } = state.camp;
    return {
        program
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    viewProgramAction: params => dispatch(viewProgramAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPorgram);