import * as actionType from './actionsTypes';

const initialState = {
    loader: false,
    search: ''
}


/************************************************************************************/
// advertisement Reducer
/************************************************************************************/


const advertisementReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.ADV_SEARCH:
            return {
                ...state,
                search: action.payload
            }
         default: return state;
    }

}

/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const advSearchAction = payload => dispatch => dispatch({ type: actionType.ADV_SEARCH, payload });

export default advertisementReducer;