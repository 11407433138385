import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { setBreadcrumbAction } from '../../../store/ui';
import { userSearchAction, activeTabAction } from '../../../store/user';
import ProfileInformation from '../../utils/ProfileInformation';
import CamperInfo from '../../utils/CamperInfo';
import { resHandle } from '../../../helper';
import { getUserDetailService } from '../../../services/userService';



const UserDetail = props => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('profileInfo');
    const [userData, setUserData] = useState({});




    let params = {
        icon: 'users',
        links: [
            { name: 'User', path: '/user'},
            { name: 'User Detail', path: ''},
        ]
    }


    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    const getUserDetail = () => {
        getUserDetailService(props.match.params.id).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setUserData(data.result)
            }
        })
    }


    useEffect(() => {
        if(props.match.params.id){
            getUserDetail();
        }

    }, [props.match.params.id])


    return (
        <div className="page_wrapper">
            <div className="page_card">
                <Tabs onSelect={e => setActiveTab(e)} defaultActiveKey="profileInfo">
                    <Tab eventKey="profileInfo" title="Profile Information">{activeTab == 'profileInfo' ? <ProfileInformation callback={getUserDetail} data={userData} {...props} /> : ''}</Tab>
                    <Tab eventKey="camperInfo" title="Participant(s)">{activeTab == 'camperInfo' ? <CamperInfo callback={getUserDetail} data={userData} {...props} /> : ''}</Tab>
                </Tabs>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { search, activeTab } = state.user;
    return {
        search,
        activeTab
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    userSearchAction: params => dispatch(userSearchAction(params)),
    activeTabAction: params => dispatch(activeTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
