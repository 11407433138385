import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Link, useHistory } from 'react-router-dom';
import { Select } from 'antd';

import { setBreadcrumbAction } from '../../store/ui';
import { lineChartOptions } from '../../helper';
import { dashboardService } from '../../services/dashboardService';
import { MainLoader } from '../reuse/SVG';

const { Option } = Select;

const pieData = {
	labels: [],
	datasets: [{
		data: [79.07, 20.93],
		backgroundColor: [
		'#F9A720',
		'#842014'
		]
	}]
};

const data = {
    labels: [0, 20, 30, 25, 40, 50],
    datasets: [{
        data: [0, 20, 30, 25, 40, 50],
        backgroundColor: 'rgba(249, 167, 32, 0.1)',
        borderColor: '#F9A720',
        borderWidth: 3,
        pointRadius: 0,
    }]
}

const minData = {
    labels: [],
    datasets: [{
        data: [0, 2, 3, 2.5, 4, 5, 4, 3, 1],
        backgroundColor: 'rgba(249, 167, 32, 0.1)',
        borderColor: '#F9A720',
        borderWidth: 3,
        pointRadius: 0,
    }]
}


const data2 = {
    labels: [0, 20, 30, 25, 40, 50],
    datasets: [{
        data: [0, 20, 30, 25, 40, 50],
        backgroundColor: 'rgba(132, 32, 20, 0.1)',
        borderColor: '#842014',
        borderWidth: 3,
        pointRadius: 0,
    }]
}


const dataLine1 = {
    labels: [0, 20, 30, 25, 40, 50],
    datasets: [{
        data: [0, 20, 30, 25, 40, 50],
        backgroundColor: 'rgba(132, 32, 20, 0.1)',
        borderColor: '#842014',
        borderWidth: 3,
    }]
}


const dataLine2 = {
    labels: [0, 20, 30, 25, 40, 50],
    datasets: [{
        data: [0, 20, 30, 25, 40, 50],
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: '#842014',
        borderWidth: 3,
    }]
}


const Home = props => {
    const history = useHistory();
    const [loader, setLoader] = useState(true);
    const [bookingType, setBookingType] = useState(2);
    const [userType, setUserType] = useState(2);
    const [bookingByFilter, setBookingByFilter] = useState([]);
    const [users, setUsers] = useState([]);
    const [topRatedPrograms, setTopRatedPrograms] = useState('');
    const [totalBooking, setTotalBooking] = useState('');
    const [campWithMaxBookings, setCampWithMaxBookings] = useState({});
    const [totalCamps, setTotalCamps] = useState('');
    const [yearlyRevenue, setYearlyRevenue] = useState([]);
    const [bookingTypeLoader, setBookingTypeLoader] = useState(false);
    const [userTypeLoader, setUserTypeLoader] = useState(false);


    let params = {
        icon: 'dashboad',
        links: [
            {name: 'Dashboard', path: ''}
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);

    useEffect(() => {
        setBookingTypeLoader(true);
    }, [bookingType])


    useEffect(() => {
        setUserTypeLoader(true);
    }, [userType])



    const getdashboard = () => {
        let params = {
            bookingType,
            userType
        }
        dashboardService(params).then(res => {
            setLoader(false);
            setBookingTypeLoader(false);
            setUserTypeLoader(false);
            if(res?.data?.error?.errorCode == 14){
                localStorage.clear();
            }
            if(res.data.statusCode){
                setTotalCamps(res.data.responseData.totalCamps);
                setTotalBooking(res.data.responseData.totalBooking);
                setTopRatedPrograms(res.data.responseData.totalBooking);
                setBookingByFilter(res.data.responseData.bookingByFilter);
                setUsers(res.data.responseData.users);
                setYearlyRevenue(res.data.responseData.yearlyRevenue);
                setCampWithMaxBookings(res.data.responseData.campWithMaxBookings[0] || {});
            }

            

            
        })
    }



    useEffect(() => {
        getdashboard();
    }, [bookingType, userType])

    const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

    const getLabels = (data, type) => {
        switch (type) {
            case 1:
                return data.map(item => monthName[item.month - 1]);
                break;
            case 3:
                return data.map(item => item.week);
                break;
        
            default:
                return data.map(item => item.year);
                break;
        }
    }



    return (
        <div className="page_wrapper">
            {loader ? <MainLoader /> : <Fragment>

            
            <div className="row">
                
                <div className="col-lg-4">
                    <div className="dash_card mb-4 h_225">
                        <p>Total Bookings</p>
                        <div className="d-flex justify-content-between">
                            <h3>{totalBooking}</h3>
                            <div><Line data={data} options={lineChartOptions}  /></div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="dash_card mb-4 h_225">
                        <p>Total Camps Registered</p>
                        <div className="d-flex justify-content-between">
                            <h3>{totalCamps}</h3>
                            <div><Line data={data2} options={lineChartOptions}   /></div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="dash_card mb-4 h_225 cm_top_img">
                        <p className="abs_wrap">Top Rated Camps Programs <Link to={`/camps/edit-${campWithMaxBookings.campId}`}>View all</Link> </p>
                        <div className="text-center mt-4"><img src={campWithMaxBookings.icon} alt="yuth" /></div>
                    </div>
                </div>

            </div>



            <div className="row">
                
                <div className="col-lg-6">
                    <div className="dash_card mb-4">
                        {bookingTypeLoader ? <MainLoader /> : ''}
                        
                        <div className="d-flex align-content-center justify-content-between mb-4">
                            <h6 className="m-0">Number of Bookings</h6>
                            <Select onSelect={e => setBookingType(e)} defaultValue={2} className="cm_dropdown">
                                <Option value={2}>Year</Option>
                                <Option value={1}>Month</Option>
                                <Option value={3}>Week</Option>
                            </Select>
                        </div>
                        <Line
                        data={{
                            labels: getLabels(bookingByFilter, bookingType),
                            datasets: [
                              {
                                backgroundColor: 'rgba(249, 167, 32, 0.1)',
                                borderColor: '#F9A720',
                                borderWidth: 3,
                                pointRadius: 0,
                                borderCapStyle: 'butt',
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: 'miter',
                                pointHoverRadius: 5,
                                pointHitRadius: 10,
                                data: bookingByFilter.map(item => item.numberofbookings)
                              }
                            ]
                          }}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: "rgba(0, 0, 0, 0)",
                                    },
                                    yAxes: [{
                                        ticks: {
                                            precision: 0,
                                            beginAtZero: true,
                                        }
                                    }
                                    ]
                                }]
                            }, legend: {
                                display: false,
                            },
                        }}  />
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="dash_card mb-4">
                        <div className="mb-4">
                            <h6>Annual Revenue Graph</h6>
                        </div>
                        
                        <Line
                            data={{
                                labels: yearlyRevenue.map(item => item.year),
                                datasets: [
                                {
                                    backgroundColor: 'rgba(132, 32, 20, 0.1)',
                                    borderColor: '#F9A720',
                                    borderWidth: 3,
                                    pointRadius: 0,
                                    borderCapStyle: 'butt',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    pointHoverRadius: 5,
                                    pointHitRadius: 10,
                                    data: yearlyRevenue.map(item => item.totalPayment)
                                }
                                ]
                            }}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: "rgba(0, 0, 0, 0)",
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        precision: 0,
                                        beginAtZero: true,
                                    }
                                }
                                ]
                            }, legend: {
                                display: false,
                            },
                        }}  />
                    </div>
                </div>


            </div>


            <div className="row">
                
                <div className="col-lg-8">
                    <div className="dash_card mb-4">
                        <h6>Number of Page Views</h6>
                        <div className="d-flex justify-content-between align-items-center cm_100_small_screen">

                                <div className="cm_centered">
                                    <div className="doughnut">
                                        <svg viewBox="0 0 160 160">
                                            <circle cx="80" cy="80" fill="transparent" r="60" stroke="#E8E8E8" strokeWidth="20" />
                                            <circle
                                                cx="80"
                                                cy="80"
                                                r="60"
                                                fill="transparent"
                                                stroke="#F9A720"
                                                strokeWidth="20"
                                                style={{strokeDasharray: `calc(2 * 22 / 7 * 60 * ${75} / 100), calc(2 * 22 / 7 * 60)`}} />
                                            <circle
                                                cx="80"
                                                cy="80"
                                                r="60"
                                                fill="transparent"
                                                stroke="#842014"
                                                strokeWidth="20"
                                                style={{
                                                    strokeDasharray: `${2 * 22 / 7 * 60 * 25 / 100}, ${2 * 22 / 7 * 60}`,
                                                    transform: `rotate(${(75 /100) * 360}deg)`,
                                                    transformOrigin: 'center'}} />
                                        </svg>
                                    </div>

                                    <ul>
                                        <li><b className="dot_ cm_primary" />Repeat Purchase <span>75%</span></li>
                                        <li><b className="dot_ cm_secondery" />New Purchase <span>25%</span></li>
                                    </ul>
                                </div>




                                <div className="table_responsive_wrap">
                                    <div className="table-responsive">
                                    
                                        <table className="table table-bordered table-striped no_t_texts">

                                            <thead>
                                                <tr>
                                                    <th><span className="t_text">#</span></th>
                                                    <th><span className="t_text">Pages</span></th>
                                                    <th><span className="t_text">Views</span></th>
                                                    <th><span className="t_text">Avg Time on Page</span></th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                <tr>
                                                    <td><span className="t_text">1</span></td>
                                                    <td><span className="t_text">/home/</span></td>
                                                    <td><span className="t_text">289</span></td>
                                                    <td><span className="t_text">00:01:60</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="t_text">2</span></td>
                                                    <td><span className="t_text">/home/Popularcamps/</span></td>
                                                    <td><span className="t_text">289</span></td>
                                                    <td><span className="t_text">00:01:60</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="t_text">3</span></td>
                                                    <td><span className="t_text">/home/Popularcamps/</span></td>
                                                    <td><span className="t_text">289</span></td>
                                                    <td><span className="t_text">00:01:60</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="t_text">4</span></td>
                                                    <td><span className="t_text">/home/</span></td>
                                                    <td><span className="t_text">289</span></td>
                                                    <td><span className="t_text">00:01:60</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="t_text">5</span></td>
                                                    <td><span className="t_text">/home/</span></td>
                                                    <td><span className="t_text">289</span></td>
                                                    <td><span className="t_text">00:01:60</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="t_text">6</span></td>
                                                    <td><span className="t_text">/home/</span></td>
                                                    <td><span className="t_text">289</span></td>
                                                    <td><span className="t_text">00:01:60</span></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="dash_card mb-4 cm_max_height pb-5">
                    {userTypeLoader ? <MainLoader /> : ''}
                        <div className="d-flex align-content-center justify-content-between mb-2">
                            <h6>Number of Users</h6>
                            <Select onSelect={e => setUserType(e)} defaultValue={2} className="cm_dropdown">
                                <Option value={2}>Year</Option>
                                <Option value={1}>Month</Option>
                                <Option value={3}>Week</Option>
                            </Select>
                        </div>

                        <Bar
                            data={{
                                labels: getLabels(users, userType),
                                datasets: [
                                {
                                    backgroundColor: 'rgba(132, 32, 20, 0.1)',
                                    borderColor: '#F9A720',
                                    borderWidth: 3,
                                    pointRadius: 0,
                                    borderCapStyle: 'butt',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    pointHoverRadius: 5,
                                    pointHitRadius: 10,
                                    data: users.map(item => item.total)
                                }
                                ]
                            }}
                            width={100}
                            height={400}
                            options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        barPercentage: 0.3,
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        }
                                    }]
                                }
                            }}
                            />
                       
                    </div>
                </div>


            </div>











            <div className="row">
                <div className="col-lg-12">
                    <div className="dash_card mb-4 cm_max_height pb-5">
                        <h6>Bounce Rate Per Day </h6>

                        <Line
                            data={dataLine1}
                            width={100}
                            height={400}
                            options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        barPercentage: 0.1,
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            precision: 0,
                                            beginAtZero: true,
                                        }
                                    }
                                    ]
                                }
                            }}
                            />
                    </div>
                </div>
            </div>




            <div className="row">
                <div className="col-lg-12">
                    <div className="dash_card mb-4 pb-5">
                        <h6>Number of Website Visits</h6>
                        <div className="btn__group">
                            <button className="btn btn-link">Hourly</button>
                            <button className="btn btn-link active">Day</button>
                            <button className="btn btn-link">Week</button>
                            <button className="btn btn-link">Month</button>
                        </div>

                        <div className="cm_max_height">
                        <Line
                            data={dataLine2}
                            width={100}
                            height={400}
                            options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        barPercentage: 0.1,
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            precision: 0,
                                            beginAtZero: true,
                                        }
                                    }
                                    ]
                                }
                            }}
                            />
                        </div>


                        <div className="people_count_bar">4,123 people visited this site</div>




                        <div className="row">
                            <div className="col-xl-6">
                                <ul className="mini_graph_list">
                                    <li>
                                        <h5>Sessions</h5>
                                        <h3>4,098</h3>
                                        <Line data={minData} options={lineChartOptions}  />
                                    </li>

                                    <li>
                                        <h5>Users</h5>
                                        <h3>2,908</h3>
                                        <Line data={minData} options={lineChartOptions}  />
                                    </li>

                                    <li>
                                        <h5>Page Views</h5>
                                        <h3>4,788</h3>
                                        <Line data={minData} options={lineChartOptions}  />
                                    </li>

                                    <li>
                                        <h5>Pages / Sessions</h5>
                                        <h3>2.82</h3>
                                        <Line data={minData} options={lineChartOptions}  />
                                    </li>

                                    <li>
                                        <h5>Avg Session Duration</h5>
                                        <h3>00:00:35</h3>
                                        <Line data={minData} options={lineChartOptions}  />
                                    </li>

                                    <li>
                                        <h5>% New Sessions</h5>
                                        <h3>81.1%</h3>
                                        <Line data={minData} options={lineChartOptions}  />
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-6">
                            <div className="d-flex justify-content-between">
                                    <div className="pie_wrap">
                                        <Pie data={pieData} options={{
                                            tooltips: {
                                                enabled: false,
                                            },
                                            elements: {
                                                arc: {
                                                    borderWidth: 0
                                                }
                                            }
                                        }} />
                                    </div>

                                <ul>
                                    <li><b className="dot_ cm_primary" />Sports Camp</li>
                                    <li><b className="dot_ cm_pink" />US Sports Camp</li>
                                    <li><b className="dot_ cm_secondery" />Cyclone Camp</li>
                                </ul>

                                
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            </Fragment> }

        </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    return {
        state
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);