import * as actionType from './actionsTypes';
import {
    campList,
    addCamp,
    campDetail,
    campStatusChange,
    createProgram,
    editProgram,
    viewProgram,
    editCamp,
    personnalByCampCodeService,
} from '../services/campService';
import { resHandle, resHandle2 } from '../helper';

/**
 mode = 
    0 for add new
    1 for view
    2 for edit
 */

const initialState = {
    loader: true,
    camps: [],
    count: 0,
    page: 1,
    error: '',
    mode: 0,
    program: '',
    campDetail: '',
    progMedia: '',
    progMediaModal: false,
    toggleProgramModal: false,
    experienceModal: false,
    experiences: '',
    scheduleModal: false,
    schedules: [],
    programPageNumner: 1,
    personnalByCode: []
}


/************************************************************************************/
// Camp Reducer
/************************************************************************************/


const campReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.CAMP_LOADER:
            return {
            ...state,
            loader: !state.loader
        }


        case actionType.CLEAR_LOADER:
            return {
            ...state,
            loader: false
        }

        case actionType.PAGE_CHANGE:
            return {
            ...state,
            page: action.payload
        }

        case actionType.ADD_PROGRAM_MODAL:
            return {
            ...state,
            program: '',
            toggleProgramModal: !state.toggleProgramModal,
        }


        case actionType.EDIT_PROGRAM_MODAL:
            return {
            ...state,
            program: action.payload,
            toggleProgramModal: !state.toggleProgramModal,
        }

        case actionType.CLEAR_CAMP_DETAIL:
            return {
            ...state,
            campDetail: '',
            program: ''
        }

        case actionType.CAMP_MODE:
            return {
            ...state,
            mode: action.payload
        }

        case actionType.CAMP_DETAIL:
            return {
            ...state,
            campDetail: action.payload,
            program: '',
            toggleProgramModal: false,

        }


        case actionType.CAMP_LIST:
            return {
            ...state,
            loader: false,
            count: action.payload.count,
            camps: action.payload.data
        }


        case actionType.VIEW_PROGRAM:
            return {
            ...state,
            loader: false,
            program: action.payload,
        }


        case actionType.PROGRAM_PAGE_NUMBER:
            return {
            ...state,
            programPageNumner: action.payload,
        }


        case actionType.PROGRAM_MEDIA:
            return {
            ...state,
            progMediaModal: !state.progMediaModal,
            progMedia: action.payload,
        }


        case actionType.EXPERIENCE_MODAL:
            return {
            ...state,
            experienceModal: !state.experienceModal,
            experiences: action.payload,
        }


        case actionType.ADD_SCHEDULER:
            return {
            ...state,
            scheduleModal: !state.scheduleModal,
            schedules: action.payload,
            toggleProgramModal: false
        }




        case actionType.CAMP_ERROR:
            return {
            ...state,
            loader: false,
            error: action.payload
        }

        case actionType.PERSONNAL_BY_CAMP_CODE:
            return {
            ...state,
            personnalByCode: action.payload
        }


        default: return state;
    }
}




/************************************************************************************/
// All Camp Actions
/************************************************************************************/


export const clearLoaderAction = () => dispatch => dispatch({ type: actionType.CLEAR_LOADER });

export const toggleProgramModalAction = () => dispatch => dispatch({ type: actionType.ADD_PROGRAM_MODAL });

export const editProgramModalAction = payload => dispatch => dispatch({ type: actionType.EDIT_PROGRAM_MODAL, payload });

export const clearCampDetailAction = () => dispatch => dispatch({ type: actionType.CLEAR_CAMP_DETAIL });

export const campModeAction = payload => dispatch => dispatch({ type: actionType.CAMP_MODE, payload });

export const programMediaModal = payload => dispatch => dispatch({ type: actionType.PROGRAM_MEDIA, payload });

export const campPageChange = payload => dispatch => dispatch({ type: actionType.PAGE_CHANGE, payload });

export const experienceModalAction = payload => dispatch => dispatch({ type: actionType.EXPERIENCE_MODAL, payload });

export const addSchedulerAction = payload => dispatch => dispatch({ type: actionType.ADD_SCHEDULER, payload });

export const programPageNumberAction = payload => dispatch => dispatch({ type: actionType.PROGRAM_PAGE_NUMBER, payload });



export const campListAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.CAMP_LOADER });
        let campListData = await campList(params);
        let { status, data, count } = resHandle2(campListData);
        if(status){
            dispatch({ type: actionType.CAMP_LIST, payload: {data, count} });
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}







export const addCampAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let camp = await addCamp(params);

        if(camp.data.statusCode){
            dispatch({ type: actionType.CLEAR_LOADER});
            return camp.data;
           
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: camp.data.responseData });
            dispatch({ type: actionType.CLEAR_LOADER});
            return camp.data;
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}


export const editCampAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let camp = await editCamp(params);

        if(camp.data.statusCode){
            dispatch({ type: actionType.CLEAR_LOADER});
            return camp.data.responseData;
           
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: camp.data.responseData });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}



export const campDetailAction = params => async dispatch => {
    try {
        let camp = await campDetail(params);
        let { status, data } = resHandle(camp);
        if(status){
            dispatch({ type: actionType.CAMP_DETAIL, payload: data.result });
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}



export const campStatusChangeAction = params => async dispatch => {
    try {
        let camp = await campStatusChange(params);
        let { status, data } = resHandle(camp);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}


export const createProgramAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let program = await createProgram(params);
        let { status, data } = resHandle(program);
        if(status){
            dispatch({ type: actionType.ADD_PROGRAM_MODAL });
            dispatch({ type: actionType.CAMP_LOADER });
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}



export const editProgramAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let program = await editProgram(params);
        let { status, data } = resHandle(program);
        if(status){
            dispatch({ type: actionType.EDIT_PROGRAM_MODAL });
            dispatch({ type: actionType.CAMP_LOADER });
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}


export const cloneProgramDataAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let program = await editProgram(params);
        let { status, data } = resHandle(program);
        if(status){
            dispatch({ type: actionType.CAMP_LOADER });
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}



export const editProgramPDFAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let program = await editProgram(params);
        let { status, data } = resHandle(program);
        if(status){
            dispatch({ type: actionType.CAMP_LOADER });
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}






export const viewProgramAction = params => async dispatch => {
    dispatch({ type: actionType.CAMP_LOADER });
    try {
        let program = await viewProgram(params);
        let { status, data } = resHandle(program);
        if(status){
            dispatch({ type: actionType.VIEW_PROGRAM, payload: data.result });
            return status;
        }else{
            dispatch({ type: actionType.CAMP_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.CAMP_ERROR, payload: error });
    }
}

export const personnalByCampCodeAction = params => async dispatch => {
    try {
        let response = await personnalByCampCodeService(params);
        let { status, data } = resHandle(response);
        if(status){
            dispatch({ type: actionType.PERSONNAL_BY_CAMP_CODE, payload: data.directors });
        }else{
            dispatch({ type: actionType.PERSONNAL_BY_CAMP_CODE, payload: [] });
        }
        
    } catch (error) {
       dispatch({ type: actionType.PERSONNAL_BY_CAMP_CODE, payload: [] });
    }
}




export default campReducer;