import React, {useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { TimePicker } from 'antd';
import moment from 'moment';

import { addSchedulerAction, editProgramAction, campDetailAction } from '../../store/camps';
import { editProgram } from '../../services/campService';
import { addBr } from '../../helper';

import { CustomTextarea, InputError } from '../util/FormControls';


const AddScheduleModal = props => {
    const url = window.location.href;
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentIndex, setCurrentndex] = useState('');
    const [time, setTime] = useState('');
    const [activity, setActivity] = useState('');
    const [timeError, setTimeError] = useState('');
    const [activityError, setActivityError] = useState('');
    const [list, setList] = useState([]);


    useEffect(() => {
        setList(props.schedules.schedule);
    },[props.schedules])


    const handleValidate = () => {
        let validate = true;
            if(time == ''){
                setTimeError('time is required');
                validate = false
            }

            if(activity == ''){
                setActivityError('description is required');
                validate = false
            }
         
            // let timesArr = list.map(item => item.time);
            // if(timesArr.includes(time)){
            //     setTimeError('Do not enter same time schedule');
            //     validate = false
            // }

        return validate;

    }



    const handleAdd = () => {
        if(handleValidate()){
            setList([...list, {time, activity }]);
            setTimeError('');
            setActivityError('');
            setActivity('');
            setTime('');
        }   
    }



    const handleSubmit = e => {
        e.preventDefault();

        if(list.length){
            let params = new FormData();
            params.append('programId', props.schedules._id);
            params.append('schedule', JSON.stringify(list));
            params.append('isOnline', props.schedules.isOnline);

            params.append('impNotes', props?.schedules?.impNotes ? addBr(props.schedules.impNotes) : '');
            params.append('programIntroduction', props?.schedules?.programIntroduction ? addBr(props.schedules.programIntroduction) : '');
            params.append('programInstruction', props?.schedules?.programInstruction ? addBr(props.schedules.programInstruction) : '');

            props.editProgramAction(params).then(status => {
                if(status){
                    props.addSchedulerAction('');
                    let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                    props.campDetailAction(query);
                }
            })
        }

        
    }
    

    const handleDelete = index => {
        let tempList = [...list];
        tempList.splice(index, 1);
        setList(tempList);

        let params = new FormData();
            params.append('programId', props.schedules._id);
            params.append('schedule', JSON.stringify(tempList));
            params.append('isOnline', props.schedules.isOnline);

            params.append('impNotes', props?.schedules?.impNotes ? addBr(props.schedules.impNotes) : '');
            params.append('programIntroduction', props?.schedules?.programIntroduction ? addBr(props.schedules.programIntroduction) : '');
            params.append('programInstruction', props?.schedules?.programInstruction ? addBr(props.schedules.programInstruction) : '');


            editProgram(params).then(res => {
                let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                props.campDetailAction(query);
            })
           
    }


    const handleEdit = index => {
        setCurrentndex(index);
        setIsEditMode(true)
        let tempData = list[index];
        setActivity(tempData.activity);
        setTime(tempData.time);
        let el = document.getElementById('scrollIntoView');
        el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }


    const handleEditApi = () => {
        let tempList = [...list];
        tempList.splice(currentIndex, 1);

        let params = new FormData();
            params.append('programId', props.schedules._id);
            params.append('schedule', JSON.stringify([...tempList, { time, activity }]));
            params.append('isOnline', props.schedules.isOnline);

            params.append('impNotes', props?.schedules?.impNotes ? addBr(props.schedules.impNotes) : '');
            params.append('programIntroduction', props?.schedules?.programIntroduction ? addBr(props.schedules.programIntroduction) : '');
            params.append('programInstruction', props?.schedules?.programInstruction ? addBr(props.schedules.programInstruction) : '');


        props.editProgramAction(params).then(status => {
            if(status){
                props.addSchedulerAction('');
                let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                props.campDetailAction(query);
                setIsEditMode(false);
                setCurrentndex('');
                setTime('');
                setActivity('');
            }
        })
    }




    return (
        <Modal size="lg" show={props.scheduleModal} animation={false} centered>
            <Modal.Header>
                <Modal.Title>Add Schedule</Modal.Title>
                <span className="modal_close" onClick={() => props.addSchedulerAction([])}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
            {
                list ? list.map((item, index) => (
                    <div className="schedule_item" key={index}>
                        <p>Time: {item.time}</p>
                        <p>Description: {item.activity}</p>

                        <div className="cm_action">
                            <i className="icon-pancil" onClick={() => handleEdit(index)} />
                            <i className="icon-delete" onClick={() => handleDelete(index)} />
                        </div>
                    </div>
                )) : ''
            }

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Time</label>
                        <TimePicker
                            minuteStep={15}
                            use12Hours
                            format="h:mm a"
                            value={time ? moment(time, 'h:mm a') : ''}
                            onChange={e => (setTime(moment(e._d).format('h:mm a')), setTimeError(''))}
                            className={`form-control ${timeError ? 'is-invalid' : ''}`} />
                            <InputError error={timeError} />
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <CustomTextarea
                            pHolder="Write Description here…"
                            value={activity}
                            error={activityError}
                            change={e => (setActivity(e.target.value), setActivityError(''))}/>
                    </div>

                    {
                        isEditMode ? '' : <div className="text-right">
                                            <span onClick={handleAdd}  className="btn pl-0 pr-0"><i className="icon-plus" /> Add</span>
                                        </div>
                    }

                    

                    <div id="scrollIntoView" className="d-flex justify-content-center mt-4 mb-4">
                        {isEditMode ? <span className="btn btn-primary" onClick={handleEditApi}>Edit</span> : <button disabled={list && list.length ? false : true} className="btn btn-primary">Save</button>}
                        
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    )
}

const mapStateToProps = state => {

    let { scheduleModal, schedules, programPageNumner } = state.camp;
    return {
        scheduleModal,
        schedules,
        programPageNumner
    }
}

const mapDispatchToProps = dispatch => ({
    addSchedulerAction: params => dispatch(addSchedulerAction(params)),
    editProgramAction: params => dispatch(editProgramAction(params)),
    campDetailAction: params => dispatch(campDetailAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddScheduleModal);