import * as actionType from './actionsTypes';
import { getTremsFeedList, addTremsFeed, editTremsFeed, changeTremsFeedStatus } from '../services/termsService';
import { resHandle } from '../helper';

const initialState = {
    loader: true,
    termsModal: false,
    terms: [],
    term: '',
    error: '',
    termsType: 1,
    isTermEdit: 0,
    count: 0
    
}


/************************************************************************************/
// terms Reducer
/************************************************************************************/


const termsReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.TERMS_LOADER:
            return {
            ...state,
            loader: !state.loader
        }

        case actionType.TERMS_TYPE:
            return {
            ...state,
            termsType: action.payload
        }

        case actionType.TERMS_MODAL:
            return {
            ...state,
            termsModal: !state.termsModal,
            isTermEdit: 0
        }

        case actionType.TERMS_EDIT_MODAL:
            return {
            ...state,
            term: action.payload,
            termsModal: !state.termsModal,
            isTermEdit: 1
        }

        case actionType.TERMS_LIST:
            return {
            ...state,
            loader: false,
            isTermEdit: 0,
            termsModal: false,
            terms: action.payload.result,
            count: action.payload.count
        }


        case actionType.TERMS_ERROR:
            return {
            ...state,
            loader: false,
            error: action.payload
        }


        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const termsModalAction = payload => dispatch => dispatch({ type: actionType.TERMS_MODAL, payload });

export const termsTypeAction = payload => dispatch => dispatch({ type: actionType.TERMS_TYPE, payload });

export const termEditAction = payload => dispatch => dispatch({ type: actionType.TERMS_EDIT_MODAL, payload });



export const tremsFeedListAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.TERMS_LOADER });
        let termsData = await getTremsFeedList(params);
        let { status, data } = resHandle(termsData);
        if(status){
            dispatch({ type: actionType.TERMS_LIST, payload: data });
        }else{
            dispatch({ type: actionType.TERMS_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.TERMS_ERROR, payload: error });
    }
}



export const addTremsFeedAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.TERMS_LOADER });
        let termsData = await addTremsFeed(params);
        let { status, data } = resHandle(termsData);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.TERMS_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.TERMS_ERROR, payload: error });
    }
}



export const editTremsFeedAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.TERMS_LOADER });
        let termsData = await editTremsFeed(params);
        let { status, data } = resHandle(termsData);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.TERMS_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.TERMS_ERROR, payload: error });
    }
}


export const changeTremsFeedStatusAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.TERMS_LOADER });
        let termsData = await changeTremsFeedStatus(params);
        let { status, data } = resHandle(termsData);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.TERMS_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.TERMS_ERROR, payload: error });
    }
}




export default termsReducer;