import ApiInstance from './intercepter';
import API from '../config/api';


export const getRateExperienceService = params => ApiInstance.get(API.GET_RATE_EXPERIENCE + params);

export const getSupportService = params => ApiInstance.get(API.GET_SUPPORT + params);

export const getPartnerService = params => ApiInstance.get(API.GET_PARTNER + params);

export const getQuestionsService = params => ApiInstance.post(API.GET_QUESTION_LIST + params);

export const newCampRequestsService = params => ApiInstance.get(API.NEW_CAMP_REQUEST_LIST + params);

export const getContactUsUsersService = params => ApiInstance.get(API.GET_CONTACT_US_USERS + params);