
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty, notification } from 'antd';

import { userListService, changeStatusService, deleteUserService, userImpersonate } from '../../services/userService';
import { userSearchAction, userPageNoAction } from '../../store/user';
import { MainLoader, MiniLoader } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';
import { Link } from 'react-router-dom';
import settings from '../../config/settings';


const UserList = props => {
    const [loader, setLoader] = useState(true);
    const [miniLoader, setMiniLoader] = useState('');
    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState('');
    const [currentUserId, setCurrentUserId] = useState('');
    const [confimDeleteModal, setConfimDeleteModal] = useState(false);

    
    useEffect(() => {
        return () => {
            props.userSearchAction('')
        }
    }, [])


    const changeStatus = user => {
        setMiniLoader({[user._id]: true});

        let params = {
            userId: user._id,
            status: user.status == 1 ? '2' : '1' 
        }

        changeStatusService(params).then(res => {
            setMiniLoader('');
            getList()
            notification.open({
                message: 'User Status',
                description: `User status change successfully to  ${user.status == 1 ? 'Inactive' : 'Active'}`
            });
        }); 
    }


    const handleDelete = () => {
        let query = `?userId=${currentUserId}&type=1`;
        deleteUserService(query).then(res => {
            if(res.data.statusCode){
                closeModal();
                notification.open({
                    message: 'User',
                    description: 'User deleted successfully.'
                });
                getList();
            }
        });
    }


    const handleDeleteModal = id => {
        setCurrentUserId(id);
        setConfimDeleteModal(true);
    }


    const closeModal = () => {
        setCurrentUserId('');
        setConfimDeleteModal(false);
    }


    const getList = () => {
        let params = {
            types: [1],
            search: props.search,
            page: props.userPageNo - 1,
            limit: 10
        };
        userListService(params).then(res => {
           if(res.data.statusCode){
            setUsers(res.data.responseData.result);
            setTotalCount(res.data.responseData.count);
            setLoader(false);
           }else{
            setLoader(false);
           }
       })
    }

    const handleImpersonateUser= (email, userId) => {
        const params = {email}
        userImpersonate(params).then(res => {
            if(res.data.statusCode){
                const loginUrl = `${settings.api.client_url}sign-in/${userId}/1`
                window.open(loginUrl, '_blank', 'noopener,noreferrer');
            }else{
                 notification.open({
                    message: 'Failed to login.',
                    description: `Something went wrong, please try again.`
                });
            }
           
        })
       
      };

    useEffect(() => {
        getList();
    }, [props.userPageNo]);


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
                getList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [props.search])


    return (
       
            <div className="table_responsive_wrap mt-4 text-center">
                {loader ? <div className="table_loader"><MainLoader /></div> : ''}
                <div className="table-responsive">
        
                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                            <tr>
                                <th><span className="t_text">S.No</span></th>
                                <th><span className="t_text">User Name</span></th>
                                <th><span className="t_text">User Code</span></th>
                                <th><span className="t_text">Camper Name</span></th>
                                <th><span className="t_text">Email</span></th>
                                <th><span className="t_text">Phone Number</span></th>
                                <th><span className="t_text">Action</span></th>
                                <th><span className="t_text">Impersonate</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                users.map((user, i) => (
                                    <tr key={user._id}>
                                        <td><span className="t_text">{((i + 1) + ((props.userPageNo - 1) * 10))}</span></td>
                                        <td><span className="t_text">{user.name}</span></td>
                                        <td><span className="t_text">{user.userCode}</span></td>
                                        <td><span className="t_text">{Array.isArray(user.campersData) ? user.campersData.map(camper => (<b className='camper_name'>{camper.firstName}</b>)) : 'N/A'}</span></td>
                                        <td><span className="t_text">{user.email}</span></td>
                                        <td><span className="t_text">{user.phone}</span></td>
                                        <td><span className="t_text">
                                            <i className="icon-delete" onClick={() => handleDeleteModal(user._id)} />
                                            <div className="cm_swich_wrap">
                                                {miniLoader[user._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={user.status == 1 ? true : false} onChange={() => changeStatus(user)} /><span /></React.Fragment>}
                                            </div>
                                            <Link title="View User" to={`/user/detail/${user._id}`} style={{color: '#000'}}><i className="icon-eye mr-0 ml-3" /></Link>
                                            </span></td>
                                        <td> 
                                            <button 
                                                className='btn btn-link'
                                                onClick={() => handleImpersonateUser(user.email, user._id)}
                                            >
                                                Login
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            
                        
                        </tbody>
                    </table>
                </div>

                {
                    users.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                }

                {
                    users.length ? <div className="mt-4">
                        <Pagination
                            activePage={props.userPageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={3}
                            onChange={p => props.userPageNoAction(p)}/>
                    </div> : ''
                }


            <ConfirmModal
                show={confimDeleteModal}
                hideModal={closeModal}
                msg="Are you sure you want to delete?"
                action={handleDelete} />

            </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { search, userPageNo } = state.user;
    return {
        search,
        userPageNo
    };
}

const mapDispatchToProps = dispatch => ({
    userSearchAction: params => dispatch(userSearchAction(params)),
    userPageNoAction: params => dispatch(userPageNoAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);