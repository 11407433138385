import ApiInstance from './intercepter';
import API from '../config/api';


export const getInboxService = params => ApiInstance.get(API.GET_INBOX + params);

export const getAllUserListService = params => ApiInstance.get(API.GET_ALL_USER_LIST + params);

export const getChatHistoryService = params => ApiInstance.get(API.CHAT_HISTORY + params);

export const createInboxGroupService = params => ApiInstance.post(API.GROUP_CREATE, params);


