import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { loginAction } from '../../store/auth';
import { isValidEmail } from '../../helper';
import { MainLoader } from '../reuse/SVG';

const Login = props => {
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            email: '',
            password: '',
            passwordShow: false,
            emailCheckIcon: false
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            email: '',
            password: ''
        }
    );


    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }



    const handleValidate = () => {
        let validate = true;
        let { email, password } = userInput;
        if(email == ''){
            setUserInput({emailCheckIcon: false})
            setError({email: 'Email is required'});
            validate = false
        }else if(!isValidEmail(email)){
            setUserInput({emailCheckIcon: false})
            setError({email: 'Email is not valid'});
            validate = false
        }

        if(password == ''){
            setError({password: 'Password is required'});
            validate = false
        }

        return validate;
    }

    const handleEmailValidate = () => {
        let { email } = userInput;
        if(isValidEmail(email)){
            setUserInput({emailCheckIcon: true})
        }else{
            setUserInput({emailCheckIcon: false})
        }
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let { email, password } = userInput;
            let params = {
                email,
                password
            }

            props.loginAction(params)
        }
    }



    const togglePasswordView = () => setUserInput({passwordShow: !userInput.passwordShow});
    return(
        <div className="d-flex align-items-center auth_wrapper">
            {props.loading ? <MainLoader /> : ''}
            
            <div className="container">
            <img className="auth_logo" src={require('../../assets/img/logo.svg')} alt="logo" />
                <div className="row no-gutters d-flex align-items-center">
                    <div className="col-lg-6 cm_hide_mob">
                        <img src={require('../../assets/img/login-bg.png')} />
                    </div>
                    <div className="col-lg-6">
                        
                        <form className="p-5" onSubmit={handleSubmit}>
                            <h2 className="mb-4">Sign In</h2>

                            <div className="form-group mb-4">
                                <label>Email</label>
                                <div className="icon_input">
                                    {/* <input type="email" required={false} name="email" style={{display: 'none'}} />
                                    <input type="password" required={false} name="password" style={{display: 'none'}} /> */}
                                    <input
                                        type="email"
                                        className={`form-control ${error.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        onBlur={handleEmailValidate}
                                        value={userInput.email}
                                        onChange={handleChange} />
                                    {
                                       userInput.emailCheckIcon ? <span className="check_green" /> : ''
                                    }

                                    {
                                        error.email ? <OverlayTrigger
                                                            placement="left"
                                                            overlay={<Tooltip>{error.email}</Tooltip>}
                                                        >
                                                            <span className="error_tootltip" />
                                                        </OverlayTrigger> : ''
                                    }

                                    
                                </div>

                            
                            </div>

                            <div className="form-group mb-3">
                                <label>Password</label>
                                <div className="icon_input">
                                    <input
                                        type={`${userInput.passwordShow ? 'text' : 'password'}`}
                                        className={`form-control ${error.password ? 'is-invalid' : ''}`}
                                        name="password"
                                        value={userInput.password}
                                        onChange={handleChange} />
                                    <i className={`icon-eye ${userInput.passwordShow ? 'slash' : ''}`} onClick={togglePasswordView} />

                                    {
                                        error.password ? <OverlayTrigger
                                                            placement="left"
                                                            overlay={<Tooltip>{error.password}</Tooltip>}
                                                        >
                                                            <span className="error_tootltip" />
                                                        </OverlayTrigger> : ''
                                    }
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3">
                                <p><div className="check_wrapper"><input type="checkbox" /><span /></div> Remember me</p>
                                <Link to='/forgot-password' className="forgot_link">Forgot your password?</Link>
                            </div>

                            <button type="submit" className="btn btn-primary btn-block">Sign In</button>

                            {props.error && <p className="error_msg">{props.error}</p>}
                        </form>
                    </div>
                </div>
            </div>

            <div className="copyright">© 2020 CampsEZ. All Rights Reserved.</div>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loading, error } = state.auth;
    return {
        loading,
        error
    };
}
  
const mapDispatchToProps = dispatch => ({
    loginAction: params => dispatch(loginAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);