
import React,  { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import Pagination from 'react-js-pagination';

import { coupanCodeModalAction } from '../../store/offer'
import { campInvoiceListService } from '../../services/campService';
import { MainLoader } from '../reuse/SVG';


const Invoices = (props) => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);


    const getcampInvoices = () => {
        let query = `?campId=5fa101887c26885b1cb9697b&page=${page - 1}&limit=${limit}`;

        campInvoiceListService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
            }
        })
    }


    useEffect(() => {
        getcampInvoices();
    }, [])

    return (
        <div className="coupan_code_wrapper">
            {loader ? <MainLoader /> : ''}

       
    <div className="table_responsive_wrap mt-4 text-center">
    <div className="table-responsive">
    
        <table className="table table-bordered table-striped no_t_texts">

            <thead>
                <tr>
                    <th><span className="t_text">S.No</span></th>
                    <th><span className="t_text">Uploaded Date</span></th>
                    <th><span className="t_text">Invoice File</span></th>
                    <th><span className="t_text">Camp Name</span></th>
                    <th><span className="t_text">Email</span></th>
                </tr>
            </thead>

            <tbody>
                {
                    list.map((item, i) => (
                        <tr key={item._id}>
                            <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                            <td><span className="t_text">{moment(item.created).format('MM/DD/YYYY')}</span></td>
                            <td><span className="t_text"><a href={item.invoiceUrl} target="_blank"><img src={require('../../assets/img/pdf_icon.svg')} /></a></span></td>
                            <td><span className="t_text">{item.campName}</span></td>
                            <td><span className="t_text">{item.email}</span></td>
                        </tr>
                    ))
                }
                
               
            </tbody>
        </table>
        </div>
    </div>
    <div className="mt-3">
    <Pagination
        activePage={page}
        itemsCountPerPage={limit}
        totalItemsCount={100}
        pageRangeDisplayed={3}
        onChange={page => setPage(page)}/>
        </div>
</div>
    )
            }
/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { toggleCoupanCodeModal } = state.offer;
    return {
        toggleCoupanCodeModal,
        
    };
}
  
const mapDispatchToProps = dispatch => ({
    coupanCodeModalAction: () => dispatch(coupanCodeModalAction()),
   
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);const loop = [1,2,3,4,5,6,7,8,9,10]
