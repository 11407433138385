import React, { useReducer, useEffect, useState, useRef } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import {Editor, EditorState, ContentState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import { MainLoader } from '../reuse/SVG'; 
import { termsModalAction, tremsFeedListAction, addTremsFeedAction, editTremsFeedAction } from '../../store/terms';


const TermsModal = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  const editor = useRef(null);


  const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
    {
      title: '',
      desc: '',
      image: '',
      iconPreview: '',
      editId: ''
    }
  );


  const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
    {
      title: '',
      desc: '',
      image: '',
    }
  );


  useEffect(() => {
    setUserInput({
      title: '',
      iconPreview: '',
      image:'',
      editId: ''
    });

    setEditorState(EditorState.createWithContent(ContentState.createFromText('')))

  }, [props.termsModal])


  useEffect(() => {
    if (props.isTermEdit) {
      setUserInput({
        title: props.term.title,
        iconPreview: props.term.image,
        editId: props.term._id
      })

      setEditorState(EditorState.createWithContent(stateFromHTML(props.term.desc)))

    }else{

      setUserInput({
        title: '',
        iconPreview: '',
        image:'',
        editId: ''
      });
  
      setEditorState(EditorState.createWithContent(ContentState.createFromText('')))
    }
  }, [props.isTermEdit]);



  const handleChange = e => {
    setUserInput({[e.target.name]: e.target.value});
    setError({[e.target.name]: '', subCatValidationError: ''});
  }


  const handleFileChange = e => {
    setError({image: ''})
    let reader = new FileReader();
    let file = e.target.files[0];

    setUserInput({image: file})
    
    reader.addEventListener('load', () => {
        setUserInput({iconPreview: reader.result})
    }, false);
    if (file) { reader.readAsDataURL(file) }
  }


  const handleValidate = () => {
    let validate = true;
    let { title, desc } = userInput;
    if(title == ''){
        setError({title: 'Title is required'});
        validate = false
    }

    return validate;
}





const handleSubmit = e => {
  e.preventDefault();

  if(handleValidate()){
      let { title, image } = userInput;

      let params = new FormData();
        params.append('editId', userInput.editId);
        params.append('type', props.termsType);
        params.append('title', title);
        params.append('desc', stateToHTML(editorState.getCurrentContent()));
        params.append('image', image);

      

      props.isTermEdit ?
      props.editTremsFeedAction(params).then(status => {
        if(status){
           props.termsModalAction(0);
           setUserInput({
            title: '',
             desc: '',
           })
           props.tremsFeedListAction(`?type=${props.termsType}`);
           
        }
      })
      :
      
      props.addTremsFeedAction(params).then(status => {
        if(status){
           props.termsModalAction(0);
           setUserInput({
            title: '',
             desc: '',
           })
           props.tremsFeedListAction(`?type=${props.termsType}`);
           
        }
      })

    }
  }


  const handleEditerFocus = () => editor.current.focus();


  return(
    <Modal
        size="lg"
        show={props.termsModal}
        animation={false}
        centered
      >
        <Modal.Header>
            <Modal.Title>
                {props.termsType == 1 ? ` ${props.isTermEdit ? 'Edit' : 'Add'} Term & Conditions` :  ''}
                {props.termsType == 2 ? ` ${props.isTermEdit ? 'Edit' : 'Add'} Disclaimer` :  ''}
                {props.termsType == 3 ? ` ${props.isTermEdit ? 'Edit' : 'Add'} Privacy Statement` :  ''}
            </Modal.Title>

          <span className="modal_close" onClick={() => props.termsModalAction(0)}><i className="icon-cross" /></span>
        </Modal.Header>

        <Modal.Body>
        {props.loader ? <MainLoader /> : ''}
            <form onSubmit={handleSubmit}>

                <div className="form-group">
                    <label>Title</label>
                    <div className="icon_input">
                    <input
                      type="text"
                      className={`form-control ${error.title ? 'is-invalid' : ''}`}
                      name="title"
                      value={userInput.title}
                      onChange={handleChange}
                    />

                    {
                        error.title ? <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip>{error.title}</Tooltip>}
                                        >
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''
                    }
                    </div>
                </div>

                <div className="form-group" onClick={handleEditerFocus}>
                    <label>Description</label>
                    <Editor
                      ref={editor}
                      editorState={editorState}
                      onChange={editorState => setEditorState(editorState)}
                      placeholder="Description"
                    />
                </div>

                <div className="cm_or_line">
                    <span>OR</span>
                </div>


              <div className="icon_upload_wrapper  w-100">
                <input type="file" onChange={handleFileChange} accept="image/x-png,image/jpeg" />
                {userInput.iconPreview ? <img src={userInput.iconPreview} alt="icon" /> : ''}
                
                {userInput.iconPreview ? <div className="cm_edit_mode">
                                          <i className="icon-create" />
                                        </div> : <div>
                                          <i className="icon-button" />
                                          <p>Upload Image</p>
                                        </div>}
                <div className="invalid_feild">{error.icon}</div>
              </div>


              <div className="d-flex justify-content-center mt-5 mb-4">
                <button onClick={() => props.termsModalAction(0)} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                <button type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
              </div>

              {props.error && <p className="error_msg">{props.error}</p>}


            </form>
        </Modal.Body>
      </Modal>
  )
}



const mapStateToProps = state => {
    let { loader, termsModal, termsType, isTermEdit, term, error } = state.terms;
    return {
        loader,
        termsModal,
        termsType,
        isTermEdit,
        term,
        error
    };
}

const mapDispatchToProps = dispatch => ({
    termsModalAction: params => dispatch(termsModalAction(params)),
    addTremsFeedAction: params => dispatch(addTremsFeedAction(params)),
    editTremsFeedAction: params => dispatch(editTremsFeedAction(params)),
    tremsFeedListAction: params => dispatch(tremsFeedListAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsModal);