
import React,  { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import moment from 'moment';

import { advSearchAction } from '../../store/advertisement';
import { advertisementListFeaturedService } from '../../services/advertisementService';


const Advertisement = (props) => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState('');
    const [page, setPage] = useState(1);


    useEffect(() => {
        props.advSearchAction('')
    }, [])

    
    const getAdvertisementListFeatured = () => {
        let query = `?page=${page - 1}&limit=15&search=${props.search}`;
        advertisementListFeaturedService(query).then(res => {
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setTotalCount(res.data.responseData.totalCount);
            }
        })
    }

    useEffect(() => {
        getAdvertisementListFeatured();
    }, [page])

    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getAdvertisementListFeatured();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [props.search])


    return (
        <div className="advertisement_wrapper">

            <div className="table_responsive_wrap mt-4 text-center">

                <div className="table-responsive">
                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                        <tr>
                            <th><span className="t_text">#</span></th>
                            <th><span className="t_text">Camp Name</span></th>
                            <th><span className="t_text">Camp Code</span></th>
                            <th><span className="t_text">Phone No.</span></th>
                            <th><span className="t_text">Email</span></th>
                            <th><span className="t_text">City/State</span></th>
                            <th><span className="t_text">Year Revenue <br /> to date</span></th>
                            <th><span className="t_text">Category</span></th>
                            <th><span className="t_text">Sub Category</span></th>
                            <th><span className="t_text">Live</span></th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                                list.map((item, i) => (
                                    <tr key={item._id}>
                                        <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                        <td><span className="t_text">{item.campName} </span></td>
                                        <td><span className="t_text">{item.campCode}</span></td>
                                        <td><span className="t_text">{item.phone}</span></td>
                                        <td><span className="t_text">{item.email}</span></td>
                                        <td><span className="t_text">{item.city}</span></td>
                                        <td><span className="t_text">{item.yearRevenue ? item.yearRevenue : ''}</span></td>
                                        <td><span className="t_text">{item.categoryName}</span></td>
                                        <td><span className="t_text">{item.subCategory.map(item => item.name)}</span></td>
                                        <td><span className="t_text">{item.campName}</span></td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mt-3">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
               </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    let { search } = state.advertisement;
    return {
        search,
    };
}

const mapDispatchToProps = dispatch => ({
    advSearchAction: params => dispatch(advSearchAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Advertisement);
