import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Tab, Tabs, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { notification } from 'antd';
import PhoneInput from 'react-phone-number-input/input';

import { setBreadcrumbAction } from '../../store/ui';
import { isValidPassword } from '../../helper';
import { updateProfileService, getAdminProfileService, changePasswordService } from '../../services/authService';
import { MainLoader } from '../reuse/SVG';


const Setting = props => {
    const history = useHistory();
    const [name, setName] = useState('');
    const [loader, setLoader] = useState(true);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [imgView, setImgView] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [mode, setMode] = useState('profile');



    let params = {
        icon: 'setting',
        links: [
            { name: 'Settings', path: '' }
        ]

    }


    const getAdminProfile = () => {
        getAdminProfileService().then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setName(res.data.responseData.result.name);
                setPhone(res.data.responseData.result.phone);
                setImgView(res.data.responseData.result.profileImage);
                setEmail(res.data.responseData.result.email); 
            }
        })
    }

    useEffect(() => {
        props.setBreadcrumbAction(params);
        getAdminProfile();
    }, []);



    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setProfileImage(file);
        reader.addEventListener('load', () => {
            setImgView(reader.result)
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    const handleEditProfile = e => {
        setLoader(true);
        e.preventDefault();
        if(name != ''){
            let params = new FormData();
                params.append('name', name);
                params.append('phone', phone);
                params.append('profileImage', profileImage);

            updateProfileService(params).then(res => {
                setLoader(false);
                notification.open({
                    message: 'Profile',
                    description:
                      'Profile Updated Successfully',
                });
                getAdminProfile();
            }) 
        }
    }


    const handleValidation = () => {
        let validate = true;
        if(oldPassword == ''){
            setOldPasswordError('old Password is required');
            validate = false;
        }
        if(password == ''){
            setPasswordError('Password is required');
            validate = false;
        }else if(!isValidPassword(password)){
            setPasswordError('Password needs to have at least one lower case, one uppercase, one number, one special character, and  must be atleast 8 characters but no more than 15. ');
            validate = false
        }

        if(password !== confirmPassword){
            setConfirmPasswordError('Password not match');
            validate = false
        }

        return validate;
    }



    const handleChangePassword = e => {
        e.preventDefault();
        if(handleValidation()){
            setLoader(true)
            let params = {oldPassword, password};

            changePasswordService(params).then(res => {
                setLoader(false);
                if(res.data.statusCode){
                    notification.open({
                        message: 'Password',
                        description:
                          'Password Updated Successfully',
                      });
                      history.push('/');
                }else{
                    setOldPasswordError(res.data.error.responseMessage)
                }
            })
        }
    }


    return (
        <div className="page_wrapper">
            <div className="page_card">
            {loader ? <MainLoader /> : '' }

                <div className="d-flex page_card_header">
                    <h4>Setting</h4>
                </div>
                <div className="page_card_body">
                    <Tabs onSelect={e => setMode(e)} defaultActiveKey="profile">

                        <Tab eventKey="profile" title="Profile">
                            <div className="row mt-4">
                                <div className="col-lg-6 d-flex">
                                    <div className="img_upload_wrap">
                                        <input accept="image/x-png,image/jpeg" type="file" onChange={handleFileChange} />
                                        <img src={imgView ? imgView : require('../../assets/img/user.png')} />
                                        <h4>Change Photo</h4>
                                    </div>
                                    <form className="w-100">
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input
                                                value={name}
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Full Name"
                                                onChange={e => setName(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <label >Phone Number</label>
                                            <PhoneInput
                                                country="US"
                                                className="form-control"
                                                placeholder="Phone Number"
                                                value={phone}
                                                onChange={e => setPhone(e)}/>

                            
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input value={email} disabled type="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="changePassword" title="Change Password">
                            <div className="row mt-4">
                                <div className="col-lg-4">
                                    <form className="w-100 input_space_form_2">
                                        <div className="form-group">
                                            <label>Old Password</label>
                                            <input
                                                type="password"
                                                className={`form-control ${oldPasswordError ? 'is-invalid' : ''}`}
                                                placeholder="Old Password"
                                                value={oldPassword}
                                                name="oldPassword"
                                                onChange={e => (setOldPassword(e.target.value), setOldPasswordError(''))} />
                                            
                                            {
                                                oldPasswordError ? <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip>{oldPasswordError}</Tooltip>}
                                                                >
                                                                    <span className="error_tootltip" />
                                                                </OverlayTrigger> : ''
                                            }
                                        </div>


                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input
                                                type="password"
                                                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                                placeholder="New Password"
                                                value={password}
                                                onChange={e => (setPassword(e.target.value), setPasswordError(''))} />
                                            
                                            {
                                                passwordError ? <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip>{passwordError}</Tooltip>}
                                                                >
                                                                    <span className="error_tootltip" />
                                                                </OverlayTrigger> : ''
                                            }
                                        </div>


                                        <div className="form-group">
                                            <label>Confirm New Password</label>
                                            <input
                                                type="password"
                                                className={`form-control ${confirmPasswordError ? 'is-invalid' : ''}`}
                                                placeholder="Confirm New Password"
                                                value={confirmPassword}
                                                onChange={e => (setConfirmPassword(e.target.value), setConfirmPasswordError(''))} />
                                            
                                            {
                                                confirmPasswordError ? <OverlayTrigger
                                                                    placement="left"
                                                                    overlay={<Tooltip>{confirmPasswordError}</Tooltip>}
                                                                >
                                                                    <span className="error_tootltip" />
                                                                </OverlayTrigger> : ''
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Tab>

                        {/* <Tab eventKey="serviceTax" title="Service Tax">
                            <ServiceTaxs />
                        </Tab> */}
                    </Tabs>
                </div>

            </div>

            {mode == 'profile' || mode == 'changePassword' ? (
                <div className="d-flex justify-content-between mt-4">
                    <Link to="/" className="btn btn-gray">Cancel</Link>
                    <button onClick={mode == 'profile' ? handleEditProfile : handleChangePassword} className="btn btn-primary">Update</button>
                </div>
            ) : ''}

            

        </div>
    )
}


const mapStateToProps = state => {
    let { adminProfile } = state.auth;
    return {
        adminProfile
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Setting);

