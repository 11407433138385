import React, { useReducer, useRef, useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { notification } from 'antd';

import { MainLoader, MiniLoader } from '../reuse/SVG';
import {
  categoryModalAction,
  AddCategoryAction,
  categoryListAction,
  editCategoryAction,
  categoryDetailAction,
  changeSubCategoryStatusAction,
  categoryDeleteAction
} from '../../store/category';

import { getPreListAction } from '../../store/ui';


const CategoryModal = props => {
  const inputRef = useRef(null);
  const [miniLoader, setMiniLoader] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
    {
      name: '',
      subCat: '',
      subCategory: [],
      icon: '',
      iconPreview: '',
      bannerImage: '',
      bannerImageView: '',
      typeInput: false
    }
  );

  const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
    {
      name: '',
      subCat: '',
      icon: '',
      bannerImage: '',
      subCatValidationError: ''
    }
  );
  

  useEffect(() => {
    if (userInput.typeInput) {
      inputRef.current.focus();
    }
  }, [userInput.typeInput]);


  useEffect(() => {
    if (props.isCategoryEditModal) {
      setUserInput({
        name: props.category.name,
        subCategory: props.category.subCategory,
        iconPreview: props.category.icon,
        bannerImageView: props.category.bannerImage,
        icon: props.category.icon,
      })
    }
  }, [props.isCategoryEditModal, isUpdate]);



  const showtypeInput = () => setUserInput({typeInput: !userInput.typeInput});

  const handleSubCatAdd = () => {
    if(userInput.subCat.length > 0){
      setUserInput({subCategory: [...userInput.subCategory, {name: userInput.subCat}], typeInput: false, subCat: '' })
    }else{
      setError({subCatValidationError: 'feild not to be empty'})
    }
  }


  const handleChange = e => {
    setUserInput({[e.target.name]: e.target.value});
    setError({[e.target.name]: '', subCatValidationError: ''});
  }


  const handleFileChange = (e, img, imgView) => {
    setError({[img]: ''})
    let reader = new FileReader();
    let file = e.target.files[0];

    setUserInput({[img]: file})
    
    reader.addEventListener('load', () => {
        setUserInput({[imgView]: reader.result})
    }, false);
    if (file) { reader.readAsDataURL(file) }
  }



const handleValidate = () => {
    let validate = true;
    let { name, icon, subCategory, bannerImage, bannerImageView } = userInput;
    if(name == ''){
        setError({name: 'Name is required'});
        validate = false
    }

    if(icon == ''){
        setError({icon: 'Icon is required'});
        validate = false
    }
    
    if(bannerImageView == ''){
        setError({bannerImage: 'Banner image is required'});
        validate = false
    }

    if(subCategory.length == 0){
      setError({subCat: 'Sub category is required'});
      validate = false
  }

    return validate;
}


const handleSubmit = e => {
    e.preventDefault();

    if(handleValidate()){
        let { name, subCategory, icon, bannerImage } = userInput;

        let params = new FormData();
            params.append('categoryId', props.category._id);
            params.append('name', name);
            params.append('icon', icon);
            params.append('bannerImage', bannerImage);
            params.append('subCategory', props.isCategoryEditModal ?  JSON.stringify(subCategory.filter((el => el._id == null))) : JSON.stringify(subCategory));

        
      props.isCategoryEditModal ?
        props.editCategoryAction(params).then(status => {
          if(status){
            props.categoryModalAction(0);
            props.categoryDetailAction(props.category._id);  
            let params = { search: '' }

            props.categoryListAction(params);
            props.getPreListAction();
          }
        })
        :
        props.AddCategoryAction(params).then(status => {
         if(status){
            props.categoryModalAction(0);
            setUserInput({
              name: '',
              subCat: '',
              subCategory: [],
              icon: '',
              iconPreview: '',
              typeInput: false
            })
            let params = { search: '' }

            props.categoryListAction(params);
            props.getPreListAction();
            
         }
       })
    }
}




  const closeModal = () => {
    props.categoryModalAction('');
    setUserInput({
      name: '',
      subCat: '',
      subCategory: [],
      icon: '',
      iconPreview: '',
      typeInput: false,
      bannerImage: '',
      bannerImageView: '',
    });
    setError({
      name: '',
      subCat: '',
      icon: '',
      bannerImage: '',
    })
  }



  const handleSubCategoryStatusChange = (e, subCategory, index) => {
    let tempSubCat = userInput.subCategory[index];
    tempSubCat.status = subCategory.status == 1 ? 0 : 1;

    let tempArr = userInput.subCategory;
    tempArr.splice(index, 1);
    tempArr.splice(index, 0, tempSubCat);
    setUserInput({subCategory: tempArr});
    setMiniLoader({[subCategory._id]: true});
    let tempS = (subCategory.status == '0' || subCategory.status == 0) ? 0 : 1;
    let params = {
        subcategoryId: subCategory._id,
        status: tempS
    }
    props.changeSubCategoryStatusAction(params).then(data => {
      setMiniLoader('');
      props.categoryDetailAction(data._id).then(status => {
        notification.open({
          message: 'Sub Category Status',
          description: `Sub category status change successfully to  ${subCategory.status == 1 ? 'Inactive' : 'Active'}`
        }); 
      }); 
      
    });  
}



const handleSubCategoryDelete = id => {
  let allsubCat = userInput.subCategory.filter(item => item._id != id);
  setUserInput({subCategory: allsubCat});
  let params = {
    subcategoryId: id,
  }

  props.categoryDeleteAction(params).then(data => {
    setMiniLoader('');
    props.categoryDetailAction(props.category._id).then(status => {
      let params = '';
      props.categoryListAction(params);
      props.getPreListAction();
    });  
  });  
}



const handleSubCategoryDeleteLocal = id => {
    let subCategory = userInput.subCategory;
    subCategory.splice(id, 1);
    setUserInput({subCategory})
}


  return(
    <Modal
        size="lg"
        show={props.toggleCategoryModal}
        animation={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>{props.isCategoryEditModal ? 'Edit Category' : 'Add Category' }</Modal.Title>
          <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
        </Modal.Header>

        <Modal.Body>
          {props.loader ? <MainLoader /> : ''}
            <form onSubmit={handleSubmit}>
              <div className="icon_upload_wrapper cat_banner_img">
                <input type="file" onChange={e => handleFileChange(e, 'bannerImage', 'bannerImageView')} accept="image/*" />
                {userInput.bannerImageView ? <img src={userInput.bannerImageView} alt="icon" /> : ''}
                
                {userInput.bannerImageView ? <div className="cm_edit_mode">
                                          <i className="icon-create" />
                                        </div> : <div>
                                          <i className="icon-button" />
                                          <p>Upload Banner Image</p>
                                        </div>}
                <div className="invalid_feild">{error.bannerImage}</div>
              </div>



              <div className="icon_upload_wrapper">
                <input type="file" onChange={e => handleFileChange(e, 'icon', 'iconPreview')} accept="image/*" />
                {userInput.iconPreview ? <img src={userInput.iconPreview} alt="icon" /> : ''}
                
                {userInput.iconPreview ? <div className="cm_edit_mode">
                                          <i className="icon-create" />
                                        </div> : <div>
                                          <i className="icon-button" />
                                          <p>Upload Icon</p>
                                        </div>}
                <div className="invalid_feild">{error.icon}</div>
              </div>


              <div className="form-group d-flex align-items-center mt-4 pl-4 pr-4 cm_top_0">
                <label className="text-nowrap m-0 mr-5">Category Name</label>
                <input
                  type="text"
                  className={`form-control ${error.name ? 'is-invalid' : ''}`}
                  name="name"
                  value={userInput.name}
                  onChange={handleChange} />

                  {error.name ?
                        <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip>{error.name}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger>
                    : ''}
                  
                  
              </div>

              <div className="additional-type">
                <p><b>Additional Type</b></p>
                {error.subCat ? <span className="invalid_feild">{error.subCat}</span>: ''}

                {
                  userInput.subCategory.length ? (
                    <div className="table_responsive_wrap">
                <div className="table-responsive">
                  
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><span className="t_text">Type</span></th>
                        <th><span className="t_text">Action</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        userInput.subCategory.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td><span className="t_text">{item.name}</span></td>
                            <td>
                            <span className="t_text">
                            <i title="Copy Sub Category ID" className="icon-copy" onClick={() => navigator.clipboard.writeText(item._id)} />
                              <i className="icon-delete" onClick={() => item._id ? handleSubCategoryDelete(item._id) : handleSubCategoryDeleteLocal(i)} />
                              {item._id ? <div className="cm_swich_wrap">
                                            {miniLoader[item._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={item.status == 0} onChange={e => handleSubCategoryStatusChange(e, item, i)} /><span /></React.Fragment>}
                                          </div> : <span className="cm_space" />}

                              
                            </span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                </div>
                  ) : ''
                }
                

              </div>

              {
                userInput.typeInput ? 
                (
                  <div className="d-flex justify-content-between form-group mt-4 mb-5 position-relative">
                    <input
                      type="text"
                      name="subCat"
                      className="form-control"
                      placeholder="Sub Category"
                      ref={inputRef}
                      onChange={handleChange} />
                    <span className="btn btn-primary ml-3" onClick={handleSubCatAdd}>Add</span>
                    {error.subCatValidationError ? <div className="invalid_feild cm_abs">{error.subCatValidationError}</div> : ''}
                  </div>
                  
                )
                :
                (
                  <div className="text-right add_type_btn" onClick={showtypeInput}><span><i className="icon-plus" /> Add Type</span></div>
                )
              }


              

              <div className="d-flex justify-content-center">
                <button onClick={() => props.categoryModalAction('')} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                <button type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
              </div>


            </form>
        </Modal.Body>
      </Modal>
  )
}



const mapStateToProps = state => {
    let { toggleCategoryModal, isCategoryEditModal, category, loader } = state.category;
    return {
        toggleCategoryModal,
        isCategoryEditModal,
        category,
        loader
    };
}

const mapDispatchToProps = dispatch => ({
    categoryModalAction: params => dispatch(categoryModalAction(params)),
    AddCategoryAction: params => dispatch(AddCategoryAction(params)),
    categoryListAction: params => dispatch(categoryListAction(params)),
    editCategoryAction: params => dispatch(editCategoryAction(params)),
    categoryDetailAction: params => dispatch(categoryDetailAction(params)),
    changeSubCategoryStatusAction: params => dispatch(changeSubCategoryStatusAction(params)),
    categoryDeleteAction: params => dispatch(categoryDeleteAction(params)),
    getPreListAction: () => dispatch(getPreListAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);