import * as actionType from './actionsTypes';

const initialState = {
    loader: false,
    toggleAddNotificationModal: false,
}


/************************************************************************************/
// offer Reducer
/************************************************************************************/


const notificationReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.ADD_NOTIFICATION:
            return {
            ...state,
            toggleAddNotificationModal: !state.toggleAddNotificationModal
            }


        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const addNotificationModalAction = () => dispatch => dispatch({ type: actionType.ADD_NOTIFICATION });


export default notificationReducer;