
import ApiInstance from './intercepter';
import API from '../config/api';


export const createAdvertisementService = params => ApiInstance.post(API.CREATE_ADVERTISEMENT, params);

export const editAdvertisementService = params => ApiInstance.post(API.EDIT_ADVERTISEMENT, params);

export const advertisementListService = params => ApiInstance.get(API.ADVERTISEMENT_LIST + params);

export const advertisementListFeaturedService = params => ApiInstance.get(API.GET_ADVERTISEMENT_FEATURED + params);

export const advertisementChangeStatusListService = params => ApiInstance.put(API.ADVERTISEMENT_STATUS_CHANGE, params);