import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatePicker, notification, Empty } from 'antd';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';

import { cloneProgramService, copyProgramItemsService, uploadExcelService } from '../../services/campService';
import { cloneProgramDataAction } from '../../store/camps';



import {
    toggleProgramModalAction,
    editProgramModalAction,
    programMediaModal,
    campDetailAction,
    experienceModalAction,
    addSchedulerAction,
    programPageNumberAction,
    editProgramPDFAction
} from '../../store/camps';
import { changeProgramStatus, programDelete } from '../../services/campService';
import { dateFormat, getBool, getProgramType, filterSingle, getExperience, gradeArr, removeTime2, filterMulti, filterMulti2, getBool2, addBr, removeHtmlTags, showAvailableSlots } from '../../helper';
import { MiniLoader } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';

const titles = [
    'Camp Program',
    'Program Code',
    'No. of Available slots',
    'Non-refundable registration fee',
    'Date Early Bird Registration is Open',
    'Date Early Bird Registration is Closed',
    'Early Bird Price',
    'Date Standard Registration Is Open',
    'Date Standard Registration is Closed',
    'Standard Price',
    'Date Late Registration is Open',
    'Date Late Registration is Closed',
    'Late Registration Price',
    'Program Category',
    'Program Sub Category',
    'Street Address',
    'City',
    'State',
    'Zip Code',
    'Gender',
    'Program Type',
    'Age Group',
    'Grade',
    'GradeExperience Level',
    'Start Date',
    'End Date',
    'Check-In Time',
    'Check-Out Time',
    'Hours of Attendance',
    'Meals',
    'T-shirt',
    'Equipment Needed',
    'Facility',
    'Aftercare',
    'Travel',
    'Scholarships',
    'Discounts',
    'Important Information Notes',
    'Year Revenue To Date',
    'EZ Pro Rating',
    'Revenue From Booking Fees',
    'Question 1',
    'Question 2',
    'Question 3',
    'Personnel',
    'Program Introduction',
    'About Program',
    'Program Instructions',
    'Disclaimer Statement',
];

const CampProgramList = props => {
    const url = window.location.href;

    const [miniLoader, setMiniLoader] = useState('');
    const [miniLoader2, setMiniLoader2] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [prgramsCSV, setProgramCSV] = useState([]);
    const [years, setYears] = useState([new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]);
    const [selectYear, setSelectYear] = useState('');
    const [selectDateModal, setSelectDateModal] = useState(false);
    const [campDate, setCampDate] = useState('');
    const [cloneProgramModal, setCloneProgramModal] = useState(false);
    const [cloneProgram, setCloneProgram] = useState('');
    const [cloneSchedule, setCloneSchedule] = useState('');
    const [cloneExperience, setCloneExperience] = useState('');
    const [cloneImages, setCloneImages] = useState('');
    const [clonePdf, setClonePdf] = useState('');
    const [programIds, setProgramsId] = useState([]);
    const [selectProgramModal, setSelectProgramModal] = useState(false);
    const [selectProgramList, setSelectProgramList] = useState([]);
    const [selectAllBtn, setSelectAllBtn] = useState(false);

    

    const handleAddYear = () => {
        if(years.length <= 7){
            let lastYear = years[years.length - 1];
            setYears([...years, lastYear + 1])
        } 
    }



    const handleDateChange = e => {
        setSelectYear('');
        setCampDate(removeTime2(e._d.getTime()));
        setSelectDateModal(false);
    }


    const handleStatusChange = (e, program) => {
        setMiniLoader({[program._id]: true});
        let params = {
            programId: program._id,
            status: program.status == 1 ? '2' : '1' 
        }
        changeProgramStatus(params).then(res => {
            setMiniLoader('');
            notification.open({
                message: 'Program Status',
                description: `Program status change successfully to  ${program.status == 2 ? 'Active' : 'Inactive'}`
            });
            let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
            props.campDetailAction(query);
        }); 
    }

    const handleStatusChange2 = (e, program) => {
        setMiniLoader2({[program._id]: true});
        let params = {
            programId: program._id,
            isApplicable: program.isApplicable == 1 ? '2' : '1' 
        }
        changeProgramStatus(params).then(res => {
            setMiniLoader2('');
            // notification.open({
            //     message: 'Program Status',
            //     description: `Program status change successfully to  ${program.status == 2 ? 'Active' : 'Inactive'}`
            // });
            let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
            props.campDetailAction(query);
        }); 
    }


    const handleConfirmModal = currentId => {
        setCurrentId(currentId);
        setConfirmModal(!confirmModal);
    }


    const handleDelete = () => {
        let params = { programId: currentId };
        programDelete(params).then(status => {
            setCurrentId('');
            let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
            props.campDetailAction(query);
            setConfirmModal(false);
        }); 
    }


    useEffect(() => {
        let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10&year=${selectYear}&campDate=${campDate}`;
        props.campDetailAction(query);
    }, [selectYear, campDate])




    useEffect(() => {
        if(props.campDetail){

        let tempPrograms = props.campDetail.programs.map(program => [
            program.programName,
            program.programCode,
            showAvailableSlots(program),
            
            program.registerNonRefundFee ? `$${program.registerNonRefundFee}` : 'N/A',
            program.dateEarlyRegisterOpen ? dateFormat(program.dateEarlyRegisterOpen) : 'N/A',
            program.dateEarlyRegisterClosed ? dateFormat(program.dateEarlyRegisterClosed) : 'N/A',
            program.earlyBirdPrice ? `$${program.earlyBirdPrice}` : 'N/A',
            dateFormat(program.registerOpenDate),
            dateFormat(program.registerCloseDate),
            program.registrationFee,
            program.dateLateRegisterOpen ? dateFormat(program.dateLateRegisterOpen) : 'N/A',
            program.dateLateRegisterClosed ? dateFormat(program.dateLateRegisterClosed) : 'N/A',
            program.lateRegistrationFee ? `$${program.lateRegistrationFee}` : 'N/A',
            program.category && filterSingle(props.preList.category, program.category),
            program.subCategory.length ? filterMulti2(props.preList.subcategory, program.subCategory) : '',
            program.addressLine1,
            program.city,
            program.state,
            program.zipCode,
            program.gender == 1 ? 'Male' : program.gender == 2 ? 'Female' : program.gender == 3 ? 'Non-Binary' : '',
            getProgramType(program.programType),
            `${program.minAge} - ${program.maxAge}`,
            program.grade,
            program.expLevel ? getExperience(program.expLevel) : '',
            dateFormat(program.startDate),
            dateFormat(program.endDate),
            program.checkInTime,
            program.checkOutTime,
            program.attendanceHour,
            program.meals.map(i => `${i}, `),
            getBool(program.tshirt),
            program.equipment,
            program.facility,
            getBool(program.afterCare),
            getBool(program.travel),
            getBool(program.scholarships),
            `${program.discountType == 1 ? '$' : ''}${program.discount}${program.discountType == 0 ? '%' : ''}`,
            program.impNotes ? program.impNotes : '',
            program.yearRevenue ? `$${program.yearRevenue}` : 'N/A',
            program.ratingCount,
            program.bookingFeesRevenue,
            program.question1 ? program.question1 : 'N/A',
            program.question2 ? program.question2 : 'N/A',
            program.question3 ? program.question3 : 'N/A',
            program.personnel.length ? filterSingle(props.preList.personnel, program.personnel[0]) : '',
            program.programIntroduction ? program.programIntroduction : 'N/A',
            program.aboutProgram ? program.aboutProgram : 'N/A',
            program.programInstruction ? program.programInstruction : 'N/A',
            program.disclaimer ? program.disclaimer : 'N/A'                                  
        ])

        tempPrograms.unshift(titles);
        setProgramCSV(tempPrograms)}
    }, [props.campDetail, props.preList])




    const handleFileChange = (e, prog) => {
        let file = e.target.files[0];
        let params = new FormData();
                params.append('programId', prog._id);
                params.append('campId', props.campDetail._id);
                params.append('pdf', file);

                params.append('impNotes', prog.impNotes ? addBr(prog.impNotes) : '');
                params.append('programIntroduction', prog.programIntroduction ? addBr(prog.programIntroduction) : '');
                params.append('programInstruction', prog.programInstruction ? addBr(prog.programInstruction) : '');
    

        props.editProgramPDFAction(params).then(status => {
            if(status){
                let query = `?campId=${props.campDetail._id}&pageNo=${props.programPageNumner - 1}&limit=10`;
                props.campDetailAction(query);
                notification.open({
                    message: 'Program',
                    description: `Program updated successfully.`
                });
            }
        })
      }



    const handleClone = () => {
        let programCode = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 7; i++){
            programCode += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        let params = {
            programId: cloneProgram._id,
            programCode
        }
        setCloneProgramModal(false);
        cloneProgramService(params).then(res => {
            
            if(res.data.statusCode){
                notification.open({
                    message: 'Program',
                    description: `Program clone successfully.`
                });
                let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10&year=${selectYear}&campDate=${campDate}`;
                props.campDetailAction(query);
            }
        })
    }




    const cloneProgramData = (program, name) => {
        setSelectProgramModal(true);
        if(name == 'programImage'){
            setCloneImages(program.programImage)
        }
        if(name == 'programSchedule'){
            setCloneSchedule(program.schedule)
        }
        if(name == 'programExperience'){
            setCloneExperience(program.additionalExp);
        }
        if(name == 'pdf'){
            setClonePdf(program.pdf);
        }
        
        let tempList = props.campDetail.programs.filter(item => program._id != item._id && item.endDate > new Date().getTime());
        setSelectProgramList(tempList);
            
    }


    const submitCloneProgram = () => {
       
        let params = {
            programIds: JSON.stringify(programIds),
        }

        if(cloneImages){
            params.programImage = JSON.stringify(cloneImages)
        }
        if(cloneSchedule){
            params.schedule = JSON.stringify(cloneSchedule)
        }
        if(cloneExperience){
            params.additionalExp = JSON.stringify(cloneExperience)
        }
        if(clonePdf){
            params.pdf = JSON.stringify(clonePdf)
        }


        copyProgramItemsService(params).then(res => {
            if(res.data.statusCode){
                closeModal();
                let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10&year=${selectYear}&campDate=${campDate}`;
                props.campDetailAction(query);
            }
        })  
    }


    const closeModal = () => {
        setSelectProgramModal(false);
        setProgramsId([]);
    }


  

    const handleMultiCheck = (e, id) => {
        if(programIds.includes(id)){
            let temp = [...programIds];
            const index = temp.indexOf(id);
            if (index > -1) {
                temp.splice(index, 1);
            }

            setProgramsId(temp);

        }else{
            setProgramsId([...programIds, id])
        }
    }



    const handleSelectAndDeselect = e => {
        setSelectAllBtn(e.target.checked)
        if(e.target.checked){
            let tempIds = selectProgramList.map(item => item._id);
            setProgramsId(tempIds);
        }else{
            setProgramsId([]);
        }
    }


    const handleExcelFileUpload = e => {
        let file = e.target.files[0];
        let params = new FormData();
        params.append('excelFile', file);

        uploadExcelService(params).then(res => {
            if(res.data.statusCode){
                let query = `?campId=${props.campDetail._id}&pageNo=${props.programPageNumner - 1}&limit=10`;
                props.campDetailAction(query);
                notification.open({
                    message: 'Program',
                    description: `Program created successfully.`
                });
            }else{
                notification.open({
                    message: 'Program',
                    description: res.data.error.responseMessage
                });
            }
            
        })
    }


    


    return(
        <Fragment>

            <Modal show={selectProgramModal} centered>
                <Modal.Header>
                <Modal.Title>Select Program</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <div className="select_all">
                        <div className="selct_program">
                            <div className="check_wrapper table_check"><input onChange={handleSelectAndDeselect} type="checkbox" /><span /></div> <b>{selectAllBtn ? 'Deselect' : 'Select'}  All</b>
                        </div>
                    </div>
                        {
                        selectProgramList.map(item => <div className="selct_program">
                            <div className="check_wrapper table_check"><input onChange={e => handleMultiCheck(e, item._id)} checked={programIds.includes(item._id) ? true : false} type="checkbox" /><span /></div> {item.programName}
                        </div>)
                        }
                    {programIds.length ? <div className="text-center">
                        <span onClick={submitCloneProgram} className="btn btn-primary">Apply</span>
                    </div> : ''}
                    
                </Modal.Body>
            </Modal>


            {
                (props.mode == 1) ? 

                <div className="d-flex justify-content-between table_header">
                    <div>
                        {
                            years.map(year => <button onClick={() => (setSelectYear(year), setCampDate(''))} className={`btn btn-outline-dark mr-3 ${selectYear === year ? 'active' : ''}`}> {year}</button>)
                        }
                        
                    </div>

                    <div className="d-flex align-items-center">
                        <button className="btn btn-primary mr-3" disabled={years.length <= 7 ? false : true} onClick={handleAddYear}>Add Year</button>
                        {/* <DatePicker className="btn btn-outline-danger" /> */}
                        <button className="btn btn-outline-danger" onClick={() => setSelectDateModal(true)}><i className="icon-calender" /> Select Date</button>
                    </div>

                    <Modal show={selectDateModal} onHide={() => setSelectDateModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Select Date</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DatePicker onChange={handleDateChange} style={{width: '100%'}} />
                        </Modal.Body>
                    </Modal>



                    



                </div>

                : ''
            }

            <div className="d-flex justify-content-between table_header">


                    <ConfirmModal
                        show={cloneProgramModal}
                        hideModal={() => setCloneProgramModal(false)}
                        msg="Are you sure you want to Clone this program?"
                        action={handleClone} />



                <div className="d-flex align-items-center">
                    <h4>Camp Program Details</h4>
                    {(props.mode == 1) ? '' : <a href="https://campsez-dev.s3.us-east-2.amazonaws.com/file_1613627458681_ProgramUpload.xlsx" download className="btn btn-outline-primary ml-3"><i className="icon-download" /> Download blank excel template</a>}
   
                </div>
                {(props.mode == 1) ? '' : <div className="table_page_set">
                                                <CSVLink className="btn btn-outline-primary mr-2" data={prgramsCSV}><i className="icon-download" /> Download Excel</CSVLink>  
                                                {/* <button className="btn btn-primary mr-2" disabled={!props.mode}><i className="icon-print" /> Print</button>    */}
                                                <div className="btn btn-primary cm_excel_upload" disabled={!props.mode}><i className="icon-upload" /><input type="file" onChange={handleExcelFileUpload} value="" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> Upload Excel</div>   
                                            </div>}
                
            </div>



            <div className="table_responsive_wrap mt-4 text-center">
                <div className="table-responsive">
                    
                    <table className="table table-bordered table-striped no_t_texts very_big_table">

                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th><span className="t_input">Actions</span></th>
                                <th><span className="t_input">User Allowed</span></th>
                                <th><span className="t_input">Camp Program</span></th>
                                <th><span className="t_input">Program Code</span></th>
                                <th><span className="t_input">No. of Available slots</span></th>
                                <th><span className="t_input">Non-refundable registration fee</span></th>
                                <th><span className="t_input">Date Early Bird Registration is Open</span></th>
                                <th><span className="t_input">Date Early Bird Registration is Closed</span></th>
                                <th><span className="t_input">Early Bird Price</span></th>
                                <th><span className="t_input">Date Standard Registration Is Open</span></th>
                                <th><span className="t_input">Date Standard Registration is Closed</span></th>
                                <th><span className="t_input">Standard Price</span></th>
                                <th><span className="t_input">Date Late Registration is Open</span></th>
                                <th><span className="t_input">Date Late Registration is Closed</span></th>
                                <th><span className="t_input">Late Registration Price</span></th>
                                <th><span className="t_input">Program Category</span></th>
                                <th><span className="t_input">Program Sub Category</span></th>
                                <th><span className="t_input">Street Address</span></th>
                                <th><span className="t_input">City</span></th>
                                <th><span className="t_input">State</span></th>
                                <th><span className="t_input">Zip Code</span></th>
                                <th><span className="t_input">Gender</span></th>
                                <th><span className="t_input">Program Type</span></th>
                                <th><span className="t_input">Age Group</span></th>
                                <th><span className="t_input">Grade</span></th>
                                <th><span className="t_input">Experience Level</span></th>
                                <th><span className="t_input">Start Date</span></th>
                                <th><span className="t_input">End Date</span></th>
                                <th><span className="t_input">Check-In Time</span></th>
                                <th><span className="t_input">Check-Out Time</span></th>
                                <th><span className="t_input">Meals</span></th>
                                <th><span className="t_input">T-shirt</span></th>
                                <th><span className="t_input">Equipment Needed</span></th>
                                <th><span className="t_input">Facility</span></th>
                                <th><span className="t_input">Aftercare</span></th>
                                <th><span className="t_input">Travel</span></th>
                                <th><span className="t_input">Scholarships</span></th>
                                <th><span className="t_input">Discounts</span></th>
                                <th><span className="t_input">Important Information Notes</span></th>
                                <th><span className="t_input">Year Revenue To Date</span></th>
                                <th><span className="t_input">EZ Pro Rating</span></th>
                                <th><span className="t_input">Revenue From Booking Fees</span></th>
                                <th><span className="t_input">Question 1</span></th>
                                <th><span className="t_input">Question 2</span></th>
                                <th><span className="t_input">Question 3</span></th>
                                <th><span className="t_input">Personnel</span></th>
                                <th><span className="t_input">Program Introduction</span></th>
                                <th><span className="t_input">About Program</span></th>
                                <th><span className="t_input">Program Instructions</span></th>
                                <th><span className="t_input">Disclaimer Statement</span></th>  
                            </tr>
                        </thead>
                        <tbody>{props.campDetail && props.campDetail.programs.map((program, i) => (                
                            <tr key={program._id}className={program.isprivate == 1 ? 'cm_pink' : ''} >
                                {/* <td>{i + 1}</td> */}
                                <td>{((i + 1) + ((props.programPageNumner - 1) * 10))}</td>
                                
                                <td><span className="t_text" style={{maxWidth: '290px'}}>
                                            <i title="Clone Program" style={{color: '#842014'}} onClick={() => (setCloneProgramModal(true), setCloneProgram(program))} className="icon-copy" />
                                            <span className="clone_icons program_pdf" title="Upload Packing List">
                                                <i title="" style={{color: '#2B2B2B'}} className="icon-add-doc" />
                                                <input accept=".pdf" type="file" onChange={e => handleFileChange(e, program)} />
                                                {program?.pdf ? <i title="Clone Upload Packing List" className="icon-copy clone_icon" onClick={() => cloneProgramData(program, 'pdf')}  /> : ''}
                                            </span>
                                            <span className="clone_icons">
                                                <i title="Add / Remove Media" style={{color: '#2B2B2B'}} className="icon-add-media" onClick={() => props.programMediaModal({ id: program._id, imgs: program.programImage})}/>
                                                {program?.programImage?.length ? <i title="Clone Media" onClick={() => cloneProgramData(program, 'programImage')} className="icon-copy clone_icon" /> : ''}
                                            </span>
                                            <span className="clone_icons">
                                                <i title="Add / Remove Schedule" style={{color: '#2B2B2B'}} className="icon-add-date" onClick={() => props.addSchedulerAction(program)} />
                                                {program?.schedule?.length ? <i title="Clone Schedule" onClick={() => cloneProgramData(program, 'programSchedule')} className="icon-copy clone_icon" /> : ''}
                                            </span>
                                            <span className="clone_icons">
                                                <i title="Add Additional Experience" style={{color: '#2B2B2B'}} className="icon-smile" onClick={() => props.experienceModalAction(program)} />
                                                {program?.additionalExp?.length ? <i title="Clone Additional Experience" onClick={() => cloneProgramData(program, 'programExperience')} className="icon-copy clone_icon" /> : ''}
                                            </span>
                                            <i title="Delete Program" style={{color: '#2B2B2B'}} className="icon-delete" onClick={() => handleConfirmModal(program._id)} />
                                            <Link title="View Program" to={`/camps/${props.campDetail._id}/program/${program._id}`}><i style={{color: '#000'}} className="icon-eye"/></Link>
                                            <i title="Edit Program" style={{color: '#842014'}} onClick={() => props.editProgramModalAction(program)} className="icon-pancil" />
                                            <div className="cm_swich_wrap">
                                                {miniLoader[program._id] ? <div className="mini_loader"><MiniLoader /></div> : <Fragment><input type="checkbox" checked={program.status == 1} onChange={e => handleStatusChange(e, program)} /><span /></Fragment>}
                                            </div>
                                        </span>
                                    </td>
                                    <td>
                                        <div className="cm_swich_wrap">
                                            {miniLoader2[program._id] ? <div className="mini_loader"><MiniLoader /></div> : <Fragment><input type="checkbox" checked={program.isApplicable == 1} onChange={e => handleStatusChange2(e, program)} /><span /></Fragment>}
                                        </div>
                                    </td>
                                    <td><span className="t_input">{program.programName}</span></td>
                                    <td><span className="t_input">{program.programCode}</span></td>
                                    <td>{showAvailableSlots(program).map(item => <span className="t_input">{item}</span>)}</td>
                                    <td><span className="t_input">{program.registerNonRefundFee ? `$${program.registerNonRefundFee}` : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.dateEarlyRegisterOpen ? dateFormat(program.dateEarlyRegisterOpen) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.dateEarlyRegisterClosed ? dateFormat(program.dateEarlyRegisterClosed) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.earlyBirdPrice ? `$${program.earlyBirdPrice}` : 'N/A'}</span></td>
                                    <td><span className="t_input">{dateFormat(program.registerOpenDate)}</span></td>
                                    <td><span className="t_input">{dateFormat(program.registerCloseDate)}</span></td>
                                    <td><span className="t_input">${program.registrationFee}</span></td>
                                    <td><span className="t_input">{program.dateLateRegisterOpen ? dateFormat(program.dateLateRegisterOpen) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.dateLateRegisterClosed ? dateFormat(program.dateLateRegisterClosed) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.lateRegistrationFee ? `$${program.lateRegistrationFee}` : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.category && filterSingle(props.preList.category, program.category)}</span></td>
                                    <td><span className="t_input last_dot">{program.subCategory.length ? filterMulti(props.preList.subcategory, program.subCategory) : ''}</span></td>
                                    <td><span className="t_input">{program.addressLine1}</span></td>
                                    <td><span className="t_input">{program.city}</span></td>
                                    <td><span className="t_input">{program.state}</span></td>
                                    <td><span className="t_input">{program.zipCode}</span></td>
                                    <td><span className="t_input">
                                            {program.gender == 1 ? 'Male' : ''}
                                            {program.gender == 2 ? 'Female' : ''}
                                            {program.gender == 3 ? 'Co-Ed' : ''}
                                        </span></td>
                                    <td><span className="t_input">{getProgramType(program.programType)}</span></td>
                                    <td><span className="t_input">{program.minAge} - {program.maxAge}</span></td>
                                    <td><span className="t_input last_dot">{program.grade && gradeArr.filter(el => program.grade.find(e => el.value == e )).sort((a, b) => a.count - b.count).map(item => <Fragment key={item.value}>{item.value}<span>,</span> </Fragment>)}</span></td>
                                    <td><span className="t_input">{program.expLevel ? getExperience(program.expLevel) : ''}</span></td>
                                    <td><span className="t_input">{dateFormat(program.startDate)}</span></td>
                                    <td><span className="t_input">{dateFormat(program.endDate)}</span></td>
                                    <td><span className="t_input">{program.checkInTime}</span></td>
                                    <td><span className="t_input">{program.checkOutTime}</span></td>
                                    <td><span className="t_input last_dot">{program.meals && program.meals.length ? program.meals.map(i => <Fragment key={i}>{i ? i : 'None'}<span>,</span> </Fragment>) : 'None'}</span></td>
                                    <td><span className="t_input">{getBool2(program.tshirt)}</span></td>
                                    <td><span className="t_input">{program.equipment}</span></td>
                                    <td><span className="t_input">
                                        {program.facility == 1 ? 'Outdoor' : ''}
                                        {program.facility == 2 ? 'Indoor' : ''}
                                        {program.facility == 3 ? 'Both' : ''}
                                        </span></td>
                                    <td><span className="t_input">{getBool(program.afterCare)}</span></td>
                                    <td><span className="t_input">{getBool2(program.travel)}</span></td>
                                    <td><span className="t_input">{getBool2(program.scholarships)}</span></td>
                                    <td><span className="t_input">{program.discountType == 1 ? '$' : ''}{program.discount}{program.discountType == 0 ? '%' : ''}</span></td>
                                    <td><span className="t_input">{program.impNotes ? removeHtmlTags(`<span>${program.impNotes}</span>`) : ''}</span></td>
                                    <td><span className="t_input">{program.yearRevenue ? `$${program.yearRevenue}` : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.ratingCount}</span></td>
                                    <td><span className="t_input">${program.bookingFeesRevenue}</span></td>
                                    <td><span className="t_input">{program.questions ? program.questions[0] ? program.questions[0].title : 'N/A' : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.questions ? program.questions[1] ? program.questions[1].title : 'N/A' : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.questions ? program.questions[2] ? program.questions[2].title : 'N/A' : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.personnel.length ? filterSingle(props.preList.personnel, program.personnel[0]) : ''}</span></td>
                                    <td><span className="t_input">{program.programIntroduction ? removeHtmlTags(`<span>${program.programIntroduction}</span>`) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.aboutProgram ? removeHtmlTags(`<span>${program.aboutProgram}</span>`) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.programInstruction ? removeHtmlTags(`<span>${program.programInstruction}</span>`) : 'N/A'}</span></td>
                                    <td><span className="t_input">{program.disclaimer ? removeHtmlTags(`<span>${program.disclaimer}</span>`) : 'N/A'}</span></td>
                                </tr>))}
                            
                        </tbody>
                    </table>

                </div>

                {
                    props.campDetail ? props.campDetail.programs.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div> : ''
                }

                <div className={`d-flex justify-content-${props.campDetail && props.campDetail.numberOfProgram ? 'between' : 'end'} align-items-center pt-3`}>
                {
                    props.campDetail ? props.campDetail.numberOfProgram ? <Pagination
                    activePage={props.programPageNumner}
                    itemsCountPerPage={10}
                    totalItemsCount={props.campDetail.numberOfProgram}
                    pageRangeDisplayed={3}
                    onChange={page => props.programPageNumberAction(page)} /> : '' : ''
                }
                

                {(props.mode == 1) ? '' :
               <div className="text-right">
                    <button className="btn btn-outline-primary" disabled={!props.mode} onClick={props.toggleProgramModalAction}><i className="icon-plus" /> Add Camp Program</button>
                </div> }

                </div>
                
                
                
                                      


            </div>

            <ConfirmModal
                show={confirmModal}
                hideModal={() => setConfirmModal(false)}
                msg="Are you sure you want to delete?"
                action={handleDelete} />


        </Fragment>
    )
}


const mapStateToProps = state => {
    let { mode, campDetail, programPageNumner } = state.camp;
    let { preList } = state.ui;
    return {
        mode,
        campDetail,
        preList,
        programPageNumner
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleProgramModalAction: () => dispatch(toggleProgramModalAction()),
    editProgramModalAction: params => dispatch(editProgramModalAction(params)),
    programMediaModal: params => dispatch(programMediaModal(params)),
    campDetailAction: params => dispatch(campDetailAction(params)),
    experienceModalAction: params => dispatch(experienceModalAction(params)),
    addSchedulerAction: params => dispatch(addSchedulerAction(params)),
    programPageNumberAction: params => dispatch(programPageNumberAction(params)),
    editProgramPDFAction: params => dispatch(editProgramPDFAction(params)),
    cloneProgramDataAction: params => dispatch(cloneProgramDataAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampProgramList);
