
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty } from 'antd';

import { userListService } from '../../services/userService';
import { userSearchAction, noneUserPageNoAction } from '../../store/user';
import { MainLoader } from '../reuse/SVG';



const NoneUserList = props => {
    const [loader, setLoader] = useState(true);
    const [noneUsers, setNoneUsers] = useState([]);
    const [totalCount, setTotalCount] = useState('');

 
    useEffect(() => {
        return () => {
            props.userSearchAction('')
        }
    }, [])


    useEffect(() => {
        let params = {
            types: [0],
            search: props.search,
            page: props.noneUserPageNo - 1,
            limit: 10
        };
        setLoader(true);
        userListService(params).then(res => {
            if(res.data.statusCode){
                setNoneUsers(res.data.responseData.result)
                setTotalCount(res.data.responseData.count)
                setLoader(false);
            }else{
             setLoader(false);
            }
        })
    }, [props.search, props.counselorPageNo]);


    return (
        
            <div className="table_responsive_wrap mt-4 text-center">
                {loader ? <div className="table_loader"><MainLoader /></div> : ''}
                <div className="table-responsive">
        
                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                            <tr>
                            <th><span className="t_text">S.No</span></th>
                                <th><span className="t_text">Name</span></th>
                                <th><span className="t_text">Email</span></th>
                                <th><span className="t_text">Phone</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                noneUsers.map((user, i) => (
                                    <tr key={user._id}>
                                        <td><span className="t_text">{((i + 1) + ((props.noneUserPageNo - 1) * 10))}</span></td>
                                        <td><span className="t_text">{user.name}</span></td>
                                        <td><span className="t_text">{user.email}</span></td>
                                        <td><span className="t_text">{user.phone}</span></td>
                                    </tr>
                                ))
                            }
                            
                        
                        </tbody>
                    </table>
                </div>

                {
                    noneUsers.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                }

                {
                    noneUsers.length ? <div className="mt-4">
                        <Pagination
                            activePage={props.noneUserPageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={3}
                            onChange={p => props.noneUserPageNoAction(p)}/>
                    </div> : ''
                }

            </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { search, noneUserPageNo } = state.user;
    return {
        search,
        noneUserPageNo
    };
}

const mapDispatchToProps = dispatch => ({
    userSearchAction: params => dispatch(userSearchAction(params)),
    noneUserPageNoAction: params => dispatch(noneUserPageNoAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoneUserList);