import setting from './settings';

export default (() => {
  return {
    'AUTH': setting.api.AUTH,
    'ADMIN_LOGIN': setting.api.url + 'admin/login',
    'ADMIN_UPDATE_PROFILE': setting.api.url + 'user/updateprofile',
    'ADMIN_GET_PROGILE': setting.api.url + 'user/profileinformation',
    'ADMIN_CHANGE_PASSWORD': setting.api.url + 'user/changepassword',
    'ADMIN_LOGOUT': setting.api.url + 'user/logout',
    'FORGOT_PASSWORD': setting.api.url + 'admin/forgotpassword',
    'RESET_PASSWORD': setting.api.url + 'admin/resetpassword',
    'VERIFY_RESET_TOKEN': setting.api.url + 'admin/verifyresettoken',
    'PRE_LIST': setting.api.url + 'admin/dropDownList',
    'CATEGORY_LIST': setting.api.url + 'category/list',
    'CATEGORY_STATUS_CHANGE': setting.api.url + 'category/statusChange',
    'CATEGORY_ADD': setting.api.url + 'category/add',
    'CATEGORY_DETAIL': setting.api.url + 'category/detail/',
    'CATEGORY_EDIT': setting.api.url + 'category/edit',
    'CATEGORY_DELETE': setting.api.url + 'category/delete',
    'CAMP_LIST': setting.api.url + 'camp/list',
    'CAMP_ADD': setting.api.url + 'camp/create',
    'CAMP_STATUS_CHANGE': setting.api.url + 'camp/statusChange',
    'CAMP_DETAIL': setting.api.url + 'camp/detail',
    'CAMP_EDIT': setting.api.url + 'camp/edit',
    'TERMS_FEED_LIST': setting.api.url + 'termsFeeds/list',
    'TERMS_FEED_ADD': setting.api.url + 'termsFeeds/create',
    'TERMS_FEED_EDIT': setting.api.url + 'termsFeeds/edit',
    'TERMS_FEED_STATUS_CHANGE': setting.api.url + 'termsFeeds/statusChange',
    'DELETE_TERMS_FEED': setting.api.url + 'termsFeeds/delete',
    'CREATE_COUPON': setting.api.url + 'coupon/create',
    'SPECIAL_DEAL_LIST': setting.api.url + 'specialdeal/list',
    'COUPON_LIST': setting.api.url + 'coupon/list',
    'COUPON_STATUS_CHANGE': setting.api.url + 'coupon/statusChange',
    'GIFT_CARD_STATUS_CHANGE': setting.api.url + 'giftcard/statusChange',
    'SPECIAL_DEAL_STATUS_CHANGE': setting.api.url + 'specialdeal/statusChange',
    'COUPON_DELETE': setting.api.url + 'coupon/delete',
    'GIFT_DELETE': setting.api.url + 'giftcard/delete',
    'SPECIAL_DEAL_DELETE': setting.api.url + 'specialdeal/delete',
    'CREATE_GIFT_CARD': setting.api.url + 'giftcard/create',
    'GIFT_CARD_LIST': setting.api.url + 'giftcard/list',
    'CREATE_SPECIAL_DEAL': setting.api.url + 'specialdeal/create',
    'CREATE_FAQ': setting.api.url + 'faq/create',
    'FAQ_LIST': setting.api.url + 'faq/list',
    'FAQ_EDIT': setting.api.url + 'faq/edit',
    'FAQ_CHANGE_STATUS': setting.api.url + 'faq/statusChange',
    'FAQ_DELETE': setting.api.url + 'faq/delete',
    'CREATE_PROGRAM': setting.api.url + 'program/createProgram',
    'EDIT_PROGRAM': setting.api.url + 'program/edit',
    'VIEW_PROGRAM': setting.api.url + 'program/viewProgram',
    'UPDATE_PROGRAM_IMG': setting.api.url + 'program/updateImage',
    'PROGRAM_STATUS_CHANGE': setting.api.url + 'program/statusChange',
    'PROGRAM_DELETE': setting.api.url + 'program/delete',
    'DIRECTOR_DELETE': setting.api.url + 'camp/deletedirector',
    'UPDATE_ADDITIONAL_EXP': setting.api.url + 'program/updateAdditionalExperience',
    'UPDATE_PROGRAM_IMAGES': setting.api.url + 'program/updateprogramimages',
    'USER_LIST': setting.api.url + 'user/userlist',
    'DIRECTOR_LIST': setting.api.url + 'user/directorlist',
    'DELETE_USER': setting.api.url + 'user/deleteUser',
    'USER_DETAIL': setting.api.url + 'user/getUserDetailById?userId=',
    'GET_BOOKING_LIST': setting.api.url + 'booking/adminlist',
    'GET_BOOKIN_DETAIL': setting.api.url + 'booking/adminbookingdetail',
    'GET_ADDITIONAL_EXP': setting.api.url + 'booking/additionalExpByCamper',
    'GET_INBOX': setting.api.url + 'group/inbox',
    'GET_ALL_USER_LIST': setting.api.url + '/user/allUserlistAdmin',
    'CHANGE_USER_STATUS': setting.api.url + 'user/statusChange',
    'GROUP_CREATE': setting.api.url + 'group/create',
    'CHAT_HISTORY': setting.api.url + 'chat/chathistory',
    'CREATE_ADVERTISEMENT': setting.api.url + 'advertisement/create',
    'ADVERTISEMENT_LIST': setting.api.url + 'advertisement/list',
    'ADVERTISEMENT_STATUS_CHANGE': setting.api.url + 'advertisement/statusChange',
    'DONATION_LIST': setting.api.url + 'donation/list',
    'EDIT_ADVERTISEMENT': setting.api.url + 'advertisement/edit',
    'GET_ADVERTISEMENT_FEATURED': setting.api.url + 'advertisement/listFeatured',
    'GET_RATE_EXPERIENCE': setting.api.url + 'admin/getRateExperience',
    'GET_SUPPORT': setting.api.url + 'admin/getSupport',
    'GET_PARTNER': setting.api.url + 'admin/getPartner',
    'GET_REVIEW': setting.api.url + 'review/getReviewByCamp',
    'CHANGE_REVIEW_STATUS': setting.api.url + 'review/statusChange',
    'UPLOAD_MEDIA': setting.api.url + 'user/uploadMedia',
    'UPLOAD_CAMP_INVOICE': setting.api.url + 'campinvoice/createinvoice',
    'CAMP_INVOICE_LIST': setting.api.url + 'campinvoice/list',
    'DELETE_CAMP_INVOICE': setting.api.url + 'campinvoice/deleteInvoice',
    'ADMIN_DASHBOARD': setting.api.url + 'admin/dashboard',
    'CLONE_PROGRAM': setting.api.url + 'program/cloneProgram',
    'GET_REWARD_LIST': setting.api.url + 'reward/list',
    'CREATE_REWARD': setting.api.url + 'reward/create',
    'CREATE_NEWS': setting.api.url + 'news/create',
    'EDIT_NEWS': setting.api.url + 'news/edit',
    'GET_NEWS': setting.api.url + 'news/list',
    'CREATE_CAREER': setting.api.url + 'career/create',
    'EDIT_CAREER': setting.api.url + 'career/edit',
    'GET_CAREER': setting.api.url + 'career/list',
    'CAREER_STATUS_CHANGE': setting.api.url + 'career/statusChange',
    'GET_ALL_POSTS': setting.api.url + 'post/getAllPosts',
    'ADD_NOTIFICATION': setting.api.url + 'notification/adminNotification',
    'GET_NOTIFICATION_LIST': setting.api.url + 'notification/listAdminNotification',
    'GET_QUESTION_LIST': setting.api.url + 'askquestion/list',
    'POST_STATUS_CHANGE': setting.api.url + 'post/statusUpdate',
    'NEW_CAMP_REQUEST_LIST': setting.api.url + 'listyourcamp/list',
    'GET_STATIC_PAGES': setting.api.url + 'staticpages/getcontent',
    'UPDATE_STATIC_PAGES': setting.api.url + 'staticpages/update',
    'COPY_PROGRAM_ITEMS': setting.api.url + 'program/copyProgramItems',
    'UNREAD_COUNT': setting.api.url + 'group/unreadCount',
    'PERSONNEL_BY_CAMP_CODE': setting.api.url + 'user/personnelByCampCode',
    'UPLOAD_EXCEL': setting.api.url + 'program/uploadExcel',
    'DISTRICT_LIST': setting.api.url + 'district/list',
    'DISTRICT_CREATE': setting.api.url + 'district/create',
    'DISTRICT_EDIT': setting.api.url + 'district/edit',
    'CHANGE_SPLIT_STATUS': setting.api.url + 'booking/update-booking-split-payment-status',
    'GET_CONTACT_US_USERS': setting.api.url + 'contactus/get-contactus-list',
    'GET_CANCEL_BOOKING_COUNT': setting.api.url + 'booking/getcancelledbookingcount',
    'USER_FAST_SIGNUP': setting.api.url + 'user/signuByAdminDirector',  
    'ADD_PARTICIPANTS': setting.api.url + 'participant/add',  
    'DELETE_PARTICIPANT': setting.api.url + 'participant/delete',  
    'EDIT_PARTICIPANT': setting.api.url + 'participant/edit',  
    'GET_PROGRAMS_LIST': setting.api.url + 'program/listForProgram',  
    'CREATE_BOOKING_BY_ADMINT': setting.api.url + 'booking/createByAdmin',
    'VALIDATE_COUPON_CODE': setting.api.url + 'coupon/validatecoupon',
    'GET_CUSTOMER_PROFILE': setting.api.url + 'user/getCustomerProfile',
    'DELETE_CUSTOMER_CARD': setting.api.url + 'user/deleteCustomerCard',
    'CREATE_CUSTOMER_CARD': setting.api.url + 'user/createCustomerProfile',
    'BILLING_DETAILS': setting.api.url + 'booking/billingdetail',
    'UPDATE_TAX': setting.api.url + 'tax/updateTax',
    'GET_TAXS': setting.api.url + 'tax/getTax',
    'GET_CAMP_DETAIL': setting.api.url + 'camp/campdetail',
    'CREATE_CUSTOMER_CARD': setting.api.url + 'user/createCustomerProfile',
    'LIST_PRODUCT': setting.api.url + 'store/list',
    'EDIT_PRODUCT': setting.api.url + 'store/edit',
    'PRODUCT_DELETE': setting.api.url + 'store/delete',
    'CREATE_PRODUCT': setting.api.url + 'store/create',
    'PRODUCT_BOOKING_LIST': setting.api.url + 'item-purchase/adminlist',
    'CALC_ALL_PRICE': setting.api.url + 'cart/calculatePrice',
    'GEN_XLS': setting.api.url + 'booking/getCampWiseBookingXls',
    'GET_SUBACCOUNT_SETTINGS': setting.api.url + 'camp/getSubAccountSettings',
    'ADMIN_USER_IMPERSONATE': setting.api.url + '/admin/user-impersonate',
  }
})()