import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setBreadcrumbAction } from '../../store/ui';
import { Tab, Tabs } from 'react-bootstrap'
import Invoices from './Invoices'

const Payment = props => {
    let params = {
        icon: 'Payment',
        links: [
            { name: 'Payment', path: '' }
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)

    }, []);
    return (
        <div className="page_wrapper">
            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Payment</h4>

                </div>

                <div className="page_card_body">
                    

                 
                    <Tabs className="" defaultActiveKey="couponCode">
                  
                        <Tab eventKey="couponCode" title="Invoices">
                        <Invoices /> 
                            </Tab>
                        
                    </Tabs>
                  
                  

                </div>

            </div>


        </div>
    )
}


const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

