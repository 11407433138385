import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getPreListAction } from '../../store/ui';
import { unreadCountAction } from '../../store/user';

const toggleSidebar = () => document.body.classList.toggle('hide_sidebar')

const Header = props => {

    useEffect(() => {
        props.getPreListAction();
        props.unreadCountAction();
    }, []);


    return(
        <header className="d-flex align-items-center justify-content-between">
            <i className="icon-menu-bar side_menu_toggler" title="Sidebar toggle" onClick={toggleSidebar} />
            <ul className="cm_breadcrumb">
                <li><i className={`icon-${props.breadcrumb.icon}`}/></li>
                {props.breadcrumb && props.breadcrumb.links.map((link, i) => <React.Fragment key={i}><li>{link.path ? <Link to={link.path}>{link.name}</Link> : link.name}</li></React.Fragment>)}
            </ul>
    
           <div className="notification_bell"><i className="icon-bell" /><span>{props.unreadCount ? props.unreadCount : 0}</span></div>
            <div className="menu_overlay" onClick={toggleSidebar} />
        </header>
    )
}

/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { breadcrumb } = state.ui;
    let { unreadCount } = state.user;
    return {
        breadcrumb,
        unreadCount
    };
}


const mapDispatchToProps = dispatch => ({
    getPreListAction: () => dispatch(getPreListAction()),
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);