import React, { Fragment, useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { resHandle } from '../../helper';
import { getDistrictsService, createDistrictService, editDistrictService } from '../../services/campService';


const Districts = props => {
    const [miniLoader, setMiniLoader] = useState(false);
    const [cmModal, setCmModal] = useState(false);
    const [list, setList] = useState([]);
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [image, setImage] = useState('');
    const [imageView, setImageView] = useState('');
    const [imageViewErr, setImageViewErr] = useState('');
    const [school, setSchool] = useState('');
    const [schoolErr, setSchoolErr] = useState('');
    const [schoolNames, setSchoolNames] = useState([]);
    const [districtId, setDistrictId] = useState('');



    const closeModal = () => {
        setCmModal(false);
        setMiniLoader(false);
        setName('');
        setNameErr('');
        setImage('');
        setImageView('');
        setImageViewErr('');
    }


    const handleEdit = item => {
        setImageView(item.image);
        setName(item.name);
        setSchoolNames(item.schoolNames || []);
        setCmModal(true);
        setDistrictId(item._id)
    }


    const handleAddMore = () => {
        if(school){
            setSchoolNames([...schoolNames, school]);
            setSchool('');
        }else{
            setSchoolErr('Please enter school name')
        }
    }


    const handleDelete = i => {
        let temp = [...schoolNames];
        temp.splice(i, 1);
        setSchoolNames(temp);
    }


    const getDistricts = () => {
        getDistrictsService().then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setList(data.result || []);
            }
        })
    }


    useEffect(() => {
        getDistricts()
    }, [])


    const handleFileChange = e => {
        setImageViewErr('');
        let reader = new FileReader();
        let file = e.target.files[0];
        setImage(file);
        
        reader.addEventListener('load', () => {
            setImageView(reader.result)
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }



    const handleValidate = () => {
        let validate = true;

    
        if(name == ''){
            setNameErr('Name is required');
            validate = false
        }
    
        if(imageView == ''){
            setImageViewErr('Image is required');
            validate = false
        }
    
        return validate;
    }
    
      const handleSubmit = e => {
        e.preventDefault();
        if(handleValidate()){
          setMiniLoader(true);
    
          let params = new FormData();
              params.append('districtId', districtId);
              params.append('name', name);
              params.append('image', image);
              params.append('schoolNames', JSON.stringify(schoolNames));

              let _service = districtId ? editDistrictService : createDistrictService;

              _service(params).then(res => {
                let { status, data } = resHandle(res);
                setMiniLoader(false);
                if(status){
                    getDistricts();
                    closeModal();
                }
            })

              
        }
      }
    

    return (
        <div className="page_wrapper">
        

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Districts</h4>
                    <button onClick={() => setCmModal(true)} className="btn btn-primary"><i className="icon-plus" /> Add District</button>
                </div>

                <div className="page_card_body">
                    <div className="row">
                     {
                         list.map(item => (
                            <div className="col-xl-3 col-lg-4 col-sm-6" key={item._id}>
                                <div className="card cat_card">
                                <i className="icon-pancil icon__pancil" onClick={() => handleEdit(item)} />
                                    <div className="dis_card_img"><img src={item.image} alt="icon" /></div>
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        ))
                     }
                    </div>
                </div>
            </div>

            <Modal
                show={cmModal}
                animation={false}
                centered
            >
                <Modal.Header>
                <Modal.Title>Add District</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit}>

                        <div className="icon_upload_wrapper w-100 mb-4 district_m">
    
                            <input type="file" onChange={handleFileChange} accept="image/jpeg, image/png" />
                            {imageView ? <img src={imageView} alt="icon" /> : ''}

                            {imageView ? <div className="cm_edit_mode">
                                <i className="icon-create" />
                            </div> : <div>
                                <i className="icon-upload-cercle" />
                                <p>Upload Image</p>
                                </div>}
                            <div className="invalid_feild">{imageViewErr}</div>
                        </div>

                        <div className='form-group'>
                            <input
                                type='text'
                                value={name}
                                onChange={e => (setName(e.target.value), setNameErr(''))}
                                className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                placeholder='Name' />
                            
                            {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                        </div>

                        {schoolNames?.length ? <div className='schoolNames_list'>
                            {schoolNames.map((item, i) => <span className='cm_chip'>{item} <i className="icon-delete" onClick={() => handleDelete(i)} /></span>)}
                        </div> : ''}
                        


                        <div className='form-group add_school_wrapper'>
                            <input
                                type='text'
                                value={school}
                                onChange={e => (setSchool(e.target.value), setSchoolErr(''))}
                                className={`form-control ${schoolErr ? 'is-invalid' : ''}`}
                                placeholder='school Name' />

                            <span className='add_school' onClick={handleAddMore}>Add</span>

                            {schoolErr ? <span className='err_msg'>{schoolErr}</span> : ''}
                            
                        </div>

                    
                   

                        <div className="d-flex justify-content-center">
                            <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                            <button disabled={miniLoader} type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>










        </div>
    )
}


export default Districts;