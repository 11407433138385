import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactCrop from "react-image-crop";

import { programMediaModal, campDetailAction } from '../../store/camps'; 
import { updateProgramImg } from '../../services/campService';
import { MainLoader } from '../reuse/SVG';
import { getCroppedImg } from '../../helper';


const ProgramMediaModal = props => {
  const url = window.location.href;
  const [images, setImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cImage, setCImage] = useState('');
  const [upImg, setUpImg] = useState();
  const [uploadImage, setUploadImage] = useState('');
  const [viewType, setViewType] = useState('');
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 16 / 9 });


  

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      setCImage(e.target.files)
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  useEffect(() => {
    if(viewType == 1){
      setCrop({ unit: "%", width: 100, aspect: 1 / 1 })
    }else{
      setCrop({ unit: "%", width: 100, aspect: 16 / 9 })
    }

  }, [viewType])


  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);


  useEffect(() => {
    setImages(props.progMedia ? props.progMedia.imgs : [])
  }, [props.progMedia])




  const completedCrop = crop => {
    getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => setUploadImage(res))
  }



  const handleFileUpload = () => {
    if(upImg){
      setLoader(true);
      let params = new FormData();
          params.append('programId', props.progMedia.id);
          params.append('programImage', uploadImage);
          params.append('viewType', viewType);

      updateProgramImg(params).then(res => {
        if(res.data.statusCode){
          setViewType('');
          setLoader('');
          setCImage('');
          setUpImg('');
          setImages(res.data.responseData.result);
        }
      })
    }
  }


 




  const handleRemove = urlId => {
    setLoader(true);
    let params = new FormData();
        params.append('programId', props.progMedia.id);
        params.append('urlId', urlId);

        updateProgramImg(params).then(res => {
          setLoader(false);
          if(res.data.statusCode){
            setImages(res.data.responseData.result)
          }
        })
  }


  const closeModal = () => {
    props.programMediaModal('');
    let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
    props.campDetailAction(query);
  }


  const closeCrop = () => {
    setCImage('');
    setCImage('');
    setUpImg('');
    
  }

  

  return(
    <Modal
        size="lg"
        show={props.progMediaModal}
        animation={false}
        centered
      >
        {loader ? <div className="popup_loader"><MainLoader /></div> : ''}
        <Modal.Header>
          <Modal.Title>Program Media</Modal.Title>
          <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <div className="icon_upload_wrapper">
              <input type="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
              <div>
                <i className="icon-button" />
                <p>Upload Image</p>
              </div>
            </div>

            {upImg ? <div className="position-relative">
              <div className="close_crop" onClick={closeCrop}>X</div>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={completedCrop}
              />
              </div> : ''}

              {
                upImg ? <div className='d-flex justify-content-between align-items-center cm_questions my-4'>
                <p className='m-0'>Is For Mobile View ?</p>
                <div className="cm_swich_wrap">
                    <input type="checkbox"  checked={viewType == 1 ? true : false} onChange={e => setViewType(viewType == 1 ? '' : 1)} />
                    <span />
                </div>
                </div> : ''
              }
            
              

            <div className="text-center">
              {upImg ? <span className="btn btn-primary" onClick={handleFileUpload}>Save</span> : ''}
            </div>
            
            <ul className="mt-4 mb-4 program_imgs">
                {    
                    images.filter(i => !i.viewType).map(item => (
                        <li key={item._id}><img src={item.url} /> <i className="icon-cross" onClick={() => handleRemove(item._id)} /></li>
                    ))
                }
            </ul>

            <ul className="mt-4 mb-4 program_imgs program_imgs_for_mob">
                {    
                    images.filter(i => i.viewType == 1).map(item => (
                        <li key={item._id}><img src={item.url} /> <i className="icon-cross" onClick={() => handleRemove(item._id)} /></li>
                    ))
                }
            </ul>
          </div>

          

        </Modal.Body>
      </Modal>
  )
}



const mapStateToProps = state => {
  let { progMedia, progMediaModal, campDetail, programPageNumner } = state.camp;
    return {
      campDetail,
      progMedia,
      progMediaModal,
      programPageNumner
    };
}

const mapDispatchToProps = dispatch => ({
  programMediaModal: params => dispatch(programMediaModal(params)),
  campDetailAction: params => dispatch(campDetailAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramMediaModal);