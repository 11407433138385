
import React, { useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DatePicker, notification, Empty } from 'antd';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import {
    createAdvertisementService,
    advertisementListService,
    advertisementChangeStatusListService,
    editAdvertisementService
} from '../../services/advertisementService';
import { advSearchAction } from '../../store/advertisement';
import { dateFormat2 } from '../../helper';


const TrRow = props => {
    return(
        <tr>
            <td><span className="t_text">{((props.index + 1) + ((props.pageNumber - 1) * 15))}</span></td>
            <td><span className="t_text">{moment(props.item.uploadDate).format('DD/MM/YYYY')}</span></td>
            <td><span className="t_text"><img className="adv_img" src={props.item.image} /></span></td>
            <td><span className="t_text">{props.item.tagline}</span></td>
            <td><span className="t_text"><a href={props.item.link} target="_blank">{props.item.link}</a></span></td>
            <td><span className="t_text">{props.item.page}</span></td>
            <td>
                <span className="t_text">
                    <i className="icon-pancil" onClick={() => props.handleEditPopup(props.item)} />
                    <i className="icon-delete" onClick={() => props.handleDelete(props.item)} />
                    <div className="cm_swich_wrap">
                        <input onChange={() => props.changeStatus(props.item)} type="checkbox" checked={props.item.status ? true : false} /><span />
                    </div>
                </span>
            </td>
        </tr>
    )
}


const ThirdPartyAdvertisement = props => {
    const [createModal, setCreateModal] = useState(false);
    const [createLoader, setCreateLoader] = useState(false);
    const [tagline, setTagline] = useState('');
    const [uploadDate, setUploadDate] = useState('');
    const [link, setLink] = useState('');
    const [page, setPage] = useState('');
    const [image, setImage] = useState('');
    const [imageView, setImageView] = useState('');

    const [taglineErr, setTaglineErr] = useState('');
    const [uploadDateErr, setUploadDateErr] = useState('');
    const [linkErr, setLinkErr] = useState('');
    const [pageErr, setPageErr] = useState('');
    const [imageViewErr, setImageViewErr] = useState('');
    const [advertisementList, setAdvertisementList] = useState([]);
    const [totalCount, setTotalCount] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [isEditMode, setIsEditMode] = useState(false);
    const [advertisementId, setAdvertisementId] = useState(false);



    const handleFileChange = e => {
        setImageViewErr('')
        let reader = new FileReader();
        let file = e.target.files[0];
        setImage(file);
        reader.addEventListener('load', () => {
            setImageView(reader.result)
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }




    const closeModal = () => {
        setCreateModal(false);
        setIsEditMode(false);
        setTagline('');
        setUploadDate('');
        setLink('');
        setPage('');
        setImageView('');
        setAdvertisementId('');
    }


    const handleValidate = () => {
        let validate = true;
        
        if(tagline == ''){
            setTaglineErr('Tagline is required');
            validate = false
        }

        if(uploadDate == ''){
            setUploadDateErr('Upload date is required');
            validate = false
        }

        if(link == ''){
            setLinkErr('Link is required');
            validate = false
        }

        if(page == ''){
            setPageErr('Page is required');
            validate = false
        }

        if(imageView == ''){
            setImageViewErr('image is required');
            validate = false
        }

        return validate;
    }



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setCreateLoader(true);
            let params = new FormData();
            params.append('tagline', tagline);
            params.append('uploadDate', uploadDate);
            params.append('link', link);
            params.append('page', page);
            params.append('image', image);


            if(isEditMode){
                params.append('advertisementId', advertisementId);
                editAdvertisementService(params).then(res => {
                    setCreateLoader(false);
                    if(res.data.statusCode){
                        closeModal();
                        getAdvertisementList();
                        notification.open({
                            message: 'Advertising',
                            description: `Advertising Edit successfully`
                        });
                    }
                })
            }else{
                createAdvertisementService(params).then(res => {
                    setCreateLoader(false);
                    if(res.data.statusCode){
                        closeModal();
                        getAdvertisementList();
                        notification.open({
                            message: 'Advertising',
                            description: `Advertising created successfully`
                        });
                    }
                })
            }

            

        }

    }



    const getAdvertisementList = () => {
        let query = `?page=${pageNumber - 1}&limit=15&search=${props.search}`;

        advertisementListService(query).then(res => {
            if(res.data.statusCode){
                setAdvertisementList(res.data.responseData.result);
                setTotalCount(res.data.responseData.count);
            }
        })
    }

    useEffect(() => {
        props.advSearchAction('')
    }, [])

    useEffect(() => {
        getAdvertisementList()
    }, [pageNumber])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getAdvertisementList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [props.search])



    const changeStatus = adv => {
        let params = {
            advertisementId: adv._id,
            status: adv.status == 1 ? '0' : '1'
        }
        advertisementChangeStatusListService(params).then(res => {
            if(res.data.statusCode){
                getAdvertisementList()
            }   
        })
    }



    const handleDelete = adv => {
        let params = {
            advertisementId: adv._id,
            status: '3'
        }
        advertisementChangeStatusListService(params).then(res => {
            if(res.data.statusCode){
                getAdvertisementList()
            }   
        })
    }



    const handleEditPopup = adv => {
        setIsEditMode(true);
        setCreateModal(true);
        setTagline(adv.tagline);
        setUploadDate(adv.uploadDate);
        setLink(adv.link);
        setPage(adv.page);
        setImageView(adv.image);
        setAdvertisementId(adv._id);
    }





    return (
        <div className="third_party_advertisement_wrapper">
             

            <div className="btn_wrapper">
                <button onClick={() => setCreateModal(true)} className="btn btn-primary">Upload</button>
            </div>

            <div className="table_responsive_wrap mt-4 text-center">
                <div className="table-responsive">
                    <table className="table table-bordered table-striped no_t_texts">
                        <thead>
                        <tr>
                            <th><span className="t_text">S.No</span></th>
                            <th><span className="t_text">Upload Date</span></th>
                            <th><span className="t_text">Image</span></th>
                            <th><span className="t_text">Tagline</span></th>
                            <th><span className="t_text">Link</span></th>
                            <th><span className="t_text">Page</span></th>
                            <th><span className="t_text">Action</span></th>
                        </tr>
                        </thead>
                        <tbody>{advertisementList.map((item, i) => <TrRow
                                                                        key={item._id}
                                                                        item={item}
                                                                        index={i}
                                                                        pageNumber={pageNumber}
                                                                        handleDelete={handleDelete}
                                                                        handleEditPopup={handleEditPopup}
                                                                        changeStatus={changeStatus} />)}
                        </tbody>
                    </table>

                </div>

               <div className="mt-3">
                    {
                        advertisementList.length ? <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={15}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPageNumber(page)}/> : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                    }    

                </div>
            </div>


            <Modal
                size="lg"
                show={createModal}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>{"Upload"}</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="icon_upload_wrapper w-100">
                            <input onChange={handleFileChange} type="file" accept="image/x-png,image/jpeg" />
                            {imageView ? <img src={imageView} alt="icon" /> : ''}

                            {imageView ? <div className="cm_edit_mode">
                                <i className="icon-create" />
                            </div> : <div>
                                <i className="icon-button" />
                                <p>Upload Image</p>
                            </div>}
                            {imageViewErr ? <div className="invalid_feild">{imageViewErr}</div> : ''}
                            
                        </div>

                        <div className="form-group">
                            <label>Tagline</label>
                            <input
                                type="text"
                                className={`form-control ${taglineErr ? 'is-invalid' : ''}`}
                                value={tagline}
                                onChange={e => (setTagline(e.target.value), setTaglineErr(''))}
                            />

                            {taglineErr ? <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip>{taglineErr}</Tooltip>} >
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>


                        <div className="form-group">
                            <label>Upload Date</label>
                            <DatePicker
                                className={`form-control ${uploadDateErr ? 'is-invalid' : ''}`}
                                value={uploadDate ? moment(dateFormat2(parseInt(uploadDate))): ''}
                                onChange={e => (setUploadDate(e._d.getTime()), setUploadDateErr(''))}
                                />
                                {uploadDateErr ? <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip>{uploadDateErr}</Tooltip>} >
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        
                        </div>


                        <div className="form-group">
                            <label>Link</label>
                            <input
                                type="url"
                                className={`form-control ${linkErr ? 'is-invalid' : ''}`}
                                value={link}
                                onChange={e => (setLink(e.target.value), setLinkErr(''))}
                            />
                            {linkErr ? <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip>{linkErr}</Tooltip>} >
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>


                        <div className="form-group">
                            <label>Page</label>
                            <Select
                                placeholder="Select a page"
                                onChange={e => (setPage(e.value), setPageErr(''))}
                                classNamePrefix="cm_select"
                                className={`form-control ${pageErr ? 'is-invalid' : ''}`}
                                value={pages.map(el => el == page && ({value: el, label: el}))}
                                options={pages.map(item => ({value: item, label: item}))} />
                           
                            {pageErr ? <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip>{pageErr}</Tooltip>} >
                                            <span className="error_tootltip" />
                                        </OverlayTrigger> : ''}
                        </div>


                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                            <button disabled={createLoader} type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    let { search } = state.advertisement;
    return {
        search,
    };
}

const mapDispatchToProps = dispatch => ({
    advSearchAction: params => dispatch(advSearchAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyAdvertisement);

const pages = [
    'home',
    'bookings',
    'calender',
    'gallery'
]

