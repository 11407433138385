
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';

import { supportSearchAction } from '../../store/support';
import { getRateExperienceService } from '../../services/supportService';
import { MainLoader, Empty } from '../reuse/SVG';


const RateExperience = props => {
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setRateTotalCount] = useState('');


    useEffect(() => {
        return () => {
            props.supportSearchAction('')
        }
    }, [])


    const getRateExperience = () => {
        let query = `?page=${page - 1}&limit=15&search=${props.search}`;

        getRateExperienceService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setRateTotalCount(res.data.responseData.count);
            }
        })        
    }


    useEffect(() => {
        getRateExperience();
    }, [page])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getRateExperience();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [props.search])


    return (
        <div className="ask_question_wrapper">
            {loader ? <MainLoader /> : '' }
            <div className="table_responsive_wrap mt-4 text-center">
                <div className="table-responsive">

                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                            <tr>
                                <th><span className="t_text">S.No</span></th>
                                <th><span className="t_text">Name</span></th>
                                <th><span className="t_text">Review</span></th>
                                <th><span className="t_text">Rating</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                list.map((item, i) => (
                                    <tr key={item._id}>
                                        <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                        <td><span className="t_text">{item.name} {item.lastName}</span></td>
                                        <td><span className="t_text">{item.appReview}</span></td>
                                        <td><span className="t_text">{item.appRating}</span></td>
                                    </tr>
                                ))
                            }


                        </tbody>
                    </table>

                    {list.length ? '' : <div className="cm_empty mt-3">
                                                <Empty />
                                                <p>No Data</p>
                                            </div> }
                </div>
                <div className="mt-3">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
               </div>
            </div>
        </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { search } = state.support;
    return {
        search
    };
}

const mapDispatchToProps = dispatch => ({
    supportSearchAction: params => dispatch(supportSearchAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateExperience);

