import ApiInstance from './intercepter';
import API from '../config/api';

export const categoryList = params => ApiInstance.post(API.CATEGORY_LIST, params);

export const changeCategoryStatus = params => ApiInstance.put(API.CATEGORY_STATUS_CHANGE, params);

export const addCategory = params => ApiInstance.post(API.CATEGORY_ADD, params);

export const editCategory = params => ApiInstance.put(API.CATEGORY_EDIT, params);

export const categoryDetail = params => ApiInstance.get(API.CATEGORY_DETAIL + params);

export const categoryDelete = params => ApiInstance.delete(API.CATEGORY_DELETE, {data: params});

