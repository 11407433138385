import ApiInstance from './intercepter';
import API from '../config/api';


export const bookingListService = params => ApiInstance.post(API.GET_BOOKING_LIST, params);

export const bookingDetailService = params => ApiInstance.post(API.GET_BOOKIN_DETAIL, params);

export const getAdditionalExperienceService = params => ApiInstance.post(API.GET_ADDITIONAL_EXP, params);

export const changeSplitStatusService = params => ApiInstance.post(API.CHANGE_SPLIT_STATUS, params);

export const getCancelBookingCountService = params => ApiInstance.post(API.GET_CANCEL_BOOKING_COUNT, params);

export const createBookingByAdminService = params => ApiInstance.post(API.CREATE_BOOKING_BY_ADMINT, params);

export const calcAllPriceService = params => ApiInstance.post(API.CALC_ALL_PRICE, params);

export const getCampDetailService = params => ApiInstance.get(API.GET_CAMP_DETAIL + params);

export const createCustomerCardService = params => ApiInstance.post(API.CREATE_CUSTOMER_CARD, params);

export const productBookingsService = params => ApiInstance.get(API.PRODUCT_BOOKING_LIST + params);

export const genXlsService = params => ApiInstance.post(API.GEN_XLS, params);