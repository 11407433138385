import React, { useReducer, useEffect, Fragment, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';

import { faqModalModalAction, addFaqAction, faqListAction, editFaqAction, viewCloseAction } from '../../store/faq';

import { faqHeadings } from '../../helper';
import { CustomSelect } from '../util/FormControls';
import { MiniLoader } from '../reuse/SVG';


const FaqModal = props => {
    const [disBtn, setDisBtn] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            heading: '',
            question: '',
            answer: ''
        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            heading: '',
            question: '',
            answer: ''
        }
    );

    useEffect(() => {
        if(props.isEditMode){
            setUserInput(props.faq);
        }
    }, [props.toggleFAQModal])


    const closeModal = () => {
        setUserInput(
          {
            heading: '',
            question: '',
            answer: ''
          }
        )
    
        setError(
          {
            heading: '',
            question: '',
            answer: ''
          }
        )
    
        props.faqModalModalAction();
      }

    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }

    const handleSelect = e => {
        setUserInput({heading: e.value});
        setError({heading: ''});
    }


    const handleValidate = () => {
        let validate = true;

        let { heading, question, answer } = userInput;

        if (heading == '') {
            setError({ heading: 'Heading is required' });
            validate = false
        }

        if (question === '') {
            setError({ question: 'Question is required' });
            validate = false
        }

        if (answer === '') {
            setError({ answer: 'Answer is required' });
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setDisBtn(true);
            let { heading, question, answer } = userInput;
            let params = { faqId:props.faq._id, heading, question, answer };

            if(props.isEditMode){
                props.editFaqAction(params).then(res => {
                    props.faqListAction('');
                    setDisBtn(false)
                    setUserInput(
                        {
                          heading: '',
                          question: '',
                          answer: ''
                        }
                      )
                })
            }else{
                props.addFaqAction(params).then(res => {
                    props.faqListAction('');
                    setDisBtn(false)
                    setUserInput(
                        {
                          heading: '',
                          question: '',
                          answer: ''
                        }
                      )
                })
            }
        }
    }

    return (
        <Modal
            size="lg"
            show={props.toggleFAQModal}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>{props.isViewMode ? 'View FAQ' : 'Add FAQ'}</Modal.Title>
                <span className="modal_close" onClick={props.isViewMode ? props.viewCloseAction: closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
                <form onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label>Heading</label>

                        {
                            props.isViewMode ?

                            <h4>{props.faq.heading}</h4>

                            :

                            <Fragment>
                                <CustomSelect
                                    pHolder="Heading"
                                    change={handleSelect}
                                    classNamePrefix="cm_select"
                                    value={userInput.heading ? {value: userInput.heading, label: userInput.heading} : ''}
                                    options={faqHeadings.map(item => ({value: item, label: item}))}
                                    error={error.heading} />
                            </Fragment>
                        }
                        
                    </div>

                    {
                        props.isViewMode ? props.faq.list.map(item => (<Fragment>
                            <div className="cm_faq_block">
                                <h6>Question </h6>
                                <p className="faq_q">{item.question}</p>
                                <h6>Answer </h6>
                                <p className="faq_a">{item.answer}</p>
                            </div>
                        </Fragment>))

                        : ''
                    }

                    {
                        props.isViewMode ? '' : (<Fragment>
                            <div className="form-group">
                                <label>FAQ Question</label>
                                    <input
                                        type="text"
                                        className={`form-control ${error.question ? 'is-invalid' : ''}`}
                                        name="question"
                                        value={userInput.question}
                                        onChange={handleChange}
                                    />
                                    {error.question ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.question}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                            </div>




                            <div className="form-group">
                                <label>FAQ Answer</label>
                                <textarea
                                    className={`cm_textarea form-control ${error.answer ? 'is-invalid' : ''}`}
                                    name="answer"
                                    value={userInput.answer}
                                    onChange={handleChange}
                                />
                                {error.answer ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.answer}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                            </div>
                        </Fragment>)
                    }



                    


                    {
                        props.isViewMode ? '' : <div className="d-flex justify-content-center mt-4 mb-4">
                                                    <button disabled={disBtn} onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                                                    <button type="submit" className="btn btn-primary pl-5 pr-5">Submit</button>
                                                </div>
                    }
                    {
                        disBtn ? <div className="btn_mini_loader"><MiniLoader /></div> : ''
                    }

                    

                </form>
            </Modal.Body>
        </Modal>

    )
}

const mapStateToProps = state => {
    let { toggleFAQModal, isEditMode, faq, isViewMode } = state.faq;
    return {
        toggleFAQModal,
        isEditMode,
        faq,
        isViewMode
    };
}

const mapDispatchToProps = dispatch => ({
    faqModalModalAction: () => dispatch(faqModalModalAction()),
    addFaqAction: params => dispatch(addFaqAction(params)),
    editFaqAction: params => dispatch(editFaqAction(params)),
    faqListAction: params => dispatch(faqListAction(params)),
    viewCloseAction: () => dispatch(viewCloseAction()),

});

export default connect(mapStateToProps, mapDispatchToProps)(FaqModal);