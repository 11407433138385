import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { clearTempEmailAction } from '../../store/ui';




const Success = props => {

    useEffect(() => {
        return () => {
          props.clearTempEmailAction()
        };
    }, []);

    return(
        <div className="d-flex align-items-center auth_wrapper fp_wrapper">
            { props.tempEmail ? '' : <Redirect to="/login"  />}
            <div className="container">
                <div className="row no-gutters h-100 d-flex align-items-center">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6 text-center">
                        <img src={require('../../assets/img/logo.svg')} alt="logo" />
    
                            <p className="success_text">Success! You will receive detailed instruction on resetting your password to your {props.tempEmail} address in the shortest time possible. </p>
    
                            <Link to='/login' className="btn btn-primary btn-block">Back to Sign In</Link>
                    </div>
                </div>
            </div>
    
            <div className="copyright">© 2020 CampsEZ. All Rights Reserved.</div>
        </div>
    )
}


const mapStateToProps = state => {
    let { tempEmail } = state.ui
    return {
        tempEmail 
    };
}


const mapDispatchToProps = dispatch => ({
    clearTempEmailAction: () => dispatch(clearTempEmailAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Success);