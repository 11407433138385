import * as actionType from './actionsTypes';
import { preList } from "../services/preListServices";
import {resHandle} from "../helper";

const initialState = {
    breadcrumb: '',
    tempEmail: '',
    bookingLimit: 12,
    preList: {
        program: [],
        camp: [],
        category: [],
        subcategory: [],
        nonSpacialProgram: [],
        personnel: [],
    },
    subcategories: [],
    error: ''
}


/************************************************************************************/
// UI Reducer
/************************************************************************************/


const uiReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.PRE_LIST_LOADER:
            return {
                ...state,
                loader: !state.loader
            }

        case actionType.SET_BREADCRUMB:
            return {
            ...state,
            breadcrumb: action.payload
        }

        case actionType.TEMP_EMAIL:
            return {
            ...state,
            tempEmail: action.payload
        }

        case actionType.BOOKING_LIMIT_ACTION:
            return {
            ...state,
            bookingLimit: action.payload
        }

        case actionType.PRE_LIST:
            return {
                ...state,
                preList: action.payload
            }

        case actionType.SET_SUB_CATEGORIES:
            return {
                ...state,
                subcategories: action.payload
            }

        case actionType.SET_NON_SPACIAL_PROGRAMS:
            return {
                ...state,
                nonSpacialProgram: action.payload
            }

        case actionType.PRE_LIST_ERROR:
            return {
                ...state,
                loader: false,
                error: action.payload
            }


        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const setBreadcrumbAction = payload => dispatch => dispatch({ type: actionType.SET_BREADCRUMB, payload });

export const clearTempEmailAction = () => dispatch => dispatch({ type: actionType.TEMP_EMAIL, payload: '' });

export const bookingLimitAction = payload => dispatch => dispatch({ type: actionType.BOOKING_LIMIT_ACTION, payload });

export const setSubCategories = payload => (dispatch => dispatch({ type: actionType.SET_SUB_CATEGORIES, payload }));

export const setNonSpacialPrograms = payload => (dispatch => dispatch({ type: actionType.SET_NON_SPACIAL_PROGRAMS, payload }));



export const getPreListAction = () => async dispatch => {

    dispatch({ type: actionType.PRE_LIST_LOADER });

        let preListData = await preList();
        let { status, data } = resHandle(preListData);

        try {
            if(status){
                    dispatch({ type: actionType.PRE_LIST, payload: data.data });

                    return status;
                }else{
                    dispatch({ type: actionType.PRE_LIST_ERROR, payload: data });
                }
        } catch (error) {
            dispatch({ type: actionType.PRE_LIST_ERROR, payload: error });
        }

}

export default uiReducer;