import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { notification, Empty } from 'antd';
import Pagination from 'react-js-pagination';

import { setBreadcrumbAction } from '../../store/ui';
import {
    createCareerService,
    getCareerService,
    editCareerService,
    careerChangeStatusService } from '../../services/pagesService';
import { MainLoader, MiniLoader } from '../reuse/SVG';
import { CustomNumber } from '../util/FormControls';


const Career = props => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);
    const [count, setCount] = useState('');
    const [page, setPage] = useState(1);
    const [viewType, setViewType] = useState(1);
    const [search, setSearch] = useState('');

    const [careerModal, setCareerModal] = useState(false);
    const [careerId, setCareerId] = useState('');
    const [jobTitle, setJobTitle ] = useState('');
    const [jobTitleErr, setJobTitleErr ] = useState('');
    const [desc, setDesc] = useState('');
    const [descErr, setDescErr] = useState('');
    const [image, setImage] = useState('');
    const [imageView, setImageView] = useState('');
    const [miniLoader, setMiniLoader] = useState('');

    let params = {
        icon: 'Pages',
        links: [
            { name: 'Pages', path: '/pages' },
            { name: 'Career', path: '' }
        ]

    }


    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);
    


    const getCareers = () => {
        let query = `?page=${page - 1}&limit=15&search=${search}`;

        getCareerService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setCount(res.data.responseData.count);
            }
        })
    }


    useEffect(() => {
        getCareers()
    }, [search, page]);


    const closeModal = () => {
        setCareerModal(false);
        setJobTitle('');
        setCareerId('')
        setDescErr('');
        setJobTitleErr('');
        setDesc('');
        setImage('');
        setImageView('');
    }


    const openEditModal = data => {
        setViewType(2);
        setCareerModal(true);
        setJobTitle(data.jobTitle);
        setCareerId(data._id);
        setDesc(data.desc);
        setImageView(data.image);
    }

    const openViewModal = data => {
        setViewType(3);
        setCareerModal(true);
        setJobTitle(data.jobTitle);
        setDesc(data.desc);
        setImageView(data.image);
    }

    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setImage(file); 
        reader.addEventListener('load', () => {
            setImageView(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }



    const handleValidate = () => {
        let validate = true;

        if(jobTitle == ''){
            setJobTitleErr('Title is required')
            validate = false
        }

        if(desc == ''){
            setDescErr('Description is required')
            validate = false
        }
    
        return validate;
    }
    
    
    
    
    
    const handleSubmit = e => {
      e.preventDefault();
    
      if(handleValidate()){
            setLoader(true);
            let params = new FormData();
            params.append('careerId', careerId);
            params.append('jobTitle', jobTitle);
            params.append('desc', desc);
            params.append('image', image);
    
            let apiService = viewType == 1 ? createCareerService : editCareerService;

        
            apiService(params).then(res => {
                setLoader(false);
            if(res.data.statusCode){
                closeModal();
                notification.open({
                    message: 'Career',
                    description: `Career ${viewType == 1 ? 'create' : 'edit'} successful`
                });
                getCareers();
            }
          })
         
      }
    }



    const handleStatusChange = (careerId, status, action) => {
        let params = ''
        if(action == 'status'){
            setMiniLoader({[careerId]: true});
            params = {
                careerId,
                status: status == 1 ? '2' : '1' 
            }
        }else{
            params = {
                careerId,
                status: '3' 
            }
        }
        
        
        careerChangeStatusService(params).then(res => {
            setMiniLoader('');
            getCareers();
            notification.open({
                message: 'Career Status',
                description: `Career status change successfully to  ${status == 1 ? 'Inactive' : 'Active'}`
            });
        }); 
    }
    

    return(
        <div className="page_wrapper">
            {loader ? <MainLoader /> : ''}

            <div className="search_wraper">

                <i className="icon-search" />
                <input type="text" onChange={e => setSearch(e.target.value)} className="form-control" placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Career</h4>
                    <div className="btn_group">
                        <button onClick={() => (setCareerModal(true), setViewType(1))} className="btn btn-primary"><i className="icon-plus" /> Add Job</button>
                    </div>
                </div>

                <div className="page_card_body ">

                        <div className="table_responsive_wrap mt-4 text-center">
                            <div className="table-responsive">

                                <table className="table table-bordered table-striped no_t_texts">

                                    <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Job Title</span></th>
                                        <th><span className="t_text">Description</span></th>
                                        <th><span className="t_text">Created</span></th>
                                        <th><span className="t_text">Action</span></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        list.map((item, i) => (
                                            <tr key={item._id}>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                                <td><span className="t_text">{item.jobTitle}</span></td>
                                                <td><span className="t_text">{item.desc}</span></td>
                                                <td><span className="t_text">{moment(item.created).format('MM/DD/YYYY')}</span></td>
                                                <td>
                                                    <span className="t_text">
                                                        <i className="icon-pancil" onClick={() => openEditModal(item)} />
                                                        <i className="icon-eye" onClick={() => openViewModal(item)}/>
                                                        <i className="icon-delete" onClick={() => handleStatusChange(item._id, item.status, 'delete')}/>
                                                        <div className="cm_swich_wrap">
                                                            {miniLoader[item._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={item.status == 1 ? true : false} onChange={() => handleStatusChange(item._id, item.status, 'status')} /><span /></React.Fragment>}
                                                        </div>
                                                    </span>
                                                    
                                                </td>
                                            </tr>
                                        ))
                                    }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                        </div>  

                        {
                            list.length ? <Pagination
                                                activePage={page}
                                                itemsCountPerPage={15}
                                                totalItemsCount={count}
                                                pageRangeDisplayed={3}
                                                onChange={page => setPage(page)}/> : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                        } 

               

            </div>





            <Modal
                size="lg"
                show={careerModal}
                animation={false}
                centered
            >
                <Modal.Header>
                                <Modal.Title>
                                    {viewType == 1 ? 'Add' : ''}
                                    {viewType == 2 ? 'Edit' : ''}
                                    {viewType == 3 ? 'View' : ''} Job</Modal.Title>

                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    {viewType == 3 ? (
                    <div className="view_news_modal">
                        <div className="icon_upload_wrapper cat_banner_img  w-100">
                            <img src={imageView} alt="icon" />
                        </div>
                        
                        <h3>{jobTitle}</h3>
                        <p>{desc}</p>
                    </div>
                    ) : (
                        <form onSubmit={handleSubmit}>

                        <div className="icon_upload_wrapper cat_banner_img  w-100">
                            <input type="file" onChange={handleFileChange} accept="image/x-png,image/jpeg" />
                            {imageView ? <img src={imageView} alt="icon" /> : ''}
                            {imageView ? <div className="cm_edit_mode"><i className="icon-create" /></div> : <div><i className="icon-button" /><p>Upload Image</p></div>}
                        </div>

                        <div className="form-group">
                            <label>Job Title</label>
                            <div className="icon_input">
                                <input
                                    type="text"
                                    className={`form-control ${jobTitleErr ? 'is-invalid' : ''}`}
                                    value={jobTitle}
                                    onChange={e => (setJobTitle(e.target.value), setJobTitleErr(''))} />

                                {jobTitleErr ? <OverlayTrigger
                                                placement="left"
                                                overlay={<Tooltip>{jobTitleErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''
                                }
                            </div>
                        </div>

         

                        <div className="form-group">
                            <label>Description</label>
                            <div className="icon_input">
                                <textarea
                                    className={`form-control ${descErr ? 'is-invalid' : ''}`}
                                    value={desc}
                                    onChange={e => (setDesc(e.target.value), setDescErr(''))} />

                                {descErr ? <OverlayTrigger
                                                placement="left"
                                                overlay={<Tooltip>{descErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''
                                }
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-5 mb-4">
                            <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                            <button type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
                        </div>
                        </form>
                    )}
               
                    
                </Modal.Body>
            </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Career);