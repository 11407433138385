import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { notification } from 'antd'

import { setBreadcrumbAction } from '../../store/ui';
import { dateFormat, removeTag } from '../../helper';
import {
    termsTypeAction,
    tremsFeedListAction,
    termsModalAction,
    termEditAction,
    changeTremsFeedStatusAction,
} from '../../store/terms';
import { deleteTermsFeed } from '../../services/termsService';
import { Empty, MainLoader, MiniLoader } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';


const AdditionalSettings = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');

    let queryParams = `?type=${props.termsType}&search=${search}&page=${page}&limit=${limit}`;

    let params = {
        icon: 'settings',
        links: [
            {name: 'Additional Settings', path: ''}
        ]

    }

    useEffect(() => {
        props.setBreadcrumbAction(params)
        props.termsTypeAction(1)
    }, []);


    const getList = () => {
        props.tremsFeedListAction(queryParams);
    }

    useEffect(() => {
        getList();
    }, [props.termsType, page]);


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])



    const changeType = type => {
        props.termsTypeAction(type);
        setSearch('');
        setPage(1);
    };

    const handleSearch = e => {
        setSearch(e.target.value);
        setPage(1);
    };

    const handlePageChange = page => setPage(page);


    const handleStatusChange = (e, item) => {
        setMiniLoader({[item._id]: true});
        let params = {
            editId : item._id,
            status: item.status == 0 ? '1' : '0' 
        }
        props.changeTremsFeedStatusAction(params).then(status => {
            notification.open({
                message: 'Status',
                description: `Status change successfully to  ${item.status == 1 ? 'Inactive' : 'Active'}`
            });
            props.termsTypeAction(props.termsType)
            setMiniLoader('');
            getList();
        }); 
    }

    
    const handleDelete = () => {
        if(currentId){
            let params = { termId : currentId };
            deleteTermsFeed(params).then(status => {
            setCurrentId('');
            getList();
            setConfirmModal(false);
        }); 
    }else {
        setCurrentId('');
        setConfirmModal(false);
        }
    }


 


    return(
        <div className="page_wrapper">

            <ConfirmModal
                show={confirmModal}
                hideModal={() => setConfirmModal(false)}
                msg="Are you sure you want to delete?"
                action={handleDelete} />

            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}
                <i className="icon-search" />
                <input type="text" onChange={handleSearch} value={search} className="form-control" placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="page_card_header">
                    <h4>Additional Settings</h4>
                </div>

                <div className="page_card_body">
                   

                    <div className="d-flex align-items-center justify-content-between tab_btns">
                        <div>
                            <span onClick={() => changeType(1)} className={props.termsType == 1 ? 'active' : ''}>Term & Conditions</span>
                            <span onClick={() => changeType(2)} className={props.termsType == 2 ? 'active' : ''}>Disclaimer</span>
                            <span onClick={() => changeType(3)} className={props.termsType == 3 ? 'active' : ''}>Privacy Statement</span>
                        </div>
                        <button onClick={() => props.termsModalAction(0)} className="btn btn-primary"><i className="icon-plus" /> Add</button>
                    </div>





                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">
                        
                            <table className="table table-bordered table-striped">

                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Date</span></th>
                                        <th><span className="t_text">Media</span></th>
                                        <th><span className="t_text">Title</span></th>
                                        <th><span className="t_text">Description</span></th>
                                        <th><span className="t_text">Action</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        props.terms.map((item, i) => (
                                            <tr key={item._id}>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                                                <td><span className="t_text">{dateFormat(item.created)}</span></td>
                                                <td><span className="t_text">{item.image ? <img className="term_img" src={item.image} /> : '--'}</span></td>
                                                <td><span className="t_text">{item.title}</span></td>
                                                <td><span className="t_text">{removeTag(item.desc)}</span></td>
                                                <td>
                                                    <span className="t_text">
                                                        
                                                        <i className="icon-pancil" onClick={() => props.termEditAction(item)} />
                                                        <i className="icon-delete" onClick={() => (setCurrentId(item._id), setConfirmModal(true))} />

                                                        <div className="cm_swich_wrap">
                                                            {miniLoader[item._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={item.status} onChange={e => handleStatusChange(e, item)} /><span /></React.Fragment>}
                                                        </div>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                        
                                    }


                                    
                                   
                                </tbody>
                            </table>

                            {props.terms.length ? '': <div className="col-12 mt-5 mb-5 cm_empty"><Empty /><p>No Data</p></div>}

                        </div>
                    </div>


                    





                </div>

                {props.terms.length ? <Pagination
                                            activePage={page}
                                            itemsCountPerPage={limit}
                                            totalItemsCount={props.count}
                                            pageRangeDisplayed={3}
                                            onChange={handlePageChange}/> : ''}

            </div>


        </div>
    )
}


const mapStateToProps = state => {
    let { loader, termsType, isTermEdit, terms, count } = state.terms;
    return {
        loader,
        termsType,
        isTermEdit,
        terms,
        count
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    termsTypeAction: params => dispatch(termsTypeAction(params)),
    tremsFeedListAction: params => dispatch(tremsFeedListAction(params)),
    termsModalAction: params => dispatch(termsModalAction(params)),
    termEditAction: params => dispatch(termEditAction(params)),
    changeTremsFeedStatusAction: params => dispatch(changeTremsFeedStatusAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalSettings);
