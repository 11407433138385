import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import Select from 'react-select';
import { DatePicker } from 'antd';

import { setBreadcrumbAction } from '../../store/ui';
import { addNotificationService, getAllNotificationService } from '../../services/notificationService';
import { MainLoader } from '../reuse/SVG';



const Notification = props => {
    const [loader, setLoader] = useState(true);
    const [createModal, setCreateModal] = useState(false);
    const [title, setTitle] = useState('');
    const [titleErr, setTitleErr] = useState('');
    const [message, setMessage] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [sendTo, setSendTo] = useState('');
    const [sendToErr, setSendToErr] = useState('');
    const [notificationTime, setNotificationTime] = useState('');
    const [notificationTimeErr, setNotificationTimeErr] = useState('');

    const [list, setList] = useState([]);
    const [count, setCount] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    let params = {
        icon: 'Notifications',
        links: [
            { name: 'Notifications', path: '' }
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);



    const closeModal = () => {
        setCreateModal(false);
        setTitle('');
        setMessage('');
    }


    const handleSelect = e => {
        setSendTo(e.value);
        setSendToErr('');
      }
    



    const handleValidate = () => {
        let validate = true;
        
        if(title == ''){
            setTitleErr('title is required');
            validate = false
        }

        if(message == ''){
            setMessageErr('message is required');
            validate = false
        }

        if(notificationTime == ''){
            setNotificationTimeErr('Notification date is required');
            validate = false
        }

        if(sendTo == ''){
            setSendToErr('Send to is required');
            validate = false
        }


        return validate;
    }



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setLoader(true);
            let params = { notificationTitle: title, message, sendTo, notificationTime };

            addNotificationService(params).then(res => {
                if(res.data.statusCode){
                    setLoader(false);
                    closeModal();
                    getAllNotification();
                }
            })

        }

    }




    const getAllNotification = () => {
        let query = `?search=${search}&page=${page - 1}&limit=${limit}`;

        getAllNotificationService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setCount(res.data.responseData.count);
            }
        })
    }

    useEffect(() => {
        getAllNotification();
    }, [page])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getAllNotification();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])



    return (
        <div className="page_wrapper">
            {loader ? <MainLoader /> : ''}
            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Notifications</h4>
                    <button onClick={() => setCreateModal(true)} className="btn btn-primary"><i className="icon-plus" />Add</button>
                </div>


                <div className="page_card_body ">

                    <div className="coupan_code_wrapper">



                        <div className="table_responsive_wrap mt-4 text-center">
                            <div className="table-responsive">

                                <table className="table table-bordered table-striped no_t_texts">

                                    <thead>
                                        <tr>
                                            <th><span className="t_text">S.No</span></th>
                                            <th><span className="t_text">Created</span></th>
                                            <th><span className="t_text">Title</span></th>
                                            <th><span className="t_text">Notification</span></th>
                                            <th><span className="t_text">Send to</span></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            list.map((item, i) => (
                                                <tr key={item._id}>
                                                    <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                                                    <td><span className="t_text">{moment(item.created).format('MM/DD/YYYY')}</span></td>
                                                    <td><span className="t_text">{item.notificationTitle}</span></td>
                                                    <td><span className="t_text">{item.message}</span></td>  
                                                    <td><span className="t_text">{item.topic}</span></td>  
                                                </tr>
                                            ))
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mt-3">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={count}
                            pageRangeDisplayed={3}
                            onChange={page => setPage(page)}/>
                            </div>
                    </div>


                </div>

            </div>



            <Modal
                size="lg"
                show={createModal}
                animation={false}
                centered
                >
                <Modal.Header>
                    <Modal.Title>{"Add Notification"}</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit}>

                
                    <div className="form-group">
                        <label>Title</label>
                        <input
                            type="text"
                            placeholder="Title"
                            className={`form-control ${titleErr ? 'is-invalid' : ''}`}
                            value={title}
                            onChange={e => (setTitle(e.target.value), setTitleErr(''))}
                        />
                        {titleErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{titleErr}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                    </div>



                    <div className="form-group">
                        <label>Upload Date</label>
                        <DatePicker
                            className={`form-control ${notificationTimeErr ? 'is-invalid' : ''}`}
                            onChange={e => (setNotificationTime(e._d.getTime()), setNotificationTimeErr(''))}
                            />
                    {notificationTimeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{notificationTimeErr}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                    </div>


                    <div className="form-group">
                        <label>Send To</label>
                        <Select
                            placeholder="Select a type"
                            onChange={handleSelect}
                            classNamePrefix="cm_select"
                            className={`form-control ${sendToErr ? 'is-invalid' : ''}`}
                            options={[
                                {value: 'all', label: 'All'},
                                {value: 'camper', label: 'Camper'},
                                {value: 'personnel', label: 'Personnel'},
                                {value: 'director', label: 'Director'},
                            ]}/>
                            {sendToErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{sendToErr}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                    </div>


                    <div className="form-group">
                        <label>Message</label>
                        <textarea
                            className={`form-control ${messageErr ? 'is-invalid' : ''}`}
                            placeholder="Message"
                            value={message}
                            onChange={e => (setMessage(e.target.value), setMessageErr(''))}
                        />
                        {messageErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{messageErr}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                    </div>

                   
                    <div className="d-flex justify-content-center mt-4">
                        <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                        <button type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
                    </div>

                    </form>
                </Modal.Body>
                </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    let { toggleAddNotificationModal } = state.notification;
    return {
        toggleAddNotificationModal,
        
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

const loop = [1]