import ApiInstance from './intercepter';
import API from '../config/api';


export const getNewsService = params => ApiInstance.get(API.GET_NEWS + params);

export const createNewsService = params => ApiInstance.post(API.CREATE_NEWS, params);

export const editNewsService = params => ApiInstance.post(API.EDIT_NEWS, params);

export const createCareerService = params => ApiInstance.post(API.CREATE_CAREER, params);

export const editCareerService = params => ApiInstance.post(API.EDIT_CAREER, params);

export const getCareerService = params => ApiInstance.get(API.GET_CAREER + params);

export const careerChangeStatusService = params => ApiInstance.put(API.CAREER_STATUS_CHANGE, params);

export const getStaticPage = params => ApiInstance.get(API.GET_STATIC_PAGES + params);

export const updateStaticPage = params => ApiInstance.put(API.UPDATE_STATIC_PAGES, params);
