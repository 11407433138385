import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import { DatePicker, notification } from 'antd';

import { genders2, removeTime, resHandle } from '../../helper';
import moment from 'moment';
import { updateProfileService } from '../../services/authService';


const dateFormatNew = 'MM/DD/YYYY';


const ProfileInformation = ({data, callback}) => {

    const [userId, seUserId] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState('');
    const [secondaryContact, setCecondaryContact] = useState('');
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [btnloder, setBtnloder] = useState(false);
    const [profileImage, setProfileImage] = useState('');


    const [nameErr, setNameErr] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');
    const [genderErr, setGenderErr] = useState('');
    const [phoneErr, setPhoneErr] = useState('');
    const [dobErr, setDobErr] = useState('');
    const [addressErr, setAddressErr] = useState('');


    useEffect(() => {
        if(data._id){
            seUserId(data._id || '');
            setName(data.name || '');
            setLastName(data.lastName || '');
            setGender(data.gender || '');
            setEmail(data.email || '');
            setPhone(data.phone || '');
            setDob(data.dob || '');
            setAddress(data.address || '');
            setCecondaryContact(data.secondaryContact || '');
            setSecondaryPhoneNumber(data.secondaryPhoneNumber || '');
            setSecondaryEmail(data.secondaryEmail || '');
            setProfileImageUrl(data.profileImage || '');
        }

    }, [data])


    const handleFileChange = e => {
        if (e.target.files[0]) {
            setProfileImage(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setProfileImageUrl(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
          }
    }



    const handleValidate = () => {
        let validate = true;

        if(name == '' || name == undefined){
            setNameErr('Name is required');
            validate = false
        }

        if(lastName == '' || lastName == undefined){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(lastName == '' || lastName == undefined){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(gender == '' || gender == undefined){
            setGenderErr('Gender is required');
            validate = false
        }

        if(phone == '' || phone == undefined){
            setPhoneErr('Phone number is required');
            validate = false
        }

        if(dob == '' || dob == undefined){
            setDobErr('Date of Birth is required');
            validate = false
        }

        if(address == '' || address == undefined){
            setAddressErr('Address is required');
            validate = false
        }

        return validate;
    }


    const handelSubmit = (e) => {
        e.preventDefault();
        if(handleValidate()){

            setBtnloder(true);
            let formData = new FormData();
            formData.append('profileImage', profileImage);
            formData.append('name', name);
            formData.append('lastName', lastName);
            formData.append('phone', phone);
            formData.append('gender', gender);
            formData.append('secondaryContact', secondaryContact);
            formData.append('secondaryPhoneNumber', secondaryPhoneNumber);
            formData.append('secondaryEmail', secondaryEmail);
            formData.append('dob', dob);
            formData.append('address', address);
            formData.append('userId', userId);

            updateProfileService(formData).then(res => {
                let { status, data } = resHandle(res);
                   if(status){
                    callback()
                   }
                   setBtnloder(false);
                
                notification.open({
                    message: 'Profile',
                    description:
                      'User profile updated successfully',
                  });
            })

        }

    }



    const  disabledDate = (current) => {
        return current && (current > moment().startOf('day') || current < moment(`${new Date().getFullYear() - 100}-10-23`).startOf('day'));
    }



    return(
        <div className="mt-3">
            <h4 className="text-center">User Information</h4>

            <form onSubmit={handelSubmit} encType="multipart/form-data" className='spaceform'>       
            <div className="profile_upload">
                {profileImageUrl ? <img src={profileImageUrl} /> : ''}
                <input onChange={handleFileChange} value="" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                <i className="icon-camera-plus" />
            </div>

            <div className="row">
                <div className="col-lg-1" />
                <div className="col-lg-10">
                
                    <div className="row">
                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    name="name"
                                    onChange={e => (setName(e.target.value), setNameErr(''))}
                                    value={name}
                                    className={`form-control ${ nameErr ? 'is-invalid' : '' }`}
                                    placeholder="First Name" />

                                    {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={e => (setLastName(e.target.value), setLastNameErr(''))}
                                    className={`form-control ${ lastNameErr ? 'is-invalid' : '' }`}
                                    placeholder="Last Name" />

                                    {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="col-md-12 d-flex cm_gendr pro_wrapper">
                                <label>Gender</label>
                                <ul className="d-flex">
                                    {
                                        genders2.map(item => (
                                            <li className="mr-2 check_box_wrapper">
                                                <input
                                                    type="checkbox"
                                                    name={item.label}
                                                    checked={gender == item.value ? 'checked': ''}
                                                    value={item.value}
                                                    onChange={e => (setGender(item.value), setGenderErr(''))}
                                                />
                                                <span className="check_box"><span /> {item.label}</span> 
                                            </li>
                                        ))
                                    }

                                </ul>
                                
                            </div>     
                            <div className='col-md-12'>
                            {genderErr ? <p><span className='cm_err'>{genderErr}</span></p> : ''}   
                            </div>                        


                            <div className="col-md-6 form-group">
                                <input
                                    type="email"
                                    value={email}
                                    className="form-control"
                                    placeholder="Email"
                                    readOnly />
                            </div>


                            <div className="col-md-6 form-group">
                            <div className="cm_phone_field cm_phone_field_2">
                                    <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                    <PhoneInput
                                        country="US"
                                        placeholder="Phone number"
                                        className={`form-control ${ phoneErr ? 'is-invalid' : '' }`}
                                        onChange={e => (setPhone(e), setPhoneErr(''))}
                                        maxLength={16}
                                        value={phone}
                                        name="phone" />

                                        {phoneErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{phoneErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>
                            </div>


                            <div className="col-md-6 form-group">
                                <DatePicker
                                    disabledDate={disabledDate}
                                    value={dob ? moment.utc(parseInt(dob)): ''}
                                    className={`form-control ${ dobErr ? 'is-invalid' : '' }`}
                                    placeholder="Date of Birth"
                                    format={dateFormatNew}
                                    onChange={e => (setDob(new Date(removeTime(e._d)).getTime()), setDobErr(''))} />

                                    {dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    name="address"
                                    onChange={e => (setAddress(e.target.value), setAddressErr(''))}
                                    value={address.trim() ? address : '' }
                                    className={`form-control ${ addressErr ? 'is-invalid' : '' }`}
                                    placeholder="Address" />

                                    {addressErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{addressErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            


                        <div className="col-md-12 pro_wrapper"> 

                        <label>Secondary Emergency Contact</label>            
                            <div className="row">
                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    onChange={e => setCecondaryContact(e.target.value)}
                                    value={secondaryContact}
                                    className="form-control"
                                    name="secondaryContact"
                                    placeholder="Enter Secondary Contact FULL Name" />
                            </div>


                            <div className="col-md-6 form-group">
                                <div className="cm_phone_field cm_phone_field_2">
                                    <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                    <PhoneInput
                                        country="US"
                                        onChange={e => setSecondaryPhoneNumber(e)}
                                        placeholder="Enter secondary phone number"
                                        className="form-control"
                                        maxLength={16}
                                        value={secondaryPhoneNumber}
                                        name="phone"/>
                                </div>
                            </div>

                            <div className="col-md-12 form-group">
                                <input
                                    type="email"
                                    onChange={e => setSecondaryEmail(e.target.value)}
                                    value={secondaryEmail}
                                    className="form-control"
                                    name="secondaryEmail"
                                    placeholder="Enter Secondary Email" />
                            </div>
                            </div>
                        </div>    


                            <div className="col-md-12 form-group  text-center">
                                <button  className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Save</button>
                            </div>         
                                
                        </div>
                    
                </div>
            </div>
            </form>   
        </div>
    )
}

export default ProfileInformation;