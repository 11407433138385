import * as actionType from './actionsTypes';


const initialState = {
    search: '',
    activeTab: '1'
}


/************************************************************************************/
// Support Reducer
/************************************************************************************/


const supportReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.SUPPORT_SEARCH:
            return {
                ...state,
                search: action.payload
            }


    
        case actionType.SUPPORT_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            }

    
        default: return state;
    }
}




/************************************************************************************/
// All Support Actions
/************************************************************************************/


export const supportSearchAction = payload => dispatch => dispatch({ type: actionType.SUPPORT_SEARCH, payload });

export const activeTabAction = payload => dispatch => dispatch({ type: actionType.SUPPORT_ACTIVE_TAB, payload });


export default supportReducer;