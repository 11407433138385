import React, { useReducer, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { resetPasswordAction } from '../../store/auth';
import { verifyResetToken } from '../../services/authService';
import { MainLoader } from '../reuse/SVG';


const ResetPassword = props => {
    const token = window.location.pathname.split('token_')[1];
    const [tokenVarify, setTokenVarify] = useState(1);
    const [passwordSuccess, setPasswordSuccess] = useState(0);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            password: '',
            confirmPassword: '',
            passwordShow: false,
            passwordShow2: false
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            password: '',
            confirmPassword: ''
        }
    );


    useEffect(() => {
        let params = {token};
        verifyResetToken(params).then(res => {
            setTokenVarify(res.data.statusCode);
        });
    }, []);




    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }


    const handleValidate = () => {
        let validate = true;
        let { password, confirmPassword } = userInput;
       

        if(password == ''){
            setError({password: 'Password is required'});
            validate = false
        }

        if(password !== confirmPassword){
            setError({confirmPassword: 'Password not match'});
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let { password } = userInput;
            let params = {
                token,
                password
            }

           props.resetPasswordAction(params).then(status => setPasswordSuccess(status));
        }
    }


    const togglePasswordView = () => setUserInput({passwordShow: !userInput.passwordShow});

    const togglePasswordView2 = () => setUserInput({passwordShow2: !userInput.passwordShow2});


    return(
        <div className="d-flex align-items-center auth_wrapper fp_wrapper">
            {props.loading ? <MainLoader /> : ''}
            {passwordSuccess ? <Redirect to="/success" /> : '' }
            {tokenVarify == 0 ? <Redirect to="/" /> : '' }
            
            <div className="container">
                <div className="row no-gutters h-100 d-flex align-items-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 text-center">
                        <img src={require('../../assets/img/logo.svg')} alt="logo" />
                        <h2 className="mb-5 mt-5">Reset your password</h2>
                        
                        <form onSubmit={handleSubmit} className="text-left">

                                <div className="form-group">
                                    <label>New password</label>
                                    <div className="icon_input">
                                        <input
                                            type={`${userInput.passwordShow ? 'text' : 'password'}`}
                                            className={`form-control ${error.password ? 'is-invalid' : ''}`}
                                            name="password"
                                            value={userInput.password}
                                            onChange={handleChange} />
                                        <i className={`icon-eye ${userInput.passwordShow ? 'slash' : ''}`} onClick={togglePasswordView} />
                                    
                                        {error.password ? <OverlayTrigger
                                                            placement="left"
                                                            overlay={<Tooltip>{error.password}</Tooltip>}
                                                        >
                                                            <span className="error_tootltip" />
                                                        </OverlayTrigger> : ''}
                                    </div>
                                    <div className="invalid-feedback">{error.password}</div>
                                </div>

                                <div className="form-group">
                                 <label>Re-enter new password</label>
                                    <div className="icon_input">
                                        <input
                                            type={`${userInput.passwordShow2 ? 'text' : 'password'}`}
                                            className={`form-control ${error.confirmPassword ? 'is-invalid' : ''}`}
                                            name="confirmPassword"
                                            value={userInput.confirmPassword}
                                            onChange={handleChange} />
                                        <i className={`icon-eye ${userInput.passwordShow2 ? 'slash' : ''}`} onClick={togglePasswordView2} />
                                        {error.confirmPassword ? <OverlayTrigger
                                                            placement="left"
                                                            overlay={<Tooltip>{error.confirmPassword}</Tooltip>}
                                                        >
                                                            <span className="error_tootltip" />
                                                        </OverlayTrigger> : ''}
                                    </div>
                                </div>

                                <div className="text-right">
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                                {props.error && <p className="error_msg">{props.error}</p>}
                            </form>


                        
                    </div>
                </div>
            </div>

            <div className="copyright">© 2020 CampsEZ. All Rights Reserved.</div>
        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loading, error } = state.auth;
    return {
        loading,
        error
    };
}
  
const mapDispatchToProps = dispatch => ({
    resetPasswordAction: params => dispatch(resetPasswordAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);