import React, { useEffect, useState, Fragment } from 'react';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { notification, Empty } from 'antd';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';

import { setBreadcrumbAction } from '../../store/ui';
import { userListService, getUserDetailService } from '../../services/userService';
import { getProgramsService } from '../../services/campService';
import { createBookingByAdminService } from '../../services/bookingService';
import { resHandle, _calculateAge, getgendersdisplay, getHtml, gerFavpropgramdate } from '../../helper';
import PaymentInformationCompoant from './PaymentInformationCompoant';
import { getProductsService } from '../../services/otherService';
import Slider from 'react-slick';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
};





const getSizes = arr => {
    let filterArr = [];
    if(arr?.length){
        let isHasSize = arr.filter(i => (i.name != 'No Size'));
        if(isHasSize.length){
            let onlyName = isHasSize.map(item => item.name);
            let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
            filterArr = uniqArr.map(i => ({value: i, label: i}))
        }
    }
    return filterArr;
}


const getColors2 = (arr, val) => {
    let filterArr = [];

    if(val){
        if(arr?.length){
            let isHasColor = arr.filter(i => i.name == val);
            let isHasColor2 = isHasColor.filter(i => i.color != 'No Color');
            if(isHasColor2.length){
                let onlyName = isHasColor2.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }else{
        if(arr?.length){
            let isHasColor = arr.filter(i => i.color != 'No Color');
            if(isHasColor.length){
                let onlyName = isHasColor.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }
    return filterArr;
}




const getDatesPrices = (_dates, _selectedDates, _campers) => {
    let _price = 0;
    for (let i = 0; i < _selectedDates.length; i++) {
        _price += _dates.find(itm => itm._id == _selectedDates[i]).standardPrice
    } 
    return _price * _campers.length;
}



const CreateBookingPage = props => {
    const history = useHistory();
    const [steps, setSteps] = useState(1);
    const [userList, setUserList] = useState([]);
    const [userSearch, setUserSearch] = useState('');

    const [programList, setProgramList] = useState([]);
    const [programListLoader, setProgramListLoader] = useState(false);
    const [programSearch, setProgramSearch] = useState('');

    const [campersList, setCampersList] = useState([]);

    const [userId, setUserId] = useState('');
    const [userIdErr, setUserIdErr] = useState('');

    const [selectedPrograms, setSelectedPrograms] = useState('');
    const [selectedProgramsErr, setSelectedProgramsErr] = useState('');
    const [activeProgram, setActiveProgram] = useState({});
    const [participantForm, setParticipantForm] = useState(false);
    const [selectedCamper, setSelectedCamper] = useState('');
    const [finalLoader, setFinalLoader] = useState(false);
    const [resErr, setResErr] = useState('');
    const [selectedDateErr, setSelectedDateErr] = useState('');

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);


    useEffect(() => {
        let params = {
            icon: 'bookings',
            links: [
                {name: 'Bookings', path: '/bookings'},
                {name: 'Create Booking', path: ''},
            ]
    
        }
        props.setBreadcrumbAction(params);
    }, []);


    const handleRemoveProgram = id => {
        let _selectedPrograms = [...selectedPrograms];

        for (var i in _selectedPrograms) {
            if (_selectedPrograms[i]._id == id) {
                _selectedPrograms.splice(i, 1);
            }
        }

        if(_selectedPrograms.length == 0){
            setSteps(2);
            setActiveProgram({});
            setSelectedPrograms('');
        }else{
            setSelectedPrograms(_selectedPrograms);
            setActiveProgram(_selectedPrograms[0])
        }
    }



    const handleUserSelect = () => {
        if(userId?.value){
            setSteps(2);
            getCampersList(userId.value);
        }else{
            setUserIdErr('User is requierd')
        }
    }


    const handleProgramsSelect = () => {
        if(selectedPrograms?.length){
            setSteps(3);

            if(selectedPrograms.length){
                let mapedAllProgram =  selectedPrograms.map(i => ({
                    ...i,
                    campers: campersList.map(m => ({
                        ...m,
                        isSelected: false,
                        camperId: m._id,
                        camperName: m.firstName,
                        additionalExperience: i.additionalExperience.map(g => ({
                            ...g,
                            additionalExperienceId: g._id,
                            numberOfBooking: 0,
                        })),
                        answers: i.questions.map(j => ({
                            ...j,
                            questionId: j._id,
                            question: j.title,
                            answer: '',
                            answerErr: '',
                            isRequred: j.isRequred
                        })),
                        waiver: '',
                        waiverLiability: ''
                    }))
                }))

                setSelectedPrograms(mapedAllProgram);
                setActiveProgram(selectedPrograms[0])  
            }
        }else{
            setSelectedProgramsErr('program is requierd')
        }
    }

    const getList = () => {
        let params = {
            types: [1],
            search: userSearch,
            page: 0,
            limit: 20
        };
        userListService(params).then(res => {
           if(res.data.statusCode){
            setUserList(res.data.responseData.result);
           }
       })
    }




    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [userSearch])

    const getProgramsList = () => {
        let query = `?pageNo=0&search=${programSearch}&limit=20`;
        setProgramListLoader(true);
        getProgramsService(query).then(res => {
            setProgramListLoader(false);
           if(res.data.statusCode){
            setProgramList(res.data.responseData.result);
           }
       })
    }



    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getProgramsList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [programSearch])


    const getCampersList = params => {
        getUserDetailService(params).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setCampersList(data?.result?.campersData || [])
            }
        })
    }


    const handleAddProgram = e => {
        setSelectedPrograms(e);
        setSelectedProgramsErr('')
    }


    const handleCamperSelect = (_activeProgram, _selectedCamper, isCamperSelected) => {
        if(!isCamperSelected){
            setParticipantForm(true);
            setResErr('');
            setSelectedCamper(_selectedCamper);
        }else{
            handleAddCamer(_selectedCamper);
        }
        

    }

    const handleCloseModal = () => {
        setParticipantForm(false) 
    }


    const handleAddCamer = (_params) => {

       let __selectedCamper = _params._id ? _params : selectedCamper;
        let _selectedPrograms = [...selectedPrograms];
        let programIndex = _selectedPrograms.findIndex(x => x._id == activeProgram._id);
        let _campers = [..._selectedPrograms[programIndex].campers];

        for (var i in _campers) {
            if (_campers[i]._id == __selectedCamper._id) {
                _campers[i] = {
                    ...__selectedCamper,
                    selectedProduct,
                    isSelected: _params._id ? false : true,
                    answers: __selectedCamper.answers.map(j => ({
                        ...j,
                        answerErr: j.isRequred == 1 ? j.answer == '' ? 'Answer is requierd' : '' : ''
                    })),
                    additionalExperience: __selectedCamper.additionalExperience.map((j) => ({
                        ...j,
                        question: {
                            ...j.question,
                            answerErr:  (j.numberOfBooking && j?.question?.isRequred == 1) ? j?.question?.answer ? '' : 'Answer is requierd' : ''
                        }
                    })),

                };
               break;
            }
        }

        
        for (var i in _selectedPrograms) {
            if (_selectedPrograms[i]._id == activeProgram._id) {
                _selectedPrograms[i] = {
                ..._selectedPrograms[i],
                campers: _campers
               };
               break;
            }
        }

        let _selectedCamper = _campers.find(x => x._id == __selectedCamper._id);




        let checkQues = _selectedCamper.answers.filter(ans => {
            if(ans.isRequred){
                return ans.answerErr
            }
        });


        let innerErrs = 0;

        for (let z = 0; z < _selectedCamper.additionalExperience.length; z++) {
            if(_selectedCamper.additionalExperience[z].question.answerErr){
                innerErrs = innerErrs + 1;
            }
        }


        if(checkQues.length == 0 && innerErrs == 0){
            handleCloseModal();
        }else{
            for (var i in _campers) {
                if (_campers[i]._id == __selectedCamper._id) {
                    _campers[i] = {
                        ...__selectedCamper,
                        isSelected: false,
    
                    };
                   break;
                }
            }
        }

        setSelectedCamper(_selectedCamper);
        setSelectedPrograms(_selectedPrograms);

        let _products = [...products];

        if(_products.length){
            let _res = _products.map(i => ({...i, count: 1}));
            setProducts(_res);
        }



        setSelectedProduct([]);

    }
    

    const handleProceedPayment = () => {
        let isCamperSelected = false;
        let manipulatePrograms = selectedPrograms.map(item => {            

            return {
                ...item,
                
                campers: item.campers.map(camper => {

                    if(camper.isSelected){
                        isCamperSelected = true
                    }

                    return {
                        ...camper,
                        answers: camper.answers.map(_answer => {
                            let final_answer = '';
                            if(Array.isArray(_answer.answer)){
                                let temp_ans = `${_answer?.answer?.join(',')}, ${_answer.answerOther}`;
                                 final_answer = temp_ans.replace("undefined", "");
                            }else{
                                final_answer = _answer.answer
                            }
                            return {
                                ..._answer,
                                answer: final_answer
                            }
                        })
                    }
                })
            }
        })

        let _programs = manipulatePrograms.map(item => {

            let campers = item.campers.filter(i => i.isSelected);

            let _totalCost = 0;
            let _additionalExperienceCost = 0;
            let _convenienceCost = item.convenienceFee * campers.length;
            let _programCost = item.registrationFee * campers.length;
            let _taxesCost = item.tax * campers.length;
            let _registerNonRefundFee = item.registerNonRefundFee * campers.length;
            let additionalDiscount = 0;

            let arr = [];

            if(campers.length){
                for (let i = 0; i < campers.length; i++) {
                    if(campers[i].additionalExperience.length){
                        for (let j = 0; j < campers[i].additionalExperience.length; j++) {
                            if(campers[i].additionalExperience[j].numberOfBooking != 0){
                                arr.push(campers[i].additionalExperience[j].numberOfBooking * campers[i].additionalExperience[j].price)  
                            }  
                        }
                    }   
                }
            }

            if (campers !== undefined) {
                campers.map(camper => {
                    if (camper.answers !== undefined) {
                        camper.answers.map(answ => {
                            if (answ.options !== undefined) {
                                answ.options.map(optio => {
                                    if(optio.opt == answ.answer){
                                        if(optio.discountType && optio.discount){
                                            additionalDiscount = (optio.discountType == 1 ? optio.discount : (((item.registrationFee + item.registerNonRefundFee + item.convenienceFee + item.tax) / 100) * optio.discount))
                                        }
                                    }
                                })
                            }
                        })
                    }
                }) 
            }

            if(arr.length){
                for (let i = 0; i < arr.length; i++) {
                    _additionalExperienceCost += arr[i];
                }
            }

            _totalCost = (_additionalExperienceCost + _programCost + _convenienceCost + _taxesCost + _registerNonRefundFee);

            return {
                ...item,
                _totalCost,
                _additionalExperienceCost,
                _programCost,
                _convenienceCost,
                _taxesCost,
                campers,
                _registerNonRefundFee,
                _additionalDiscount: additionalDiscount * campers.length
            }
        })



        if(isCamperSelected){
            setSelectedPrograms(_programs);
            setSteps(4);
            
        }else{
            setResErr('Please select Participant');
        }
    }



    const handleSetAnswer = (e, ind, targetType) => {
        let _selectedCamper = {...selectedCamper};

        if(targetType === 'text'){
            _selectedCamper.answers[ind].answer = e.target.value;
            _selectedCamper.answers[ind].answerErr = '';
        }
        if(targetType === 'select'){
            _selectedCamper.answers[ind].answer = e.value;
            _selectedCamper.answers[ind].answerErr = '';
        }
        if(targetType === 'single'){
            _selectedCamper.answers[ind].answer = e.target.value;
            _selectedCamper.answers[ind].answerErr = '';
        }
        if(targetType === 'text2'){
            _selectedCamper.answers[ind].answerOther = e.target.value;
            _selectedCamper.answers[ind].answerErr = '';
        }

        if(targetType === 'singleChecked'){
            _selectedCamper.answers[ind].answer = e.target.value;
        }

        if(targetType === 'multiple'){
            let temp = [..._selectedCamper.answers[ind].answer || []];
            _selectedCamper.answers[ind].answerErr = '';
            if(e.target.checked){
                temp.push(e.target.value);
                _selectedCamper.answers[ind].answer = temp;
            }else{
                temp.splice(temp.indexOf(e.target.value), 1);
                _selectedCamper.answers[ind].answer = temp;
            }
        }

        setSelectedCamper(_selectedCamper);
    }


    const handleGroupCodeChange = e => {
        let _selectedCamper = {...selectedCamper}
        _selectedCamper.groupCode = e.target.value;
        setSelectedCamper(_selectedCamper);
    }


    const handleAdditionalExCountChange = (index, type, oldVal) => {
        let _selectedCamper = {...selectedCamper};
            if(type == 'add'){
                _selectedCamper.additionalExperience[index].numberOfBooking = oldVal + 1
            }else{
                _selectedCamper.additionalExperience[index].numberOfBooking = oldVal - 1
            }
        setSelectedCamper(_selectedCamper)
    }



    const onChangeSelectedDates = dateId => {
        setSelectedDateErr('');
        let _selectedCamper = {...selectedCamper};
        let _selectedDates = [..._selectedCamper.selectedDates || []];
            if(_selectedCamper?.selectedDates?.includes(dateId)){
                let res = _selectedDates.filter(i => i != dateId);
                _selectedCamper.selectedDates = res;
            }else{
                _selectedDates.push(dateId);
                _selectedCamper.selectedDates = _selectedDates
            }
        setSelectedCamper(_selectedCamper);
    } 


    const change1 = (e, i) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.additionalExperience[i].question.answer = e.target.value;
        _selectedCamper.additionalExperience[i].question.answerErr = '';
        setSelectedCamper(_selectedCamper);
    }

    const change2 = (e, i) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.additionalExperience[i].question.answer = e.value;
        _selectedCamper.additionalExperience[i].question.answerErr = '';
        setSelectedCamper(_selectedCamper);
    }

    const change3 = (e, i) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.additionalExperience[i].question.answer = e;
        _selectedCamper.additionalExperience[i].question.answerErr = '';
        setSelectedCamper(_selectedCamper);
    }



    const handleAddProduct = (item, i) => {
        let _products = [...products];
        let _selectedProduct = [...selectedProduct];

        let validate = true;

        if(getColors2(item.sizes).length){
            if(!item.selectColor){
                _products[i].selectColorErr = 'Please Select Color';
                validate = false;
            }
        }

        if(getSizes(item.sizes).length){
            if(!item.selectSize){
                _products[i].selectSizeErr = 'Please Select Size';
                validate = false;
            }
        }


        if(validate){
            let isHasItem = _selectedProduct.filter(sp => sp._id == item._id);

            if(isHasItem.length){
                _selectedProduct = _selectedProduct.filter(sp => sp._id != item._id);
            }else{
                _selectedProduct.push(item)
            }
    
            setSelectedProduct(_selectedProduct);
        }

        setProducts(_products)

    }



    const handleColorChange = (e, i) => {
        let _products = [...products];
        _products[i].selectColor = e.value;
        _products[i].selectColorErr = '';
        setProducts(_products);

    }


    const handleSizeChange = (e, i) => {
        let _products = [...products];
        _products[i].selectSize = e.value;
        _products[i].selectSizeErr = '';
        _products[i].selectColor = '';
        _products[i].selectColorErr = '';
        setProducts(_products);
    }


    const checkIfItemInclude = (item, all) => {
        let isHasItem = all.filter(sp => sp._id == item._id);

        if(isHasItem.length){
            return true;
        }else{
            return false
        }
    }




    const getProducts = () => {
        let query = `?&page=0&limit=100&type=2&programId=${activeProgram._id}`;
        getProductsService(query).then(res => {
            if(res?.data?.statusCode){
                if(res?.data?.responseData?.result?.length){
                    let temp = res.data.responseData.result.map(i => ({
                        ...i,
                        count: 1,
                        selectColorErr: '',
                        selectColor: '',
                        selectSize: '',
                        selectSizeErr: '',
                    }))

                    setProducts(temp);
                }
                //setCount(res.data.responseData.count);
            }
        })
    }



    useEffect(() => {
        if(activeProgram){
            getProducts()
        }

    }, [activeProgram])


    const handleItemCount = (itemIndex, type) => {

        let _products = [...products];
        let oldCount = _products[itemIndex].count;
        if(type == 'add'){
            _products[itemIndex].count = oldCount + 1;
            
        }else{
            if(oldCount != 1){
                _products[itemIndex].count = oldCount - 1;
            }
        }

        setProducts(_products);

    }


    const getMultipleDatePriceWithCommuter = () => {
        const _res = activeProgram.additionalDates.filter(el => activeProgram.selectedDates.find((id) => el._id == id));
        let _totalPrice = 0;
            if(_res.length){
                _res.map(i => _totalPrice += i.commuterPrice)
            }
        return _totalPrice;
    }

    const getMultipleDatePrice = () => {
        const _res = activeProgram.additionalDates.filter(el => activeProgram.selectedDates.find((id) => el._id == id));
        let _totalPrice = 0;
            if(_res.length){
                _res.map(i => _totalPrice += i.standardPrice)
            }
        return _totalPrice;
    }


    const onChangeSelectedDates2 = (dateId, indx) => {
        setSelectedDateErr('');
        let _selectedPrograms = [...selectedPrograms];
        let _selectedDates = [..._selectedPrograms[indx].selectedDates || []];
            if(_selectedDates?.includes(dateId)){
                let res = _selectedDates.filter(i => i != dateId);
                _selectedPrograms[indx].selectedDates = res;
            }else{
                _selectedDates.push(dateId);
                _selectedPrograms[indx].selectedDates = _selectedDates
            }

            setSelectedPrograms(_selectedPrograms);
    } 





    return(
        <div className="page_wrapper">
            <div className="page_card">

                <div className='row'>
                    <div className='col-md-3'>
                        <ul className='cm_steppes_list'>
                            <li className={`${steps > 1 ? 'done' : ''} ${steps == 1 ? 'active' : ''}`}>Select User</li>
                            <li className={`${steps > 2 ? 'done' : ''} ${steps == 2 ? 'active' : ''}`}>Select Programs</li>
                            <li className={`${steps > 3 ? 'done' : ''} ${steps == 3 ? 'active' : ''}`}>Select Participants</li>
                            <li className={`${steps > 4 ? 'done' : ''} ${steps == 4 ? 'active' : ''}`}>Payment Information</li>
                        </ul>
                    </div>
                    <div className='col-md-9 p-3 pr-5'>
                        {
                            (steps == 1) ? (
                                <div className='cm_stepper_box'>
                                        <div className='form-group'>
                                            <label>Select User</label>
                                            <Select
                                                onInputChange={e => setUserSearch(e)}
                                                placeholder="Select User"
                                                onChange={e => (setUserId(e), setUserIdErr(''))}
                                                classNamePrefix="cm_select"
                                                value={userId ? userId : ''}
                                                options={userList.map(i => ({...i, value: i._id, label: `${i.name} - ${i.email}`}))}
                                                className={`form-control ${userIdErr ? 'is-invalid' : ''}`}
                                            />

                                            {userIdErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{userIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className='d-flex justify-content-end mt-5'>
                                            <Link to="/bookings" className='btn btn-dark mr-3'>Cancel</Link>
                                            <span className='btn btn-primary' onClick={handleUserSelect}>Next</span>
                                        </div>
                                    </div>
                            ) : ''
                        }




                        {
                            (steps == 2) ? (
                                <div className='cm_stepper_box'>
                                        <div className='form-group'>
                                            <label>Select Programs</label>
                                            <Select
                                                isMulti
                                                onInputChange={e => setProgramSearch(e)}
                                                placeholder="Select programs"
                                                onChange={handleAddProgram}
                                                classNamePrefix="cm_select"
                                                value={selectedPrograms || ''}
                                                options={programList.map(i => ({...i, value: i._id, label: `${i.campName} - ${i.programName}`}))}
                                                className={`form-control ${selectedProgramsErr ? 'is-invalid' : ''}`}
                                            />

                                            {selectedProgramsErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedProgramsErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>


                                        {
                                            selectedPrograms.length ? selectedPrograms.map((_program, indx) => {
                                                if(_program.byDates == 1){
                                                    return (
                                                        <Fragment>
                                                            <p>Select Dates For <b>{_program.programName}</b></p>
                                                            <div className='multi_dates pt-0 pl-5'>
                                                                <ul>
                                                                    {_program.additionalDates.filter(mi => mi.slots > 0).map((additionalDate, i) => (
                                                                        <li key={i}>
                                                                            <div className="check_box_wrapper cm_dark_check">
                                                                                <input
                                                                                    onChange={e => onChangeSelectedDates2(additionalDate._id, indx)}
                                                                                    checked={_program?.selectedDates?.includes(additionalDate._id)}
                                                                                    type="checkbox"
                                                                                    name="selectedDate" />
                                                                                <span className="check_box"><span></span> {gerFavpropgramdate(additionalDate.startDate, additionalDate.endDate)} <b className='ml-3'>${additionalDate.standardPrice?.toFixed(2)}</b></span>
                                                                            </div>
                                                                            <small className='cm_a_slots'>available slots: <b>{additionalDate.slots}</b></small>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </Fragment>
                                                        
                                                    
                                                    )
                                                }
                                            }) : ''
                                        }

                                       


                                        <div className='d-flex justify-content-end mt-5'>
                                            <span onClick={() => setSteps(steps - 1)} className='btn btn-dark mr-3'>Back</span>
                                            {programListLoader ? <span className='miniBtnLoader'>Data Loading...</span> : <span className='btn btn-primary' onClick={handleProgramsSelect}>Next</span>}
                                            
                                        </div>
                                    </div>
                            ) : ''
                        }




                        {
                            (steps == 3) ? (
                                <div className='cm_stepper_box'>
                                        <div className='program_btn_group'>
                                            {selectedPrograms.map(i => <div className='program_btn_item' key={i._id}><span className='program_btn_close' onClick={() => handleRemoveProgram(i._id)}><i className="icon-cross" /></span> <span onClick={() => setActiveProgram(i)} className={`mr-2 btn btn-${activeProgram._id == i._id ? 'primary' : 'outline-primary'}`}>{`${i.campName} - ${i.programName}`}</span></div>)}
                                        </div>
                                        
                                        <div className='form-group'>
                                            <label>Select Participants for {activeProgram.campName} - {activeProgram.programName}</label>
                                            

                                            <ul className='campers_list' style={{maxWidth: '100%'}}>
                                                {
                                                    selectedPrograms.find(i => activeProgram._id == i._id).campers.map(item => (
                                                        <li key={item._id}>
                                                            <img src={item.profileImage || require('../../assets/img/user.png')} />
                                                            <div>
                                                                <p>{item.firstName} {item.lastName}</p>
                                                                <p><b>Group code: </b>{item.groupcode} <b>Age: </b> {_calculateAge(item.dob)} <b>Gender: </b> {getgendersdisplay(item.gender)}</p>
                                                            </div>
                                                            <div>
                                                                <div className="check_wrapper">
                                                                    <input
                                                                        onChange={() => handleCamperSelect(activeProgram, item, item.isSelected)}
                                                                        checked={item.isSelected}
                                                                        type="checkbox" />
                                                                    <span />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>

                                            {
                                                campersList.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                                            }
                                        </div>

                                        <div>{resErr ? <span className='cm_error text-center'>{resErr}</span> : ''}</div>


                                        <div className='d-flex justify-content-end mt-5'>
                                            <span onClick={() => setSteps(steps - 1)} className='btn btn-dark mr-3'>Back</span>
                                            <span className='btn btn-primary' onClick={handleProceedPayment}>Proceed to Payment</span>
                                        </div>
                                    </div>
                            ) : ''
                        }







                        {
                            (steps == 4) ? (
                                <div className='cm_stepper_box'>
                                        <div className='form-group'>
                                            <label>Payment Information</label>
                                        </div>

                                        <PaymentInformationCompoant
                                            userEmail={userId.email}
                                            user_data={userId}
                                            handleBack={() => setSteps(steps - 1)}
                                            programs={selectedPrograms} 
                                            userId={userId._id} />
                                    </div>
                            ) : ''
                        }




                    </div>
                </div>


                
             </div>


             <Modal
                size="xl"
                show={participantForm}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Participant</Modal.Title>
                    <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                   

                    <h3>{selectedCamper.firstName }</h3>
                    <p>Age: {_calculateAge(selectedCamper.dob)} &nbsp; &nbsp; &nbsp; Gender: {getgendersdisplay(selectedCamper.gender)}</p>

                    <div className="col-md-6 form-group">
                        <label>Group Code [Optional]</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={handleGroupCodeChange}
                            value={selectedCamper.groupCode} />
                    </div>


                    <div className='isCommuter_wrapper'>
                        <p><b>Are you staying overnight or day only?</b></p>
                        <p>Overnight campers receive breakfast, lunch, & dinner each day. All overnight campers stay on campus in the dorm rooms and will be roomed with at least one fellow camper their age. They are supervised by our
                        team of counselors who are background checked. Day campers receive lunch & dinner each day. They will NOT receive breakfast. Day campers are also supervised throughout the day by our team of counselors
                        who are background checked.</p>



                        {
                            activeProgram.byDates == 1 ? <Fragment>

                            <div className="check_box_wrapper cm_dark_check">
                                <b className='ml-3'>Overnight (${getMultipleDatePrice()})</b>
                                <input
                                    type="checkbox"
                                    checked={!selectedCamper.isCommuter}
                                    onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                    name="isCommuter" />
                                <span className="check_box"><span></span></span>
                            </div>


                        <div className="check_box_wrapper cm_dark_check">
                            <b className='ml-3'>Day only (${getMultipleDatePriceWithCommuter()})</b>
                            <input
                                type="checkbox"
                                checked={selectedCamper.isCommuter}
                                onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                name="isCommuter" />
                            <span className="check_box"><span></span></span>
                        </div>

                            </Fragment> : <Fragment>
                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ml-3'>Overnight (${(activeProgram?.campRegistrationFee - (activeProgram.registerNonRefundFee || 0))?.toFixed(2)})</b>
                                        <input
                                            type="checkbox"
                                            checked={!selectedCamper.isCommuter}
                                            onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>


                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ml-3'>Day only (${activeProgram?.commuterPrice?.toFixed(2)})</b>
                                        <input
                                            type="checkbox"
                                            checked={selectedCamper.isCommuter}
                                            onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>
                            </Fragment>
                        }
                   </div> 




                    {
                        selectedCamper?.additionalExperience?.length ? (
                            <Fragment>
                                <label>Additional Experiences</label>
                                <ul className="campers_add_exp">
                                    {
                                        selectedCamper?.additionalExperience.map((item, i) => (
                                            <li key={item._id}>
                                                <p className={`${item.title.includes('Session 1:') ? 'DarkOrange' : ''} ${item.title.includes('Session 2:') ? 'Blue' : ''} ${item.title.includes('Session 3:') ? 'Green' : ''}`}><div><b>Title</b></div> {getHtml(item.title)}</p>
                                                <p><b>Price</b>: ${item.price}</p>
                                                <p><div><b>Description </b></div> {getHtml(item.description)}</p>
                                                <div className="quantity mb-3">
                                                    <button disabled={item.numberOfBooking == 0} className="inc" onClick={() => handleAdditionalExCountChange(i, 'remove', item.numberOfBooking)} />
                                                    <b>{item.numberOfBooking}</b>
                                                    <button className="dec" onClick={() => handleAdditionalExCountChange(i, 'add', item.numberOfBooking)} />
                                                </div>

                                                {
                                                    item.numberOfBooking ? <Fragment>
                                                    {
                                                    item?.question !== undefined &&
                                                    <Fragment>
                                                        <div className="form-group mb-3 mt-3">
                                                            <p className='m-0'>Q. {getHtml(item?.question.title)}</p>
                                                            {item?.question.subTitle ? <p><small>{getHtml(item?.question.subTitle)}</small></p> : ''}
                                                        </div>

                                                        {
                                                            item?.question.questionType == 1 ? (
                                                                <Fragment>
                                                                    <textarea
                                                                        value={selectedCamper.additionalExperience[i].question.answer}
                                                                        onChange={e => change1(e, i)}
                                                                        className={`form-control ${selectedCamper.additionalExperience[i].question.answerErr ? 'is-invalid' : ''}`} />
                                                                        {selectedCamper.additionalExperience[i].question.answerErr ? <h6 className='cm_error'>{selectedCamper.additionalExperience[i].question.answerErr}</h6> : ''}
                                                                </Fragment>
                                                            ) : ''
                                                        }

                                                        {
                                                            item?.question.questionType == 2 ? (
                                                                <Fragment>
                                                                    <Select
                                                                        className={`form-control ${selectedCamper.additionalExperience[i].question.answerErr ? 'is-invalid' : ''}`}
                                                                        placeholder="Select"
                                                                        classNamePrefix="cm_select"
                                                                        onChange={e => change2(e, i)}
                                                                        options={item?.question.options.map(im => ({label: im.opt, value: im.opt}))} />

                                                                    {selectedCamper.additionalExperience[i].question.answerErr ? <h6 className='cm_error'>{selectedCamper.additionalExperience[i].question.answerErr}</h6> : ''}
                                                                </Fragment>
                                                            ) : ''
                                                        }


                                                        {
                                                            item?.question.questionType == 4 ? (
                                                                <Fragment>
                                                                    {item?.question.options.length ? item?.question.options.map(im => (
                                                                        <div className="check_box_wrapper cm_dark_check cm_dark_check_2">
                                                                            <input
                                                                                checked={im.opt == selectedCamper.additionalExperience[i].question.answer}
                                                                                onChange={() => change3(im.opt, i)}
                                                                                type="checkbox" />
                                                                            <span className="check_box"><span></span></span>
                                                                            <p>{im.opt}</p>
                                                                        </div>
                                                                    )) : ''}

                                                                    {
                                                                        item?.question.isOtherOption == 1 ? <div className="check_box_wrapper cm_dark_check cm_dark_check_2">
                                                                        <input
                                                                            type="checkbox" />
                                                                        <span className="check_box">
                                                                            <span>
                                                                        </span>
                                                                        </span>

                                                                        <p>Other</p>
                                                                    </div> : ''
                                                                    }

                                                                    <textarea
                                                                        className="form-control d-none" name="" />

                                                                    {selectedCamper.additionalExperience[i].question.answerErr ? <h6 className='cm_error'>{selectedCamper.additionalExperience[i].question.answerErr}</h6> : ''}
                                                                </Fragment>
                                                            ) : ''
                                                        }



                                                    </Fragment>
                                                }
                                                    </Fragment> : ''
                                                }


                                                
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Fragment>
                        ) : ''
                    }




                    {
                        selectedCamper?.answers?.length ? (
                            <Fragment>
                                <label className="ml-3"><b>Responses Required</b></label>
                            {
                                selectedCamper.answers.map((ques, i) => (
                                    <div className="col-sm-12 mb-3 form-group mb-5" key={i}>
                                        <p className='m-0'>Q.{i + 1} {getHtml(ques.title)}</p>
                                        {ques.subTitle ? <p><small>{getHtml(ques.subTitle)}</small></p> : ''}


                                        {ques.questionType == 1 ? (
                                            <div className='form-group'>
                                                <textarea
                                                    value={ques.answer}
                                                    onChange={e => handleSetAnswer(e, i, 'text')}
                                                    className={`form-control ${ques.answerErr ? 'is-invalid' : ''}`} />

                                                <div>{ques.answerErr ? <span className='cm_error'>{ques.answerErr}</span> : ''}</div>
                                            </div>
                                        ) : ''}

                                        {
                                        ques.questionType == 3 ? <Fragment>
                                            {ques.options.length ? ques.options.map((im, opIndx) => (
                                                <div key={opIndx} className="check_box_wrapper cm_dark_check">
                                                    <input
                                                        name={ques._id}
                                                        value={im.opt}
                                                        checked={ques.answer == im.opt}
                                                        onChange={e => (handleSetAnswer(e, i, 'single'))}
                                                        type="radio" />
                                                    <span className="check_box"><span></span></span>
                                                    <p>{im.opt}</p>
                                                </div>
                                            )) : ''}

                                            {
                                                ques.isOtherOption == 1 ? (
                                                    <div className="check_box_wrapper cm_dark_check">
                                                        <input
                                                            name={ques._id}
                                                            type="radio" />
                                                        <span className="check_box"> <span></span></span>
                                                        <p>Other</p>
                                                    </div> 
                                                ): ''
                                            }

                                            <div>{ques.answerErr ? <span className='cm_error text-left'>{ques.answerErr}</span> : ''}</div>

                                        </Fragment> : ''}




                                        {ques.questionType == 4 ? <Fragment>
                                            {ques.options.length ? ques.options.map((im, opIndx) => (
                                                <div key={opIndx} className="check_box_wrapper cm_dark_check">
                                                    <input
                                                        value={im.opt}
                                                        name={im.opt}
                                                        checked={ques.answer.includes(im.opt)}
                                                        onChange={e => (handleSetAnswer(e, i, 'multiple'))} 
                                                        type="checkbox" />
                                                    <span className="check_box"><span></span></span>
                                                    <p>{im.opt}</p>
                                                </div>
                                            )) : ''}

                                            {
                                                ques.isOtherOption == 1 ? (
                                                    <div className="check_box_wrapper cm_dark_check">
                                                        <input
                                                            name="other_option"
                                                            type="checkbox" />
                                                        <span className="check_box"><span></span></span>
                                                        <p>Other</p>
                                                    </div>
                                                ) : ''
                                            }
                                            
                                        </Fragment> : ''}


                                        {ques.questionType == 2 ? <Fragment>

                                            <Select
                                                value={ques.answer ? ({label: ques.answer, value: ques.answer}) : ''}
                                                className="form-control"
                                                onChange={e => (handleSetAnswer(e, i, 'select'))} 
                                                placeholder="Select"
                                                classNamePrefix="cm_select"
                                                options={ques.options.map(im => ({label: im.opt, value: im.opt}))} />

                                            <div>{ques.answerErr ? <span className='cm_error text-left'>{ques.answerErr}</span> : ''}</div>
                                            
                                        </Fragment> : ''}


                                        {ques.questionType == 5 ? <Fragment>

                                        {ques.options.length ? ques.options.map(im => (
                                            <div className="check_box_wrapper cm_dark_check">
                                                <input
                                                    name={ques._id}
                                                    value={im.opt}
                                                    checked={ques.answer.includes(im.opt)}
                                                    onChange={e => (handleSetAnswer(e, i, 'singleChecked'))} 
                                                    type="radio" />
                                                <span className="check_box">
                                                    <span>
                                                </span>
                                                </span>

                                                <p>{im.opt}</p>
                                            </div>
                                        )) : ''}

                                        </Fragment> : ''}


                                        {ques.questionType == 6 ? <Fragment>

                                        <Select
                                            value={ques.answer ? ({label: ques.answer, value: ques.answer}) : ''}
                                            className="form-control"
                                            placeholder="Select"
                                            classNamePrefix="cm_select"
                                            onChange={e => (handleSetAnswer(e, i, 'select'))} 
                                            options={ques.options.map(im => ({label: im.opt, value: im.opt}))} />


                                        </Fragment> : ''}



                                    </div>
                                ))
                            }
                            </Fragment>
                        ) : ''
                           
                    }





                    <label>Camp Related Products</label>

                    <div className='dir_product_slider'>
                        <Slider {...settings2} className='product_slider'>
                            {
                                products.map((item, i) => (
                                    <div className='product_card product_card_2'>
                                    {
                                        item?.image?.length ? (
                                            <Slider {...settings}>
                                                {item?.image.map(img => (
                                                    <img src={img} className="img-fluid pointer" />
                                                ))}
                                            </Slider>
                                        ) : ''
                                    }

                                    <div className='product_text'>
                                        <div className='pro_card_price'>
                                            <p>${item?.price?.toFixed(2)}</p>
                                        </div>
                                        <h3 style={{color: item.textColor || '#000'}}>{item.title}</h3>



                                    <div className=''>
                                        {getSizes(item.sizes)?.length ? <div  className="form-group w-100 mb-1">
                                                <label>Size</label>
                                                <Select
                                                    isDisabled={checkIfItemInclude(item, selectedProduct)}
                                                    className={`form-control ${item.selectSizeErr ? 'is-invalid' : ''}`}
                                                    placeholder="Select Size"
                                                    classNamePrefix="cm_select"
                                                    value={{label: item.selectSize, value: item.selectSize}}
                                                    onChange={e => handleSizeChange(e, i)}
                                                    options={getSizes(item.sizes)} />


                                                    {item.selectSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.selectSizeErr }</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div> : ''}


                                            {
                                                getColors2(item.sizes, item.selectSize)?.length ? <div className={`form-group w-100 mb-1`}>
                                                <label>Color</label>
                                                <Select
                                                    isDisabled={checkIfItemInclude(item, selectedProduct) ? true : (getSizes(item.sizes).length && !item.selectSize) ? true : false}
                                                    className={`form-control ${item.selectColorErr ? 'is-invalid' : ''}`}
                                                    placeholder="Select Color"
                                                    classNamePrefix="cm_select"
                                                    value={{label: item.selectColor, value: item.selectColor}}
                                                    onChange={e => handleColorChange(e, i)}
                                                    options={getColors2(item.sizes, item.selectSize)} />

                                                {item.selectColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{item.selectColorErr }</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            
                                            </div> : ''
                                            }
                                    </div>
                                        



                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div class="quantity mt-0">
                                                <button disabled={checkIfItemInclude(item, selectedProduct)} class="inc" onClick={() => handleItemCount(i, 'remove')}></button>
                                                <b>{item.count}</b>
                                                <button disabled={checkIfItemInclude(item, selectedProduct)} class="dec" onClick={() => handleItemCount(i, 'add')}></button>
                                            </div>
                                            <div>

                                            <span onClick={() => handleAddProduct(item, i)} className='btn btn-secondary'>{checkIfItemInclude(item, selectedProduct) ? 'Remove' : 'Add'}</span>

                                                
                                            
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                ))
                            }
                        </Slider> 
                    </div>





                    {selectedDateErr ? <h6 className='res_error'>{selectedDateErr}</h6> : ''}


                    <div className="text-center">
                                    <button
                                        onClick={handleCloseModal}
                                        className="btn btn-light rounded-0 mr-2"
                                        style={{ width: '130px' }}>Cancel</button>
                                    <button
                                        className="btn btn-primary rounded-0"
                                        onClick={handleAddCamer}
                                        style={{ width: '130px' }}>Done</button>
                                </div>


                    

                   
                </Modal.Body>
            </Modal>


  




        </div>
    )
}



/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { categories, loader } = state.category;
    return {
        categories,
        loader
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBookingPage);