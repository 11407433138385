import React, { useReducer, useEffect, useState, Fragment } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { TimePicker, notification, Tooltip } from 'antd';
import moment from 'moment';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

import { setSubCategories } from '../../store/ui';
import {
    toggleProgramModalAction,
    createProgramAction,
    campDetailAction,
    editProgramAction
} from '../../store/camps';
import {
    dateFormat,
    programTypes,
    genders,
    trueFalse,
    trueFalse2,
    facilies,
    mealsArr,
    gradeArr,
    removeTime,
    experiences,
    addBr,
    getBr,
    resHandle,
    schoolNameData,
    removeHtmlTags,
    calMultiDatesPrices
} from '../../helper';
import {
    CustomDateRange,
    CustomNumber,
    CustomSelect,
    CustomText,
    CustomTextarea,
    InputError,
    CustomDatePicker
} from '../util/FormControls';
import { set, trim } from 'lodash';
import settings from '../../config/settings';
import { getDistrictsService } from '../../services/campService';

const amountOpt = [
        {value: 1, label: 'Amount'},
        {value: 2, label: 'Percentage'},
]

const questionTypes = [
    {label: 'Short Answer', value: 1},
    {label: 'Drop-down', value: 2},
    {label: 'Multiple Choice', value: 3},
    {label: 'Checkboxes', value: 4},
    {label: 'Multiple Choice with Discounts', value: 5},
    {label: 'Drop-down with Discounts', value: 6},
];


const getQuestionType = i => {
    return questionTypes[i - 1]
}


const findMaxMin = (arr) => {
    let max = arr[0];
    let min = arr[0];
    for(let i = 0; i < arr.length; i++) {
       if(arr[i] > max) {
          max = arr[i];
       }
       else if (arr[i] < min) {
          min = arr[i];
       }
    };
    return {
       min, max
    };
 };

const AddProgramModal = props => {
    const url = window.location.href;
    const [cmQuestions, setCmQuestions] = useState([]);
    const [cmQuestionsErr, setCmQuestionsErr] = useState('');
    const [location, setLocation] = useState('');
    const [tempTime, setTempTime] = useState('');
    const [errorArr, setErrorArr] = useState('');
    const [districtList, setDistrictList] = useState([]);
    const [district, setDistrict] = useState('');
    const [schoolNames, setSchoolNames] = useState('');
    const [schoolNamesList, setSchoolNamesList] = useState([]);
    const [byDates, setByDates] = useState(2);
    const [additionalDates, setAdditionalDates] = useState([{
        startDate: '',
        endDate: '',
        standardPrice: 0,
        slots: 0,
        commuterPrice: 0,
        endDateErr: '',
        standardPriceErr: '',
        slotsErr: '' 
    }]);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
    {
        programName: '',
        programCode: '',
        noOfSlots: '',
        noOfSplitPayment: '',
        availableSlots: '',
        registerNonRefundFee: '',
        dateEarlyRegisterOpen: '',
        dateEarlyRegisterClosed: '',
        earlyBirdPrice: 0,
        registerOpenDate: '',
        registerCloseDate: '',
        registrationFee: 0,
        dateLateRegisterOpen: '',
        dateLateRegisterClosed: '',
        lateRegistrationFee: 0,
        commuterPrice: 0,
        category: '',
        subCategory: '',
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        gender: '',
        campId: '',
        programType: '',
        minAge: '',
        maxAge: '',
        grade: '',
        expLevel: '',
        startDate: '',
        endDate: '',
        checkInTime: '',
        checkOutTime: '',
        attendanceHour: '',
        meals: '',
        tshirt: '',
        equipment: '',
        facility: '',
        travel: '',
        travelInfo: '',
        scholarships: '',
        discount: '',
        impNotes: '',
        questions: [],
        personnel: '',
        programIntroduction: '',
        aboutProgram: '',
        programInstruction: '',
        disclaimer: '',
        lat: '',
        long: '',
        scholarshipsUrl: '',
        isfeatured: '',
        isprivate: '',
        discountType: '',
        siblingDiscount: '',
        siblingDiscountType: '',
        multiProgramDiscount: '',
        multiProgramDiscountType: '',
        isOnline: '',
        tax: '',
        earlyConvenienceFee: '',
        convenienceFee: '',
        lateConvenienceFee: '',
        is_lacrose: '',
        is_waivers: '',
        is_keydrom: '',
        isParentAthlete: '',
    }
  );

  const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
    {
        programName: '',
        programCode: '',
        availableSlots: '',
        registerOpenDate: '',
        registerCloseDate: '',
        registrationFee: '',
        category: '',
        subCategory: '',
        addressLine1: '',
        city: '',
        state: '',
        gender: '',
        campId: '',
        programType: '',
        minAge: '',
        maxAge: '',
        grade: '',
        expLevel: '',
        startDate: '',
        endDate: '',
        checkInTime: '',
        checkOutTime: '',
        tshirt: '',
        equipment: '',
        facility: '',
        travel: '',
        travelInfo: '',
        scholarships: '',
        impNotes: '',
        questions: '',
        personnel: '',
        programIntroduction: '',
        aboutProgram: '',
        programInstruction: '',
        disclaimer: '',
        lat: '',
        long: '',
        location: '',
        tax: ''
    }
  );


  useEffect(() => {
    if(district){
        setSchoolNamesList(district.schoolNames || []);
    }

  }, [district])
  

  const closeModal = () => {
    setLocation('');
    setDistrict('');
    setCmQuestions([]);
    setCmQuestionsErr('');
    setErrorArr('');
    setByDates(2);
    setAdditionalDates([{
        startDate: '',
        endDate: '',
        standardPrice: 0,
        commuterPrice: 0,
        slots: 0,
        endDateErr: '',
        standardPriceErr: '',
        slotsErr: '' 
    }])
    setUserInput(
      {
        programName: '',
        programCode: '',
        noOfSlots: '',
        noOfSplitPayment: '',
        availableSlots: '',
        registerNonRefundFee: '',
        dateEarlyRegisterOpen: '',
        dateEarlyRegisterClosed: '',
        earlyBirdPrice: 0,
        registerOpenDate: '',
        registerCloseDate: '',
        registrationFee: 0,
        commuterPrice: 0,
        dateLateRegisterOpen: '',
        dateLateRegisterClosed: '',
        lateRegistrationFee: 0,
        category: '',
        subCategory: '',
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        gender: '',
        campId: '',
        programType: '',
        minAge: '',
        maxAge: '',
        grade: '',
        expLevel: '',
        startDate: '',
        endDate: '',
        checkInTime: '',
        checkOutTime: '',
        attendanceHour: '',
        meals: '',
        tshirt: '',
        equipment: '',
        facility: '',
        travel: '',
        travelInfo: '',
        scholarships: '',
        discount: '',
        impNotes: '',
        questions: [],
        is_lacrose: '',
        is_waivers: '',
        is_keydrom: '',
        isParentAthlete: '',
        personnel: '',
        programIntroduction: '',
        aboutProgram: '',
        programInstruction: '',
        disclaimer: '',
        lat: '',
        long: '',
        scholarshipsUrl: '',
        discountType: '',
        siblingDiscount: '',
        siblingDiscountType: '',
        multiProgramDiscount: '',
        multiProgramDiscountType: '',
        isOnline: '',
        tax: '',
        earlyConvenienceFee: '',
        convenienceFee: '',
        lateConvenienceFee: ''
      }
    )

    setError(
      {
        programName: '',
        programCode: '',
        noOfSlots: '',
        availableSlots: '',
        registerOpenDate: '',
        registerCloseDate: '',
        registrationFee: '',
        category: '',
        subCategory: '',
        addressLine1: '',
        city: '',
        state: '',
        gender: '',
        campId: '',
        programType: '',
        minAge: '',
        maxAge: '',
        grade: '',
        expLevel: '',
        startDate: '',
        endDate: '',
        checkInTime: '',
        checkOutTime: '',
        tshirt: '',
        equipment: '',
        facility: '',
        travel: '',
        travelInfo: '',
        scholarships: '',
        impNotes: '',
        questions: '',
        personnel: '',
        programIntroduction: '',
        aboutProgram: '',
        programInstruction: '',
        disclaimer: '',
        lat: '',
        long: '',
        location: '',
        isfeatured: '',
        isprivate: '',
        tax: ''
      }
    )

    props.toggleProgramModalAction();
  }

  const getDistricts = () => {
    getDistrictsService().then(res => {
        let { status, data } = resHandle(res);
        if(status){
            let _data = data.result.map(i => ({...i, label: i.name, value: i._id}))
            setDistrictList(_data || []);
        }
    })
  }



  useEffect(() => {
    getDistricts();
  }, [])

  useEffect(() => {
    let programCode = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 7; i++){
        programCode += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setUserInput({programCode})
  }, [props.toggleProgramModal])


  const handleLocationChange = location => {
    setLocation(location);
    setError({location: ''});
    setErrorArr('');
    setError({
        lat: '',
        addressLine1: '',
        city: '',
        state: '',
    });
  };

 
  const handleLocationSelect = location => {
    setLocation(location);
    setErrorArr('');
    geocodeByAddress(location)
      .then(results => {
        let zipCode;
        let city;
        let state;
        let addressLine1;
        for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < results[i].address_components.length; j++) {
                for (let k = 0; k < results[i].address_components[j].types.length; k++) {
                    if (results[i].address_components[j].types[k] == "route") {
                        addressLine1 = results[i].address_components[j].long_name;
                        if(addressLine1){
                            setUserInput({addressLine1})
                        }else{
                            setUserInput({addressLine1: ''})
                        }
        
                    }
                    if (results[i].address_components[j].types[k] == "postal_code") {
                        zipCode = results[i].address_components[j].short_name;
                        if(zipCode){
                            setUserInput({zipCode})
                        }else{
                            setUserInput({zipCode: ''})
                        }
                        
                    }
                    if (results[i].address_components[j].types[k] == "locality") {
                        city = results[i].address_components[j].long_name;
                        if(city){
                            setUserInput({city})
                        }else{
                            setUserInput({city: ''})
                        }
                    
                    }
                    if (results[i].address_components[j].types[k] == "administrative_area_level_1") {
                        state = results[i].address_components[j].long_name;
                        if(state){
                            setUserInput({state})
                        }else{
                            setUserInput({state: ''})
                        }
                    }

                }
            }
        }
        return results;
      })
      .then(results => getLatLng(results[0]))
      .then(latLng => setUserInput({lat: latLng.lat, long: latLng.lng}))
      .catch(error => console.error('Error', error));
  };


    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
        setErrorArr('');
    }


    const handleEditerChange = (e, name) => {
        setUserInput({[name]: e});
        setError({[name]: ''});
        setErrorArr('');
    }


    const handleNumberChange = (e, name) => {
        if(name == 'discount') {
            if(userInput.discountType == 1){
                if (e < userInput.registrationFee || e < calMultiDatesPrices(additionalDates)){
                    setUserInput({discount: e})
                    setError({discount: ''}) 
                }else{
                    setUserInput({discount: ''})
                    setError({discount: 'Discount amount must be less then standard price.'})
                
                }
            }else{
                setUserInput({discount: e})
                setError({discount: ''}) 
            }



            // if (userInput.discountType) {
            //     if (e < userInput.registrationFee || e < calMultiDatesPrices(additionalDates)) {
            //         setUserInput({discount: e });
            //         setError({ discount: '' });
            //     } else {
            //         setUserInput({ discount: '' });
            //         setError({ discount: 'Discount amount must be less than or equal to the standard price.' });
        
            //     }
            // }
            
        }else{
            setUserInput({[name]: e});
            setError({[name]: ''});
            setErrorArr('');
        }

        
    }


    const handleDateRangeChange = (e, from, to) => {
        setUserInput({ [from]: removeTime(new Date(e[0]._d)), [to]: removeTime(new Date(e[1]._d))});
        setError({[from]: '', [to]: ''});
        setErrorArr('');
    }


    const handleMultiDateChange = (e, i) => {
        let temp = [...additionalDates];
        temp[i].startDate = removeTime(new Date(e[0]._d));
        temp[i].endDate = removeTime(new Date(e[1]._d));
        temp[i].endDateErr = '';
        setAdditionalDates(temp);
    }


    const handleMultiDatePriceChange = (e, i) => {
        let temp = [...additionalDates];
        temp[i].standardPrice = e;
        temp[i].standardPriceErr = '';
        setAdditionalDates(temp);
    }


    const handleMultiDateSloteChange = (e, i) => {
        let temp = [...additionalDates];
        temp[i].slots = e;
        temp[i].slotsErr = '';
        setAdditionalDates(temp);
    }

    const handleCommuterChange = (e, i) => {
        let temp = [...additionalDates];
        temp[i].commuterPrice = e;
        setAdditionalDates(temp);
    }


    const handleDateChange = (e, key) => {
        setUserInput({ [key]: removeTime(new Date(e._d)) });
        if(key == 'dateEarlyRegisterClosed'){
            setUserInput({
                registerOpenDate: removeTime(new Date(e._d)) + 86400000,
                registerCloseDate: ''
            });
            setError({ registerOpenDate: ''});
        }

        if(key == 'registerCloseDate'){
            setUserInput({ dateLateRegisterOpen: removeTime(new Date(e._d)) + 86400000 });
            setError({ dateLateRegisterOpen: ''});
        }

        setError({[key]: ''});
        setErrorArr('');
    }

    const handleTimeChange = (e, name) => {
        if(name == 'checkInTime'){
            setTempTime(new Date(e._d).getHours());

            if(userInput.checkOutTime){
                setUserInput({checkOutTime: ''})
                
            }

        }

        setUserInput({[name]: moment(e._d).format('h:mm a')});
        setError({[name]: ''});
        setErrorArr('');
    }


    const handleSelect = (e, name) => {
        if(name == 'discountType'){
            if(e.value){
                if(userInput.discount > userInput.registrationFee){
                    setUserInput({discount: ''})
                }
            }
        }
        setUserInput({[name]: e.value});
        setError({[name]: ''});
        setErrorArr('');
    }

    const handleMultiSelect = (e, name) => {
        let tempVal = e ? e.map(i => i.value) : '';
        setUserInput({[name]: tempVal});
        setError({[name]: ''});    
        setErrorArr('');
    }




    const handleCategory = e => {
        setUserInput({category: e.value})
        const subCatArr = props.category.filter(el => el._id == e.value)[0].subCategory;
        props.setSubCategories(subCatArr);   
        setError({category: ''});
        setErrorArr('');
    }

    const handleCheckboxChange = e => {
        setUserInput({isOnline: e.target.checked ? 1 : 0});
        if(userInput.isOnline){
            setUserInput({
                addressLine1: '',
                city: '',
                state: '',
                zipCode: '',
                lat: '',
                long: ''
            })
            setLocation('');
        }
    }


    const handleValidate = () => {
        let validate = true;
        let {
            programName,
            noOfSlots,
            availableSlots,
            registrationFee,
            category,
            subCategory,
            addressLine1,
            city,
            state,
            gender,
            programType,
            minAge,
            maxAge,
            grade,
            expLevel,
            endDate,
            checkInTime,
            checkOutTime,
            tshirt,
            equipment,
            facility,
            travel,
            travelInfo,
            scholarships,
            impNotes,
            personnel,
            programIntroduction,
            aboutProgram,
            programInstruction,
            disclaimer,
            isOnline,
            registerOpenDate,
            registerCloseDate,
            tax
        } = userInput;
        
        if(programName == ''){
            setError({programName: 'Program name is required'});
            validate = false
        }

        if(category == ''){
            setError({category: 'Category is required'});
            validate = false
        }

        if(subCategory == ''){
            setError({subCategory: 'Sub category is required'});
            validate = false
        }

        if(programType == ''){
            setError({programType: 'Program type is required'});
            validate = false
        }

        // if(trim(programIntroduction) == ''){
        //     setError({programIntroduction: 'Program introduction is required'});
        //     validate = false
        // }

        if(removeHtmlTags(aboutProgram) == ''){
            setError({aboutProgram: 'About program is required'});
            validate = false
        }

        if(removeHtmlTags(disclaimer) == ''){
            setError({disclaimer: 'Disclaimer is required'});
            validate = false
        }

        // if(trim(programInstruction) == ''){
        //     setError({programInstruction: 'Program instruction is required'});
        //     validate = false
        // }

        // if(trim(impNotes) == ''){
        //     setError({impNotes: 'Important information notes is required'});
        //     validate = false
        // }

        if(byDates != 1){
            if(trim(registerOpenDate) == ''){
                setError({registerOpenDate: 'Register open date is required'});
                validate = false
            }
    
            if(trim(registerCloseDate) == ''){
                setError({registerOpenDate: 'Register close date is required'});
                validate = false
            }

            if(registerCloseDate == ''){
                setError({registerCloseDate: 'Date standard registration is required'});
                validate = false
            }

            if(endDate == ''){
                setError({endDate: 'Start end date is required'});
                validate = false
            }

            if(noOfSlots == ''){
                setError({noOfSlots: 'Number of slots is required'});
                validate = false
            }

            if(props.program){
                if(availableSlots == ''){
                    setError({availableSlots: 'Number of available slots is required'});
                    validate = false
                }

                if(availableSlots > noOfSlots){
                    setError({availableSlots: 'available slots not be greater than total slots '});
                    validate = false
                }


            }

            
    
        }

        if(gender == ''){
            setError({gender: 'Gender is required'});
            validate = false
        }

        if(expLevel == ''){
            setError({expLevel: 'Experience level is required'});
            validate = false
        }

        if(minAge == ''){
            setError({minAge: 'Min age is required'});
            validate = false
        }

        if(maxAge == ''){
            setError({maxAge: 'Max age is required'});
            validate = false
        }

        if(grade == ''){
            setError({grade: 'Grade is required'});
            validate = false
        }

        


        if(!isOnline){
            if(location == ''){
                setError({location: 'Location is required'});
                validate = false
            }
            
            if(addressLine1 == ''){
                setError({addressLine1: 'Street address is required'});
                validate = false
            }
    
            if(city == ''){
                setError({city: 'City is required'});
                validate = false
            }
    
            if(state == ''){
                setError({state: 'State is required'});
                validate = false
            }
        }

        

        if(tshirt == '' || tshirt == null || tshirt == 'undefined'){
            setError({tshirt: 'T-shirt is required'});
            validate = false
        }

        if(equipment == ''){
            setError({equipment: 'Equipment is required'});
            validate = false
        }


        if(facility == ''){
            setError({facility: 'Facility is required'});
            validate = false
        }

        if(scholarships == '' || scholarships == null || scholarships == 'undefined'){
            setError({scholarships: 'Scholarships is required'});
            validate = false
        }

        if(travel == '' || travel == null || travel == 'undefined'){
            setError({travel: 'Travel Info is required'});
            validate = false
           
        }

        if(travel == 1){
            if(travelInfo == ''){
                setError({travelInfo: 'Travel Info is required'});
                validate = false
            }
        }

        
        if(checkInTime == ''){
            setError({checkInTime: 'Check in time is required'});
            validate = false
        }

        if(checkOutTime == ''){
            setError({checkOutTime: 'Check out time is required'});
            validate = false
        }


        return validate;
    }


    useEffect(() => {
        setErrorArr(Object.values(error).filter(i => i != ''))
    }, [error])



    const handleSubmit = e => {
        e.preventDefault();

        

        if(handleValidate()){
            let {
                programName,
                programCode,
                noOfSlots,
                noOfSplitPayment,
                availableSlots,
                registerNonRefundFee,
                earlyBirdPrice,
                registerOpenDate,
                registerCloseDate,
                registrationFee,
                dateLateRegisterOpen,
                dateLateRegisterClosed,
                lateRegistrationFee,
                category,
                subCategory,
                addressLine1,
                city,
                state,
                zipCode,
                gender,
                programType,
                minAge,
                maxAge,
                grade,
                expLevel,
                startDate,
                endDate,
                checkInTime,
                checkOutTime,
                attendanceHour,
                meals,
                tshirt,
                equipment,
                facility,
                travel,
                travelInfo,
                scholarships,
                discount,
                impNotes,
                is_lacrose,
                is_waivers,
                is_keydrom,
                isParentAthlete,
                personnel,
                programIntroduction,
                aboutProgram,
                programInstruction,
                disclaimer,
                lat,
                long,
                scholarshipsUrl,
                isfeatured,
                isprivate,
                dateEarlyRegisterOpen,
                dateEarlyRegisterClosed,
                discountType,
                siblingDiscount,
                siblingDiscountType,
                multiProgramDiscount,
                multiProgramDiscountType,
                isOnline,
                tax,
                earlyConvenienceFee,
                convenienceFee,
                lateConvenienceFee,
                commuterPrice
            } = userInput;



            let params = new FormData();
                params.append('programId', props.program._id);
                if(district.value){
                    params.append('districtId', district.value);
                    params.append('schoolNames', JSON.stringify([schoolNames.value]));
                }
                
                params.append('programName', programName);
                params.append('programCode', programCode);
                params.append('registerNonRefundFee', registerNonRefundFee ? registerNonRefundFee : 0);
                params.append('dateEarlyRegisterOpen', dateEarlyRegisterClosed ? ((dateEarlyRegisterOpen == 'null' || dateEarlyRegisterOpen == '' || dateEarlyRegisterOpen == 'NaN') ? '' : dateEarlyRegisterOpen) : '') ;
                params.append('dateEarlyRegisterClosed', dateEarlyRegisterOpen == '' ? '' : (dateEarlyRegisterClosed == 'null' || dateEarlyRegisterClosed == '' || dateEarlyRegisterClosed == 'NaN') ? '' : dateEarlyRegisterClosed);
                params.append('earlyBirdPrice', earlyBirdPrice ? earlyBirdPrice : 0);
                params.append('registrationFee', registrationFee ? registrationFee : 0);
                params.append('commuterPrice', commuterPrice ? commuterPrice : 0);
                params.append('dateLateRegisterOpen', dateLateRegisterClosed ? ((dateLateRegisterOpen == 'null' || dateLateRegisterOpen == '' || dateLateRegisterOpen == 'NaN') ? '' : dateLateRegisterOpen) : '');
                params.append('dateLateRegisterClosed', (dateLateRegisterClosed == '' || dateLateRegisterClosed == '' || dateLateRegisterClosed == 'NaN') ? '' : dateLateRegisterClosed);
                params.append('lateRegistrationFee', lateRegistrationFee ? lateRegistrationFee : 0);
                params.append('category', category);
                params.append('subCategory', JSON.stringify(subCategory));
                params.append('addressLine1', isOnline ? '' : addressLine1);
                params.append('city', isOnline ? '' : city);
                params.append('state', isOnline ? '' : state);
                params.append('zipCode', isOnline ? '' : zipCode);
                params.append('gender', gender);
                params.append('campId', props.campDetail._id || url.slice(url.indexOf('-') + 1));
                params.append('programType', programType);
                params.append('minAge', minAge);
                params.append('maxAge', maxAge);
                params.append('grade', JSON.stringify(grade));
                params.append('expLevel', expLevel);
                params.append('checkInTime', checkInTime);
                params.append('checkOutTime', checkOutTime);
                params.append('attendanceHour', attendanceHour);
                params.append('meals', meals ? JSON.stringify(meals) : '[]');
                params.append('tshirt', tshirt);
                params.append('equipment', equipment);
                params.append('facility', parseInt(facility));
                params.append('travel', parseInt(travel));
                params.append('travelInfo', addBr(travelInfo));
                params.append('scholarships', scholarships);
                params.append('is_lacrose', is_lacrose);
                params.append('is_waivers', is_waivers);
                params.append('is_keydrom', is_keydrom);
                params.append('isParentAthlete', isParentAthlete);
                params.append('discount', discount ? parseInt(discount) : 0);
                params.append('questions', JSON.stringify(cmQuestions));
                if(personnel){
                    params.append('personnel', JSON.stringify(personnel));
                }else{
                    params.append('personnel', JSON.stringify([]));
                }

                params.append('impNotes', impNotes ? addBr(impNotes) : '');
                params.append('programIntroduction', programIntroduction ? addBr(programIntroduction) : '');
                params.append('programInstruction', programInstruction ? addBr(programInstruction) : '');

                params.append('aboutProgram', addBr(aboutProgram));
                params.append('disclaimer', addBr(disclaimer));
                params.append('lat', lat);
                params.append('long', long);
                params.append('scholarshipsUrl', scholarshipsUrl);
                params.append('isfeatured', isfeatured ? parseInt(isfeatured) : 0);
                params.append('isprivate', isprivate ? isprivate : 0);
                params.append('discountType', discountType);
                params.append('siblingDiscount', siblingDiscount ? siblingDiscount : 0);
                params.append('siblingDiscountType', siblingDiscountType ? siblingDiscountType : 1);
                params.append('multiProgramDiscount', multiProgramDiscount ? multiProgramDiscount : props.campDetail.multiProgramDiscount);
                params.append('multiProgramDiscountType', multiProgramDiscountType ? multiProgramDiscountType : props.campDetail.multiProgramDiscountType);
                params.append('isOnline', isOnline);
                params.append('tax', tax ? tax : 0);
                params.append('earlyConvenienceFee', earlyConvenienceFee);
                params.append('convenienceFee', convenienceFee ? convenienceFee : 0);
                params.append('lateConvenienceFee', lateConvenienceFee);
                params.append('additionalDates', JSON.stringify(additionalDates));
                params.append('byDates', byDates);

                if(byDates == 1){
                    let all_additionalDates = []
                    for (let i = 0; i < additionalDates.length; i++) {
                        all_additionalDates.push(additionalDates[i].startDate);
                        all_additionalDates.push(additionalDates[i].endDate);
                    }
                    let {min, max} = findMaxMin(all_additionalDates);

                    params.append('startDate', min);
                    params.append('endDate', max);
                    params.append('registerOpenDate', parseInt(new Date().getTime()));
                    params.append('registerCloseDate', max);
                }else{
                    params.append('startDate', startDate);
                    params.append('endDate', endDate);
                    params.append('noOfSlots', parseInt(noOfSlots));
                    params.append('noOfSplitPayment', parseInt(noOfSplitPayment));
                    params.append('availableSlots', parseInt(availableSlots));
                    params.append('registerOpenDate', registerOpenDate);
                    params.append('registerCloseDate', registerCloseDate);
                }

      
            if(props.program) {
                
                props.editProgramAction(params).then(status => {
                    if(status){
                        let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                        props.campDetailAction(query);
                        closeModal();
                        notification.open({
                            message: 'Program',
                            description: `Program updated successfully.`
                        });
                    }
                })
            }else{
                props.createProgramAction(params).then(status => {
                    if(status){
                        let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                        props.campDetailAction(query);
                        closeModal();
                        notification.open({
                            message: 'Program',
                            description: 'Program created successfully.'
                        });
                    }
                })
            }
        }
    }



    useEffect(() => {
        if(props.program){
          

        setUserInput({
            programName: props.program.programName,
            programCode: props.program.programCode,
            noOfSlots: props.program.noOfSlots,
            noOfSplitPayment: props.program.noOfSplitPayment,
            availableSlots: props.program.availableSlots,
            registerNonRefundFee:  props.program.registerNonRefundFee ? props.program.registerNonRefundFee : '',
            dateEarlyRegisterOpen: props.program.dateEarlyRegisterOpen ? props.program.dateEarlyRegisterOpen : '',
            dateEarlyRegisterClosed: props.program.dateEarlyRegisterClosed ? props.program.dateEarlyRegisterClosed : '',
            earlyBirdPrice: props.program.earlyBirdPrice ? props.program.earlyBirdPrice : '',
            registerOpenDate: props.program.registerOpenDate,
            registerCloseDate: props.program.registerCloseDate,
            registrationFee: props.program.registrationFee,
            commuterPrice: props.program.commuterPrice,
            dateLateRegisterOpen: props.program.dateLateRegisterOpen ? props.program.dateLateRegisterOpen : '',
            dateLateRegisterClosed: props.program.dateLateRegisterClosed ? props.program.dateLateRegisterClosed : '',
            lateRegistrationFee: props.program.lateRegistrationFee ? props.program.lateRegistrationFee : '',
            category: props.program.category,
            subCategory: props.program.subCategory,
            addressLine1: props.program.addressLine1,
            city: props.program.city,
            state: props.program.state,
            zipCode: props.program.zipCode,
            gender: props.program.gender,
            campId: props.program.campId,
            programType: props.program.programType,
            minAge: props.program.minAge,
            maxAge: props.program.maxAge,
            grade: props.program.grade,
            expLevel: props.program.expLevel,
            startDate: props.program.startDate,
            endDate: props.program.endDate,
            checkInTime: props.program.checkInTime,
            checkOutTime: props.program.checkOutTime,
            attendanceHour: props.program.attendanceHour,
            meals: props.program.meals,
            tshirt: props.program.tshirt,
            equipment: props.program.equipment,
            facility: props.program.facility,
            travel: props.program.travel,
            travelInfo: props?.program?.travelInfo ? getBr(props.program.travelInfo) : '',
            scholarships: props.program.scholarships,
            scholarshipsUrl: props.program.scholarshipsUrl,
            discount: props.program.discount,
            is_lacrose: props.program.is_lacrose == 1 ? props.program.is_lacrose : 2,
            is_waivers: props.program.is_waivers == 1 ? props.program.is_waivers : 2,
            is_keydrom: props.program.is_keydrom == 1 ? props.program.is_keydrom : 2,
            isParentAthlete: props.program.isParentAthlete == 1 ? props.program.isParentAthlete : 2,
            impNotes: getBr(props.program.impNotes),
            programIntroduction: getBr(props.program.programIntroduction),
            aboutProgram: getBr(props.program.aboutProgram),
            programInstruction: getBr(props.program.programInstruction),
            disclaimer: getBr(props.program.disclaimer),
            scholarships: props.program.scholarships,
            lat: props.program.geoLocation ? props.program.geoLocation[1] : '',
            long: props.program.geoLocation ? props.program.geoLocation[0] : '',
            isfeatured: props.program.isfeatured,
            isprivate: props.program.isprivate,
            siblingDiscount: props.program.siblingDiscount,
            siblingDiscountType: props.program.siblingDiscountType,
            multiProgramDiscount: props.program.multiProgramDiscount,
            multiProgramDiscountType: props.program.multiProgramDiscountType,
            discountType: props.program.discountType,
            isOnline: props.program.isOnline,
            tax: props.program.tax,
            earlyConvenienceFee: props.program.earlyConvenienceFee ? props.program.earlyConvenienceFee : '',
            convenienceFee: props.program.convenienceFee ? props.program.convenienceFee : 0,
            lateConvenienceFee: props.program.lateConvenienceFee ? props.program.lateConvenienceFee : '',
            personnel: props?.program?.personnel,
        });
        if(props?.program?.districtId){
            let tempDistrict = districtList.find(i => i._id == props?.program?.districtId);
            setDistrict({...tempDistrict, value: tempDistrict._id, label: tempDistrict.name});
         
            if(props?.program?.schoolNames?.length){
                setSchoolNames({label: props?.program?.schoolNames[0], value: props?.program?.schoolNames[0]})
            }
     
        }

        setByDates(props?.program?.byDates == 1 ? 1 : 2);
        setAdditionalDates(props?.program?.additionalDates || []);
        
        setCmQuestions(props?.program?.questions?.length ? props.program.questions : [])

        setLocation(`${props.program.addressLine1} ${props.program.city} ${props.program.state} ${props.program.addressLine1}`)
    }else{
        setUserInput({
            programName: '',
            programCode: '',
            noOfSlots: '',
            noOfSplitPayment: '',
            availableSlots: '',
            registerNonRefundFee: '',
            dateEarlyRegisterOpen: '',
            dateEarlyRegisterClosed: '',
            earlyBirdPrice: '',
            registerOpenDate: '',
            registerCloseDate: '',
            registrationFee: '',
            commuterPrice: '',
            dateLateRegisterOpen: '',
            dateLateRegisterClosed: '',
            lateRegistrationFee: '',
            category: '',
            subCategory: '',
            addressLine1: '',
            city: '',
            state: '',
            zipCode: '',
            gender: '',
            campId: '',
            programType: '',
            minAge: '',
            maxAge: '',
            grade: '',
            expLevel: '',
            startDate: '',
            endDate: '',
            checkInTime: '',
            checkOutTime: '',
            attendanceHour: '',
            meals: '',
            tshirt: '',
            equipment: '',
            facility: '',
            travel: '',
            travelInfo: '',
            scholarships: '',
            discount: '',
            impNotes: '',
            personnel: '',
            programIntroduction: '',
            aboutProgram: '',
            programInstruction: '',
            disclaimer: '',
            lat: '',
            long: '',
            scholarshipsUrl: '',
            isfeatured: '',
            isprivate: '',
            discountType: '',
            isOnline: '',
            tax: '',
            earlyConvenienceFee: '',
            convenienceFee: '',
            lateConvenienceFee: '',
            is_lacrose: '',
            is_waivers: '',
            is_keydrom: '',
            isParentAthlete: ''
        })
    }
    }, [props.program])





    const clearDates = () => {
        setUserInput({
            dateEarlyRegisterOpen: '',
            dateEarlyRegisterClosed: '',
            registerOpenDate: '',
            registerCloseDate: '',
            dateLateRegisterOpen: '',
            dateLateRegisterClosed: '',
            startDate: '',
            endDate: ''
        })
    }



    useEffect(() => {
        return () => {
          props.setSubCategories([]);
        }
    }, [])



    const handleAddDates = () => {
        let temp = [...additionalDates];
        temp.push({
            startDate: '',
            endDate: '',
            standardPrice: 0,
            slots: 0,
            endDateErr: '',
            standardPriceErr: '',
            slotsErr: '' 
        })

        setAdditionalDates(temp)
    }


    const handleAddQues = () => {
        let temp = [...cmQuestions];
        let isEmpty = temp[temp.length - 1]?.title == '';

        if(cmQuestions.length == 0){
            temp.push({
                questionType: '',
                title: '',
                subTitle: '',
                optionVal: '',
                options: [],
                isRequred: 2,
                isOtherOption: 2,
                childOptions: [],
                childOptionVal: '',
            });
        }else {
            if(!isEmpty){
                temp.push({title: ''});
                setCmQuestionsErr('');
            }else{
                setCmQuestionsErr('last field can not be empty');
            }
        }
        setCmQuestions(temp);
        
    }


    const handleQuestionTypeChange = (e, ind) => {
        let temp = [...cmQuestions];
        temp[ind] = {
            questionType: e.value,
            title: '',
            subTitle: '',
            optionVal: '',
            options: [],
            isRequred: 2,
            isOtherOption: 2,
            childOptionVal: '',
            childOptions: []
        }
        setCmQuestions(temp);
    }


    const handleQuestionChange = (e, index) => {
        let temp = [...cmQuestions];
        temp[index].title = e.target.value ? addBr(e.target.value) : '';
        setCmQuestions(temp); 
  
        
    }
    const handleQuestionSubTitleChange = (e, index) => {
        let temp = [...cmQuestions];
        temp[index].subTitle = e.target.value ? addBr(e.target.value) : '';
        setCmQuestions(temp);
    }

    const handleAddOption = ind => {
        let temp = [...cmQuestions];

        if(temp[ind].optionVal){
            temp[ind].options.push({opt: temp[ind].optionVal});
            setCmQuestions(temp);

            setTimeout(() => {
                let temp = [...cmQuestions];
                temp[ind].optionVal = '';
                setCmQuestions(temp);
            }, 2)

        }
    }

    const handleOptionValChange = (e, ind) => {
        let temp = [...cmQuestions];
        temp[ind].optionVal = e.target.value;
        setCmQuestions(temp); 
  
    }


    const handleDiscountTypeSelect = (e, ind, cind) => {
        let temp = [...cmQuestions];
        temp[ind].options[cind].discountType = e.value;
        setCmQuestions(temp); 
    }

    const handleDiscountChange = (e, ind, cind) => {
        let temp = [...cmQuestions];
        temp[ind].options[cind].discount = e;
        setCmQuestions(temp); 
    }


    const handleRemoveQues = index => {
        let temp = [...cmQuestions];
        temp.splice(index, 1)
        setCmQuestions(temp);     
    }

    const handleRemoveDate = index => {
        let temp = [...additionalDates];
        temp.splice(index, 1)
        setAdditionalDates(temp);     
    }

    const handleIsRequredChnage = i => {
        let temp = [...cmQuestions];
        temp[i].isRequred = temp[i].isRequred == 1 ? 2 : 1
        setCmQuestions(temp);    
    }

    const handleIsOtherOptionChnage = i => {
        let temp = [...cmQuestions];
        temp[i].isOtherOption = temp[i].isOtherOption == 1 ? 2 : 1
        setCmQuestions(temp);    
    }


    const handleRemoveOption = (i, j) => {
        let temp = [...cmQuestions];
        temp[i].options.splice(j, 1)
        setCmQuestions(temp);     
    }

    
  return (
    <Modal
      size="lg"
      show={props.toggleProgramModal}
      animation={false}
      dialogClassName="add_prog_modal"
      centered
    >
      <Modal.Header>
        <Modal.Title> {props.program ? 'Edit' : 'Add'} Program</Modal.Title>
        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
      </Modal.Header>

      <Modal.Body>
        <form className="input_space_form" onSubmit={handleSubmit}>
            <div className="row">

                <div className="col-12">
                    <h3 className="cm_form_title">Description</h3>
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Camp Program*</label>
                    <CustomText
                        name="programName"
                        value={userInput.programName}
                        change={handleChange}
                        pHolder="Camp program name"
                        error={error.programName} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Program Code (Auto generated)</label>
                    <input
                        type="text"
                        value={userInput.programCode}
                        readOnly
                        className="form-control" />
                </div>
                

                 <div className="col-md-6 form-group">
                    <label>Program Category*</label>
                    <CustomSelect
                        pHolder="Select a category"
                        change={handleCategory}
                        value={props.category.map(el => el._id == userInput.category && ({value: el._id, label: el.name}))}
                        error={error.category}
                        options={props.category.map(item => ({value: item._id, label: item.name}))} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Program Sub Category*</label>
                    <CustomSelect
                        isMulti
                        error={error.subCategory}
                        pHolder="Select sub category"
                        change={e => handleMultiSelect(e, 'subCategory')}
                        value={userInput.subCategory && props.subcategory.filter(el => userInput.subCategory.find(e => el._id == e)).map(i => ({value: i._id, label: i.name}))}
                        options={props.subcategories.map(item => ({value: item._id, label: item.name}))} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Program Type*</label>
                    <CustomSelect
                        error={error.programType}
                        pHolder="Select program type"
                        change={e => handleSelect(e, 'programType')}
                        value={programTypes.map(el => el.value == userInput.programType && el)}
                        options={programTypes} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Personnel</label>
                    <CustomSelect
                        isMulti
                        pHolder="Select camp personnel"
                        change={e => handleMultiSelect(e, 'personnel')}
                        value={userInput.personnel && props.personnalByCode.filter(el => userInput.personnel.find(e => el._id == e)).map(i => ({value: i._id, label: i.name}))}
                        options={props.personnalByCode.map(item => ({value: item._id, label: item.name}))} />
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>Program Introduction</label>
                    <ReactQuill
                        className={`${error.programIntroduction ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.programIntroduction}
                        onChange={e => handleEditerChange(e, 'programIntroduction')} />
                    {error.programIntroduction ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.programIntroduction}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}

                    {/* <CustomTextarea
                        name="programIntroduction"
                        pHolder="Program Introduction"
                        value={userInput.programIntroduction}
                        change={handleChange}
                        error={error.programIntroduction} /> */}
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>About Program*</label>
                    <ReactQuill
                        className={`${error.aboutProgram ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.aboutProgram}
                        onChange={e => handleEditerChange(e, 'aboutProgram')} />
                    {error.aboutProgram ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.aboutProgram}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                    {/* <CustomTextarea
                        name="aboutProgram"
                        pHolder="About Program"
                        value={userInput.aboutProgram}
                        change={handleChange}
                        error={error.aboutProgram} /> */}
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>Disclaimer Statement*</label>
                    <ReactQuill
                        className={`${error.disclaimer ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.disclaimer}
                        onChange={e => handleEditerChange(e, 'disclaimer')} />
                    {error.disclaimer ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.disclaimer}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                    {/* <CustomTextarea
                        name="disclaimer"
                        pHolder="Disclaimer Statement"
                        value={userInput.disclaimer}
                        change={handleChange}
                        error={error.disclaimer} /> */}
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>Program Instructions</label>
                    <ReactQuill
                        className={`${error.programInstruction ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.programInstruction}
                        onChange={e => handleEditerChange(e, 'programInstruction')} />
                    {error.programInstruction ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.programInstruction}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                    {/* <CustomTextarea
                        name="programInstruction"
                        pHolder="Program Instructions"
                        value={userInput.programInstruction}
                        change={handleChange}
                        error={error.programInstruction} /> */}
                </div>
                

                <div className="col-md-12 form-group my_editer">
                    <label>Important Information Notes</label>
                    <ReactQuill
                        className={`${error.impNotes ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.impNotes}
                        onChange={e => handleEditerChange(e, 'impNotes')} />
                    {error.impNotes ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.impNotes}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                    {/* <CustomTextarea
                        name="impNotes"
                        pHolder="Important Information Notes"
                        value={userInput.impNotes}
                        change={handleChange}
                        error={error.impNotes} /> */}
                </div>


                <div className="col-12"><h3 className="cm_form_title">Applicable Groups</h3></div>
                

                <div className="col-md-6 form-group">
                    <label>Gender*</label>
                    <CustomSelect
                        pHolder="Select a gender"
                        change={e => handleSelect(e, 'gender')}
                        error={error.gender}
                        value={(userInput.gender == '') ? '' : genders.map(el => el.value == userInput.gender && el)}
                        options={genders} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Experience Level*</label>
                    <CustomSelect
                        pHolder="Experience Level"
                        change={e => handleSelect(e, 'expLevel')}
                        error={error.expLevel}
                        value={(userInput.expLevel == '') ? '' : experiences.map(el => el.value == userInput.expLevel && el)}
                        options={experiences} />
                </div>
                

                <div className="col-md-3 form-group">
                    <label>Age Group (Min)*</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'minAge')}
                        value={userInput.minAge}
                        pHolder="Min age"
                        error={error.minAge}
                        min={0}
                        max={99} />
                </div>

                


                <div className="col-md-3 form-group">
                    <label>Age Group (Max)*</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'maxAge')}
                        value={userInput.maxAge}
                        pHolder="Max age"
                        error={error.maxAge}
                        max={100}
                        disabled={userInput.minAge == ''}
                        min={userInput.minAge + 1} />
                </div>
                

                 <div className="col-md-6 form-group">
                    <label>Grade*</label>
                    <CustomSelect
                        isMulti
                        pHolder="Select Grade"
                        change={e => handleMultiSelect(e, 'grade')}
                        error={error.grade}
                        value={userInput.grade && userInput.grade.map(item => ({value: item, label: item}))}
                        options={gradeArr.map(item => ({value: item.value, label: item.value}))}
                        closeMenuOnSelect={false} />
                </div>
                

                <div className="col-12"><h3 className="cm_form_title">Price</h3></div>

                <div className="col-12 date_option">
                    <p>Program price is based on?</p>
                    <div className="check_wrapper">
                        <input onChange={() => setByDates(2)} checked={byDates == 2} name="byDates" type="radio" /><span />
                    </div>
                    <span>Registration Dates</span>
                    <div className="check_wrapper">
                        <input onChange={() => setByDates(1)} checked={byDates == 1} name="byDates" type="radio" /><span />
                    </div>
                    <span>Program Dates</span>
                </div>

                
                <div className="col-md-6 form-group">
                    <label>Non-refundable registration fee</label>
                    <CustomNumber
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        change={e => handleNumberChange(e, 'registerNonRefundFee')}
                        value={userInput.registerNonRefundFee}
                        min={0}
                        pHolder="Non-refundable registration fee"  />
                </div>

                {byDates != 1 ? <Fragment>
                    <div className="col-md-3 form-group">
                    <label>No. of slots*</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'noOfSlots')}
                        value={userInput.noOfSlots}
                        pHolder="No. of Available slots"
                        min={0}
                        error={error.noOfSlots} />
                </div>

                {
                    props.program ? <div className="col-md-3 form-group">
                                        <label>No. of Available slots*</label>
                                        <CustomNumber
                                            change={e => handleNumberChange(e, 'availableSlots')}
                                            value={userInput.availableSlots}
                                            pHolder="No. of Available slots"
                                            min={0}
                                            error={error.availableSlots} />
                                    </div> : ''
                }

                
                </Fragment> : ''}

                






                <div className="col-12 mb-4">
                    <span onClick={clearDates} className="btn btn-primary btn-sm">Clear Dates</span>
                </div>


                {byDates != 1 ? <Fragment>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                        <label>Date Early Bird Registration Start</label>
                                        <CustomDatePicker
                                            disabledDate={d => userInput.registerOpenDate ? d.isAfter(dateFormat(userInput.registerOpenDate + 86400000)) : d.isSameOrBefore(dateFormat(new Date().getTime()))}
                                            value={userInput.dateEarlyRegisterOpen ? moment(dateFormat(parseInt(userInput.dateEarlyRegisterOpen))): ''}
                                            change={e => handleDateChange(e, 'dateEarlyRegisterOpen')} />
                                    </div>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                        <label>Date Early Bird Registration End</label>
                                        <CustomDatePicker
                                            disabledDate={d => d.isSameOrBefore(userInput.dateEarlyRegisterOpen + 86400000)}
                                            disabled={userInput.dateEarlyRegisterOpen ? false : true}
                                            value={userInput.dateEarlyRegisterClosed ? moment(parseInt(userInput.dateEarlyRegisterClosed)): ''}
                                            change={e => handleDateChange(e, 'dateEarlyRegisterClosed')} />
                                    </div>
                                    

                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                        <label>Early Bird Price</label>
                                        <CustomNumber
                                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            change={e => handleNumberChange(e, 'earlyBirdPrice')}
                                            value={userInput.earlyBirdPrice}
                                            disabled={userInput.dateEarlyRegisterOpen ? false : true}
                                            min={0}
                                            pHolder="Early Bird Price" />
                                    </div>

                                    {
                                        props.program ? <div className="col-md-3 form-group">
                                                            <label>Early Convenience Fee</label>
                                                            <CustomNumber
                                                                min={0}
                                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                change={e => handleNumberChange(e, 'earlyConvenienceFee')}
                                                                value={userInput.earlyConvenienceFee}
                                                                pHolder="Early Convenience Fee" />
                                                        </div> : ''
                                    }
                                    

                                    <div className="col-12" />


                                <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Standard Registration Start*</label>
                                    <CustomDatePicker
                                        error={error.registerOpenDate}
                                        disabledDate={d => (userInput.dateEarlyRegisterOpen != '' && userInput.dateEarlyRegisterClosed) ? d.isSameOrBefore(dateFormat(userInput.dateEarlyRegisterClosed + 86400000)) : d.isSameOrBefore(dateFormat(new Date().getTime()))}
                                        value={userInput.registerOpenDate ? moment(dateFormat(parseInt(userInput.registerOpenDate))): ''}
                                        change={e => handleDateChange(e, 'registerOpenDate')} />
                                </div>

                                <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Standard Registration End*</label>
                                    <CustomDatePicker
                                        error={error.registerCloseDate}
                                        disabledDate={d => d.isSameOrBefore(dateFormat(userInput.registerOpenDate + 86400000))}
                                        disabled={userInput.registerOpenDate ? false : true}
                                        value={userInput.registerCloseDate ? moment(dateFormat(parseInt(userInput.registerCloseDate))): ''}
                                        change={e => handleDateChange(e, 'registerCloseDate')} />
                                </div>


                                <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Standard Price*</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleNumberChange(e, 'registrationFee')}
                                        value={userInput.registrationFee}
                                        pHolder="Standard Price"
                                        min={0}
                                        error={error.registrationFee}/>
                                </div>

                                {props.program ? <div className="col-md-3 form-group">
                                                    <label>Convenience Fee</label>
                                                    <CustomNumber
                                                        min={0}
                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        change={e => handleNumberChange(e, 'convenienceFee')}
                                                        value={userInput.convenienceFee}
                                                        pHolder="Convenience Fee" />
                                                </div> : ''
                                }

                                <div className="col-12" />

                                <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Late Registration Start</label>
                                    <CustomDatePicker
                                        disabledDate={d => d.isSameOrBefore(dateFormat(userInput.registerCloseDate + 86400000))}
                                        value={userInput.dateLateRegisterOpen ? moment(dateFormat(parseInt(userInput.dateLateRegisterOpen))): ''}
                                        change={e => handleDateChange(e, 'dateLateRegisterOpen')} />
                                </div>


                                <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Late Registration End</label>
                                    <CustomDatePicker
                                        disabledDate={d => d.isSameOrBefore(dateFormat(userInput.dateLateRegisterOpen + 86400000))}
                                        disabled={userInput.dateLateRegisterOpen ? false : true}
                                        value={userInput.dateLateRegisterClosed ? moment(dateFormat(parseInt(userInput.dateLateRegisterClosed))): ''}
                                        change={e => handleDateChange(e, 'dateLateRegisterClosed')} />
                                </div>


                                <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Late Registration Price</label>
                                    <CustomNumber
                                        disabled={userInput.dateLateRegisterOpen ? false : true}
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleNumberChange(e, 'lateRegistrationFee')}
                                        value={userInput.lateRegistrationFee}
                                        min={0}
                                        pHolder="Late Registration Price"
                                    />
                                </div>


                                {props.program ? <div className="col-md-3 form-group">
                                                    <label>Late Convenience Fee</label>
                                                    <CustomNumber
                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        change={e => handleNumberChange(e, 'lateConvenienceFee')}
                                                        value={userInput.lateConvenienceFee}
                                                        min={0}
                                                        pHolder="Late Convenience Fee" />
                                                </div> : ''
                                }


                                <div className={`col-md-4 form-group`}>
                                    <label>Commuter Price</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleNumberChange(e, 'commuterPrice')}
                                        value={userInput.commuterPrice}
                                        min={0}
                                        pHolder="Commuter Price"
                                    />
                                </div>


                </Fragment> : <Fragment>

                    {additionalDates.map((aDate, i) => (
                        <Fragment key={i}>
                            <div className="col-md-3 form-group">
                                <label>Start Date* <span className="end_date_label2">End Date*</span></label>
                                <CustomDateRange
                                    error={aDate.endDateErr}
                                    value={aDate.endDate ? [
                                        moment(dateFormat(parseInt(aDate.startDate))),
                                        moment(dateFormat(parseInt(aDate.endDate)))
                                    ] : ''}
                                    change={e => handleMultiDateChange(e, i)} />
                            </div>

                            <div className='col-md-8'>
                                <div className='row'>

                                <div className="col-md-4 form-group">
                                    <label>Standard Price*</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleMultiDatePriceChange(e, i)}
                                        value={aDate.standardPrice}
                                        pHolder="Standard Price"
                                        min={0}
                                        error={aDate.standardPriceErr}/>
                                </div>


                                <div className="col-md-4 form-group">
                                    <label>Available slots*</label>
                                    <CustomNumber
                                        change={e => handleMultiDateSloteChange(e, i)}
                                        value={aDate.slots}
                                        pHolder="No. of Available slots"
                                        min={0}
                                        error={aDate.slotsErr} />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label>Commuter Price</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleCommuterChange(e, i)}
                                        value={aDate.commuterPrice}
                                        pHolder="Commuter Price"
                                        min={0}
                                        error={''} />
                                </div>

                                </div>
                            </div>


                            

                            

                            <div className='col-md-1 remove_date'>
                                <i className="icon-close" onClick={() => handleRemoveDate(i)} />
                            </div>


                        </Fragment>
                    ))}


                    <div className='col-md-12 mb-4'>
                        <div className="add_question_btn ml-0" onClick={handleAddDates}>
                            <i className="icon-plus" /><small>Add Dates</small>
                        </div>
                    </div>
                    
                    
                
                    
                    </Fragment>}


                


            <div className="col-12"><h3 className="cm_form_title">Location</h3></div>
                

                <div className="col-12">
                    <p>
                        <div className="check_wrapper">
                            <input
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                checked={userInput.isOnline} />
                            <span />
                        </div> Online Program? </p>
                </div>


                {
                    userInput.isOnline ? '' : <Fragment>

                <div className="col-md-12 form-group">
                    <label>Location*</label>
                    <PlacesAutocomplete
                        value={location}
                        onChange={handleLocationChange}
                        onSelect={handleLocationSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                                className={`form-control ${error.location ? 'is-invalid' : ''}`}
                            {...getInputProps({
                                placeholder: 'Enter location'
                            })}
                            />
                            <div className="cm_locations">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                    <InputError error={error.location} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Street Address*</label>
                    <CustomText
                        name="addressLine1"
                        value={userInput.addressLine1}
                        change={handleChange}
                        pHolder="Street Address"
                        error={error.addressLine1} />
                </div>


                <div className="col-md-6 form-group">
                    <label>City*</label>
                    <CustomText
                        name="city"
                        value={userInput.city}
                        change={handleChange}
                        pHolder="City"
                        error={error.city} />
                </div>


                <div className="col-md-6 form-group">
                    <label>State*</label>
                    <CustomText
                        name="state"
                        value={userInput.state}
                        change={handleChange}
                        pHolder="State"
                        error={error.state} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Zip Code</label>
                    <CustomText
                        name="zipCode"
                        value={userInput.zipCode}
                        change={handleChange}
                        pHolder="Zip Code" />
                </div>

                    </Fragment>
                }



                <div className="col-md-6 form-group">
                    <label>Tax</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'tax')}
                        value={userInput.tax}
                        pHolder="State tax"
                        min={0}
                        formatter={value => `${value}%`}
                        max={99} />
                </div>

                <div className="col-md-6"></div>

                {settings.api.districtCamp == props.campDetail._id ? <div className="col-md-6 form-group">
                    <label>District</label>
                    <CustomSelect
                        pHolder="Select District"
                        change={e => (setDistrict(e), setSchoolNames(''))}
                        value={district}
                        options={districtList} />
                </div> : ''}

                {district ? schoolNamesList.length ? <div className="col-md-6 form-group">
                    <label>School</label>
                    <CustomSelect
                        pHolder="Select School"
                        change={e => setSchoolNames(e)}
                        value={schoolNames}
                        disabled={!district}
                        options={schoolNamesList.map(i => ({label: i, value: i}))} />
                </div> : '' : ''}
                


                <div className="col-12"><h3 className="cm_form_title">Amenities</h3></div>


                <div className="col-md-6 form-group">
                    <label>Meals</label>
                    <Tooltip placement="right" title="Leave blank if no meals are provided">
                        <i className="icon-information" />
                    </Tooltip>
                    <CustomSelect
                        isMulti
                        pHolder="Meals"
                        change={e => handleMultiSelect(e, 'meals')}
                        value={userInput.meals && userInput.meals.map(item => ({value: item, label: item}))}
                        options={mealsArr.map(item => ({value: item, label: item}))}
                        closeMenuOnSelect={false} />
                </div>


                <div className="col-md-6 form-group">
                            <label>T-shirt*</label>
                    <CustomSelect
                        pHolder="T-shirt"
                        change={e => handleSelect(e, 'tshirt')}
                        value={trueFalse.map(el => el.value == userInput.tshirt && el)}
                        options={trueFalse}
                        error={error.tshirt} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Equipment Needed*</label>
                    <Tooltip placement="right" title="Enter, ‘None’, if no equipment is needed to be brought by camper.">
                        <i className="icon-information" />
                    </Tooltip>
                    <CustomText
                        name="equipment"
                        value={userInput.equipment}
                        change={handleChange}
                        pHolder="Equipment"
                        error={error.equipment} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Facility*</label>
                    <CustomSelect
                        pHolder="Facility"
                        change={e => handleSelect(e, 'facility')}
                        value={facilies.map(el => el.value == userInput.facility && el)}
                        options={facilies}
                        error={error.facility} />
                </div>

                
                <div className="col-12" />


                <div className="col-md-6 form-group">
                    <label>Scholarships*</label>
                    <CustomSelect
                        pHolder="Scholarships"
                        change={e => handleSelect(e, 'scholarships')}
                        value={trueFalse.map(el => el.value == userInput.scholarships && el)}
                        options={trueFalse}
                        error={error.scholarships} />
                </div>


                {
                    (userInput.scholarships == '1') ? (
                        <div className="col-md-6 form-group">
                            <label>Scholarships Url</label>
                            <CustomText
                                name="scholarshipsUrl"
                                value={userInput.scholarshipsUrl}
                                change={handleChange}
                                pHolder="Scholarships Url" />
                        </div>
                    ) : ''
                }


                <div className="col-12" />
                

                <div className="col-md-6 form-group">
                    <label>Travel*</label>
                    <CustomSelect
                        pHolder="Travel"
                        change={e => handleSelect(e, 'travel')}
                        classNamePrefix="cm_select"
                        value={trueFalse.map(el => el.value == userInput.travel && el)}
                        options={trueFalse}
                        error={error.travel} />
                </div>


                {
                    (userInput.travel == '1') ? (
                        <div className="col-md-6 form-group">
                            <label>Travel Info*</label>
                            <CustomTextarea
                                name="travelInfo"
                                value={userInput.travelInfo}
                                change={handleChange}
                                error={error.travelInfo}
                                pHolder="Travel Info" />
                        </div>
                    ) : ''
                }


                <div className="col-12"><h3 className="cm_form_title">Timing</h3></div>

                {byDates != 1 ? <div className="col-md-6 form-group">
            <label>Start Date* <span className="end_date_label">End Date*</span></label>
                    <CustomDateRange
                        error={error.endDate}
                        disabled={byDates == 1 && userInput.registerCloseDate == ''}
                        disabledDate={d => userInput.dateEarlyRegisterOpen ? d.isSameOrBefore(dateFormat(userInput.dateEarlyRegisterOpen)) : d.isSameOrBefore(dateFormat(userInput.registerOpenDate))}
                        value={userInput.startDate ? [
                            moment(dateFormat(parseInt(userInput.startDate))),
                            moment(dateFormat(parseInt(userInput.endDate)))
                        ] : ''}
                        change={e => handleDateRangeChange(e, 'startDate', 'endDate')} />
                </div> : ''}


                


                <div className="col-md-6 form-group">
                    <div className="row">

                        <div className="col">
                        {/* {(userInput.programType == 1 || userInput.programType == 2) ? false : true} */}
                            <label>Check-In Time*</label>
                            <TimePicker
                                popupClassName={`${userInput.programType == 1 ? 'hide_pm' : ''} ${userInput.programType == 2 ? 'hide_am' : ''}`}
                                minuteStep={15}
                                format="h:mm a"
                                value={userInput.checkInTime ? moment(userInput.checkInTime, 'h:mm a') : ''}
                                onChange={e => handleTimeChange(e, 'checkInTime')}
                                className={`form-control ${error.checkInTime ? 'is-invalid' : ''}`} />
                                <InputError error={error.checkInTime} />
                        </div>


                        <div className="col">
                            <label>Check-Out Time*</label>
                            <TimePicker
                                popupClassName={`${userInput.programType == 1 ? 'hide_pm' : ''} ${userInput.programType == 2 ? 'hide_am' : ''}`}
                                minuteStep={15}
                                format="h:mm a"
                                disabled={userInput.checkInTime ? false : true}
                                value={userInput.checkOutTime ? moment(userInput.checkOutTime, 'h:mm a') : ''}
                                onChange={e => handleTimeChange(e, 'checkOutTime')}
                                className={`form-control ${error.checkOutTime ? 'is-invalid' : ''}`} />
                                <InputError error={error.checkOutTime} />
                        </div>


                    </div>
                </div>

                <div className="col-md-4 form-group">
                    <label>Alternate Last Day Check-Out Time</label>
                    <TimePicker
                        disabled={userInput.programType == 5 ? true : false}
                        minuteStep={15}
                        use12Hours format="h:mm a"
                        value={userInput.attendanceHour ? moment(userInput.attendanceHour, 'h:mm a') : ''}
                        onChange={e => handleTimeChange(e, 'attendanceHour')}
                        className="form-control" />
                </div>

                <div className="col-md-4 form-group">
                            <label>Number Of Split Payment</label>
                            <CustomNumber
                                change={e => handleNumberChange(e, 'noOfSplitPayment')}
                                value={userInput.noOfSplitPayment}
                                pHolder="Number Of Split Payment"
                                min={0}
                                error={''} />
                        </div>


                <div className="col-12"><h3 className="cm_form_title">Extra</h3></div>



                <div className="col-md-6 form-group">
                    <label>Discount type</label>
                    <CustomSelect
                        pHolder="Select a type"
                        change={e => handleSelect(e, 'discountType')}
                        value={amountOpt.map(item => item.value == userInput.discountType && item)}
                        options={amountOpt}/>
                </div>


                <div className="col-md-6 form-group">
                    <label>Discount</label>
                    <CustomNumber
                        disabled={(userInput.registrationFee || calMultiDatesPrices(additionalDates)) ? false : true}
                        change={e => handleNumberChange(e, 'discount')}
                        formatter={value => (userInput.discountType == 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                        value={userInput.discount}
                        pHolder="Discount"
                        min={0}
                        max={userInput.discountType == 1 ? Number.MAX_VALUE : 100} />
                </div>



                <div className="col-md-6 form-group">
                    <label>Sibling Discount Type</label>
                    <CustomSelect
                        pHolder="Select a type"
                        change={e => handleSelect(e, 'siblingDiscountType')}
                        value={amountOpt.map(item => item.value == userInput.siblingDiscountType && item)}
                        options={amountOpt}/>
                </div>


                <div className="col-md-6 form-group">
                    <label>Sibling Discount</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'siblingDiscount')}
                        formatter={value => (userInput.siblingDiscountType == 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                        value={userInput.siblingDiscount}
                        pHolder="Discount"
                        min={0}
                        max={userInput.siblingDiscountType == 1 ? Number.MAX_VALUE : 100} />
                </div>



                <div className="col-md-6 form-group">
                    <label>Multi Program Discount Type</label>
                    <CustomSelect
                        pHolder="Select a type"
                        change={e => handleSelect(e, 'multiProgramDiscountType')}
                        value={amountOpt.map(item => item.value == userInput.multiProgramDiscountType && item)}
                        options={amountOpt}/>
                </div>


                <div className="col-md-6 form-group">
                    <label>Multi Program Discount</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'multiProgramDiscount')}
                        formatter={value => (userInput.multiProgramDiscountType == 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                        value={userInput.multiProgramDiscount}
                        pHolder="Discount"
                        min={0}
                        max={userInput.multiProgramDiscountType == 1 ? Number.MAX_VALUE : 100} />
                </div>






                <div className="col-md-6 form-group">
                    <label>Is Featured</label>
                    <CustomSelect
                        pHolder="Is featured"
                        change={e => handleSelect(e, 'isfeatured')}
                        value={trueFalse.map(el => el.value == userInput.isfeatured && el)}
                        options={trueFalse}/>
                </div>



                <div className="col-md-6 form-group">
                    <label>Is Private</label>
                    <CustomSelect
                        pHolder="Is Private"
                        change={e => handleSelect(e, 'isprivate')}
                        value={trueFalse.map(el => el.value == userInput.isprivate && el)}
                        options={trueFalse2} />
                </div>

                
                <div className="col-md-6 form-group">
                    <label>Is Lacrosse Program</label>
                    <CustomSelect
                        pHolder="Is Lacrosse Program"
                        change={e => handleSelect(e, 'is_lacrose')}
                        value={trueFalse.map(el => el.value == userInput.is_lacrose && el)}
                        options={trueFalse} />
                </div>

                <div className="col-md-6 form-group">
                    <label>Is waivers Program</label>
                    <CustomSelect
                        pHolder="Is Waivers Program"
                        change={e => handleSelect(e, 'is_waivers')}
                        value={trueFalse.map(el => el.value == userInput.is_waivers && el)}
                        options={trueFalse} />
                </div>


                {
                    props.campDetail._id || url.slice(url.indexOf('-') + 1) == settings.api.sportInternationalCamp ? <div className="col-md-6 form-group">
                    <label>Is Key Dorm</label>
                    <CustomSelect
                        pHolder="Is Key Dorm"
                        change={e => handleSelect(e, 'is_keydrom')}
                        value={trueFalse.map(el => el.value == userInput.is_keydrom && el)}
                        options={trueFalse} />
                </div> : ''
                }

                {
                    props.campDetail._id || url.slice(url.indexOf('-') + 1) == settings.api.sportInternationalCamp ? <div className="col-md-6 form-group">
                    <label>Is Parent/Athlete</label>
                    <CustomSelect
                        pHolder="Is Parent/Athlete"
                        change={e => handleSelect(e, 'isParentAthlete')}
                        value={trueFalse.map(el => el.value == userInput.isParentAthlete && el)}
                        options={trueFalse} />
                </div> : ''
                }

                



                <div className="col-12">

                    {cmQuestions.map((item, i) => <div className="form-group cm_questions">
                        <i className="icon-close" onClick={() => handleRemoveQues(i)} />
                        <div className='d-flex justify-content-between mb-3'>
                            <label>Question {i + 1}</label>
                            <span className='question_types'>
                            {(item.questionType == 3 || item.questionType == 4) ? <Fragment>
                                <p>Is Other Option ?</p>
                                <div className="cm_swich_wrap">
                                    <input type="checkbox"  checked={item.isOtherOption == 1 ? true : false} onChange={e => handleIsOtherOptionChnage(i)} />
                                    <span />
                                </div>
                            </Fragment> : ''}
                            

                            <p>Is Mandatory ?</p>
                            <div className="cm_swich_wrap">
                                <input type="checkbox"  checked={item.isRequred == 1 ? true : false} onChange={e => handleIsRequredChnage(i)} />
                                <span />
                            </div>
                            <CustomSelect
                                pHolder="Select Question Type"
                                value={getQuestionType(cmQuestions[i].questionType)}
                                onChange={e => handleQuestionTypeChange(e, i)}
                                options={questionTypes} />
                            </span>
                        </div>
                        {
                            item.questionType ? <div className='cm_ques'>
                            <CustomTextarea
                                pHolder="Question*"
                                value={cmQuestions[i].title ? getBr(cmQuestions[i].title) : ''}
                                change={e => handleQuestionChange(e, i)} />
                            
                            <CustomTextarea
                                pHolder="Sub Title"
                                value={cmQuestions[i].subTitle ? getBr(cmQuestions[i].subTitle) : ''}
                                change={e => handleQuestionSubTitleChange(e, i)} />

                        </div> : ''
                        }


                        {(item.questionType == 5 || item.questionType == 6) ? <div className='cm_options'>

                            {item.options.length ? (
                                <div className='cm_child_options_wrapper'>
                                    <ul>
                                        {
                                            item.options.map((itm, j) => (
                                                <li>
                                                    <p>{itm.opt}</p>
                                                    <i className="icon-close" onClick={() => handleRemoveOption(i, j)} />

                                                    <div className="form-group mr-3 mb-0">
                                                        <CustomSelect
                                                            pHolder="Select a type"
                                                            change={e => handleDiscountTypeSelect(e, i, j)}
                                                            value={amountOpt.map(e => e.value == itm.discountType && e)}
                                                            options={amountOpt}/>
                                                    </div>

                                                    <div className="form-group mr-3 mb-0">
                                                        <CustomNumber
                                                            change={e => handleDiscountChange(e, i, j)}
                                                            formatter={value => (itm.discountType == 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                                                            value={itm.discount ? itm.discount : ''}
                                                            pHolder="Discount"
                                                            min={0}
                                                            max={itm.discountType == 1 ? Number.MAX_VALUE : 100} />
                                                    </div>
                                                    </li>
                                            ))
                                        }
                                    </ul>

                                    
                                </div>
                                ) : ''}

                            <div className='d-flex align-items-center'>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Add Option'
                                    value={item.optionVal}
                                    onChange={e => handleOptionValChange(e, i)} />
                                
                                <div>
                                    <span style={{minWidth: '150px', marginLeft: '20px'}} className='btn btn-outline-primary btn-sm' onClick={() => handleAddOption(i)}><i className="icon-plus" /><small>Add</small></span>  
                                </div>
                            </div>
                        </div> : ''}
                        

                        {(item.questionType == 2 || item.questionType == 3 || item.questionType == 4) ? <div className='cm_options'>
                                                    {item.options.length ? <ul>{item.options.map((itm, j) => <li>{itm.opt} <i className="icon-close" onClick={() => handleRemoveOption(i, j)} /></li>)}</ul> : ''}
                                                    <div className='d-flex align-items-center'>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Add Option'
                                                            value={item.optionVal}
                                                            onChange={e => handleOptionValChange(e, i)} />
                                                        
                                                        <div>
                                                            <span style={{minWidth: '150px', marginLeft: '20px'}} className='btn btn-outline-primary btn-sm' onClick={() => handleAddOption(i)}><i className="icon-plus" /><small>Add</small></span>  
                                                        </div>
                                                    </div>
                                                </div> : ''}

                        

                  
                </div>)}

                    <div className="add_question">
                        {cmQuestionsErr ? <span className="cm_error">{cmQuestionsErr}</span> : ''}
                        <div className="add_question_btn" onClick={handleAddQues}>
                            <i className="icon-plus" /><small>Add Question</small>
                        </div>
                    </div>

                </div>

            </div>

            <div className="cm_feild_err">{errorArr ? errorArr[0] : ''}</div>

            <div className="text-center mt-4">
                <button type="submit" className="btn btn-primary pl-5 pr-5 mb-3">Save</button> 
            </div>
         
        </form>
      </Modal.Body>
    </Modal>


  )
}



const mapStateToProps = state => {
  let { toggleProgramModal, campDetail, program, programPageNumner, personnalByCode } = state.camp;
  let { category, subcategory, personnel } = state.ui.preList;
  let { subcategories } = state.ui;
  
  return {
    toggleProgramModal,
    category,
    subcategory,
    campDetail,
    program,
    personnel,
    subcategories,
    programPageNumner,
    personnalByCode
  };
}

const mapDispatchToProps = dispatch => ({
    toggleProgramModalAction: () => dispatch(toggleProgramModalAction()),
    createProgramAction: params => dispatch(createProgramAction(params)),
    campDetailAction: params => dispatch(campDetailAction(params)),
    editProgramAction: params => dispatch(editProgramAction(params)),
    setSubCategories: params => dispatch(setSubCategories(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AddProgramModal);