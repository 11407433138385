import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setBreadcrumbAction } from '../../store/ui';
import { Link } from 'react-router-dom';


const pages = [
    {title : 'About us', link: '/pages/about'},
    {title : 'Career', link: '/pages/career'},
    {title : 'List Your Camp', link: '/pages/list-your-camp'},
    // {title : 'Advertising', link: '/pages/advertising'},
    {title : 'News & Events', link: '/pages/news'},
]

const Pages = props => {
    let params = {
        icon: 'Pages',
        links: [
            { name: 'Pages', path: '' }
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    return(
        <div className="page_wrapper">


            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>pages</h4>
                </div>

                <div className="page_card_body ">

                        <div className="table_responsive_wrap mt-4 text-center">
                            <div className="table-responsive">

                                <table className="table table-bordered table-striped no_t_texts">

                                    <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Title</span></th>
                                        <th><span className="t_text">Action</span></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        pages.map((item, i) => (
                                            <tr key={item.title}>
                                                <td><span className="t_text">{i + 1}</span></td>
                                                <td><span className="t_text">{item.title}</span></td>
                                                <td><span className="t_text"><Link to={item.link}><i className="icon-eye" /></Link></span></td>
                                            </tr>
                                        ))
                                    }


                                    </tbody>
                                </table>
                            </div>
                        </div>



                </div>

            </div>


        </div>
    )
}


const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);

const loop = [1,2,3,4,5,6,7,8]