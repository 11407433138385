import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Empty } from 'antd';
import { Modal } from 'react-bootstrap';

import { setBreadcrumbAction } from '../../store/ui';
import { unreadCountAction } from '../../store/user';
import {
    getInboxService,
    getAllUserListService,
    createInboxGroupService,
    getChatHistoryService
} from '../../services/inboxService';
import socket from '../../services/socket';
import { MsgLoader } from '../reuse/SVG';
import { getHtml } from '../../helper';


const Inbox = props => {
    const chatScreen = document.getElementById('chat__screen');
    const [inputHeight, setInputHeight]                   = useState(0);
    const [users, setUsers]                               = useState([]);
    const [userSearch, setUserSearch]                     = useState('');
    const [userPageNo, setUserPageNo]                     = useState(1);
    const [createGroupModal, setCreateGroupModal]         = useState(false);
    const [createGroupstep, setCreateGroupstep]           = useState(1);
    const [newChatModal, setNewChatModal]                 = useState(false);
    const [inboxUser, setInboxUser]                       = useState([]);
    const [isSelected, setIsSelected]                     = useState(false);
    const [totalUserCount, setTotalUserCount]             = useState(0);
    const [singleUserId, setSingleUserId]                 = useState('');
    const [groupName, setGroupName]                       = useState('');
    const [groupImage, setGroupImage]                     = useState('');
    const [groupImageView, setGroupImageView]             = useState('');
    const [userId, setUserId]                             = useState([]);
    const [messages, setMessages]                         = useState([]);
    const [textMessage, setTextMessage]                   = useState('');
    const [newMessage, setNewMessage]                     = useState('');
    const [currentGroupId, setCurrentGroupId]             = useState('');
    const [currentChatUser, setCurrentChatUser]           = useState('');
    const [filterUser, setFilterUser]                     = useState('');
    const [msgLoader, setMsgLoader]                       = useState(false);
    const [lastMsgId, setLastMsgId]                       = useState('');
    const [msgCount, setMsgCount]                         = useState('');


    let params = {icon: 'email', links: [{name: 'Inbox', path: ''}]}


    useEffect(() => {
        props.setBreadcrumbAction(params);
        document.getElementById('scroll_end').scrollIntoView({behavior: 'smooth'});
        socket.emit('join');
        setNewMessage('');
        socket.on('receiveMessage', data => {
            setNewMessage(data);
        });
    }, []);



    useEffect(() => {
        if(newMessage.groupId == currentGroupId){
            setMessages([...messages, newMessage]);
            document.getElementById('scroll_end').scrollIntoView({behavior: 'smooth'});
        }
    }, [newMessage])



    const getInbox = () => {
        let query = `?search=${filterUser}`
        getInboxService(query).then(res => {
            if(res.data.statusCode){
                setInboxUser(res.data.responseData.result)
            }
        })
    }

    useEffect(() => {
        getInbox()
    }, [filterUser])


    const getAllUserList = () => {
        let query = `?search=${userSearch}&pageNo=${userPageNo}&limit=10`;
        getAllUserListService(query).then(res => {
            if(res.data.statusCode){
                setTotalUserCount(res.data.responseData.count)
                if(userSearch){
                    setUsers(res.data.responseData.result)
                }else{
                    setUsers([...users, ...res.data.responseData.result])
                }
                
            }
        })
    }

    useEffect(() => {
        getAllUserList()
    }, [userPageNo])

    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getAllUserList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [userSearch])


    const handleMsgChange = e => {
        setTextMessage(e.target.value)
        setInputHeight((e.target.value.match(/\n/g)||[]).length)
    }

    const toggleMsgSidebar = () => document.body.classList.toggle('toogle_msg_sider');


    const handleUserSerach = e => {
        setUserSearch(e.target.value);
        setUserPageNo(1);
    }


    const handleLoadMore = () => {
        setUserPageNo(userPageNo + 1);
    }

    const closeModal = () => {
        setCreateGroupModal(false);
        setNewChatModal(false);
        setCreateGroupstep(1);
        setUserSearch('');
        setUserPageNo(1);
    }

    const sendMessage = () => {
        chatScreen.scrollTop = chatScreen.scrollHeight;
        if(textMessage.trim() != ''){
            setTextMessage('');
            setMessages([...messages, {
                message: textMessage,
                senderId: props.adminProfile._id,
                timeStamp: new Date().getTime()
            }]); 
            socket.emit('sendMessage', {
                'groupId': currentGroupId ? currentGroupId : null,
                'receiverId': singleUserId ? singleUserId : '',
                'senderId': props.adminProfile._id,
                'message': textMessage,
                'timeStamp': new Date().getTime()
            });

            
            document.getElementById('scroll_end').scrollIntoView({behavior: 'smooth'});
            
            getInbox();
        }else{

        }
    }

    const handleSingleUserId = user => {
        setCurrentChatUser(user);
        setSingleUserId(user._id);
        closeModal();
        setIsSelected(true);
        setMessages([]);
    }

    const createGroup = () => {
        let params = new FormData();
        params.append('groupName',groupName);
        params.append('groupImage',groupImage);
        params.append('userId',JSON.stringify([...userId, props.adminProfile._id]));
        params.append('type','2');

        createInboxGroupService(params).then(res => {
          if(res.data.statusCode){
            closeModal();
            getInbox();
          }
        })  
    }

    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setGroupImage(file);
        
        reader.addEventListener('load', () => {
            setGroupImageView(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }

    const addUserInGroup = e => {
        if(userId.includes(e.target.value)){
            let tempArr = userId.filter(item => item != e.target.value);
            setUserId(tempArr);
        }else{
            setUserId([...userId, e.target.value])
        }
    }

    const getMessages = user => {
        setMsgLoader(true);
        if(user.type == 1){
            setCurrentChatUser({
                name: user.userId[0].name,
                profileImage: user.userId[0].profileImage
            })
        }else{
            setCurrentChatUser({
                name: user.groupName,
                profileImage: user.groupImage
            })
        }
        
        setIsSelected(true);
        setCurrentGroupId(user.groupId);
        toggleMsgSidebar();
            let query = `/?groupId=${user.groupId}&receiverId=${''}&limit=15`;
            getChatHistoryService(query).then(res => {
                setMsgLoader(false);
                if(res.data.statusCode){
                    setLastMsgId(res.data.responseData.result.length ? res.data.responseData.result[res.data.responseData.result.length - 1].chatId : '');
                    setMessages(res.data.responseData.result.reverse());
                    chatScreen.scrollTop = chatScreen.scrollHeight;

                    props.unreadCountAction();
                    getInbox();
                }
        })
    }





    const handleScroll = e => {
        let chatScroll = document.getElementById('chat__screen');
          if(chatScroll.scrollTop < 1 && msgCount !== 0){
            setMsgLoader(true);
            let query = `/?lastMsgId=${lastMsgId}&groupId=${currentGroupId}&receiverId=${''}&limit=15`;
            getChatHistoryService(query).then(res => {
                setMsgLoader(false);
                if(res.data.statusCode){
                    setMsgCount(res.data.responseData.count);
                    setLastMsgId(res.data.responseData.result.length ? res.data.responseData.result[res.data.responseData.result.length - 1].chatId : '');
                    let oldMsg = res.data.responseData.result.reverse();
                    setMessages([...oldMsg, ...messages]);
                }
            })
          }  
      };




    return(
        <div className="page_wrapper">
            <div className="page_card pb-0">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Inbox</h4>
                    <div className="btn_group">
                        <button className="btn btn-outline-primary" onClick={() => setNewChatModal(true)}>New Message</button>
                        <button className="btn btn-primary ml-3" onClick={() => setCreateGroupModal(true)}>Organize Group</button>
                    </div>
                </div>

                <div className="page_card_body p-0">
                    <div className="d-flex chat_wrapper">
                        <div className="cht_users">
                            <div className="contact_search">
                                <i className="icon-search" />
                                <input type="text" value={filterUser} onChange={e => setFilterUser(e.target.value)} placeholder="Search" />
                            </div>

                            <ul>
                                {
                                    inboxUser.length ? inboxUser.map(item => (
                                        <li key={item.groupId} className="d-flex align-items-center" onClick={() => getMessages(item)}>
                                        
                                            {item.type == 1 ? item.userId?.length ? <Fragment>
                                                <img className="chat_user_img" src={item.userId[0].profileImage ? item.userId[0].profileImage : require('../../assets/img/user.png')} />
                                                <p>{item.userId[0].name} {item.count ? <span className="msg_count">{item.count}</span> : ''}</p>
                                            </Fragment> : '' : ''}


                                            {item.type == 2 ? <Fragment>
                                                <img className="chat_user_img" src={item.groupImage ? item.groupImage : require('../../assets/img/user.png')} />
                                            <p>{item.groupName}</p>
                                            </Fragment> : ''}

                                
                                        </li>
                                    )) : <li className="cm_empty"><Empty description="Data Not Found" /></li>
                                }
                            </ul>

                        </div>




                        <div className={`d-flex w-100 flex-column chat_box ${isSelected ? '' : 'd_none'}`}>
                            <div className="chat_mob_trigger" onClick={toggleMsgSidebar}><i className="icon-search" /> Search</div>
                            <div className="mb-auto d-flex align-items-center chat_user_head">
                                <img className="chat_user_img" src={currentChatUser.profileImage ? currentChatUser.profileImage : require("../../assets/img/user.png")} />
                                <p><b className="d-block">{currentChatUser.name}</b></p>
                            </div>


                            <div id="chat__screen" onScroll={handleScroll} className={`chat_screen ${msgLoader ? 'no_scroll' : ''}`}>
                                {msgLoader ? <div className="msg_loader"><MsgLoader /></div> : ''}
                                <div className="chat_msgs">



                                {
                                 
                                  messages.length ? messages.map(message => (
                                    <React.Fragment>                                  
                                    {
                                        message.senderId == props.adminProfile._id ? 
                                        <div key={message.timeStamp} className="chat_msg me_user">
                                            <div className="cm_name_m"><time>{moment(message.timeStamp).format('hh:mm a, (MMM Do YYYY)')}</time></div>
                                            {/* <p>{message.message}</p> */}
                                            <div className="msg_text">{message.message ? getHtml(message.message) : ''} </div>
                                        </div>
                                        :
                                        <div key={message.timeStamp}  className="chat_msg other_user">
                                            <img className="chat_user_img" src={message.profileImage ? message.profileImage : require("../../assets/img/user.png")} />
                                            <div className="cm_name_m">{message.name} <time>{moment(message.timeStamp).format('hh:mm a, (MMM Do YYYY)')}</time></div>
                                            {/* <p>{message.message}</p> */}
                                            <div className="msg_text">{message.message ? getHtml(message.message) : ''} </div>
                                        </div>

                                    }
                                    </React.Fragment>
                                )) : ''
                               }

                                    <div id="scroll_end" />
                                </div>
                            </div>


                            <div className="mt-auto chat_text_area">
                                <textarea
                                    onChange={handleMsgChange}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            sendMessage()
                                        }
                                    }}
                                    value={textMessage}
                                    className="form-control"
                                    style={{height: `${inputHeight * 30}px`}}
                                    placeholder="type a message"
                                ></textarea>
                                <span className="send_btn" onClick={sendMessage}><i className="icon-send" /></span>
                            </div>
                        </div>

                        

                        <div className={`chat_welcom_screen ${isSelected ? 'd_none' : ''}`}>
                            <div className="chat_welcom_inner">
                                <img src={props.adminProfile?.profileImage ? props.adminProfile?.profileImage : require('../../assets/img/user.png')} alt={props.adminProfile?.name} />
                                <h4>Welcome back {props.adminProfile?.name}</h4>
                            </div>
                        </div>



                    </div>
                </div>

            </div>


            <Modal
                size="lg"
                show={newChatModal}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>New Chat</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                    <div className="search_wrapper">
                        <i className="icon-search" />
                        <input
                            type="text"
                            value={userSearch}
                            onChange={handleUserSerach}
                            placeholder="Search" />
                    </div>
                    
                    <div className="user_list single_user">
                        <ul>
                            {
                                users.length ? users.map(user => (
                                    <li key={user._id} onClick={() => handleSingleUserId(user)}>
                                        <img src={user.profileImage ? user.profileImage : require("../../assets/img/user.png")} />
                                        <p>{user.name}</p>
                                        {user.UserType == 2 ? <small>Camp Director</small> : ''}
                                    </li>
                                )) : <div className="cm_empty">
                                <Empty />
                            </div>
                            }
                        </ul>

                        {
                            (totalUserCount == users.length || (totalUserCount - users.length) > 0) ? 
                            <div className="text-center position-relative mt-4">
                                <button onClick={handleLoadMore} className="btn btn-primary btn-sm">Load more</button>
                                <div className="remain_user">{totalUserCount - users.length} more users</div>
                            </div> : ''
                        }
                        
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={createGroupModal}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Create Group</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                
                {
                    (createGroupstep === 1) ? <Fragment>
                        <div className="search_wrapper">
                            <i className="icon-search" />
                            <input
                                type="text"
                                value={userSearch}
                                onChange={handleUserSerach}
                                placeholder="Search" />
                        </div>
                        
                        <div className="user_list">
                            <ul>
                                {
                                    users.length ? users.map(user => (
                                        <li key={user._id}>
                                            <img src={user.profileImage ? user.profileImage : require("../../assets/img/user.png")} />
                                            <p>{user.name}</p>
                                            <div className="check_wrapper">
                                                <input type="checkbox" value={user._id} checked={userId.includes(user._id)} onChange={addUserInGroup} />
                                                <span />
                                            </div>
                                        </li>
                                    )) : <div className="cm_empty">
                                    <Empty />
                                </div>
                                }  
                            </ul>

                            {
                                (totalUserCount == users.length || (totalUserCount - users.length) > 0) ? 
                                <div className="text-center position-relative mt-4">
                                    <button onClick={handleLoadMore} className="btn btn-primary btn-sm">Load more</button>
                                    <div className="remain_user">{totalUserCount - users.length} more users</div>
                                </div> : ''
                            }
                        </div>
                    </Fragment>
                    
                    :

                    <Fragment>
                        <div className="icon_upload_wrapper" style={{width: '100%'}}>
                            <input type="file" onChange={handleFileChange} accept="image/*" />
                            {groupImageView ? <img src={groupImageView} /> : ''}

                            {groupImageView ? <i className="icon-create" /> : <div>
                                                                                <i className="icon-upload-cercle" />
                                                                                <p>Upload Image</p>
                                                                            </div>}
                            
                        </div>

                        <div className="group_name">
                            <input
                                type="text"
                                value={groupName}
                                onChange={e => setGroupName(e.target.value)}
                                placeholder="Group name" />
                        </div>

                    </Fragment>
                }



                    <div className="d-flex justify-content-center">
                        <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                        {
                            createGroupstep == 1 ?
                            <button disabled={userId.length ? false : true} onClick={() => setCreateGroupstep(2)} className="btn btn-primary pl-5 pr-5">Next</button>:
                            <button disabled={groupName != '' ? false : true} onClick={createGroup} className="btn btn-primary pl-5 pr-5">Done</button>
                        }
                    </div>


                </Modal.Body>
            </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    let { adminProfile } = state.auth;
    return {
        adminProfile,
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);