import React, { useReducer, Fragment, useState } from 'react';
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { DatePicker, InputNumber } from 'antd';

import { specialDealsModalAction, addSpecialDealAction, specialDealListAction, dealViewModalAction } from '../../store/offer';
import { setNonSpacialPrograms } from '../../store/ui';
import {dateFormat, removeTime} from '../../helper';

const { RangePicker } = DatePicker;

const SpecialDealsModal = props => {
  const [miniLoader, setMiniLoader] = useState(false);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      title: '',
      price: '',
      campId: '',
      programId: '',
      type: 0,
      validFrom: '',
      validTo: '',
      // dealimage: '',
      // imageView: ''
    }
  );

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      title: '',
      price: '',
      campId: '',
      programId: '',
      type: '',
      validFrom: '',
      validTo: '',
      // dealimage: '',
      // imageView: ''
    }
  );


  const closeModal = () => {
    setUserInput(
      {
        title: '',
        price: '',
        campId: '',
        programId: '',
        type: 0,
        validFrom: '',
        validTo: '',
        // dealimage: '',
        // imageView: ''
      }
    )

    setError(
      {
        title: '',
        price: '',
        campId: '',
        programId: '',
        type: '',
        validFrom: '',
        validTo: '',
        // dealimage: '',
        // imageView: ''
      }
    )

    props.specialDealsModalAction();
  }



  const handleChange = e => {
    setUserInput({[e.target.name]: e.target.value});
    setError({[e.target.name]: ''});
  }



  const handleDateChange = e => {
    //setUserInput({validFrom: e[0]._d.getTime(), validTo:  e[1]._d.getTime()});
    setUserInput({validFrom: removeTime(e[0]._d), validTo: removeTime(e[1]._d)});
    setError({
      validFrom: '',
      validTo: ''
    });
  }



  // const handleFileChange = e => {
  //   setError({icon: '', imageView: ''})
  //   let reader = new FileReader();
  //   let file = e.target.files[0];

  //   setUserInput({dealimage: file})
    
  //   reader.addEventListener('load', () => {
  //       setUserInput({imageView: reader.result})
  //   }, false);
  //   if (file) { reader.readAsDataURL(file) }
  // }



  const handleSelect = (e, name) => {
    if(name == 'campId'){
        const nonSpacialProgramArr = props.preList.program.filter(el => el.campId == e.value);
        props.setNonSpacialPrograms(nonSpacialProgramArr);
    }
    setUserInput({[name]: e.value});
    setError({[name]: ''});
  }

  const handleChangeVal = (e, name) => {
    setUserInput({[name]: e});
    setError({[name]: ''});
  }

  const handleValidate = () => {
    let validate = true;

    let {
      title,
      price,
      campId,
      programId,
      type,
      validTo,
      //imageView
    } = userInput;

    if(title == '') {
      setError({ title: 'Title is required' });
      validate = false
    }

    if(price == 'undefined' || price == null || price === ''){
      setError({ price: 'Price is required' });
      validate = false
    }

    if(campId == '') {
      setError({ campId: 'Camp is required' });
      validate = false
    }

    if(programId == '') {
      setError({ programId: 'Program is required' });
      validate = false
    }


    if(validTo == '') {
      setError({ validTo: 'Date is required' });
      validate = false
    }

    // if(imageView == '') {
    //   setError({ imageView: 'Image is required' });
    //   validate = false
    // }

   
    return validate;
  }


  const handleSubmit = e => {

    e.preventDefault();
    if(handleValidate()){
      setMiniLoader(true);
      let {
        title,
        price,
        campId,
        programId,
        type,
        validFrom,
        validTo,
        //dealimage
      } = userInput;

      let params = new FormData();
          params.append('title', title);
          params.append('price', price);
          params.append('campId', campId);
          params.append('programId', programId);
          params.append('type', type ? 1 : 0);
          params.append('validFrom', validFrom);
          params.append('validTo', validTo);
          //params.append('dealimage', dealimage);


      props.addSpecialDealAction(params).then(res => {
        props.specialDealListAction('');
        closeModal();
        setMiniLoader(false)
      })
    }
  }

  return (
    <Modal
      size="lg"
      show={props.toggleSpecialDealsModal}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{props.isDealView ? 'View' : 'Add'} Special Deals</Modal.Title>
        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
      </Modal.Header>

      <Modal.Body>

        <form onSubmit={handleSubmit}>



          {/* <div className="icon_upload_wrapper w-100 mb-4">
            {props.isDealView ? <img src={props.viewDeal.dealimage} /> :
            <Fragment>
              <input type="file" onChange={handleFileChange} accept="image/x-png,image/jpeg" />
              {userInput.imageView ? <img src={userInput.imageView} alt="icon" /> : ''}

              {userInput.imageView ? <div className="cm_edit_mode">
                <i className="icon-create" />
              </div> : <div>
                  <i className="icon-upload-cercle" />
                  <p>Upload Image</p>
                </div>}
              <div className="invalid_feild">{error.imageView}</div>
            </Fragment>}
          </div> */}




          <div className="form-group">
            <label>Title</label>
            {props.isDealView ? <p className="inputs_view">{props.viewDeal.title}</p> :
            <Fragment>
              <input
              type="text"
              placeholder="Title"
              className={`form-control ${error.title ? 'is-invalid' : ''}`}
              name="title"
              value={userInput.title}
              onChange={handleChange}
            />
             {error.title ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.title}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>




          <div className="form-group">
            <label>Type</label>
            {props.isDealView ? <p className="inputs_view">{props.viewDeal.type ? 'Amount' : 'Percentage'}</p> :
            <Fragment>
              <Select
              placeholder="Type"
              onChange={e => handleSelect(e, 'type')}
              classNamePrefix="cm_select"
              className="form-control"
              options={[
                  {value: 1, label: 'Amount'},
                  {value: 0, label: 'Percentage'},
              ]}/>
            </Fragment>}
          </div>




          <div className="form-group">
            <label>New Price</label>
            {props.isDealView ? <p className="inputs_view">{props.viewDeal.price}{props.viewDeal.type ? '$' : '%'}</p> :
            <Fragment>

              <InputNumber
                onChange={e => handleChangeVal(e, 'price')}
                formatter={value => userInput.type ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                defaultValue={userInput.price}
                min={0}
                placeholder="New Price"
                max={userInput.type == 1 ? Number.MAX_VALUE : 100}
                className={`form-control ${error.price ? 'is-invalid' : ''}`} />

             {error.price ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.price}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>



          
          <div className="form-group">
            <label>Valid From - Valid To</label>
            {props.isDealView ? <p className="inputs_view">From {dateFormat(props.viewDeal.validFrom)} To {dateFormat(props.viewDeal.validTo)}</p> :
            <Fragment>
              <RangePicker
                disabledDate={d => d.isSameOrBefore(dateFormat(new Date().getTime()))}
                className={`form-control ${error.validTo ? 'is-invalid' : ''}`}
                onChange={handleDateChange}
              />
              {error.validTo ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.validTo}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>
          




          <div className="form-group">
            <label>Camp Name</label>
            {props.isDealView ? <p className="inputs_view">{props.viewDeal.campname}</p> :
            <Fragment>
              <Select
                onChange={e => handleSelect(e, 'campId')}
                classNamePrefix="cm_select"
                className={`form-control ${error.campId ? 'is-invalid' : ''}`}
                options={props.preList.camp.map(item => ({value: item._id, label: `${item.campName} - ${item.campCode}`}))}
                placeholder="Enter Camp Name"
              />
              {error.campId ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.campId}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>

            {
                props.isDealView ? <div className="form-group">
                <label>Program Name</label>
                <p className="inputs_view">{props.viewDeal.programname}</p>
              </div> : ''
              }

              {
                userInput.campId ? <div className="form-group">
                <label>Program Code</label>
                <Fragment>
                  <Select
                    classNamePrefix="cm_select"
                    className={`form-control ${error.programId ? 'is-invalid' : ''}`}
                    onChange={e => handleSelect(e, 'programId')}
                    options={props.nonSpacialProgram ? props.nonSpacialProgram.map(item => ({value: item._id, label: `${item.programName} - ${item.programCode}`})) : ''}
                    placeholder="Enter Program Code"
                  />
                  {error.programId ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.programId}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                </Fragment>
              </div> : ''
              }

            
         




          <div className="d-flex justify-content-center mt-4 pb-4">
            <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
            
            {props.isDealView ? '' : <button disabled={miniLoader} type="submit" className="btn btn-primary pl-5 pr-5">Save</button>}
          </div>

        </form>
      </Modal.Body>
    </Modal>


  )
}



const mapStateToProps = state => {
  let { preList, nonSpacialProgram } = state.ui;
  let { toggleSpecialDealsModal, loader, viewDeal, isDealView } = state.offer;
  return {
    toggleSpecialDealsModal,
    loader,
    preList,
    viewDeal,
    isDealView,
    nonSpacialProgram
  };
}

const mapDispatchToProps = dispatch => ({
    specialDealsModalAction: () => dispatch(specialDealsModalAction()),
    addSpecialDealAction: params => dispatch(addSpecialDealAction(params)),
    specialDealListAction: params => dispatch(specialDealListAction(params)),
    dealViewModalAction: params => dispatch(dealViewModalAction(params)),
    setNonSpacialPrograms: params => dispatch(setNonSpacialPrograms(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDealsModal);