export const development = {
    api: {
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        url: 'https://devapi.campsez.com/api/v1/',
        client_url: 'https://devweb.campsez.com/',
        mode: 'cors',
        AUTH: 'Basic Y2FtcHNlel9hZG1pbjpjYW1wc2V6QGRldg==',
        socketurl: 'https://devsocket.campsez.com',
        SITE_URL: 'https://devadmin.campsez.com/',
        districtCamp: '6241cff5868f5261b4c64293',
        sportInternationalCamp: '651d0cd63958a3aea6b0d4e1',
    }

    // api: {
    //     mode: 'cors',
    //     url: 'http://192.168.12.32:5001/api/v1/',
    //     AUTH: 'Basic Y2FtcDpjYW1w',
    //     socketurl: 'http://localhost:4000',
    //     districtCamp: '6241cff5868f5261b4c64293'
    // }

    // api: {
    //     mySecretKey: 'Hb@DOQQY!Y|8ljgp',
    //     url: 'https://api.campsez.com/api/v1/',
    //     socketurl: 'https://socket.campsez.com/',
    //     mode: 'cors',
    //     AUTH: 'Basic Y2FtcHNlel9hdXRoOjJrTmxZejVCbUg=',
    //     SITE_URL: 'https://campsez.com/',
    //     districtCamp: '61689494218cfc630c5394b2',
    //     sportInternationalCamp: '64e647f1e0b09dbf3c390fd2',
    // }
}


export const local = {
    api: {
        mode: 'cors',
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        url: 'http://localhost:5001/api/v1/',
        client_url: 'http://localhost:3000/',
        socketurl: 'http://localhost:4000',
        AUTH: 'Basic Y2FtcDpjYW1w',
        districtCamp: '6241cff5868f5261b4c64293'
    }
}



export const staging = {
    api: {
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        url: 'https://stagapi.campsez.com/api/v1/',
        mode: 'cors',
        AUTH: 'Basic Y2FtcHNlel9hZG1pbjpjYW1wc2V6QHN0YWc=',
        socketurl: 'https://stagsocket.campsez.com',
    }
}

export const prod = {
    api: {
        mySecretKey: 'Hb@DOQQY!Y|8ljgp',
        url: 'https://api.campsez.com/api/v1/',
        client_url: 'https://campsez.com/',
        socketurl: 'https://socket.campsez.com/',
        mode: 'cors',
        AUTH: 'Basic Y2FtcHNlel9hdXRoOjJrTmxZejVCbUg=',
        SITE_URL: 'https://campsez.com/',
        districtCamp: '61689494218cfc630c5394b2'
    }

}