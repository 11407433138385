import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { experienceModalAction, campDetailAction } from '../../store/camps';
import { CustomTextarea, CustomNumber, CustomText, CustomSelect } from '../util/FormControls';
import { updateAdditionalExp } from '../../services/campService';
import { addBr, colorsList, getBr, getHtml } from '../../helper'

const isColor = c => {
    let s = new Option().style;
    s.color = c;
    return s.color == c;
  }

const AdditionalExperience = props => {
    const url = window.location.href;
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [titleError, setTitleError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [allError, setAllError] = useState('');
    const [color, setColor] = useState('');
    const [colorErr, setColorErr] = useState('');
    const [list, setList] = useState([]);
    const [additionalExpEditItiem, setAdditionalExpEditItiem] = useState('');
    const [maxAdditionalExpCount, setMaxAdditionalExpCount] = useState('');
    const [remainingAdditionalExpCount, setRemainingAdditionalExpCount] = useState('');
    const [remainingAdditionalExpCountTemp, setRemainingAdditionalExpCountTemp] = useState('');
    const [maxAdditionalExpCountErr, setMaxAdditionalExpCountErr] = useState('');
    

    const closeModal = () => {
        props.experienceModalAction('');
        setAllError('');
        setTitle('');
        setPrice('');
        setColor('');
        setDescription('');
        setMaxAdditionalExpCount('');
        setRemainingAdditionalExpCount('');
    }

    useEffect(() => {
        setList(props.experiences.additionalExp ? props.experiences.additionalExp : []);
    },[props.experiences])


    const handleExpCountChange = e => {
        setMaxAdditionalExpCountErr('')
        if(remainingAdditionalExpCountTemp){
            if(e < remainingAdditionalExpCountTemp){
                setMaxAdditionalExpCountErr('Please enter value greater then available slots') 
            }else{
                setMaxAdditionalExpCount(e);
                setRemainingAdditionalExpCount(e - remainingAdditionalExpCountTemp);
            }
        }else{
            setMaxAdditionalExpCount(e);
            setRemainingAdditionalExpCount(e);
        }
        
       
    }


    const handleValidate = () => {
        let validate = true;

        if(title.trim() == ''){
            setTitleError('Title is required');
            validate = false
        }

        if(price == 'undefined' || price == null || price === ''){
            setPriceError('Price is required');
            validate = false
        }

        if(maxAdditionalExpCount == 'undefined' || maxAdditionalExpCount == null || maxAdditionalExpCount === ''){
            setMaxAdditionalExpCountErr('Total slots is required');
            validate = false
        }

        if(description.trim() == ''){
            setDescriptionError('description is required');
            validate = false
        }
        if(color != '' || color != undefined || color != 'undefined'){
            if(!isColor(color)){
                setColorErr('Enter valid color');
                validate = false
            }
        }
        
            
        return validate;

    }



    const handleAdd = () => {
        setAllError('');
        if(handleValidate()){
        if(additionalExpEditItiem == ''){
            setList([...list, {
                        price,
                        description: addBr(description),
                        title: addBr(title),
                        color,
                        maxAdditionalExpCount,
                        remainingAdditionalExpCount,
                }]);
        }else{
            let tempList = [...list];
            let index;
            tempList.findIndex((item, i) => {
                if (item._id == additionalExpEditItiem._id) {
                    index = i;
                    return true;
                }
            });
            tempList.splice(index, 1, {_id: additionalExpEditItiem._id, price, description: addBr(description), title: addBr(title), color, maxAdditionalExpCount, remainingAdditionalExpCount });
            setList(tempList);
        }
        

        setTitle('');
        setTitleError('');
        setPrice('');
        setPriceError('');
        setDescription('');
        setDescriptionError('');
        setColor('');
        setAdditionalExpEditItiem('');
        setMaxAdditionalExpCount('');
        setRemainingAdditionalExpCount('');

        
            // setList([...list, {price, description: addBr(description), title: addBr(title), color }]);
            // setTitle('');
            // setTitleError('');
            // setPrice('');
            // setPriceError('');
            // setDescription('');
            // setDescriptionError('');
            // setColor('');
        }   
    }



    const handleDelete = index => {
        let tempList = [...list];
        tempList.splice(index, 1);
        setList(tempList);

        let params = {
            programId: props.experiences._id,
            additionalExp: JSON.stringify(tempList)
        }

        updateAdditionalExp(params)
        let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
        props.campDetailAction(query);
    }


    const handleEdit = item => {
        setAdditionalExpEditItiem(item);
        setTitle(getBr(item.title));
        setPrice(item.price);
        setDescription(getBr(item.description));
        setColor(item.color);
        setMaxAdditionalExpCount(item.maxAdditionalExpCount);
        setRemainingAdditionalExpCount(item.remainingAdditionalExpCount);
        setRemainingAdditionalExpCountTemp(item.maxAdditionalExpCount - item.remainingAdditionalExpCount);
    }

    
      




    const handleSubmit = e => {
        e.preventDefault();

        if(list.length){
            // let params = new FormData();
            // params.append('programId', props.experiences._id);
            // params.append('additionalExp', JSON.stringify(list));

            let params = {
                programId: props.experiences._id,
                additionalExp: JSON.stringify(list)
            }
            
            updateAdditionalExp(params).then(status => {
                if(status){
                    props.experienceModalAction('');
                    let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                    props.campDetailAction(query);
                }
            })
        }else{
            setAllError('All feild required')
        }

        
    }


    const handleAddQuestion = index => {
        let _list = [...list];
        _list[index].question = {
            questionType: '',
            title: '',
            subTitle: '',
            optionVal: '',
            options: [],
            isRequred: 2,
            isOtherOption: 2,
        }
        setList(_list);

    }



    const handleQuestionTypeChange = (e, index) => {
        let _list = [...list];
        _list[index].question = {
            questionType: e.value,
            title: '',
            subTitle: '',
            optionVal: '',
            options: [],
            isRequred: 2,
            isOtherOption: 2,
        }
        setList(_list);
    }


    const handleQuestionChange = (e, index) => {
        let _list = [...list];
        _list[index].question.title = e.target.value;
        setList(_list); 
        
    }
    const handleQuestionSubTitleChange = (e, index) => {
        let _list = [...list];
        _list[index].question.subTitle = e.target.value;
        setList(_list);
        
    }

    const getQuestionType = i => {
        return questionTypes[i - 1]
    }


    const handleClearValue = ind => {
        let _list = [...list];
        _list[ind].question.optionVal = '';
        _list[ind].question.optionValErr = '';
        setList(_list);
    }


    const handleAddOption = ind => {

        let _list = [...list];
    
        if(_list[ind].question.optionVal && _list[ind].question.optionVal.trim()){
    
            let isDuplicate = _list[ind].question.options.filter(i => i.opt == _list[ind].question.optionVal);

            if(isDuplicate.length){
                _list[ind].question.optionValErr = 'dupliate value not allowed';
                setList(_list);
            }else{
                let temp = [..._list[ind].question.options];
                temp.push({opt: _list[ind].question.optionVal});
                _list[ind].question.options = temp;
                setList(_list);
                handleClearValue(ind)
            }        
    
        }
    }
    
    const handleOptionValChange = (e, ind) => {
        let _list = [...list];
        _list[ind].question.optionVal = e.target.value;
        _list[ind].question.optionValErr = '';
        setList(_list);
    
    }


    const handleRemoveOption = (index, childIndex) => {
        let _list = [...list];

        let temp = [..._list[index].question.options];

        temp.splice(childIndex, 1);

        _list[index].question.options = temp;

        setList(_list);
    }


    const questionTypes = [
        {label: 'Short Answer', value: 1},
        {label: 'Drop-down', value: 2},
        // {label: 'Multiple Choice', value: 3},
        {label: 'Checkboxes', value: 4},
    ];


    const handleIsRequredChnage = i => {
        let _list = [...list];
        _list[i].question.isRequred = _list[i].question.isRequred == 1 ? 2 : 1
        setList(_list);    
    }


    const handleIsOtherOptionChnage = index => {
        let _list = [...list];
        _list[index].question.isOtherOption = _list[index]?.question?.isOtherOption  == 1 ? 2 : 1;
        setList(_list);  
    }
    



    return (
        <Modal
            size="xl"
            show={props.experienceModal}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Additional Experience</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>

            {
                list ? list.map((item, index) => (
                    <div className="schedule_item" key={index}>
                        <h6 style={{color: item.color}} className="mb-2"><b>Title:</b> {getHtml(item.title)}</h6>
                        <p style={{color: item.color}} className="mb-2"><b>Price:</b> ${item.price.toFixed(2)}</p>
                        <p style={{color: item.color}} className="mb-2"><b>Totol Slots:</b> {item.maxAdditionalExpCount}</p>
                        <p style={{color: item.color}} className="mb-2"><b>Available Slots:</b> {item.remainingAdditionalExpCount}</p>
                        <p style={{color: item.color}} className="mb-2"><b>Description:</b> {getHtml(item.description)}</p>

                        <div className="cm_action">
                            <i className="icon-delete" onClick={() => handleDelete(index)} />
                            {item._id ? <i className="icon-pancil" onClick={() => handleEdit(item)} /> : ''}
                        </div>



                        {
                            item.question ? <div className="form-group cm_questions cm_questions2">
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <label>Question</label>
                                                    <span className='question_types mr-0'>
                                                    {(item.question.questionType == 3 || item.question.questionType == 4) ? <Fragment>
                                                        <p>Is Other Option ?</p>
                                                        <div className="cm_swich_wrap">
                                                            <input type="checkbox" onChange={() => handleIsOtherOptionChnage(index)}  checked={item.question.isOtherOption == 1 ? true : false} />
                                                            <span />
                                                        </div>
                                                    </Fragment> : ''}
                                                    

                                                    <p>Is Mandatory ?</p>
                                                    <div className="cm_swich_wrap">
                                                        <input type="checkbox"  checked={item.question.isRequred == 1 ? true : false} onChange={e => handleIsRequredChnage(index)} />
                                                        <span />
                                                    </div>
                                                    <CustomSelect
                                                        pHolder="Select Question Type"
                                                        value={getQuestionType(item.question.questionType)}
                                                        onChange={e => handleQuestionTypeChange(e, index)}
                                                        options={questionTypes} />
                                                    </span>
                                                </div>



                                                {
                                                    item.question.questionType ? <div className='cm_ques'>
                                                    <CustomTextarea
                                                        pHolder="Question*"
                                                        value={item.question.title}
                                                        change={e => handleQuestionChange(e, index)} />
                                                    
                                                    <CustomTextarea
                                                        pHolder="Sub Title"
                                                        value={item.question.subTitle}
                                                        change={e => handleQuestionSubTitleChange(e, index)} />

                                                </div> : ''
                                                }


                                        {(item.question.questionType == 2 || item.question.questionType == 3 || item.question.questionType == 4) ? <div className='cm_options'>
                                                {item.question.options.length ? <ul>{item.question.options.map((itm, j) => <li>{itm.opt} <i className="icon-close" onClick={() => handleRemoveOption(index, j)} /></li>)}</ul> : ''}
                                                <div className='d-flex align-items-center'>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${item.question.optionValErr ? 'is-invalid' : ''}`}
                                                        placeholder='Add Option'
                                                        value={item.question.optionVal}
                                                        onChange={e => handleOptionValChange(e, index)} />
                                                    
                                                    {item.question.optionValErr ? <h6>{item.question.optionValErr}</h6> : ''}
                                                    
                                                    <div>
                                                        <span style={{minWidth: '150px', marginLeft: '20px'}} className='btn btn-outline-primary btn-sm' onClick={() => handleAddOption(index)}><i className="icon-plus" /><small>Add</small></span>  
                                                    </div>
                                                </div>
                                            </div> : ''}
                            </div> : ''
                        }






                        {
                            item?.question ? '' : <div className="add_question_btn" onClick={() => handleAddQuestion(index)}><i className="icon-plus" /><small>Add Question</small></div>
                        }
                    </div>
                )) : ''
            }


                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Title</label>
                        <CustomTextarea
                            change={e => (setTitle(e.target.value), setTitleError(''))}
                            value={title}
                            pHolder="Title"
                            error={titleError}  />
                    </div>

                    <div className="row">
                        <div className="col form-group">
                            <label>Price</label>
                            <CustomNumber
                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                change={e => (setPrice(e), setPriceError(''))}
                                value={price}
                                min={0}
                                precision={2}
                                pHolder="Price"
                                error={priceError}  />
                        </div>
                        <div className="col form-group">
                            <label>Color</label>
                            <select
                                value={color}
                                className={`form-control ${colorErr ? 'is-invalid' : ''}`}
                                error={colorErr}
                                onChange={e => (setColor(e.target.value), setColorErr(''))}
                                placeholder="Select color">
                                    <option value="">Select color</option>
                                    {
                                        colorsList.map(colr => <option value={colr}>{colr}</option>)
                                    }
                                </select>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col form-group">
                        <label>Total Slots</label>
                        <CustomNumber
                                min={0}
                                precision={0}
                                step={1}
                                value={maxAdditionalExpCount}
                                error={maxAdditionalExpCountErr}
                                change={handleExpCountChange}
                                pHolder="Enter Additional Experience Count" />
                        
                    </div>

                    <div className="col form-group">
                        <label>Available Slots</label>
                        <CustomNumber
                                value={remainingAdditionalExpCount}
                                disabled/>
                        
                    </div>
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <CustomTextarea
                            value={description}
                            change={e => (setDescription(e.target.value), setDescriptionError(''))}
                            pHolder="Write Description here…"
                            error={descriptionError} />
                    </div>




                    <div className="text-right">
                        <span onClick={handleAdd} className="btn pl-0 pr-0"><i className="icon-plus" /> Add</span>
                    </div>


                    {allError ? <p className="text-center error_msg">{allError}</p> : ''}

                    <div id="scrollIntoView" className="d-flex justify-content-around mt-4 mb-4">
                        <span className="btn btn-gray" onClick={closeModal}>Cancel</span>
                        {/* <button disabled={list.length ? false : true} className="btn btn-primary">Save</button> */}
                        <button className="btn btn-primary">Save</button>
                    </div>

                </form>
            </Modal.Body>
        </Modal>

    )
}

const mapStateToProps = state => {
    let { experienceModal, experiences } = state.camp;
    let { programPageNumner } = state.camp;
    return {
        experienceModal,
        experiences,
        programPageNumner
    };
}

const mapDispatchToProps = dispatch => ({
    experienceModalAction: params => dispatch(experienceModalAction(params)),
    campDetailAction: params => dispatch(campDetailAction(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalExperience);
