
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import { supportSearchAction } from '../../store/support';
import { newCampRequestsService } from '../../services/supportService';
import { Empty } from '../reuse/SVG';


const NewCampRequests = props => {
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setRateTotalCount] = useState('');
    const [viewMessageModal, setViewMessageModal] = useState(false);
    const [currentData, setCurrentData] = useState('');

    useEffect(() => {
        return () => {
            props.supportSearchAction('')
        }
    }, [])


    const newCampRequests = () => {
        let query = `?page=${page - 1}&limit=15&search=${props.search}`;

        newCampRequestsService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setRateTotalCount(res.data.responseData.count);
            }
        })        
    }


    useEffect(() => {
        newCampRequests();
    }, [page])



    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            newCampRequests();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [props.search])


    const showViewMessage = data => {
        setViewMessageModal(true);
        setCurrentData(data);
    }

    return (
        <div className="ask_question_wrapper">

            <div className="table_responsive_wrap mt-4 text-center">
                <div className="table-responsive">

                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                            <tr>
                                <th><span className="t_text">S.No</span></th>
                                <th><span className="t_text">Name</span></th>
                                <th><span className="t_text">Camp Name</span></th>
                                <th><span className="t_text">Category</span></th>
                                <th><span className="t_text">Email</span></th>
                                <th><span className="t_text">Phone Number</span></th>
                                <th><span className="t_text">State</span></th>
                                <th><span className="t_text">Average Attendee</span></th>
                                <th><span className="t_text">Comment</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                list.map((item, i) => (
                                    <tr key={item._id}>
                                        <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                        <td><span className="t_text">{item.firstName} {item.lastName}</span></td>
                                        <td><span className="t_text">{item.campName}</span></td>
                                        <td><span className="t_text">{item.category}</span></td>
                                        <td><span className="t_text">{item.email}</span></td>
                                        <td><span className="t_text">{item.phone}</span></td>
                                        <td><span className="t_text">{item.state}</span></td>
                                        <td><span className="t_text">{item.averageAttendee}</span></td>
                                        <td className="cm_with_btn"><span className="t_text">{item.comment} </span><i className="icon-eye view_btn" onClick={() => showViewMessage(item)} /></td>
                                    </tr>
                                ))
                            }


                        </tbody>
                    </table>
                    {list.length ? '' : <div className="cm_empty mt-3">
                                                <Empty />
                                                <p>No Data</p>
                                            </div> }
                </div>

                <div className="mt-3">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
               </div>
            </div>

            <Modal
                size="lg"
                show={viewMessageModal}
                animation={false}
                centered>
                <Modal.Header>
                    <Modal.Title>Help & Support Detail</Modal.Title>
                    <span className="modal_close" onClick={() => (setViewMessageModal(false), setCurrentData(''))}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    <h6>Name</h6>
                    <p>{currentData.firstName} {currentData.lastName}</p>

                    <h6>Camp Name</h6>
                    <p>{currentData.campName}</p>
                    
                    <h6>Category</h6>
                    <p>{currentData.category}</p>

                    <h6>Email</h6>
                    <p>{currentData.email}</p>

                    <h6>Phone</h6>
                    <p>{currentData.phone}</p>

                    <h6>State</h6>
                    <p>{currentData.state}</p>

                    <h6>Average Attendee</h6>
                    <p>{currentData.averageAttendee}</p>

                    <h6>Comment</h6>
                    <p>{currentData.comment}</p>
                   
                </Modal.Body>
            </Modal>
        </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { search } = state.support;
    return {
        search
    };
}

const mapDispatchToProps = dispatch => ({
    supportSearchAction: params => dispatch(supportSearchAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCampRequests);