import * as actionType from './actionsTypes';
import {
    categoryList,
    changeCategoryStatus,
    addCategory,
    categoryDetail,
    editCategory,
    categoryDelete
} from '../services/categoryService';
import { resHandle2 } from '../helper';

const initialState = {
    loader: true,
    toggleCategoryModal: false,
    categories: [],
    category: '',
    error: '',
    isCategoryEditModal: 0
    
}


/************************************************************************************/
// category Reducer
/************************************************************************************/


const categoryReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.CATEGORY_LOADER:
            return {
            ...state,
            loader: !state.loader
        }

        case actionType.CATEGORY_MODAL:
            return {
            ...state,
            toggleCategoryModal: !state.toggleCategoryModal,
            isCategoryEditModal: action.payload
        }


        case actionType.CATEGORY_EDIT_MODAL:
            return {
            ...state,
            category: action.payload,
            isCategoryEditModal: 1,
            toggleCategoryModal: true
        }

        case actionType.CATEGORY_LIST:
            return {
            ...state,
            loader: false,
            isCategoryEditModal: 0,
            toggleCategoryModal: false,
            categories: action.payload
        }

        case actionType.CATEGORY_DETAIL:
            return {
            ...state,
            loader: false,
            category: action.payload
        }

        case actionType.CATEGORY_ERROR:
            return {
            ...state,
            loader: false,
            error: action.payload
        }


        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const categoryModalAction = payload => dispatch => dispatch({ type: actionType.CATEGORY_MODAL, payload });


export const categoryEditModalAction = payload => dispatch => dispatch({ type: actionType.CATEGORY_EDIT_MODAL, payload });



export const categoryListAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.CATEGORY_LOADER });
        let categoryListData = await categoryList(params);
        let { status, data } = resHandle2(categoryListData);
        if(status){
            dispatch({ type: actionType.CATEGORY_LIST, payload: data });
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
}




export const changeCategoryStatusAction = params => async dispatch => {
    try {
        let categoryStatus = await changeCategoryStatus(params);
        let { status, data } = resHandle2(categoryStatus);
        if(status){
            let categoryListData = await categoryList(params);
            let { status, data } = resHandle2(categoryListData);
            if(status){
                dispatch({ type: actionType.CATEGORY_LIST, payload: data });
            }else{
                dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
            }

            return status
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
    
}



export const changeSubCategoryStatusAction = params => async dispatch => {
    try {
        let categoryStatus = await changeCategoryStatus(params);
        let { status, data } = resHandle2(categoryStatus);
        if(status){
            return data;
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
    
}


export const categoryDeleteAction = params => async dispatch => {
    try {
        let res = await categoryDelete(params);
        let { status, data } = resHandle2(res);
        if(status){
            return data;
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
    
}



export const categoryDetailAction = params => async dispatch => {
    try {
        dispatch({ type: actionType.CATEGORY_LOADER });
        let categoryStatus = await categoryDetail(params);
        let { status, data } = resHandle2(categoryStatus);
        if(status){
            dispatch({ type: actionType.CATEGORY_DETAIL, payload: data });
            return status;
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
    
}




export const AddCategoryAction = params => async dispatch => {
    dispatch({ type: actionType.CATEGORY_LOADER });
    try {
        let category = await addCategory(params);
        let { status, data } = resHandle2(category);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }

        
    } catch (error) {
       dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
}


export const editCategoryAction = params => async dispatch => {
    dispatch({ type: actionType.CATEGORY_LOADER });
    try {
        let category = await editCategory(params);
        let { status, data } = resHandle2(category);
        if(status){
            dispatch({ type: actionType.CATEGORY_MODAL, payload: 0 });
            return status;
        }else{
            dispatch({ type: actionType.CATEGORY_ERROR, payload: data });
        }

        
    } catch (error) {
       dispatch({ type: actionType.CATEGORY_ERROR, payload: error });
    }
}



export default categoryReducer;