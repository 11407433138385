import React, { useEffect, useState } from 'react';
import { DatePicker, Empty } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';
import Select from 'react-select';

import { setBreadcrumbAction, bookingLimitAction } from '../../store/ui';
import { bookingListService, getCancelBookingCountService, genXlsService } from '../../services/bookingService';
import { dateFormat, displayPerPage, resHandle } from '../../helper';
import { MainLoader } from '../reuse/SVG';

const { RangePicker } = DatePicker;


const titles = [
    'Booking Number',
    'Date of Booking',
    'Date of Camp',
    'Camp Name',
    'Guardian Name',
    'Program Name'
];


export const getprogramdatesinCart = (startDate, endDate) => {
    //formate July 20 - July 24, 2020 
    let sDate = new Date(parseInt(startDate));
    let eDate = new Date(parseInt(endDate));
    let startdateYear = sDate.getFullYear();
    let enddateYear = eDate.getFullYear();
    let monthnamestart = moment.utc(sDate).format('MMM')
    let monthnameend =  moment.utc(eDate).format('MMM')
    let starthdatenumber = moment.utc(sDate).format('DD')
    let enddatenumber = moment.utc(eDate).format('DD')
    if (startdateYear == enddateYear) {
        return `${monthnamestart} ${starthdatenumber} - ${monthnameend} ${enddatenumber}, ${startdateYear}`;
    }
    else {
        return `${monthnamestart} ${starthdatenumber} ${startdateYear} - ${monthnameend} ${enddatenumber}, ${enddateYear}`;
    }
}



const getSelectedDates = (_all, _sel) => {

    if(_all.length){
        let _res = _all?.filter(el => _sel?.find((id) => el._id == id));

        return _res.map(i => (
        <span className='cm_selected_date' key={i._id}>
            {getprogramdatesinCart(i.startDate, i.endDate)}
        </span>
        ));
    }else {
        return ''
    }
    

}


const Bookings = props => {
    const [bookings, setBookings] = useState([]);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [googleLinkModal, setGoogleLinkModal] = useState(false);
    const [googleLoader, setGoogleLoader] = useState(false);
    const [googleSheetLinkResErr, setGoogleSheetLinkResErr] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [bookingCSV, setBookingCSV] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [type, setType] = useState(1);
    const [unreadCount, setUnreadCount] = useState(0);
    const [selectedCamp, setSelectedCamp] = useState('');
    const [selectedCampErr, setSelectedCampErr] = useState('');
    const [genLoader, setGenLoader] = useState(false);

    const [r_sheet, setR_sheet] = useState('');
    const [m_sheet, setM_sheet] = useState('');


    const handleClose = () => {
        setGoogleLoader(false);
        setGoogleLinkModal(false);
        setGoogleSheetLinkResErr('')
    }

    let params = {
        icon: 'bookings',
        links: [
            {name: 'Bookings', path: ''}
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    const generateGoogleSheet = (isUpdateSheet, medicalSheet) => {

        setGoogleLinkModal(true);
        setConfirmPopup(false);
        setGoogleLoader(true);

        let params = {
            isGoogleSheet: 1,
            isUpdateSheet,
            medicalSheet
        };
        bookingListService(params).then(res => {
            if(res.data.statusCode){
                if(medicalSheet == 1){
                    setM_sheet(res.data.responseData.result[0])
                }else{
                    setR_sheet(res.data.responseData.result[0]) 
                }
                setGoogleLoader(false);
            }else{
                setGoogleSheetLinkResErr('somthing went wrong')
            }
        })
    }



    const getBookingList = () => {
        let params = {fromDate, toDate, search, page: page - 1, limit: props.bookingLimit, type};
        let params2 = {fromDate, toDate, search, page: page - 1, limit: props.bookingLimit};
        bookingListService(params).then(res => {
            if(res.data.statusCode){
                setBookings(res.data.responseData.result);
                setCount(res.data.responseData.count);
                let tempBookings = res.data.responseData.result.map(booking => [
                    booking.bookingNumber,
                    booking ? moment(booking.bookingDate).format('MM/DD/YYYY') : '',
                    booking.programDate ? moment(booking.programDate[0]).format('MM/DD/YYYY') : '',
                    booking ? booking.campName[0] : '',
                    booking ? booking.guardianName[0] : '',
                    booking ? booking.programName[0] : '',                                 
                ])
        
                tempBookings.unshift(titles);
                setBookingCSV(tempBookings)
            }
        })


        getCancelBookingCountService(params2).then(res => {
            if(res.data.statusCode){
                setUnreadCount(res.data.responseData.result);
        
            }
        })
    }


    useEffect(() => {
        getBookingList();
    }, [toDate, toDate, page, props.bookingLimit, type])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getBookingList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])



    const handleDateChange = e => {
        if(e[0]._d.getTime() == e[1]._d.getTime()){
            let tomorrow = e[1]._d;
            tomorrow.setDate(e[1]._d.getDate() + 1);
            setToDate(tomorrow.getTime());
        }else{
            setToDate(e[1]._d.getTime());
        }

        setFromDate(e[0]._d.getTime());

    }
    

    const handleGenerate = () => {

        if(selectedCamp.value){
            setGenLoader(true);

            let params = {
                campId: selectedCamp.value
            }

            genXlsService(params).then(res => {
                setGenLoader(false);
                let { status, data } = resHandle(res);
                if(status){
                    if(data?.result?.location){
                        setConfirmPopup(false)
                        downloadText(data.result.location)
                    }else{
                        setSelectedCampErr(data.message);
                    }
                
                }
             
            })
        }else{
            setGenLoader(false);
            setSelectedCampErr('Please select Provider')
        }

    }


    const downloadText = (url) => {
        const a = document.createElement('a');
        a.download = 'bookings.xlsx';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };





    return(
        <div className="page_wrapper">

            <Modal show={googleLinkModal} centered>
                <Modal.Body>
                    <div className="d-flex justify-content-center mt-5 mb-4 google_sheet_modal">
                    {googleLoader ? <div>
                        <MainLoader />
                        <p>Please wait ...</p>
                    </div> : googleSheetLinkResErr ? <div>
                        <p>{googleSheetLinkResErr}</p>
                        <div className='text-center mt-4'>
                            <button className='btn btn-primary' onClick={handleClose}>Back</button>
                        </div>
                    </div> : <div className='google_sheet_wrapper'>
                        <h6>Generated Google Sheet Link:</h6>

                        <p className='mb-0 mt-5'>Medical Sheet</p>
                        <a className='btn-link' target='_blank' href={m_sheet}>{m_sheet}</a>

                        <p className='mb-0 mt-5'>Roster Sheet</p>
                        <a className='btn-link' target='_blank' href={r_sheet}>{r_sheet}</a>

                        <div className='text-center mt-4'><button className='btn btn-primary' onClick={handleClose}>Back</button>
                        </div></div>}
                    </div>
                </Modal.Body>
            </Modal>





            <Modal show={confirmPopup} centered>
                <Modal.Header>
                <span className="modal_close" onClick={() => setConfirmPopup(false)}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Select Provider</label>
                        <Select
                            placeholder="Select Provider"
                            onChange={e => (setSelectedCamp(e), setSelectedCampErr(''))}
                            classNamePrefix="cm_select"
                            className={`form-control mt-4 ${selectedCampErr ? 'is-invalid' : ''}`}
                            options={props?.camp?.map(i => ({label: i.campName, value: i._id}))} />
                        
                    </div>

                    {selectedCampErr ? <h6 className='cm_error'>{selectedCampErr}</h6> : ''}

                        {/* <div className='d-flex justify-content-between align-items-center mt-4 mb-4'>
                            <h6>Medical Sheet</h6>
                            <div className='d-flex justify-content-between align-items-center'>
                                <button className="btn btn-light btn-sm" onClick={() => generateGoogleSheet(1, 1)}>Update</button> 
                                <button className="btn btn-primary btn-sm ml-3" onClick={() => generateGoogleSheet(0, 1)}>Generate</button>
                            </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center mt-4 mb-4'>
                            <h6>Roster Sheet</h6>
                            <div className='d-flex justify-content-between align-items-center'>
                                <button className="btn btn-light btn-sm" onClick={() => generateGoogleSheet(1, 0)}>Update</button> 
                                <button className="btn btn-primary btn-sm ml-3" onClick={() => generateGoogleSheet(0, 0)}>Generate</button>
                            </div>
                        </div> */}


                        <div className='pt-4 pb-4 mt-4'>
                            <button className={`btn btn-primary w-100 ${genLoader ? 'btnLoader' : ''}`} onClick={() => genLoader ? null : handleGenerate()}>Generate</button>
                        </div>
                </Modal.Body>
            </Modal>

            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by booking number"
                    value={search}
                    onChange={e => (setSearch(e.target.value), setPage(1))}    />
            </div>

            <div className="page_card">


                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <div className='d-flex align-items-center justify-content-between'>
                    <h4>Bookings</h4>
                    <div className='tab_group'>
                        <span className={`${type == 1 ? 'active' : ''}`} onClick={() => setType(1)}>Bookings</span>
                        <span className={`${type == 2 ? 'active' : ''}`} onClick={() => setType(2)}>Half Pay</span>
                        <span className={`booking_unread_cont ${type == 3 ? 'active' : ''}`} onClick={() => setType(3)}>Canceled  {unreadCount ? <b>{unreadCount}</b> : ''}</span>
                    </div>
                    </div>
                    <div className="btn_group">
                        <button className="btn  btn-outline-primary" onClick={() => setConfirmPopup(true)}> Generate Google Sheet</button>
                        <CSVLink className="btn btn-outline-primary ml-3" data={bookingCSV}>Download Excel</CSVLink> 
                        <button className="btn btn-primary ml-3" onClick={() => window.print()}><i className="icon-print" /> Print</button>
                    </div>
                </div>

                <div className="page_card_body">
                   

                    <div className="table_filters">
                        <div className="form-group">
                            
                            <div className="d-flex align-items-center">
                                <label className="mb-0 mr-4">Date Of Booking</label>
                                <RangePicker
                                    style={{maxWidth: '350px'}}
                                    className="form-control"
                                    onChange={handleDateChange}
                                />

                                <div className="ml-auto table_page_set">
                                    
                                    Results displayed per page: 
                                    <select onChange={e => props.bookingLimitAction(parseInt(e.target.value))} value={props.bookingLimit}>
                                        {displayPerPage.map(item => <option key={item} value={item}>{item}</option>)}
                                    </select>

                                    <Link to="/bookings/create" className='btn btn-primary ml-3'>Create Booking</Link>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">
                        
                            <table className="table table-bordered table-striped" id="section-to-print">

                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Booking Number</span></th>
                                        <th><span className="t_text">Date of Booking</span></th>
                                        {type == 3 ? <th><span className="t_text">Date of Canceled</span></th> : ''}
                                        <th><span className="t_text">Date of Camp</span></th>
                                        <th><span className="t_text">Camp Name</span></th>
                                        <th><span className="t_text">Guardian Email</span></th>
                                        <th><span className="t_text">Program Name</span></th>
                                        <th className="hide_on_print"><span className="t_text">Action</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        bookings.map((booking, i) => (
                                            <tr key={booking.bookingId} className={`${booking.is_payment_split == 1 ? 'is_split' : ''} ${booking.status == 2 ? 'cancel_booking' : ''}`}>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * props.bookingLimit))}</span></td>
                                                <td><span className="t_text">{booking.bookingNumber}</span></td>
                                                <td><span className="t_text">{booking ? moment(booking.bookingDate).format('MM/DD/YYYY, h:mm a') : ''}</span></td>
                                                {type == 3 ?  <td><span className="t_text">{booking ? moment(booking.updated).format('MM/DD/YYYY, h:mm a') : ''}</span></td> : ''}
                                                {booking.byDates[0] == 1 ? (
                                                    <td><span>{getSelectedDates(booking.additionalDates[0], booking.selectedDates[0])}</span></td>
                                                ) : (
                                                    <td><span className="t_text">{booking.programDate ? moment.utc(booking.programDate[0]).format('MM/DD/YYYY') : ''}</span></td>
                                                )}
                                                
                                                <td><span className="t_text">{booking ? booking.campName[0] : ''}</span></td>
                                                <td><span className="t_text">{booking ? booking.guardianName[0] || booking.userEmail : ''}</span></td>
                                                <td><span className="t_text">{booking ? booking.programName[0] : ''}</span></td>
                                                <td className="hide_on_print"><span className="t_text"><Link to={`/bookings/detail/${booking.bookingId}`}><i className="icon-eye" /></Link></span></td>
                                            
                                            </tr>
                                        ))
                                    }
                                    
                                   
                                </tbody>
                            </table>

                        </div>

                        {
                            bookings.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                        }
                    </div>


                    





                </div>

                <Pagination
                        activePage={page}
                        itemsCountPerPage={props.bookingLimit}
                        totalItemsCount={count}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>

            </div>


        </div>
    )
}


const mapStateToProps = state => {
    let { bookingLimit, preList } = state.ui
    let { camp } = preList;
    return {
        bookingLimit,
        camp
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    bookingLimitAction: params => dispatch(bookingLimitAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);