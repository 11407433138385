import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
      this.setState({ hasError: true });
    }
  
    render() {
        return(
            this.state.hasError ? <div className="page_wrapper error_boundary"><div className="page_card p-5 text-center"><h3 className="mb-5">Something went wrong.</h3> <a href='/' className="btn btn-primary">Go to Dashboard</a></div></div>: this.props.children
        )
    }
}

export default ErrorBoundary;