import React, { useReducer, Fragment, useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { DatePicker, InputNumber, notification } from 'antd';

import { giftCardModalAction, addGiftCardAction, giftViewModalAction, isNewGiftCardAction } from '../../store/offer';
import { dateFormat, removeTime } from '../../helper';


const GiftCardModel = props => {
  const [miniLoader, setMiniLoader] = useState(false);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      giftcardName: '',
      giftcardValue: '',
      fromPurchaseDate: '',
      toPurchaseDate: '',
      giftcardimage: '',
      imageView: '',
    }
  );

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      giftcardName: '',
      giftcardValue: '',
      fromPurchaseDate: '',
      toPurchaseDate: '',
      giftcardimage: '',
      imageView: ''
    }
  );

  const closeModal = () => {
    setUserInput(
      {
        giftcardName: '',
        giftcardValue: '',
        fromPurchaseDate: '',
        toPurchaseDate: '',
        giftcardimage: '',
        imageView: ''
      }
    )

    setError(
      {
        giftcardName: '',
        giftcardValue: '',
        fromPurchaseDate: '',
        toPurchaseDate: '',
        giftcardimage: '',
        imageView: ''
      }
    )

    props.giftCardModalAction();
  }


  const handleChange = e => {
    setUserInput({[e.target.name]: e.target.value});
    setError({[e.target.name]: ''});
  }




  const handleDateChange = (e, name) => {
    setUserInput({[name]: removeTime(e._d)});
    setError({
      fromPurchaseDate: '',
      toPurchaseDate: ''
    });
  }


  const handleFileChange = e => {
    setError({icon: '', imageView: ''})
    let reader = new FileReader();
    let file = e.target.files[0];

    setUserInput({giftcardimage: file})
    
    reader.addEventListener('load', () => {
        setUserInput({imageView: reader.result})
    }, false);
    if (file) { reader.readAsDataURL(file) }
  }


  const handleSelect = (e, name) => {
    setUserInput({[name]: e.value});
    setError({[name]: ''});
  }


  const handleChangeVal = (e, name) => {
    setUserInput({[name]: e});
    setError({[name]: ''});
  }


  const handleValidate = () => {
    let validate = true;
    let { giftcardName, giftcardValue, fromPurchaseDate, imageView } = userInput;

    if(giftcardName == ''){
        setError({giftcardName: 'Gift card name is required'});
        validate = false
    }


    if(giftcardValue == ''){
        setError({giftcardValue: 'Gift card value is required'});
        validate = false
    }


    if(imageView == ''){
        setError({imageView: 'Gift image is required'});
        validate = false
    }

    if(fromPurchaseDate == ''){
        setError({fromPurchaseDate: 'Start end date is required'});
        validate = false
    }


    return validate;
}

  const handleSubmit = e => {
    e.preventDefault();
    if(handleValidate()){
      setMiniLoader(true);
      let { giftcardName, giftcardValue, giftcardimage, fromPurchaseDate, toPurchaseDate } = userInput;

      let params = new FormData();
          params.append('giftcardName', giftcardName);
          params.append('giftcardValue', giftcardValue);
          params.append('giftcardimage', giftcardimage);
          params.append('fromPurchaseDate', fromPurchaseDate);
          params.append('toPurchaseDate', toPurchaseDate);


      props.addGiftCardAction(params).then(res => {
        if(res){
          props.isNewGiftCardAction();
          closeModal();
          setMiniLoader(false);
        }else{
          setMiniLoader(false);
        }
        

         
      })
    }
  }


  useEffect(() => {
    props.error && notification.open({ message: 'Offers', description: props.error });
  }, [props.error])

  return (
    <Modal
      size="lg"
      show={props.toggleGiftCardModal}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{props.isGiftView ? 'View' : 'Add'} Gift Card</Modal.Title>
        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
      </Modal.Header>

      <Modal.Body>

        <form onSubmit={handleSubmit}>

          <div className="icon_upload_wrapper w-100 mb-4">
            {props.isGiftView ? <img src={props.viewGift.giftcardimage} /> :
            <Fragment>
              <input type="file" onChange={handleFileChange} accept="image/jpeg, image/png" />
              {userInput.imageView ? <img src={userInput.imageView} alt="icon" /> : ''}

              {userInput.imageView ? <div className="cm_edit_mode">
                <i className="icon-create" />
              </div> : <div>
                  <i className="icon-upload-cercle" />
                  <p>Upload Gift Card Image</p>
                </div>}
              <div className="invalid_feild">{error.imageView}</div>
            </Fragment>}
          </div>

      



          <div className="form-group">
            <label>Gift Card Name</label>
            {props.isGiftView ? <p className="inputs_view">{props.viewGift.giftcardName}</p> :
            <Fragment>
              <input
              type="text"
              placeholder="Gift Card Name"
              className={`form-control ${error.giftcardName ? 'is-invalid' : ''}`}
              name="giftcardName"
              value={userInput.giftcardName}
              onChange={handleChange}
            />
            {error.giftcardName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.giftcardName}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>


        


            <div className="form-group">
              <label>Gift Card Value</label>
              {props.isGiftView ? <p className="inputs_view">${props.viewGift.giftcardValue}</p> :
              <Fragment>


                <InputNumber
                  onChange={e => handleChangeVal(e, 'giftcardValue')}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  defaultValue={userInput.giftcardValue}
                  min={0}
                  placeholder="Gift Card Value"
                  className={`form-control ${error.giftcardValue ? 'is-invalid' : ''}`} />

                {error.giftcardValue ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.giftcardValue}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
              </Fragment>}
            </div>





        







          <div className="form-group">
            <label>Start & End Date</label>
            {props.isGiftView ? <p className="inputs_view">{dateFormat(props.viewGift.fromPurchaseDate)} {props?.viewGift?.toPurchaseDate ? `- ${dateFormat(props.viewGift.toPurchaseDate)}` : ''}</p> :
            <Fragment>
              <div className="row">
                <div className="col">
                  <DatePicker
                    disabledDate={d => d.isSameOrBefore(dateFormat(new Date().getTime()))}
                    className={`form-control ${error.fromPurchaseDate ? 'is-invalid' : ''}`}
                    onChange={e => handleDateChange(e, 'fromPurchaseDate')}
                  />
                </div>
                <div className="col">
                  <DatePicker
                    disabled={userInput.fromPurchaseDate ? false : true}
                    disabledDate={d => d.isSameOrBefore(dateFormat(new Date(userInput.fromPurchaseDate).getTime()))}
                    className={`form-control ${error.toPurchaseDate ? 'is-invalid' : ''}`}
                    onChange={e => handleDateChange(e, 'toPurchaseDate')}
                  />
                </div>
              </div>
              
              {error.fromPurchaseDate ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.fromPurchaseDate}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>





          <div className="d-flex justify-content-center mt-4 pb-4">
            <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
            {props.isGiftView ? '' : <button disabled={miniLoader} type="submit" className="btn btn-primary pl-5 pr-5">Save</button>}
          </div>

        </form>
      </Modal.Body>
    </Modal>


  )
}



const mapStateToProps = state => {
  let { toggleGiftCardModal, loader, isGiftView, viewGift, error } = state.offer;
  return {
    toggleGiftCardModal,
    loader,
    isGiftView,
    viewGift,
    error
  };
}

const mapDispatchToProps = dispatch => ({
  giftCardModalAction: () => dispatch(giftCardModalAction()),
  addGiftCardAction: params => dispatch(addGiftCardAction(params)),
  giftViewModalAction: params => dispatch(giftViewModalAction(params)),
  isNewGiftCardAction: () => dispatch(isNewGiftCardAction()),

});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardModel);