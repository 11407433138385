import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { logout } from '../../services/authService';
import ConfirmModal from '../modals/ConfirmModal';
import { unreadCountAction } from '../../store/user';
import { LocationIcon } from '../reuse/SVG';

const toggleSidebar = () => document.body.classList.remove('hide_sidebar');

const Sidebar = props => {
    const [logoutModal, setLogoutModal] = useState(false);

    const handleLogout = () => {
        logout().then(res => {
            if(res.data.statusCode){
                localStorage.clear();
                window.location.href = '/login';
            }
           
        })
    }

    return(
        <div className="sidebar">
            <div className="cm_logo">
                <img src={require('../../assets/img/logo.svg')} alt="logo" />
                <p>Super Admin</p>
            </div>

            <ul>
                <li><NavLink onClick={toggleSidebar} exact to='/'><i className="icon-dashboad" /><span>Dashboard</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/inbox'><i className="icon-email" /><span>Inbox</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/bookings'><i className="icon-bookings" /><span>Bookings</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/camps'><i className="icon-camps" /><span>Providers</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/category'><i className="icon-fire" /><span>Program Category</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/offers'><i className="icon-offers" /><span>Additional Offers</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/rewards'><i className="icon-rewards" /><span>Rewards</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/feed-regulation'><i className="icon-feed" /><span>Feed Regulation</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/support'><i className="icon-question" /><span>Support</span> {props.unreadMessage ? <div className="unread_sport">{props.unreadMessage}</div> : ''}</NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/review-regulation'><i className="icon-reviews" /><span>Review Regulation</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/additional-settings'><i className="icon-settings" /><span>Additional Settings</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/user'><i className="icon-users" /><span>User</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/notification'><i className="icon-bell" /><span>Notifications</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/payments'><i className="icon-payments" /><span>Payment</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/pages'><i className="icon-pages" /><span>Pages</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/faq'><i className="icon-FAQ" /><span>FAQ</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/advertising'><i className="icon-advertising" /><span>Advertising</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/donation'><i className="icon-donation" /><span>Donations</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/districts'><LocationIcon /><span>Districts</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/setting'><i className="icon-setting" /><span>Settings</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/online-store'><i className="icon-cart" /><span>Online Store</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/product-bookings'><i className="icon-bookings" /><span>Product Bookings</span></NavLink></li>
                <li><NavLink onClick={toggleSidebar} to='/site-maintenance'><i className="icon-lock" /><span>Site Maintenance</span></NavLink></li>
            </ul>

            <div className="text-center">
            <button className="btn btn-primary pl-5 pr-5 mt-5" onClick={() => setLogoutModal(!logoutModal)}><i className="icon-logout-2" /> Logout</button>
            </div>


            <ConfirmModal
                show={logoutModal}
                hideModal={() => setLogoutModal(false)}
                msg="Are you sure, you want to Log out?"
                action={handleLogout} />

        </div>
    )
}


const mapStateToProps = state => {
    let { unreadMessage } = state.user;
    return {
        unreadMessage
    };
}


const mapDispatchToProps = dispatch => ({
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);