import React, { useEffect, useState } from 'react';
import { DatePicker, Empty } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';
import Select from 'react-select';

import { setBreadcrumbAction, bookingLimitAction } from '../../store/ui';
import { productBookingsService } from '../../services/bookingService';
import { MainLoader } from '../reuse/SVG';





const ProductBookings = props => {
    const [loader, setLoader] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [campId, setCampId] = useState('');
    const [count, setCount] = useState(0);

    const limit = 10;


    let params = {
        icon: 'Product bookings',
        links: [
            {name: 'Product Bookings', path: ''}
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


  


    const getBookingList = () => {
        let query = `?search=${search}&page=${page - 1}&limit=${limit}&campId=${campId ? campId.value : ''}`;
        setLoader(true)
        productBookingsService(query).then(res => {
            setLoader(false)
            if(res.data.statusCode){
                setBookings(res.data.responseData.result || [])
                setCount(res.data.responseData.totalCount)
            
            }
        })
    }


    useEffect(() => {
        getBookingList();
    }, [search, page, campId])





    return(
        <div className="page_wrapper">

    


            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by booking number"
                    value={search}
                    onChange={e => (setSearch(e.target.value), setPage(1))}    />
            </div>

            <div className="page_card">


                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <div className='d-flex align-items-center justify-content-between'>
                        <h4>Product Bookings</h4>

                    </div>

                    <div className='formgroup' style={{zIndex: '999'}}>
                            <Select
                                className="form-control mm_with"
                                style={{minWisth: '350px'}}
                                onChange={e => setCampId(e)}
                                value={campId}
                                classNamePrefix="cm_select"
                                options={[{label: 'All', value: ''}, ...props?.camp?.map(i => ({label: i.campName, value: i._id}))]}/>
                        </div>
               
                </div>

                <div className="page_card_body">
                
                    <div className="table_filters">
                            
                        
                    </div>



                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">
                        
                            <table className="table table-bordered table-striped" id="section-to-print">

                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Booking Date</span></th>
                                        <th><span className="t_text">Product Image</span></th>
                                        <th><span className="t_text">Product Name</span></th>
                                        <th><span className="t_text">Product sizes / colors</span></th>
                                        <th><span className="t_text">Quantity</span></th>
                                        <th><span className="t_text">Price</span></th>
                                        <th><span className="t_text">User Name</span></th>
                                        <th><span className="t_text">User Email</span></th>
                                       
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        bookings.map((booking, i) => (
                                            <tr key={booking._id}>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                                           
                                                <td><span className="t_text">{moment(booking.orderedDate).format('DD MMM, YYYY, HH:MM')}</span></td>
                                                <td><span className="t_text">
                                                <div className='product_wide_card_img'>
                                                    <img src={booking.image[0]} />
                                                </div>    
                                                </span></td>
                                                <td><span className="t_text">{booking.name}</span></td>
                                                <td><span className="t_text">
                                                    {booking.sizes} /  {booking.color}
                                                </span></td>
                                                <td><span className="t_text">{booking.quantity}</span></td>
                                                <td><span className="t_text">${booking.itemPrice.toFixed(2)}</span></td>
                                                <td><span className="t_text">{booking.users.name}</span></td>
                                                <td><span className="t_text">{booking.users.email}</span></td>
                                            
                                            </tr>
                                        ))
                                    }
                                    
                                   
                                </tbody>
                            </table>

                        </div>

                        {
                            bookings.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                        }
                    </div>


                    





                </div>

                <Pagination
                        activePage={page}
                        itemsCountPerPage={props.bookingLimit}
                        totalItemsCount={count}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>

            </div>


        </div>
    )
}


const mapStateToProps = state => {
    let { bookingLimit, preList } = state.ui;
    let { camp } = preList;
    return {
        bookingLimit,
        camp
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    bookingLimitAction: params => dispatch(bookingLimitAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductBookings);

