import ApiInstance from './intercepter';
import API from '../config/api';


export const addCoupon = params => ApiInstance.post(API.CREATE_COUPON, params);

export const specialDealList = params => ApiInstance.post(API.SPECIAL_DEAL_LIST + params);

export const addGiftCard = params => ApiInstance.post(API.CREATE_GIFT_CARD, params);

export const addSpecialDeal = params => ApiInstance.post(API.CREATE_SPECIAL_DEAL, params);

export const giftCardList = params => ApiInstance.post(API.GIFT_CARD_LIST + params);

export const couponList = params => ApiInstance.post(API.COUPON_LIST + params);

export const couponDelete = params => ApiInstance.delete(API.COUPON_DELETE, {data: params});

export const giftDelete = params => ApiInstance.delete(API.GIFT_DELETE, {data: params});

export const specialDealDelete = params => ApiInstance.delete(API.SPECIAL_DEAL_DELETE, {data: params});

export const couponStatusChange = params => ApiInstance.put(API.COUPON_STATUS_CHANGE, params);

export const giftCardStatusChange = params => ApiInstance.put(API.GIFT_CARD_STATUS_CHANGE, params);

export const specialDealStatusChange = params => ApiInstance.put(API.SPECIAL_DEAL_STATUS_CHANGE, params);

export const validateCouponCodeService = params => ApiInstance.post(API.VALIDATE_COUPON_CODE, params);