import * as actionType from './actionsTypes';
import { resHandle } from '../helper';
import { login, forgotPassword, resetPassword } from '../services/authService';


let auth = JSON.parse(localStorage.getItem('auth'));
const initialState = auth ? { loggedIn: true, adminProfile: auth } : {};


/************************************************************************************/
// User Reducer
/************************************************************************************/


const authReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.LOGIN_REQIEST:
            return {
                ...state,
                loading: true,
            };

        case actionType.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loading: false,
                adminProfile: action.payload
            };


        case actionType.LOGIN_FAILURE:
            return {
                loading: false,
                error: action.payload
            };


        case actionType.SUCCESS_PAGE:
            return {
                loading: false
            };


        default:
            return state
    }
}




/************************************************************************************/
// All User Actions
/************************************************************************************/





export const loginAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let admin = await login(params);
        let { status, data } = resHandle(admin);
        if(status){
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('auth', JSON.stringify(data.userProfile));
            dispatch({ type: actionType.LOGIN_SUCCESS, payload: data.userProfile });
        }else{
            dispatch({ type: actionType.LOGIN_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }
}






export const forgotPasswordAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    dispatch({ type: actionType.TEMP_EMAIL, payload: params.email});
    
    try {
        let resData = await forgotPassword(params);
        let { status, data } = resHandle(resData);
        if(status){
            dispatch({ type: actionType.SUCCESS_PAGE });
            return status;
        }else{
            dispatch({ type: actionType.LOGIN_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }  
}





export const resetPasswordAction = params => async dispatch => {
    dispatch({ type: actionType.LOGIN_REQIEST });
    try {
        let resData = await resetPassword(params);
        let { status, data } = resHandle(resData);
        if(status){
            dispatch({ type: actionType.SUCCESS_PAGE });
            return status;
        }else{
            dispatch({ type: actionType.LOGIN_FAILURE, payload: data });
        }
    } catch (error) {
        dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }
}




export default authReducer;