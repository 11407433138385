import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { setBreadcrumbAction } from '../../store/ui';
import { getStaticPage, updateStaticPage } from '../../services/pagesService';
import { MainLoader } from '../reuse/SVG';


const AboutUs = props => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);

    const [modal, setModal] = useState(false);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [titleErr, setTitleErr] = useState('');
    const [content, setContent] = useState('');
    const [contentErr, setContentErr] = useState('');
    const [image, setImage] = useState('');
    const [imageView, setImageView] = useState('');


    let params = {
        icon: 'Pages',
        links: [
            { name: 'Pages', path: '/pages' },
            { name: 'About Us', path: '' }
        ]
    }


    const getPageData = () => {
        let query = `?page=about`;

        getStaticPage(query).then(res => {
            setLoader(false);
            if(res.data.statusCode);
            setList(res.data.responseData.result)
        })
    }

    useEffect(() => {
        props.setBreadcrumbAction(params);
        getPageData();
    }, []);


    const handleFileChange = e => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setImage(file); 
        reader.addEventListener('load', () => {
            setImageView(reader.result);
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }


    const handleValidate = () => {
        let validate = true;

        if(title == ''){
            setTitleErr('Title is required')
            validate = false
        }

        if(content == ''){
            setContentErr('Content is required')
            validate = false
        }
    
        return validate;
    }



    const handleSubmit = e => {
        e.preventDefault();
      
        if(handleValidate()){
              setLoader(true);
    
            let params = new FormData();
            params.append('type', type);
            params.append('title', title);
            params.append('content', content);
            params.append('image', image);
            

            updateStaticPage(params).then(res => {
                setLoader(false);
                closeModal();
                getPageData();
            })
           
        }
    }



    const closeModal = () => {
        setModal(false);
        setType('');
        setTitle('');
        setTitleErr('');
        setContent('');
        setContentErr('');
        setImage('');
        setImageView('');
    }



    const editSection = (e, data) => {
        setType(e);
        setModal(true);
        setImageView(data.image);
        setContent(data.content);
        setTitle(data.title);
    }

    
    return(
        <div className="page_wrapper">
            {loader ? <MainLoader /> : ''}
            <div className="page_card">
                {
                    list.length ? list.filter(item => item.type == 'aboutSection1').map(i => (
                        <div className="cm_banner_wrapper">
                            <div className="edit_btn" onClick={() => editSection('aboutSection1', i)}><i className="icon-pancil" /></div>
                            <img src={i.image} />
                            <div className="cm_banner_text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2>{i.title}</h2>
                                            <p className="line_h_2">{i.content}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : ''
                }


                {
                    list.length ? list.filter(item => item.type == 'aboutSection2').map(i => (
                        <div className="cm_section">
                            <div className="edit_btn edit_btn_dark" onClick={() => editSection('aboutSection2', i)}><i className="icon-pancil" /></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <h2 className="section_title_left">{i.title}</h2>
                                            <p className="line_h_2">{i.content}</p>
                                        
                                            <ul className="cm_counts">

                                            {
                                                list.length ? list.filter(item => item.type == 'aboutSection3').map(i => (
                                                    <li>
                                                        <img src={require('../../assets/img/campers.svg')} />
                                                        <h3>{i.title}</h3>
                                                        <h5>{i.content}</h5>
                                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection3', i)}><i className="icon-pancil" /></div>
                                                    </li>
                                                )) : ''
                                            }

                                            {
                                                list.length ? list.filter(item => item.type == 'aboutSection4').map(i => (
                                                    <li>
                                                        <img src={require('../../assets/img/unique-activities.svg')} />
                                                        <h3>{i.title}</h3>
                                                        <h5>{i.content}</h5>
                                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection4', i)}><i className="icon-pancil" /></div>
                                                    </li>
                                                )) : ''
                                            }

                                            {
                                                list.length ? list.filter(item => item.type == 'aboutSection5').map(i => (
                                                    <li>
                                                         <img src={require('../../assets/img/nature.svg')} />
                                                        <h3>{i.title}</h3>
                                                        <h5>{i.content}</h5>
                                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection5', i)}><i className="icon-pancil" /></div>
                                                    </li>
                                                )) : ''
                                            }

                                            {
                                                list.length ? list.filter(item => item.type == 'aboutSection6').map(i => (
                                                    <li>
                                                        <img src={require('../../assets/img/baseball.svg')} />
                                                        <h3>{i.title}</h3>
                                                        <h5>{i.content}</h5>
                                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection6', i)}><i className="icon-pancil" /></div>
                                                    </li>
                                                )) : ''
                                            }

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <img className="about_img" src={i.image} />
                            </div>
                    )) : ''
                }
                
                <div className="cm_section ball_right" style={{backgroundImage: `url(${require('../../assets/img/dash-line.svg')})`}}>
                    <div className="container">
                        <h2 className="section_title_center ">Why Choose Us</h2>

                        <div className="row">

                        {
                            list.length ? list.filter(item => item.type == 'aboutSection7').map(i => (
                                <div className="col-md-4">
                                    <div className="shadow_card mt-5">
                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection7', i)}><i className="icon-pancil" /></div>
                                        <span className="card_num">01</span>
                                        <h5>{i.title}</h5>
                                        <p>{i.content}</p>
                                    </div>
                                </div>
                            )) : ''
                        }

                        {
                            list.length ? list.filter(item => item.type == 'aboutSection8').map(i => (
                                <div className="col-md-4">
                                    <div className="shadow_card mt-5">
                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection8', i)}><i className="icon-pancil" /></div>
                                        <span className="card_num">02</span>
                                        <h5>{i.title}</h5>
                                        <p>{i.content}</p>
                                    </div>
                                </div>
                            )) : ''
                        }

                        {
                            list.length ? list.filter(item => item.type == 'aboutSection9').map(i => (
                                <div className="col-md-4">
                                    <div className="shadow_card mt-5">
                                        <div className="edit_btn edit_btn_sm" onClick={() => editSection('aboutSection9', i)}><i className="icon-pancil" /></div>
                                        <span className="card_num">03</span>
                                        <h5>{i.title}</h5>
                                        <p>{i.content}</p>
                                    </div>
                                </div>
                            )) : ''
                        }

        
                        </div>
                    </div>
                </div>


               
            </div>
            


            <Modal
                show={modal}
                animation={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Edit Section</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    <form onSubmit={handleSubmit}>
                        {(type == 'aboutSection1' || type == 'aboutSection2') ? 
                        <div className="icon_upload_wrapper cat_banner_img  w-100">
                            <input type="file" onChange={handleFileChange} accept="image/x-png,image/jpeg" />
                            {imageView ? <img src={imageView} alt="icon" /> : ''}
                            {imageView ? <div className="cm_edit_mode"><i className="icon-create" /></div> : <div><i className="icon-button" /><p>Upload Image</p></div>}
                        </div>
                        : '' }

                        <div className="form-group">
                            <label>Title</label>
                            <div className="icon_input">
                                <input
                                    type="text"
                                    className={`form-control ${titleErr ? 'is-invalid' : ''}`}
                                    value={title}
                                    onChange={e => (setTitle(e.target.value), setTitleErr(''))} />

                                {titleErr ? <OverlayTrigger
                                                placement="left"
                                                overlay={<Tooltip>{titleErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <div className="icon_input">
                                <textarea
                                    className={`form-control ${contentErr ? 'is-invalid' : ''}`}
                                    value={content}
                                    onChange={e => (setContent(e.target.value), setContentErr(''))} />

                                {contentErr ? <OverlayTrigger
                                                placement="left"
                                                overlay={<Tooltip>{contentErr}</Tooltip>}>
                                                    <span className="error_tootltip" />
                                                </OverlayTrigger> : ''
                                }
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-5 mb-4">
                            <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">Cancel</button>
                            <button type="submit" className="btn btn-primary pl-5 pr-5">Save</button>
                        </div>
                    </form>
                    
               
                    
                </Modal.Body>
            </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);