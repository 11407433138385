import React, { useEffect, useState, Fragment } from 'react';
import {connect} from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty, notification } from 'antd';

import {setBreadcrumbAction} from '../../store/ui';
import { MainLoader } from '../reuse/SVG';
import { getReviewService, changeReviewStatusService } from '../../services/reviewService';


const ReviewRegulation = props => {
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState('');


    let params = {
        icon: 'Review Regulation',
        links: [
            { name: 'Review Regulation', path: '' }
        ]
    }

    useEffect(() => {
       props.setBreadcrumbAction(params);
    }, []);



    const getReviews = () => {
        let query = `?page=${page}&limit=15`;

        getReviewService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setTotalCount(res.data.responseData.count);
            }
        })
    }


    useEffect(() => {
        getReviews();
    }, [page])


    const handleChangeStatus = review => {
        let params = {
            reviewId: review._id,
            status: review.status == 1 ? '2' : '1'
        }

        changeReviewStatusService(params).then(res=> {
            if(res.data.statusCode){
                notification.open({
                    message: 'Review Status',
                    description: `Review status change successfully to  ${review.status == 1 ? 'Inactive' : 'Active'}` 
                })
                getReviews();
            }
        })
    } 

    const handleDelete = review => {
        let params = {
            reviewId: review._id,
            status: '3'
        }

        changeReviewStatusService(params).then(res=> {
            if(res.data.statusCode){
                notification.open({
                    message: 'Review Status',
                    description: `Review delete successfully` 
                })
                getReviews();
            }
        })
    } 


    return (
        <div className="page_wrapper">
            {loader ? <MainLoader /> : '' }
            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Review Regulation</h4>
                </div>

                <div className="page_card_body">



                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">

                            <table className="table table-bordered table-striped">

                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Camp Name</span></th>
                                        <th><span className="t_text">Camp Programs</span></th>
                                        <th><span className="t_text">Name</span></th>
                                        <th><span className="t_text">Reviews</span></th>
                                        <th><span className="t_text">Rating</span></th>
                                        <th><span className="t_text">Action</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                {
                                    list.map((item, i) => (
                                        <tr key={item._id}>
                                            <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                            <td><span className="t_text">{item.campName}</span></td>
                                            <td><span className="t_text"><p className="last_dot">{item.programName}</p></span></td>
                                            <td><span className="t_text">{item.username}</span></td>
                                            <td><span className="t_text">{item.reviews}</span></td>
                                            <td><span className="t_text">{item.rating}</span></td>
                                            <td><span className="t_text">
                                                <i className="icon-delete" onClick={() => handleDelete(item)} />
                                                <div className="cm_swich_wrap">
                                                    <input onChange={() => handleChangeStatus(item)} checked={item.status == 1 ? true : false} type="checkbox" /><span />
                                                </div></span>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>

                        </div>

                        {
                            list.length ? <div className="mt-3">
                                            <Pagination
                                                activePage={page}
                                                itemsCountPerPage={15}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={3}
                                                onChange={page => setPage(page)}/>
                                        </div> : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                        }

                        

                    </div>


                </div>

            </div>


        </div>
    )
}

const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewRegulation);