import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setBreadcrumbAction } from '../../store/ui';
import { categoryModalAction, categoryDetailAction } from '../../store/category';
import { Link } from 'react-router-dom';
import { MainLoader } from '../reuse/SVG';


const Category = props => {
    const categoryId = props.match.params.id;
    let params = {
        icon: 'fire',
        links: [
            {name: 'Camps Category', path: '/category'},
            {name: 'category name', path: ''}
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params);
        props.categoryDetailAction(categoryId);
    }, []);


    let { icon, name, subCategory, bannerImage } = props.category;

    return (
        <div className="page_wrapper">
            {props.loader ? <MainLoader /> : '' }
            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Camps Category</h4>
                    <button onClick={() => props.categoryModalAction(1)} className="btn btn-primary">Edit Program Category</button>
                </div>

                <div className="page_card_body">
                    <div className="d-flex">
                        <div className="mr-5 mb-3">
                            <p><b>Category Banner Image</b></p>
                            <div className="cat_img_box cat_banner_img">
                                <img src={bannerImage} alt="category" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <p><b>Category Icon</b></p>
                            <div className="cat_img_box">
                                <img src={icon} alt="category" />
                            </div>
                        </div>
                    </div>
                    

                   
                    <p><b>Category Name :</b> {name}</p>

                    <div className="additional-type">
                        <p><b>Additional Type</b></p>
                        <ol>
                            {   subCategory ?
                                subCategory.map(item => <li key={item._id}>{item.name}</li>) : ''
                            }
                        </ol>
                    </div>
                </div>
            </div>

            <Link to='/category' className="btn btn-outline-primary mt-4 mb-3"><i className="icon-left-arrow" /> GO Back</Link>

        </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { category, loader } = state.category;
    return {
        category,
        loader
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    categoryModalAction: params => dispatch(categoryModalAction(params)),
    categoryDetailAction: params => dispatch(categoryDetailAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);