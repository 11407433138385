import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { loginAction } from '../../store/auth';
import { getStaticPage, updateStaticPage } from '../../services/pagesService';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MainLoader, MiniLoader } from '../reuse/SVG';

const SiteMaintenance = () => {
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [titleErr, setTitleErr] = useState('')
    const [contentErr, setContentErr] = useState('')
    const [isMaintenance, setIsMaintenance] = useState(false)
    const [modal, setModal] = useState(false)
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState({});
    const page = "maintenance";

    useEffect(() => {
        getPageData()
    }, [])

    const getPageData = () => {
        setLoader(true)
        let query = `?page=${page}`;

        getStaticPage(query).then(res => {
            if (res.data.statusCode);
            setList(res.data.responseData.result[0]);
            setIsMaintenance(res.data.responseData.result[0]?.isMaintenance);
            setContent(res.data.responseData.result[0]?.content)
            setTitle(res.data.responseData.result[0]?.title)
            setLoader(false)
        })
    }
    const handleValidate = () => {
        let validate = true;

        if (title === '') {
            setTitleErr('Title is required')
            validate = false
        }

        if (content === '') {
            setContentErr('Content is required')
            validate = false
        }

        return validate;
    }
    const handleSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setLoader(true);
            let params = {
                isMaintenance,
                page,
                title,
                content,
                status: 1,
                type: page
            };

            updateStaticPage(params).then(res => {
                setLoader(false);
                closeModal(true);
                getPageData();
            })
        }
    }

    const resetData = () => {
        setIsMaintenance(list?.isMaintenance ? list.isMaintenance : "");
        setContent(list?.content ? list.content : "")
        setTitle(list?.title ? list.title : "")
    }

    const closeModal = (preview=false) => {
        setModal(false)
        if (preview)return
        resetData();
    }
    return (
        <div className="page_wrapper">

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Maintenance</h4>
                    <button onClick={() => setModal(true)} className="btn btn-primary">EDIT</button>
                </div>
                {loader ?
                    <MainLoader />
                    :
                    <div className="page_card_body">
                        <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light"
                            style={{ maxHeight: "75vh" }}
                        >
                            <div className="text-center">
                                <div className="cm_logo_large"><img src={require(`../../assets/img/logo.svg`)} alt="" /></div>
                                <h1 className="display-4 mb-4" style={{ color: "#842014" }}>
                                    {title ? title : "We're Currently Down"}
                                </h1>
                                <p className="lead">
                                    {content ? content :
                                        "Our site is currently experiencing technical difficulties. Please bear with us while we work on getting it back up and running"
                                    }
                                </p>
                                <hr/>
                                <small className="text-muted">
                                    Try refreshing the page or check back later. We apologize for the inconvenience.
                                </small>
                                <br/>
                                <span className="btn btn-primary mt-4" onClick={getPageData}>
                                    Refresh Page
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <Modal
                show={modal}
                animation={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Edit Section</Modal.Title>
                    <span className="modal_close" onClick={() => closeModal()}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                    <div>
                        <div className="form-group">
                            <div className="d-flex justify-content-center align-items-center form-group">
                                <label >Maintenance</label>
                                <div className="ml-2 cm_swich_wrap">
                                    <input
                                        type="checkbox"
                                        checked={isMaintenance}
                                        onChange={() => setIsMaintenance(prev => !prev)} />
                                    <span />
                                </div>

                            </div>
                            <label>Title</label>
                            <div className="icon_input">
                                <input
                                    type="text"
                                    className={`form-control ${titleErr ? 'is-invalid' : ''}`}
                                    value={title}
                                    onChange={e => (setTitle(e.target.value), setTitleErr(''))} />

                                {titleErr ? <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip>{titleErr}</Tooltip>}>
                                    <span className="error_tootltip" />
                                </OverlayTrigger> : ''
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Description</label>
                            <div className="icon_input">
                                <textarea
                                    className={`form-control ${contentErr ? 'is-invalid' : ''}`}
                                    value={content}
                                    onChange={e => (setContent(e.target.value), setContentErr(''))} />

                                {contentErr ? <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip>{contentErr}</Tooltip>}>
                                    <span className="error_tootltip" />
                                </OverlayTrigger> : ''
                                }
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-5 mb-4">
                            <button onClick={() => closeModal()} className="btn btn-light mr-4 pl-5 pr-5">Reset</button>
                            <button onClick={() => closeModal(true)} className="btn btn-secondary mr-4 pl-5 pr-5">Preview</button>
                            <button onClick={handleSubmit} className="btn btn-primary pl-5 pr-5">Save</button>
                        </div>
                    </div>



                </Modal.Body>
            </Modal>
        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loading, error } = state.auth;
    return {
        loading,
        error
    };
}

const mapDispatchToProps = dispatch => ({
    loginAction: params => dispatch(loginAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteMaintenance);

