import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { Modal } from 'react-bootstrap';
import { notification } from 'antd';

import { setBreadcrumbAction } from '../../store/ui';
import { getAllPostService, chaangePostStatusService } from '../../services/feedService';
import { MainLoader, MiniLoader } from '../reuse/SVG';



const renderMedia = file => {
    let { url } = file;
    let type = url.slice(url.lastIndexOf('.') + 1, url.length);
    switch(type){
        case 'mp4':
            return <Fragment><i className="icon-play-button" /><video src={url} type={type} /></Fragment>;
        break;    

        case 'm4v':
            return <Fragment><i className="icon-play-button" /><video src={url} type={type} /></Fragment>;
        break;

        case 'webm':
            return <Fragment><i className="icon-play-button" /><video src={url} type={type} /></Fragment>;
        break;

        default:
            return <img src={url} />;
    }
}



const FeedRegulation = props => {
    const [loader, setLoader] = useState(true);
    const [miniLoader, setMiniLoader] = useState(true);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState('');
    const [viewPostModal, setViewPostModal] = useState(false);
    const [post, setPost] = useState('');


    let params = {
        icon: 'booking',
        links: [
            { name: 'Feed Regulation', path: '' }
        ]

    }

    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);

    const getFeeds = () => {
        let query = `?search=${search}&page=${page - 1}&limit=${15}`;

        getAllPostService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setCount(res.data.responseData.count);
            }
        })
    }


    useEffect(() => {
        getFeeds();
    }, [page])

    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getFeeds();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])



    const viewPost = data => {
        setViewPostModal(true);
        setPost(data);
    }

    const closeModal = () => {
        setViewPostModal(false);
        setPost('');
    }


    const changeStatus = (item, name) => {
        let params = ''
        if(name == 'delete'){
            params = {
                postId: item._id,
                status: 3 
            }
        }else {
            setMiniLoader({[item._id]: true});
            params = {
                postId: item._id,
                status: item.status == 1 ? 2 : 1 
            }
        }
        

        chaangePostStatusService(params).then(res => {
            setMiniLoader('');
            getFeeds();
            notification.open({
                message: 'Feed Status',
                description: name == 'delete' ? `Feed delete successfully` : `Feed status change successfully to  ${item.status == 1 ? 'Inactive' : 'Active'}`
            });
        }); 
    }


    return (
        <div className="page_wrapper">
            {loader ? <MainLoader /> : ''}
            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Feed Regulation</h4>
                </div>

                <div className="page_card_body">



                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">

                            <table className="table table-bordered table-striped">

                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Camp Name/User Name</span></th>
                                        <th><span className="t_text">Posted On</span></th>
                                        <th><span className="t_text">Admin</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        list.map((item, i) => (
                                            <tr key={item._id}>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * 15))}</span></td>
                                                <td><span className="t_text">{item.campName ? item.campName : item.user.name}</span></td>
                                                <td><span className="t_text">{moment(item.createdDate).format('MMMM Do YYYY, h:mm a')}</span></td>
                                                <td><span className="t_text">
                                                    <i className="icon-eye" onClick={() => viewPost(item)} />
                                                    <i className="icon-delete" onClick={() => changeStatus(item, 'delete')} />
                                                    <div className="cm_swich_wrap">
                                                        {miniLoader[item._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={item.status == 1 ? true : false} onChange={() => changeStatus(item, 'status')} /><span /></React.Fragment>}
                                                    </div></span></td>
                                            </tr>
                                        ))
                                    }


                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="mt-3">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={count}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
                        </div>





                </div>

            </div>



            <Modal show={viewPostModal} centered>
                <Modal.Header>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>


                    {post ? <div className="feed_card">
                        <div className="feed_head">
                            
                            { 
                                <img src={post.user.profileImage ? post.user.profileImage : require('../../assets/img/user.png')} />
                            }

                            {
                                <h3>{post.user.name}</h3>
                            }
                            <time>{moment.utc(post.createdDate).format('MMM DD')} at {moment(post.createdDate).format('h:mm a')}</time>
                        </div>

                        <div className="feed_content"><p>{post.caption}</p></div>

                        <div className="feed_media">
                            <div className={post.media.length > 2 ? 'more_then' : 'd-flex'}>
                                {post.media ? post.media.map((item, i) => <div className="feed_m_item" key={item._id}>{renderMedia(item)}</div>) : <img src={require('../../assets/img/slider-1.jpg')} />}
                              
                            </div>
                        </div>

                        <div className="feed_actions">

                            <div className="d-flex justify-content-between">
                                <p><i className="icon-logo-icon" /> {post.likeCount}</p>
                                <p>{post.commentCount} comments</p>
                            </div>


                        </div>

                    </div> : ''}
                    
                </Modal.Body>
            </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedRegulation);