import * as actionType from './actionsTypes';
import { unreadCountService } from '../services/userService';
import { resHandle } from '../helper';


const initialState = {
    search: '',
    activeTab: 'director',
    directorPageNo: 1,
    counselorPageNo: 1,
    userPageNo: 1,
    noneUserPageNo: 1,
    unreadCount: '',
    unreadMessage: ''
}


/************************************************************************************/
// User Reducer
/************************************************************************************/


const userReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.USER_SEARCH:
            return {
                ...state,
                search: action.payload,
                directorPageNo: 1,
                counselorPageNo: 1,
                userPageNo: 1,
                noneUserPageNo: 1,
            }


        case actionType.DIRECTOR_PAGE_NO:
            return {
                ...state,
                directorPageNo: action.payload
            }


        case actionType.COUNSELOR_PAGE_NO:
            return {
                ...state,
                counselorPageNo: action.payload
            }


        case actionType.USER_PAGE_NO:
            return {
                ...state,
                userPageNo: action.payload
            }


        case actionType.NON_USER_PAGE_NO:
            return {
                ...state,
                noneUserPageNo: action.payload
            }


        case actionType.USER_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            }


        case actionType.GET_UNREAD_COUNT:
            return {
                ...state,
                unreadCount: action?.payload?.count,
                unreadMessage: action?.payload?.unreadMessage
            }

        default: return state;
    }
}




/************************************************************************************/
// All User Actions
/************************************************************************************/


export const userSearchAction = payload => dispatch => dispatch({ type: actionType.USER_SEARCH, payload });

export const activeTabAction = payload => dispatch => dispatch({ type: actionType.USER_ACTIVE_TAB, payload });

export const directorPageNoAction = payload => dispatch => dispatch({ type: actionType.DIRECTOR_PAGE_NO, payload });

export const counselorPageNoAction = payload => dispatch => dispatch({ type: actionType.COUNSELOR_PAGE_NO, payload });

export const userPageNoAction = payload => dispatch => dispatch({ type: actionType.USER_PAGE_NO, payload });

export const noneUserPageNoAction = payload => dispatch => dispatch({ type: actionType.NON_USER_PAGE_NO, payload });


export const unreadCountAction = () => async dispatch => {
    try {
        let countData = await unreadCountService();
        let { status, data } = resHandle(countData);
        if(data){
            dispatch({ type: actionType.GET_UNREAD_COUNT, payload: data});
        }else{
            dispatch({ type: actionType.GET_UNREAD_COUNT, payload: ''});
        }
    } catch (error) {
        console.log(error, 'error')
    }
}


export default userReducer;