import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import { setBreadcrumbAction } from '../../store/ui';
import Advertisement from './Advertisement';
import ThirdPartyAdvertisement from './ThirdPartyAdvertisement';
import { advSearchAction } from '../../store/advertisement';


const Advertising = props => {
    const [activeTab, setActiveTab] = useState(1);
    let params = {
        icon: 'advertising',
        links: [
            { name: 'Advertising', path: '' }
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);
    return (
        <div className="page_wrapper">
             <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    value={props.search}
                    onChange={e => props.advSearchAction(e.target.value)}
                    className="form-control"
                    placeholder="Type here to search.." />
            </div>
            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Advertising</h4>
                </div>

                <div className="page_card_body">
                    <Tabs onSelect={e => setActiveTab(e)} className="" defaultActiveKey="1">
                        <Tab eventKey="1" title="Advertisement">
                            {activeTab == 1 ? <Advertisement /> : ''}
                        </Tab>
                        <Tab eventKey="2" title="3rd party Advertisement">
                            {activeTab == 2 ? <ThirdPartyAdvertisement /> : ''}
                        </Tab>
                    </Tabs>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { search } = state.advertisement;
    return {
        search,
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    advSearchAction: params => dispatch(advSearchAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Advertising);

