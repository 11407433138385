import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { notification } from 'antd';
import { CSVLink } from 'react-csv';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import { setBreadcrumbAction } from '../../store/ui';
import { campListAction, campStatusChangeAction, campPageChange } from '../../store/camps';
import { MiniLoader, Empty, MainLoader } from '../reuse/SVG';
import { displayPerPage } from '../../helper';
import {
    updateMediaService,
    uploadCampInvoiceService,
    campInvoiceListService,
    deleteCampInvoiceService
} from '../../services/campService';
import { PDFIcon } from '../reuse/SVG';


const titles = [
    'Camp Name',
    'Camp Code',
    'Number of programs',
    'Camp Phone',
    'Camp Email',
    'Camp Address',
    'No. Of Bookings YTD',
    'Year Revenue To date',
    'Accumulated Total For camper fees',
    'Membership Fee'
];




const Camps = props => {
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [miniLoader, setMiniLoader] = useState('');
    const [campsCSV, setCampsCSV] = useState([])
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [currentCampId, setCurrentCampId] = useState('');
    const [invoiceList, setInvoiceList] = useState([]);
    
    let params = {
        icon: 'camps',
        links: [
            {name: 'Providers', path: ''}
        ]

    }


    const campList = () => {
        let params = `?search=${search}&limit=${limit}&page=${props.page - 1}`;
        props.campListAction(params);
    }


    const handleStatusChange = (campId, status) => {
        setMiniLoader({[campId]: true});
        let params = {
            campId,
            status: status == 0 ? 1 : 0 
        }
        
        props.campStatusChangeAction(params).then(res => {
            setMiniLoader('');
            notification.open({
                message: 'Camp Status',
                description: `Camp status change successfully to  ${status == 0 ? 'Active' : 'Inactive'}`
            });
        }); 
    }



    useEffect(() => campList(), [limit, miniLoader, props.page]);


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            campList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    useEffect(() => {
        props.setBreadcrumbAction(params);
    }, []);



    useEffect(() => {
        if(props.camps){
        let tempCamps = props.camps.map(camp => [
            camp.campName,                                 
            camp.campCode,                                 
            camp.numberOfProgram,                                 
            camp.phone,                                 
            camp.email,                               
            camp.address,
            '0',                             
            '0',                             
            '0',                             
            '0',                             
        ])

        tempCamps.unshift(titles);
        setCampsCSV(tempCamps)}
    }, [props.camps])

    const handleFileChange = e => {
        let file = e.target.files[0];
        let params = new FormData();
                params.append('file', file);
            updateMediaService(params).then(res => {
                if(res.data.statusCode){
                    let obj = {
                        invoiceUrl: res.data.responseData.result.fileUrl,
                        campId: currentCampId,
                    }
                    uploadCampInvoiceService(obj).then(res => {
                        if(res.data.statusCode){
                            getInvoiceList();
                            notification.open({
                                message: 'Camp Invoice',
                                description: `Upload successfully.`
                            });
                        }
                    })
                }
           
        })
      }




    const getInvoiceList = () => {
        let query = `?campId=${currentCampId}&page=0&limit=100000`;
        campInvoiceListService(query).then(res => {
            if(res.data.statusCode){
                setInvoiceList(res.data.responseData.result);
            }
        })
    }

      useEffect(() => {
          if(currentCampId){
            getInvoiceList()
          }
      }, [currentCampId])

    

    const handleDeleteInvoice = id => {
        let temp = invoiceList.filter(i => i._id != id);
        setInvoiceList(temp);
        deleteCampInvoiceService(`?invoiceId=${id}`)
    }





    return(
        <div className="page_wrapper">

            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}

                <i className="icon-search" />
                <input type="text" onChange={e => setSearch(e.target.value)} className="form-control" placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Providers</h4>
                    <div className="btn_group">
                        <Link className="btn btn-primary" to="/camps/new"><i className="icon-plus" /> Add Provider</Link>
                    </div>
                </div>

                <div className="page_card_body">
                   

                    <div className="table_filters">
                        
                            
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                {/* <label>City/State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter City/State"
                                /> */}
                            </div>
                            <div className="col-sm-6 btn_group text-right">
                                <CSVLink className="btn btn-outline-primary" data={campsCSV}>Download as Excel</CSVLink> 
                                <button className="btn btn-primary ml-3" onClick={() => window.print()}><i className="icon-print" /> Print</button>
                            </div>
                        </div>

                    </div>



                    <div className="d-flex justify-content-between table_header">
                        <h4>Providers List</h4>
                        <div className="table_page_set">
                            Results displayed per page: 
                            <select onChange={e => (setLimit(parseInt(e.target.value), props.campPageChange(1)))} value={limit}>
                                {displayPerPage.map(item => <option key={item} value={item}>{item}</option>)}
                            </select>
                        </div>
                    </div>







                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">
                        
                            <table className="table table-bordered table-striped no_t_texts" id="section-to-print">

                                <thead>
                                    <tr>
                                        <th className="hide_on_print"><span className="t_text">S.No</span></th>
                                        <th className="hide_on_print"><span className="t_text">Actions</span></th>
                                        <th><span className="t_text">Name</span></th>
                                        <th><span className="t_text">Org Code</span></th>
                                        <th><span className="t_text">Total Program</span></th>
                                        <th><span className="t_text">Phone No.</span></th>
                                        <th><span className="t_text">Email</span></th>
                                        <th><span className="t_text">City/State</span></th>
                                        <th><span className="t_text">No. Of Bookings YTD</span></th>
                                        <th><span className="t_text">Year Revenue To date</span></th>
                                        <th><span className="t_text">Accumulated Total For camper fees</span></th>
                                        <th><span className="t_text">Membership Fee</span></th>
                                        
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        props.camps.map((camp, i) => (
                                            <tr key={camp._id}>
                                                <td><span className="t_text">{((i + 1) + ((props.page - 1) * limit))}</span></td>
                                                <td className="hide_on_print">
                                                    <span className="t_text">
                                                        
                                                        <i title="Copy Camp Id" className="icon-copy" onClick={() => navigator.clipboard.writeText(camp._id)} />
                                                        <i title="Invoice List" className="icon-payments" onClick={() => (setShowInvoiceModal(true), setCurrentCampId(camp._id))} />
                                                        <Link title="View Camp" to={`/camps/view-${camp._id}`} style={{color: '#000'}}><i className="icon-eye" /></Link>
                                                        <Link title="Edit Camp"  to={`/camps/edit-${camp._id}`} style={{color: '#842014'}}><i className="icon-pancil" /></Link>
                                                        <div className="cm_swich_wrap">
                                                            {miniLoader[camp._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={camp.status} onChange={() => handleStatusChange(camp._id, camp.status)} /><span /></React.Fragment>}
                                                        </div>
                                                    </span>
                                                </td>
                                                <td><span className="t_text">{camp.campName}</span></td>
                                                <td><span className="t_text">{camp.campCode}</span></td>
                                                <td><span className="t_text">{camp.numberOfProgram}</span></td>
                                                <td><span className="t_text">{formatPhoneNumberIntl(camp.phone)}</span></td>
                                                <td><span className="t_text">{camp.email}</span></td>
                                                <td><span className="t_text">{camp.address}</span></td>
                                                <td><span className="t_text">{camp._id ? '0' : ''}</span></td>
                                                <td><span className="t_text">{camp._id ? '0' : ''}</span></td>
                                                <td><span className="t_text">{camp._id ? '0' : ''}</span></td>
                                                <td><span className="t_text">{camp._id ? '0' : ''}</span></td>
                                                
                                            </tr>
                                        ))
                                    }
                                    
                                   
                                </tbody>
                            </table>

                        </div>

                        {props.camps.length ? '' : <div className="cm_empty mt-3">
                                                            <Empty />
                                                            <p>No Data</p>
                                                        </div> }
                    </div>





                </div>

                    <Pagination
                        activePage={props.page}
                        itemsCountPerPage={limit}
                        totalItemsCount={props.count}
                        pageRangeDisplayed={3}
                        onChange={page => props.campPageChange(page)}/>

            </div>







            <Modal
                size="lg"
                show={showInvoiceModal}
                animation={false}
                centered
            >
                <Modal.Header>
                <Modal.Title>Invoice List</Modal.Title>
                <span className="modal_close" onClick={() => (setShowInvoiceModal(false), setInvoiceList([]), setCurrentCampId(''))}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                <div className="text-center">
                    <div className="icon_upload_wrapper">
                    <input accept=".pdf" type="file" value="" onChange={handleFileChange} />
                    <div>
                        <i className="icon-button" />
                        <p>Upload Invoice</p>
                    </div>
                    </div>
                    
                    <ul className="mt-4 mb-4 invoice_list">
                        {    
                            invoiceList.map((item, i) => <li key={item._id}><PDFIcon /> &nbsp; {moment(item.created).format('MM/DD/YYYY, h:mm:ss a')} <a target="_blank" href={item.invoiceUrl} className="btn btn-primary btn-sm">View</a> <i className="ml-4 icon-delete" onClick={() => handleDeleteInvoice(item._id)} /> </li>)
                        }
                    </ul>
                </div>

                

                </Modal.Body>
            </Modal>

            


        </div>
    )
}


const mapStateToProps = state => {
    let { camps, loader, count, page } = state.camp;
    return {
        camps,
        loader,
        count,
        page
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    campListAction: params => dispatch(campListAction(params)),
    campStatusChangeAction: params => dispatch(campStatusChangeAction(params)),
    campPageChange: params => dispatch(campPageChange(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Camps);