
import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty, notification } from 'antd';

import { specialDealsModalAction, specialDealListAction, dealViewModalAction } from '../../store/offer';
import { specialDealStatusChange, specialDealDelete } from '../../services/offerService';
import { MainLoader, MiniLoader } from '../reuse/SVG';
import { dateFormat } from '../../helper';
import ConfirmModal from '../modals/ConfirmModal';


const SpecialDeals = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');


    const specialDealList = () => {
        let params = `?limit=${limit}&page=${page - 1}&search=${search}`;
        props.specialDealListAction(params)
    };


    const changeStatus = deal => {
        setMiniLoader({[deal._id]: true});

        let params = {
            specialdealId: deal._id,
            status: deal.status == 0 ? '1' : '0' 
        }

        specialDealStatusChange(params).then(status => {
            setMiniLoader('');
            specialDealList();
            notification.open({
                message: 'Special Deal Status',
                description: `Special deal status change successfully to  ${deal.status == 0 ? 'Active' : 'Inactive'}`
            });
        }); 
    }



    const handleConfirmModal = currentId => {
        setCurrentId(currentId);
        setConfirmModal(!confirmModal);
    }


    const handleDelete = () => {
        let params = { specialdealId: currentId }
        specialDealDelete(params).then(status => {
            setCurrentId('');
            setConfirmModal(false);
            specialDealList();
            
        }); 
    }


    useEffect(() => {
        specialDealList();
    }, [page]);


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            specialDealList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    return (
        <Fragment>
            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}
                <i className="icon-search" />
                <input
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    className="form-control"
                    placeholder="Type here to search.." />
            </div>


        <div className="child_el">
        <div className="btn_wrapper">
        <button onClick={ props.specialDealsModalAction } className="btn btn-primary">Add Special Deals</button>
    </div>
    <div className="table_responsive_wrap mt-4 text-center">
    <div className="table-responsive">
    
        <table className="table table-bordered table-striped no_t_texts">

            <thead>
                <tr>
                    <th><span className="t_text">S.No</span></th>
                    <th><span className="t_text">Special Deal Title</span></th>
                    <th><span className="t_text">Program Name</span></th>
                    <th><span className="t_text">Type</span></th>
                    <th><span className="t_text">New Price</span></th>
                    <th><span className="t_text">Expiray Date</span></th>
                    <th><span className="t_text">Action</span></th>
                </tr>
            </thead>

            <tbody>
                {
                    props.specialDealList.map((deal, i) => (
                        <tr key={deal._id}>
                            <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                            <td><span className="t_text">{deal.title}</span></td>
                            <td><span className="t_text">{deal.programname}</span></td>
                            <td><span className="t_text">{deal.type ? 'Amount' : 'Percentage'}</span></td>
                            <td><span className="t_text">{deal.type ? '$' : ''}{deal.price}{deal.type ? '' : '%'}</span></td>
                            <td><span className="t_text">{dateFormat(deal.validTo)}</span></td>
                            <td><span className="t_text">
                                    <i className="icon-eye" onClick={() => props.dealViewModalAction(deal)} />
                                    <i className="icon-delete" onClick={() => handleConfirmModal(deal._id)} />
                                    <div className="cm_swich_wrap">
                                        {miniLoader[deal._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={deal.status} onChange={() => changeStatus(deal)} /><span /></React.Fragment>}
                                    </div>
                                </span>
                            </td>        
                        </tr>
                    ))
                }
                
               
            </tbody>
        </table>

    </div>
                {
                    props.specialDealList.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                }

            </div>
            </div>

            {
                props.specialDealList.length ? <div className="mt-4">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={props.specialDealCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
                </div> : ''
            }



        <ConfirmModal
            show={confirmModal}
            hideModal={() => setConfirmModal(false)}
            msg="Are you sure you want to delete?"
            action={handleDelete} />

</Fragment>
    )
            }
/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { toggleSpecialDealsModal, specialDealList, specialDealCount } = state.offer;
    return {
        toggleSpecialDealsModal,
        specialDealList,
        specialDealCount
        
    };
}
  
const mapDispatchToProps = dispatch => ({
    specialDealsModalAction: () => dispatch(specialDealsModalAction()),
    specialDealListAction: params => dispatch(specialDealListAction(params)),
    dealViewModalAction: params => dispatch(dealViewModalAction(params)),



});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDeals);