import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { setBreadcrumbAction } from '../../store/ui';
import { userSearchAction, activeTabAction } from '../../store/user';

import DirectorList from './DirectorList'
import CounselorList from './CounselorList';
import UserList from './UserList';
import NoneUserList from './NoneUserList';
import { isValidEmail, resHandle } from '../../helper';
import { fastSignupService } from '../../services/userService';



const User = props => {
    const history = useHistory();
    const [userSignupModal, setUserSignupModal] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [resErr, setResErr] = useState('');
    const [loader, setLoader] = useState(false);



    let params = {
        icon: 'users',
        links: [{ name: 'User', path: ''}]
    }


    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    const handleClose = () => {
        setUserSignupModal(false)
    }


    
    const handleValidate = () => {
        let validate = true;

        if(name == ''){
            setNameErr('Name is required');
            validate = false
        }

        if(email == ''){
            setEmailErr('Email is required');
            validate = false
        }else if(!isValidEmail(email)){
            setEmailErr('Email not valid');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                deviceID: 'campsez',
                deviceToken: 'campsez',
                deviceTypeID: '3',
                UserType: 1,
                name,
                email,
                password:'CampsEZ@123'
            }
            setLoader(true);
            fastSignupService(params).then(res => {
                let { status, data } = resHandle(res);
                setLoader(false);
                if(status){
                    if(data.statusCode == 12){
                        setResErr('User email already exists')
                    }else{
                        history.push(`/user/detail/${data.userId}`);
                        notification.open({
                            message: 'User',
                            description: `User created successfully`
                        });

                    }
                
                }else {
                    setResErr(data)
                }

    

            })
            
        }
    }


    return (
        <div className="page_wrapper">

            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    value={props.search}
                    className="form-control"
                    placeholder="Type here to search.."
                    onChange={e => props.userSearchAction(e.target.value)} />
            </div>

            <div className="page_card">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>User</h4>
                </div>

                <div className="page_card_body">

                    {props.activeTab == 'user' ? <button onClick={() => setUserSignupModal(true)} className='btn btn-primary cm_add_user_btn'>Add User</button> : ''}

                    <Tabs onSelect={e => props.activeTabAction(e)} defaultActiveKey={props.activeTab}>
                        <Tab eventKey="director" title="Director List">{props.activeTab === 'director' ? <DirectorList /> : ''}</Tab>
                        <Tab eventKey="couselor" title="Personnel List">{props.activeTab === 'couselor' ? <CounselorList /> : ''}</Tab>
                        <Tab eventKey="user" title="User List">{props.activeTab === 'user' ? <UserList /> : ''}</Tab>
                        <Tab eventKey="nonUser" title="Non User List">{props.activeTab === 'nonUser' ? <NoneUserList /> : ''}</Tab>
                    </Tabs>

                </div>

            </div>



            <Modal show={userSignupModal} centered>
                <Modal.Header>
                <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <h3 className="text-center mb-4">Create New User</h3>

                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="First Name"
                                className={`form-control ${nameErr ? 'is-invalid' : ''}`}
                                name="name"
                                value={name}
                                onChange={e => (setName(e.target.value), setNameErr(''), setResErr(''))} />
                            
                            {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        </div>

                        <div className="form-group">
                            <input type="email" name="email" value="" style={{display: 'none'}} />
                            <input
                                type="email"
                                placeholder="Email"
                                className={`form-control ${emailErr ? 'is-invalid' : ''}`}
                                name="email"
                                autocomplete="nope"
                                value={email}
                                onChange={e => (setEmail(e.target.value), setEmailErr(''), setResErr(''))} />
                            
                            {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>

                        {resErr ? <p className="error_msg">{resErr}</p> : ''}

                        <button
                            type="submit"
                            disabled={loader}
                            className={`btn btn-primary rounded-0 btn-block mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Sign Up'}</button>
                        </form>
                </Modal.Body>
            </Modal>


        </div>
    )
}


const mapStateToProps = state => {
    let { search, activeTab } = state.user;
    return {
        search,
        activeTab
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    userSearchAction: params => dispatch(userSearchAction(params)),
    activeTabAction: params => dispatch(activeTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
