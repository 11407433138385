import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import { setBreadcrumbAction } from '../../store/ui';
import { unreadCountAction } from '../../store/user';
import { supportSearchAction, activeTabAction } from '../../store/support';
import AskQuestion from './AskQuestion';
import HelpSupport from './HelpSupport';
import RateExperience from './RateExperience';
import NewCampRequests from './NewCampRequests';
import NewPartnerRequests from './NewPartnerRequests';
import ContactUsUsers from './ContactUsUsers';

const Support = props => {
    let params = {
        icon: 'question',
        links: [
            { name: 'Support', path: '' }
        ]

    }

    useEffect(() => {
        props.setBreadcrumbAction(params);
        props.unreadCountAction();
    }, []);

    return (
        <div className="page_wrapper">

            <div className="search_wraper">
                <i className="icon-search" />
                <input
                    type="text"
                    value={props.search}
                    className="form-control"
                    placeholder="Type here to search.."
                    onChange={e => props.supportSearchAction(e.target.value)} />
            </div>


            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Support</h4>
                </div>

                <div className="page_card_body">
                    
                    <Tabs onSelect={e => props.activeTabAction(e)} defaultActiveKey={props.activeTab}>
                        <Tab eventKey="1" title="Ask a Question">{props.activeTab == '1' ? <AskQuestion /> : ''}</Tab>
                        <Tab eventKey="2" title="Help & Support">{props.activeTab == '2' ? <HelpSupport /> : ''}</Tab>
                        <Tab eventKey="3" title="Rate Experience">{props.activeTab == '3' ? <RateExperience /> : ''}</Tab>
                        <Tab eventKey="4" title="New Camp Requests">{props.activeTab == '4' ? <NewCampRequests /> : ''}</Tab>
                        <Tab eventKey="5" title="New Partner Requests">{props.activeTab == '5' ? <NewPartnerRequests /> : ''}</Tab>
                        <Tab eventKey="6" title="Contact Us Users">{props.activeTab == '6' ? <ContactUsUsers /> : ''}</Tab>
                    </Tabs>
                  
                </div>

            </div>


        </div>
    )
}



const mapStateToProps = state => {
    let { search, activeTab } = state.support;
    return {
        search,
        activeTab
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    activeTabAction: params => dispatch(activeTabAction(params)),
    supportSearchAction: params => dispatch(supportSearchAction(params)),
    unreadCountAction: () => dispatch(unreadCountAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Support);

