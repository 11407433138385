import axios from "axios";

const ApiInstance = axios.create();

ApiInstance.interceptors.response.use(res => {
    if(res?.data?.error?.errorCode == 22 || res?.data?.error?.errorCode == 52){
      localStorage.clear();
      window.location.href = '/';
    }else {
        return res
    }
  }
);

export default ApiInstance;