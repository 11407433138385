import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import moment from 'moment';

export const resHandle = res => {
    let status = res.data.statusCode;
    let data;
    if (status) {
        data = res.data.responseData
    } else {
        data = res.data.error.responseMessage
    }
    return { status, data }
}


export const resHandle2 = res => {
    let status = res.data.statusCode;
    let data;
    let count;
    if (status) {
        data = res.data.responseData.result;
        count = res.data.responseData.count;
    } else {
        data = res.data.error.responseMessage
    }
    return { status, data, count }
}

const pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
export const isValidEmail = e => new RegExp(pattern).test(e);

const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
export const isValidPassword = e => new RegExp(passwordPattern).test(e);

export const removeHtmlTags = e => {
    return e.replace(/(<([^>]+)>)/gi, " ");
}

export const lineChartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
        xAxes: [{ display: false, }], yAxes: [{ display: false, }],
    }, legend: {
        display: false,
    },
}


export const addBr = e => e ? e.replace(/\r?\n/g, '<br />') : '';
export const getBr = e => e.replace(/<br\s*\/?>/mg, "\n");
export const getHtml = e => parse(`<span>${e}</span>`);


export const dateFormat = val => {
    let d = new Date(val)
    return (d.getUTCMonth() + 1) + '/' + d.getUTCDate() + '/' + d.getUTCFullYear()
}

export const dateFormat2 = val => {
    let d = new Date(val)
    return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear()
}


export const removeTag = data => data.replace(/(<([^>]+)>)/ig, '');


export const getBool = val => {
    switch (val) {
        case 0: return 'No'; break;
        case 1: return 'Yes'; break;
    }
}

export const getBool2 = val => {
    switch (val) {
        case 2: return 'No'; break;
        case 1: return 'Yes'; break;
    }
}

export const groupCodeGenerate = () => {
    let groupCode = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 7; i++) {
        groupCode += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return groupCode;
}


export const gendersdisplay = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Co-Ed' }
]


export const getgendersdisplay = val => {
    if (val) {
        return gendersdisplay.filter(item => item.value == val)[0].label
    }
};



export const displayPerPage = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100];




export const programTypes = [
    { value: 1, label: 'Morning Half Day' },
    { value: 2, label: 'Afternoon Half Day' },
    { value: 3, label: 'Full Day' },
    { value: 4, label: 'Full Day Extended' },
    { value: 5, label: 'Overnight' }
]


// export const getProgramType = val => programTypes.filter(item => item.value == val)[0].label;

export const getProgramType = val => {
    if (val == null || val == 'undefined' || val == '') {
        return 'All'
    } else {
        return programTypes.filter(item => item.value == val)[0].label
    }

};

export const _calculateAge = (birthday) => { // birthday is a date
    let ageDifMs = Date.now() - birthday;
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}


export const genders = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Co-Ed' }
]

export const genders2 = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' }
]

export const experiences = [
    { value: 1, label: 'N/A' },
    { value: 2, label: 'Novice' },
    { value: 3, label: 'Beginner' },
    { value: 4, label: 'Intermediate' },
    { value: 5, label: 'Advanced' },
    { value: 6, label: 'Expert' }
]

export const getExperience = e => experiences.filter(item => item.value == e)[0].label;
export const stateAbbreviations = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];
export const shortsAndTshirtSizes = ["Youth Small", "Youth Medium", "Youth Large", "Adult Small", "Adult Medium", "Adult Large", "Adult X-Large", "Adult XX-Large"]

export const medicalList = ['None', 'ADHD', 'Amputation', 'Anxiety/Depression', 'Asperger', 'Asthma/Respiratory Ailment', 'Autism', 'Behavior/Emotional Problems', 'Blood Disorder', 'Brain Injury', 'Burn', 'Cancer', 'Celliac Disease', 'Cerebral Palsy', 'Craniofacial', 'Crohn’s Disease / Colitis', 'Cystic Fibrosis', 'Diabetes', 'Down Syndrome', 'Epilepsy', 'Hearing Impairment/Deaf', 'Heart Defects', 'Hemophilia', 'HIV/Aids', 'Intellectual Disability', 'Leukemia', 'Mobility Limitation', 'Muscular Dystrophy', 'Obsessive Compulsive Disorder', 'Organ Transplant', 'Post-Traumatic Stress', 'Prader-Will Syndrome', 'Sickle Cell', 'Skin Disorder', 'Speech/Communication Impairment', 'Spina Bifida', 'Substance Abuse', 'Tourette Syndrome', 'Visual Impairment / Blind', 'Weight Loss', 'Other']

export const allergyList = ['None', `Cow's Milk`, 'Eggs', 'Tree Nuts', 'Peanuts', 'Shellfish', 'Wheat', 'Soy', 'Fish', 'Gluten', 'Other'];

export const trueFalse = [
    { value: '2', label: 'No' },
    { value: '1', label: 'Yes' },
]

export const trueFalse2 = [
    { value: '0', label: 'No' },
    { value: '1', label: 'Yes' },
]

export const facilies = [
    { value: '1', label: 'Outdoor' },
    { value: '2', label: 'Indoor' },
    { value: '3', label: 'Both indoor and outdoor facilities' },
]

export const mealsArr = ['breakfast', 'lunch', 'dinner']


export const gradeArr = [
    { count: 1, value: 'Pre-school' },
    { count: 2, value: 'Kindergarten' },
    { count: 3, value: '1st' },
    { count: 4, value: '2nd' },
    { count: 5, value: '3rd' },
    { count: 6, value: '4th' },
    { count: 7, value: '5th' },
    { count: 8, value: '6th' },
    { count: 9, value: '7th' },
    { count: 10, value: '8th' },
    { count: 11, value: '9th' },
    { count: 12, value: '10th' },
    { count: 13, value: '11th' },
    { count: 14, value: '12th' },
    { count: 15, value: 'Camper is older than 18' }
]


export const gradeArr2 = [
    'Pre-school',
    'Kindergarten',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    'Camper is older than 18'
];




export const removeTime = t => {
    let time = new Date(t);
    time.setUTCDate(time.getDate());
    time.setUTCHours(0);
    time.setUTCMinutes(0);
    time.setUTCSeconds(0);
    time.setUTCMilliseconds(0);
    return time.getTime();
}


export const removeTime2 = t => {
    let time = new Date(t);
    time.setUTCHours(0);
    time.setUTCMinutes(0);
    time.setUTCSeconds(0);
    time.setUTCMilliseconds(0);
    return time.getTime();
}



export const gerFavpropgramdate = (startDate, endDate) => {
    let sDate = new Date(parseInt(startDate));
    let eDate = new Date(parseInt(endDate));
    let monthnamestart = moment.utc(sDate).format('MMM')
    let monthnameend = moment.utc(eDate).format('MMM')
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let starthdatenumber = moment.utc(sDate).format('DD')
    let enddatenumber = moment.utc(eDate).format('DD')

    if (sDate.getFullYear() == eDate.getFullYear()) {
        if (monthnamestart == monthnameend) {
            return `${monthnamestart} ${starthdatenumber}-${enddatenumber}, ${sDate.getFullYear()} (${days[moment.utc(sDate).day()]}-${days[moment.utc(eDate).day()]})`;
        }
        else if (sDate.getFullYear() == eDate.getFullYear()) {
            return `${monthnamestart} ${starthdatenumber} - ${monthnameend} ${enddatenumber}, ${sDate.getFullYear()} (${days[moment.utc(sDate).day()]}-${days[moment.utc(eDate).day()]})`;
        }

        else {
            return `${monthnamestart} ${starthdatenumber}, ${sDate.getFullYear()} - ${monthnameend} ${enddatenumber}, ${eDate.getFullYear()} (${days[moment.utc(sDate).day()]}-${days[moment.utc(eDate).day()]})`;
        }
    } else {
        if (monthnamestart == monthnameend) {
            return `${monthnamestart} ${starthdatenumber}-${enddatenumber}, ${sDate.getFullYear()}`;
        }
        else if (sDate.getFullYear() == eDate.getFullYear()) {
            return `${monthnamestart} ${starthdatenumber} - ${monthnameend} ${enddatenumber}, ${sDate.getFullYear()}`;
        }

        else {
            return `${monthnamestart} ${starthdatenumber}, ${sDate.getFullYear()} - ${monthnameend} ${enddatenumber}, ${eDate.getFullYear()}`;
        }
    }




}



export const filterSingle = (a, b) => a.length && (a.filter(i => i._id == b).length ? a.filter(i => i._id == b)[0].name : 'no data');

export const filterMulti = (a, b) => a.length ? a.filter(i => b.find(e => e == i._id)).map(item => <Fragment key={item._id}>{item.name}<span>,</span> </Fragment>) : 'no data';

export const filterMulti2 = (a, b) => a.length ? a.filter(i => b.find(e => e == i._id)).map(item => item.name) : 'no data';


export const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                return;
            }
            blob.name = fileName;
            resolve(blob);
        }, 'image/png');
    });
}

export const faqHeadings = [
    'Refunds and cancelation on programs and activities',
    'View your booking information',
    'Registration Confirmation',
    'Rewards Points',
    'Discounts and Coupons',
    'Authorized Pickup',
    'Auto Scheduler',
    'Group Codes',
    'List your Program',
    'Miscellaneous',
]



export const getSignature = e => {
    switch (e) {
        case 1:
            return 'Mother';
            break;
        case 2:
            return 'Father';
            break;
        case 3:
            return 'Other';
            break;
        default:
            return '18 yrs or older and signing for myself';
    }
}

// export const getCamperAge = dob => {
//     var today = new Date();
//     var birthDate = new Date(dob);
//     var age = today.getFullYear() - birthDate.getFullYear();
//     var m = today.getMonth() - birthDate.getMonth();
//     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//         age--;
//     }
//     return age;
// }




export const schoolNameData = [
    { name: 'Baker City', district: 'Baker' },
    { name: 'Baker Middle School', district: 'Baker' },
    { name: 'Brooklyn Primary School', district: 'Baker' },
    { name: 'South Baker Intermediate', district: 'Baker' },
    { name: 'Fir Grove Elementary', district: 'Beaverton' },
    { name: 'Highland Park', district: 'Beaverton' },
    { name: 'Centennial Middle School', district: 'Centennial' },
    { name: 'Parklane Elementary', district: 'Centennial' },
    { name: 'Patrick Lynch ES', district: 'Centennial' },
    { name: 'Pleasant Valley', district: 'Centennial' },
    { name: 'Powell Butte', district: 'Centennial' },
    { name: 'Arbor Glen', district: 'Community Club' },
    { name: 'Bellrose Station Apt', district: 'Community Club' },
    { name: 'Reedway Place', district: 'Community Club' },
    { name: 'Rockwood/Rosewood', district: 'Community Club' },
    { name: 'Wood Village', district: 'Community Club' },
    { name: 'Woodie Gutherie', district: 'Community Club' },
    { name: 'Ron Russel Middle School', district: 'David Douglas' },
    { name: 'Image', district: 'Evergreen PS' },
    { name: 'Orchards', district: 'Evergreen PS' },
    { name: 'Silver Star', district: 'Evergreen PS' },
    { name: 'Tom McCall Upper Elementary', district: 'Forest Grove' },
    { name: 'Neil Armstrong Middle', district: 'Forest Grove' },
    { name: 'Allen Dale Elementary', district: 'Grants Pass' },
    { name: 'Lincoln Elementary School', district: 'Grants Pass' },
    { name: 'Dexter McCarty Elementary', district: 'Gresham/Barlow' },
    { name: 'North Gresham', district: 'Gresham/Barlow' },
    { name: 'Brookwood Elementary', district: 'HBSD' },
    { name: 'Butternut Creek', district: 'HBSD' },
    { name: 'Eastwood Elementary', district: 'HBSD' },
    { name: 'Free Orcahrds', district: 'HBSD' },
    { name: 'Indian Hills Elementary', district: 'HBSD' },
    { name: 'Lincoln Street', district: 'HBSD' },
    { name: 'McKinney Elementary', district: 'HBSD' },
    { name: 'Minter Bridge', district: 'HBSD' },
    { name: 'Mooberry Elementary', district: 'HBSD' },
    { name: 'Rosedale Elementary', district: 'HBSD' },
    { name: 'South Meadows Middle School', district: 'HBSD' },
    { name: 'Tobias Elementary', district: 'HBSD' },
    { name: 'W.L. Henry', district: 'HBSD' },
    { name: 'Witchhazel', district: 'HBSD' },
    { name: 'Armand Larive Middle ', district: 'Hermiston' },
    { name: 'Hermiston High School', district: 'Hermiston' },
    { name: 'Sandstone Middle', district: 'Hermiston' },
    { name: 'Jefferson', district: 'Jefferson SD' },
    { name: 'Neah-Kah-Ni Middle School', district: 'Neah-Kah-Ni' },
    { name: 'Giribaldi Grade School', district: 'Neah-Kah-Ni' },
    { name: 'Neah-Kah-Ni High School', district: 'Neah-Kah-Ni' },
    { name: 'Nehalem Elementary', district: 'Neah-Kah-Ni' },
    { name: 'North Marion', district: 'North Marion SD' },
    { name: 'Metro Montessori', district: 'Other?' },
    { name: 'Parkrose Middle', district: 'Parkrose' },
    { name: 'Beach K-8', district: 'Portland/PPS' },
    { name: 'Bridger K-8', district: 'Portland/PPS' },
    { name: 'Chief Joseph Elementary', district: 'Portland/PPS' },
    { name: 'Faubion K-8', district: 'Portland/PPS' },
    { name: 'Forest Park $', district: 'Portland/PPS' },
    { name: 'George Middle School', district: 'Portland/PPS' },
    { name: 'Harriet Tubman Middle School', district: 'Portland/PPS' },
    { name: 'Harrison Park K-8', district: 'Portland/PPS' },
    { name: 'Harvey Scott K-8', district: 'Portland/PPS' },
    { name: 'James John Elementary', district: 'Portland/PPS' },
    { name: 'Kellog Middle School', district: 'Portland/PPS' },
    { name: 'Kelly Elementary', district: 'Portland/PPS' },
    { name: 'Lane Middle School', district: 'Portland/PPS' },
    { name: 'Lent K-8', district: 'Portland/PPS' },
    { name: 'Martin Luther King, Jr. K-8+', district: 'Portland/PPS' },
    { name: 'Ockley Green Middle School', district: 'Portland/PPS' },
    { name: 'OLA', district: 'Portland/PPS' },
    { name: 'Rigler Elementary', district: 'Portland/PPS' },
    { name: 'Rosa Parks K-8', district: 'Portland/PPS' },
    { name: 'Roseway Heights Middle School', district: 'Portland/PPS' },
    { name: 'Sabin PK-8', district: 'Portland/PPS' },
    { name: 'Sitton Elementary', district: 'Portland/PPS' },
    { name: 'Vernon PK-8', district: 'Portland/PPS' },
    { name: 'Vestal K-8', district: 'Portland/PPS' },
    { name: 'Whitman Elementary', district: 'Portland/PPS' },
    { name: 'Woodlawn Elementary', district: 'Portland/PPS' },
    { name: 'Elton Gregory Middle School', district: 'Redmond' },
    { name: 'John Tuck Elementary', district: 'Redmond' },
    { name: 'M.A. Lynch Elementary', district: 'Redmond' },
    { name: 'Tom McCall Elementary', district: 'Redmond' },
    { name: 'Davis Elementary', district: 'Reynolds SD' },
    { name: 'Auburn Elementary', district: 'Salem-Keizer' },
    { name: 'Bush', district: 'Salem-Keizer' },
    { name: 'Candalaria', district: 'Salem-Keizer' },
    { name: 'Cesar E. Chavez', district: 'Salem-Keizer' },
    { name: 'Chapman Hill Elementary', district: 'Salem-Keizer' },
    { name: 'Cummings', district: 'Salem-Keizer' },
    { name: 'Four Corners Elementary', district: 'Salem-Keizer' },
    { name: 'Harritt Elementary', district: 'Salem-Keizer' },
    { name: 'Kalapuya', district: 'Salem-Keizer' },
    { name: 'Keizer', district: 'Salem-Keizer' },
    { name: 'Lamb Elementary', district: 'Salem-Keizer' },
    { name: 'Morningside', district: 'Salem-Keizer' },
    { name: 'Myers', district: 'Salem-Keizer' },
    { name: 'Swegle', district: 'Salem-Keizer' },
    { name: 'Weddle', district: 'Salem-Keizer' },
    { name: 'Wright Elementary', district: 'Salem-Keizer' },
    { name: 'Tillamook High School', district: 'Tillamook' },
    { name: 'Tillamook Junior High', district: 'Tillamook' },
    { name: 'Trask River High', district: 'Tillamook' },
    { name: 'Fruit Valley', district: 'Vancouver PS' },
    { name: 'Marshall Elementary', district: 'Vancouver PS' },
    { name: 'Minnehaha', district: 'Vancouver PS' },
    { name: 'Ogden Elementary', district: 'Vancouver PS' },
    { name: 'Sacajawea', district: 'Vancouver PS' },
    { name: 'Truman Elementary', district: 'Vancouver PS' },
    { name: 'Walnut Grove', district: 'Vancouver PS' },
]


export const colorsList = [
    'aqua',
    'black',
    'blue',
    'fuchsia',
    'gray',
    'green',
    'lime',
    'maroon',
    'navy',
    'olive',
    'orange',
    'purple',
    'red',
    'silver',
    'teal',
    'white',
    'yellow'
];



export const calMultiDatesPrices = dates => {
    let _price = 0;
    for (let i = 0; i < dates.length; i++) {
        _price += dates[i].standardPrice
    }
    return _price;
}

export const showAvailableSlots = (program) => {
    let slots = [];
    if (program.additionalDates.length > 0) {
        program.additionalDates.map(date => {
            if (date.startDate !== undefined) {
                slots.push(` ${formatDate(date.startDate)} - ${formatDate(date.endDate)} (${date.slots})`);
            }
        });
    }
    if (slots.length > 0)
        return slots;
    return [program.availableSlots]
}


export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}
export const  objectValuesToString = (obj) => {
    return Object.values(obj).join(", ");
  }