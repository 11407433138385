import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import Coupan from './Coupan'
import Gift from './Gift';
import SpecialDeals from './SpecialDeals';

import { setBreadcrumbAction } from '../../store/ui';
import { activeTabAction } from '../../store/offer';




const Offers = props => {
    let params = {
        icon: 'offers',
        links: [
            { name: 'Additional Offers', path: '' }
        ]

    }

    useEffect(() => {
        props.setBreadcrumbAction(params);
    }, []);
    return (
        <div className="page_wrapper">

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Additional Offers</h4>
                </div>

                <div className="page_card_body position-static">
                    <Tabs className="" onSelect={e => props.activeTabAction(e)} defaultActiveKey={props.activeTab}>
                        <Tab eventKey="couponCode" title="Coupon Code"><Coupan /></Tab>
                        <Tab eventKey="giftCard" title="Gift Card"><Gift /></Tab>
                        <Tab eventKey="specialDeals" title="Special Deals" ><SpecialDeals /></Tab>
                    </Tabs>
                </div>

            </div>

        </div>
    )
}


const mapStateToProps = state => {
    let { activeTab } = state.offer
    return {
        activeTab
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    activeTabAction: params => dispatch(activeTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);

