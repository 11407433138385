import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { notification } from 'antd';
import Pagination from 'react-js-pagination';
import moment from 'moment';

import { setBreadcrumbAction } from '../../store/ui';
import { getRewardsService, crateRewardsService } from '../../services/rewardService';
import { CustomNumber } from '../util/FormControls';
import { MainLoader } from '../reuse/SVG';


const Rewards = props => {
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);
    const [count, setCount] = useState('');
    const [page, setPage] = useState(1);
    const [rewardModal, setRewardModal] = useState(false);
    const [rewardPoints, setRewardPoints] = useState('');
    const [rewardPointsErr, setRewardPointsErr] = useState('');
    const [rewardValue, setRewardValue] = useState('');
    const [rewardValueErr, setRewardValueErr] = useState('');


    let params = {
        icon: 'rewards',
        links: [
            { name: 'Rewards', path: '' }
        ]
    }

    const closeModal = () => {
        setRewardModal(false);
    }

    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    const getRewards = () => {
        let query = `?page=${page - 1}&limit=15`;
        getRewardsService(query).then(res => {
            setLoader(false);
            if(res.data.statusCode){
                setList(res.data.responseData.result);
                setCount(res.data.responseData.count);
            }
        })
    }


    useEffect(() => {
        getRewards();
    }, [page]);



    const handleValidate = () => {
        let validate = true;
        
        if(rewardPoints == '' || rewardPoints == null){
            setRewardPointsErr('Reward point is required');
            validate = false
        }

        if(rewardValue == '' || rewardValue == null){
            setRewardValueErr('Reward value is required');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setLoader(true);
            let params = { rewardPoints, rewardValue };
            crateRewardsService(params).then(res => {
                setLoader(false);
                if(res.data.statusCode){
                    notification.open({
                        message: 'Rewards',
                        description: `Reward create successfully`
                    });

                    closeModal();
                    getRewards();
                }
            })
        }
    }


    return (
        <div className="page_wrapper">
            {loader ? <MainLoader /> : '' }
            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Rewards</h4>
                    <button onClick={() => setRewardModal(true)} className="btn btn-primary">Add Rewards</button>
                </div>

                <div className="page_card_body">
                    <div className="table_responsive_wrap mt-4 text-center">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped no_t_texts">
                                <thead>
                                    <tr>
                                        <th><span className="t_text">S.No</span></th>
                                        <th><span className="t_text">Created</span></th>
                                        <th><span className="t_text">Reward Points</span></th>
                                        <th><span className="t_text">Reward Value</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        list.map((item, i) => (
                                            <tr key={item._id} className={item.status == 1 ? '' : 'in__active' }>
                                                <td><span className="t_text">{((i + 1) + ((page - 1) * 10))}</span></td>
                                                <td><span className="t_text">{moment(item.created).format('DD/MM/YYYY')}</span></td>
                                                <td><span className="t_text">{item.rewardPoints}</span></td>
                                                <td><span className="t_text">{item.rewardValue}</span></td>
                                                {/* <td>
                                                    <span className="t_text">
                                                        <i title="View reward" className="icon-eye" />
                                                        <i title="Delete reward" className="icon-delete" />
                                                        <i title="Edit reward" className="icon-pancil" />
                                                        <i title="View reward redeemed" className="icon-mark" />
                                                    </span>
                                                </td> */}
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>

                        </div>
                    </div>

                    

                </div>

                <Pagination
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={count}
                        pageRangeDisplayed={3}
                        onChange={page => (setPage(page), setLoader(true))}/>

            </div>
            




            <Modal
                show={rewardModal}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Add Reward</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Reward Point</label>
                        <CustomNumber
                            change={e => (setRewardPoints(e), setRewardPointsErr(''))}
                            value={rewardPoints}
                            pHolder="Reward Point"
                            error={rewardPointsErr}
                            min={0} />
                    </div>
                    <div className="form-group">
                        <label>Reward Value</label>
                        <CustomNumber
                            change={e => (setRewardValue(e), setRewardValueErr(''))}
                            value={rewardValue}
                            pHolder="Reward Value"
                            error={rewardValueErr}
                            min={0} />
                    </div>

                    <div className="d-flex mt-4 mb-3">
                        <span className="btn btn-light w-100 mr-2">close</span>
                        <button type="submit" className="btn btn-primary w-100 ml-2">Add</button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        state
    };
}

const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
const loop = [1,2,3,4,5]
