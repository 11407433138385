import axios from 'axios';
import ApiInstance from './intercepter';
import API from '../config/api';
import { resHandle } from '../helper';


ApiInstance.defaults.headers.common['accessToken'] = localStorage.getItem('accessToken');
ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;


export const login = params => ApiInstance.post(API.ADMIN_LOGIN, params).then(res => {
    let { status, data } = resHandle(res);
    if(status){
        ApiInstance.defaults.headers.common['accessToken'] = `${data.accessToken}`;
        ApiInstance.defaults.headers.common['Authorization'] = `${API.AUTH}`;
    }
    return res;
});



export const logout = () => ApiInstance.post(API.ADMIN_LOGOUT);

export const forgotPassword = params => ApiInstance.post(API.FORGOT_PASSWORD, params);

export const verifyResetToken = params => ApiInstance.post(API.VERIFY_RESET_TOKEN, params);

export const resetPassword = params => ApiInstance.post(API.RESET_PASSWORD, params);

export const updateProfileService = params => ApiInstance.put(API.ADMIN_UPDATE_PROFILE, params);

export const getAdminProfileService = () => ApiInstance.get(API.ADMIN_GET_PROGILE);

export const changePasswordService = params => ApiInstance.put(API.ADMIN_CHANGE_PASSWORD, params);

export const updateTaxService = params => ApiInstance.post(API.UPDATE_TAX, params);

export const getTaxService = () => ApiInstance.get(API.GET_TAXS);
