import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { notification } from 'antd';

import { setBreadcrumbAction } from '../../store/ui';
import {
    categoryListAction,
    changeCategoryStatusAction,
    categoryModalAction,
    categoryEditModalAction
} from '../../store/category';
import { MiniLoader, Empty, MainLoader } from '../reuse/SVG';


const Categories = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [search, setSearch] = useState('');
    let params = {
        icon: 'fire',
        links: [
            {name: 'Program Category', path: ''}
        ]
    }




    const handleStatusChange = (e, category) => {
        setMiniLoader({[category._id]: true});
        let params = {
            categoryId: category._id,
            status: category.status == 0 ? '1' : '0' 
        }
        props.changeCategoryStatusAction(params).then(status => {
            setMiniLoader('');
            notification.open({
                message: 'Category Status',
                description: `Category status change successfully to  ${category.status == 1 ? 'Active' : 'Inactive'}`
            });
        }); 
    }


    const categoryList = () => {
        let params = { search }
        props.categoryListAction(params);
    }



    const handleSearch = e => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            categoryList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    useEffect(() => {
        props.setBreadcrumbAction(params);
    }, []);


    return (
        <div className="page_wrapper">
            
            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}
                
                <i className="icon-search" />
                <input type="text" onChange={handleSearch} className="form-control" placeholder="Type here to search.." />
            </div>

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Category</h4>
                    <button onClick={() => props.categoryModalAction(0)} className="btn btn-primary"><i className="icon-plus" /> Add Program Category</button>
                </div>

                <div className="page_card_body">
                    <div className="row">
                        {   
                            props.categories.length ?
                            props.categories.map(category => (
                                <div className="col-xl-3 col-lg-4 col-sm-6" key={category._id}>
                                    <div className="card cat_card">
                                    <i title="Copy Category ID" className="icon-copy" onClick={() => navigator.clipboard.writeText(category._id)} />
                                        <div className="cat_card_img"><img src={category.icon} alt="icon" /></div>
                                        <p>{category.name}</p>
                                        <div className="d-flex cat_card_action">
                                            <Link to={`/category/${category._id}`}><i className="icon-eye" style={{color: '#161616'}} /></Link>
                                            <i onClick={() => props.categoryEditModalAction(category)} className="icon-pancil" style={{color: '#842014'}} />
                                            <div className="cm_swich_wrap">
                                                {miniLoader[category._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={!category.status} onChange={e => handleStatusChange(e, category)} /><span /></React.Fragment>}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            ))
                            :

                            <div className="col-12 cm_empty">
                                <Empty />
                                <p>No Data</p>
                            </div>
                        }

                        
                    </div>
                </div>
            </div>
        </div>
    )
}


/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { categories, loader } = state.category;
    return {
        categories,
        loader
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    categoryModalAction: params => dispatch(categoryModalAction(params)),
    categoryListAction: params => dispatch(categoryListAction(params)),
    changeCategoryStatusAction: params => dispatch(changeCategoryStatusAction(params)),
    categoryEditModalAction: params => dispatch(categoryEditModalAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);