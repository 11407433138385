import ApiInstance from './intercepter';
import API from '../config/api';


export const getTremsFeedList = params => ApiInstance.get(API.TERMS_FEED_LIST + params);

export const addTremsFeed = params => ApiInstance.post(API.TERMS_FEED_ADD, params);

export const editTremsFeed = params => ApiInstance.post(API.TERMS_FEED_EDIT, params);

export const changeTremsFeedStatus = params => ApiInstance.post(API.TERMS_FEED_STATUS_CHANGE, params);

export const deleteTermsFeed = params => ApiInstance.post(API.DELETE_TERMS_FEED, params);
