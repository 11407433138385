
import React,  { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty, notification } from 'antd';

import { giftCardModalAction, giftCardListAction, giftViewModalAction, isNewGiftCardAction } from '../../store/offer';
import { giftCardStatusChange, giftDelete } from '../../services/offerService';
import { MiniLoader, MainLoader } from '../reuse/SVG';
import { dateFormat } from '../../helper';
import ConfirmModal from '../modals/ConfirmModal';


const Gift = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');


    const showGiftCardList = () => {
        let params = `?type=1&limit=${limit}&page=${page - 1}&search=${search}`;
        props.giftCardListAction(params)
        props.isNewGiftCardAction();
    };


    const changeStatus = giftCard => {
        setMiniLoader({[giftCard._id]: true});

        let params = {
            giftcardId: giftCard._id,
            status: giftCard.status == 0 ? '1' : '0' 
        }

        giftCardStatusChange(params).then(status => {
            setMiniLoader('');
            showGiftCardList();
            notification.open({
                message: 'Gift Card Status',
                description: `Gift card status change successfully to  ${giftCard.status == 0 ? 'Active' : 'Inactive'}`
            });
        }); 
    }


    const handleConfirmModal = currentId => {
        setCurrentId(currentId);
        setConfirmModal(!confirmModal);
    }


    const handleDelete = () => {
        let params = { giftcardId: currentId }
        giftDelete(params).then(status => {
            setCurrentId('');
            setConfirmModal(false);
            showGiftCardList();
            
        }); 
    }





    useEffect(() => {
        showGiftCardList();
    }, [page])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            showGiftCardList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])


    useEffect(() => {
        if(props.isNewGiftCard){
            showGiftCardList();
        }
    
    }, [props.isNewGiftCard])

    return (
        <Fragment>
            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}
                <i className="icon-search" />
                <input
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    className="form-control"
                    placeholder="Type here to search.." />
            </div>


        <div className="child_el">

            <div className="btn_wrapper">
                <button onClick={ props.giftCardModalAction } className="btn btn-primary">Add Gift Card</button>
            </div>

            <div className="table_responsive_wrap mt-4 text-center">
                <div className="table-responsive">
    
                    <table className="table table-bordered table-striped no_t_texts">

                        <thead>
                            <tr>
                                <th><span className="t_text">S.No</span></th>
                                <th><span className="t_text">Gift Card</span></th>
                                <th><span className="t_text">Entry Date</span></th>
                                <th><span className="t_text">Value</span></th>
                                <th><span className="t_text">Number of Purchases</span></th>
                                <th><span className="t_text">Action</span></th>
                            </tr>
                        </thead>

                        <tbody>
                        {props.giftCardList.map((gift, i) => (
                            <tr key={gift._id}>
                                <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                                <td><span className="t_text">{gift.giftcardName}</span></td>
                                <td><span className="t_text">{dateFormat(gift.fromPurchaseDate)}</span></td>
                                <td><span className="t_text">${gift.giftcardValue}</span></td>
                                <td><span className="t_text">{gift.noOfTimesUsed ? gift.noOfTimesUsed : 0}</span></td>
                        
                                <td><span className="t_text">
                                        <i className="icon-eye" onClick={() => props.giftViewModalAction(gift)} />
                                        <i className="icon-delete" onClick={() => handleConfirmModal(gift._id)} />
                                        <div className="cm_swich_wrap">
                                            {miniLoader[gift._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={gift.status} onChange={() => changeStatus(gift)} /><span /></React.Fragment>}
                                        </div>
                                    </span>
                                </td>        
                            </tr>
                            ))
                        }
                        </tbody>
                    </table>

                </div>

                {
                    props.giftCardList.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                }

            </div>
            </div>

            {
                props.giftCardList.length ? <div className="mt-4">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={props.giftCardCount}
                        pageRangeDisplayed={3}
                        onChange={page => setPage(page)}/>
                </div> : ''
            }



            <ConfirmModal
                show={confirmModal}
                hideModal={() => setConfirmModal(false)}
                msg="Are you sure you want to delete?"
                action={handleDelete} />


        </Fragment>
    )
}
/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { giftCardList, giftCardCount, isNewGiftCard } = state.offer;
    return {
        giftCardList,
        giftCardCount,
        isNewGiftCard
        
    };
}
  
const mapDispatchToProps = dispatch => ({
    giftCardModalAction: () => dispatch(giftCardModalAction()),
    giftCardListAction: params => dispatch(giftCardListAction(params)),
    giftViewModalAction: params => dispatch(giftViewModalAction(params)),
    isNewGiftCardAction: () => dispatch(isNewGiftCardAction()),
   
});

export default connect(mapStateToProps, mapDispatchToProps)(Gift);
